import { useEffect, useContext } from "react";
import DWQnotifications from "../components/notifications/DWQnotifications";
import SWnotifications from "../components/notifications/SWnotifications";
import LAnotifications from "../components/notifications/LAnotifications";
import { AccountContext } from "../components/Account";
import GroupError from "../components/GroupError";

// Get The List fo Notifications
const NotifTest = () => {
  const { permissions, userGroup, getUserGroups } = useContext(AccountContext);

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
  }, [userGroup]);

  return (
    <div className="page_wrapper">
      {permissions.dwqPermissions.includes(userGroup) ? (
        <DWQnotifications />
      ) : (
        ""
      )}

      {permissions.swPermissions.includes(userGroup) ? <SWnotifications /> : ""}

      {permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        <LAnotifications />
      ) : (
        ""
      )}

      {permissions.dwqPermissions.includes(userGroup) ||
      permissions.swPermissions.includes(userGroup) ||
      permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        ""
      ) : (
        <GroupError />
      )}
    </div>
  );
};

export default NotifTest;
