import { useRef } from "react";
import Loader from "../Loader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import useOutsideClick from "../../utils/useOutsideClick";

const SaveModal = ({
  setDisplayModal,
  setErrorGetAssessment,
  statusCode,
  setStatusCode,
  loader,
}) => {
  const modal = useRef();

  function handleCancel() {
    setDisplayModal(false);
    setErrorGetAssessment(null);
    setStatusCode(null);
  }

  useOutsideClick(
    modal,
    () => {
      handleCancel();
    },
    loader
  );

  return (
    <div className="assessmentSaveContainer">
      <div className="regionArea timeoutChild" ref={modal}>
        <div className="assetHeader">
          {statusCode === 200 ? (
            <>
              <h1>Assessment saved</h1>
              <button
                title="Close"
                aria-label="Close"
                className="iconButton"
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                }}
              >
                <CloseIcon onClick={() => handleCancel()} />
              </button>
            </>
          ) : (
            <>Saving Assessment...</>
          )}
        </div>

        {loader ? (
          <Loader height={"150px"} />
        ) : (
          <div className="regionBody">
            <p>Your assessment details have been saved</p>
            <CheckCircleOutlineIcon
              style={{ color: "#5eb135", fontSize: "5rem" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SaveModal;
