const Prompt = ({ setDeleteUserPrompt, handleDelete }) => {
  return (
    <div className="timeoutContainer">
      <div className="regionArea timeoutChild">
        <div className="regionHeader">Delete User?</div>

        <div className="regionBody">
          <p>Are you sure you wish to delete this user?</p>

          <div className="modalButtons">
            <button onClick={() => setDeleteUserPrompt(false)}>Cancel</button>
            <button onClick={(e) => handleDelete(e)}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
