import { useHistory } from "react-router-dom";

const Callback = (props) => {
  const history = useHistory();

  let findTokenId;

  function redirectTo() {
    if (
      props.location.pathname.includes("&") &&
      props.location.pathname.includes("id_token")
    ) {
      findTokenId = props.location.pathname.split("&");
      const match = findTokenId.find((item) => {
        if (item.includes("id_token")) {
          return true;
        }
      });

      const jwt = match.split("id_token")[1].split("=")[1];
      if (jwt) {
        localStorage.setItem("jwt", jwt);
      }

      return history.push(`/home`);
    } else {
      return history.push(`/not-found`);
    }
  }

  return <>{redirectTo()}</>;
};

export default Callback;
