import SingleNotification from "./SingleNotification";

const NotificationSidebar = ({ data }) => {
  return (
    <div className="notifsidebar__wrapper">
      {data.length !== 0 ? (
        data.map((element) => {
          return (
            <SingleNotification
              key={element._id}
              id={element._id}
              message={element.Message}
              date={element.Date}
              category={element.Category}
            />
          );
        })
      ) : (
        <SingleNotification message={"No unread notifications"} category={""} />
      )}
    </div>
  );
};

export default NotificationSidebar;
