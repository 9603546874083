import React, { useState, useContext, useEffect } from "react";
import Loader from "../components/Loader";
import { Col, Row } from "react-bootstrap";
import EventTable from "../components/EventTable";
import { AccountContext } from "../components/Account";
import { ParameterContext } from "../components/ParametersProvider";
import { CallBackend } from "../utils/apiCall.js";
import SearchIcon from "../assets/img/search.svg";
import { handleChange } from "../utils/helpers";
import moment from "moment";

// This Component is the main page for E&I - Display Table and Search Through
const EventIncidents = () => {
  const { configCredentials, userGroup, permissions, refreshUser } =
    useContext(AccountContext);

  const { parameters } = useContext(ParameterContext);

  // State for API Queries
  const [assignee, setAssignee] = useState("");
  const [sitename, setSiteName] = useState("");
  const [category, setCategory] = useState("");
  const [area, setArea] = useState("");
  const [status, setStatus] = useState("Open");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [eventnumber, setEventNumber] = useState("");
  const [search, setSearch] = useState("");

  // State for spinner
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getData();
  }, [
    area,
    assignee,
    category,
    fromDate,
    toDate,
    eventnumber,
    status,
    sitename,
    configCredentials,
    pageNumber,
  ]);

  const getData = async () => {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
        area: area,
        assignee: assignee,
        status: status,
        from_date: fromDate,
        to_date: toDate,
        category: category,
        search: search,
      },
    };

    await refreshUser().then((result) => {
      if (fromDate <= moment().format("YYYY-MM-DD") && toDate === "") {
        CallBackend(body, "event_search", "GET", result, additionalParams)
          .then((response) => {
            setSpinner(false);
            setData(response);
            setTotalPages(response[1].total_pages);
          })
          .catch((error) => {
            setError(error);
            setSpinner(false);
          });
      } else if (
        fromDate <= moment().format("YYYY-MM-DD") &&
        toDate !== "" &&
        toDate >= fromDate
      ) {
        CallBackend(body, "event_search", "GET", result, additionalParams)
          .then((response) => {
            setSpinner(false);
            setData(response);
            setTotalPages(response[1].total_pages);
          })
          .catch((error) => {
            setError(error);
            setSpinner(false);
          });
      } else {
        setSpinner(false);
      }
    });
  };

  return (
    <>
      {permissions.dwqPermissions.includes(userGroup) ? (
        <div className="page_wrapper">
          <div className="regionArea">
            <div className="regionHeader">
              <h1>Event &amp; Incidents</h1>
            </div>

            <div className="regionBody">
              <Row className="eventincidents_search">
                <Col>
                  <>
                    <label className="ds_label ov_label" htmlFor="assignee">
                      Assignee
                    </label>

                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        name="assignee"
                        id="assignee"
                        value={assignee}
                        onChange={(e) => (
                          handleChange(e, setAssignee), setPageNumber(1)
                        )}
                      >
                        <option value="">View all Assignees</option>
                        {parameters &&
                          parameters.assessors.map((user, index) => {
                            return (
                              <option value={user.name} key={index}>
                                {user.name}
                              </option>
                            );
                          })}
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </>
                </Col>

                <Col>
                  <label className="ds_label ov_label" htmlFor="category">
                    Category
                  </label>
                  <div
                    className="ds_select-wrapper"
                    value={category}
                    onChange={(e) => (
                      handleChange(e, setCategory), setPageNumber(1)
                    )}
                  >
                    <select className="ds_select" name="category" id="category">
                      <option value="">View All</option>
                      <option value="Event">View Events Only</option>
                      <option value="Incident">View Incidents Only</option>
                      <option value="Potential Incident">
                        View Potential Incidents
                      </option>
                    </select>
                    <span className="ds_select-arrow" aria-hidden="true"></span>
                  </div>
                </Col>

                <Col>
                  <label className="ds_label ov_label" htmlFor="area">
                    Area
                  </label>
                  <div className="ds_select-wrapper">
                    <select
                      className="ds_select"
                      name="area"
                      id="area"
                      value={area}
                      onChange={(e) => (
                        handleChange(e, setArea), setPageNumber(1)
                      )}
                    >
                      <option value="">View All Areas</option>
                      <option value="NORTH">North</option>
                      <option value="SOUTH">South</option>
                      <option value="EAST">East</option>
                      <option value="WEST">West</option>
                    </select>
                    <span className="ds_select-arrow" aria-hidden="true"></span>
                  </div>
                </Col>
                <Col>
                  <label className="ds_label ov_label" htmlFor="status">
                    Status
                  </label>
                  <div className="ds_select-wrapper">
                    <select
                      className="ds_select"
                      name="status"
                      id="status"
                      value={status}
                      onChange={(e) => (
                        handleChange(e, setStatus), setPageNumber(1)
                      )}
                    >
                      <option value="">View All Status</option>
                      <option value="Open">Open</option>
                      <option value="Closed">Closed</option>
                    </select>
                    <span className="ds_select-arrow" aria-hidden="true"></span>
                  </div>
                </Col>
              </Row>

              <Row className="eventincidents_search">
                <Col>
                  <div data-module="ds-datepicker-from">
                    <label className="ds_label ov_label" htmlFor="start-date">
                      From date
                    </label>
                    <div className="ds_select-wrapper">
                      <input
                        name="start-date"
                        id="start-date"
                        value={fromDate}
                        onChange={(e) => (
                          handleChange(e, setFromDate), setPageNumber(1)
                        )}
                        data-dateformat="dd/mm/yyyy"
                        placeholder="dd/mm/yyyy"
                        type="date"
                        className="ds_select"
                      />
                      <span className="ds_select-calendar"></span>
                    </div>
                    {fromDate > moment().format("YYYY-MM-DD") && (
                      <div style={{ marginTop: "1rem" }}>
                        <span style={{ color: "red" }}>
                          <strong>The date cannot be more than today</strong>
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col>
                  <div data-module="ds-datepicker-to">
                    <label className="ds_label ov_label" htmlFor="end-date">
                      To date
                    </label>
                    <div className="ds_select-wrapper">
                      <input
                        name="end-date"
                        id="end-date"
                        value={toDate}
                        onChange={(e) => (
                          handleChange(e, setToDate), setPageNumber(1)
                        )}
                        data-dateformat="dd/mm/yyyy"
                        placeholder="dd/mm/yyyy"
                        type="date"
                        className="ds_select"
                      />
                      <span className="ds_select-calendar"></span>
                    </div>
                    {toDate !== "" && toDate < fromDate && (
                      <div style={{ marginTop: "1rem" }}>
                        <span style={{ color: "red" }}>
                          <strong>
                            The date cannot be less than "From Date"
                          </strong>
                        </span>
                      </div>
                    )}
                  </div>
                </Col>

                <Col xs={6}>
                  <label className="ov_label remove" htmlFor="search">
                    Search
                  </label>
                  <div className="ds_select-wrapper">
                    <input
                      className="ds_input "
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? (setPageNumber(1), getData()) : ""
                      }
                    />
                    <button
                      className="ds_select-search"
                      onClick={(e) => (setPageNumber(1), getData())}
                      aria-label="search"
                    >
                      <img
                        className="ds_searchIcon"
                        src={SearchIcon}
                        alt="search icon"
                      />
                    </button>

                    <span className="visually-hidden">Search</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {error && error.response !== undefined ? (
            error.response.status >= 400 && error.response.status <= 599 ? (
              <div className="regionArea">
                <div className="regionHeader">Error</div>

                <div className="regionBody">
                  <h2>
                    There has been a problem with the system, if this problem
                    persists please contact DWQ.
                  </h2>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {spinner ? <Loader height={"192px"} /> : ""}

          <div>
            <Row className="eventincidents_search">
              <div>
                {data && !spinner ? (
                  <EventTable
                    data={data}
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    toDate={toDate}
                  />
                ) : (
                  ""
                )}
              </div>
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EventIncidents;
