import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AccountContext } from "../components/Account";

import AssetView from "../components/assetCase/AssetView";
import AssetInformation from "../components/assetCase/AssetInformation";
import AssociatedEI from "../components/assetCase/AssociatedEI";
import SampleFailures from "../components/assetCase/SampleFailures";
import SamplingRequirements from "../components/assetCase/SamplingRequirements";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AppsIcon from "@mui/icons-material/Apps";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PrintIcon from "@mui/icons-material/Print";

const AssetCase = (props) => {
  const { isUserActive } = useContext(AccountContext);

  const currentParams = useParams();

  const [pageFormatOne, setPageFormatOne] = useState(false);
  const [pageFormatTwo, setPageFormatTwo] = useState(false);

  const [maxHeightOne, setMaxHeightOne] = useState(false);
  const [maxHeightTwo, setMaxHeightTwo] = useState(false);
  const [maxHeightThree, setMaxHeightThree] = useState(false);
  const [maxHeightFour, setMaxHeightFour] = useState(false);

  const [view, setView] = useState(false);
  const [expandView, setExpandView] = useState(true);

  const [parent, setParent] = useState(false);
  const [child, setChild] = useState(false);
  const [parentChild, setParentChild] = useState(true);
  const [all, setAll] = useState(false);

  const handleClickOne = () => {
    maxHeightOne === false && maxHeightTwo === false && pageFormatOne === false
      ? (setPageFormatOne(!pageFormatOne), setMaxHeightOne(!maxHeightOne))
      : maxHeightOne === true &&
        maxHeightTwo === false &&
        pageFormatOne === true
      ? (setPageFormatOne(!pageFormatOne), setMaxHeightOne(!maxHeightOne))
      : maxHeightOne === false &&
        maxHeightTwo === true &&
        pageFormatOne === true
      ? setMaxHeightOne(!maxHeightOne)
      : maxHeightOne === true && maxHeightTwo === true && pageFormatOne === true
      ? setMaxHeightOne(!maxHeightOne)
      : setPageFormatOne(!pageFormatOne);
  };

  const handleClickTwo = () => {
    maxHeightTwo === false && maxHeightOne === false && pageFormatOne === false
      ? (setPageFormatOne(!pageFormatOne), setMaxHeightTwo(!maxHeightTwo))
      : maxHeightTwo === true &&
        maxHeightOne === false &&
        pageFormatOne === true
      ? (setPageFormatOne(!pageFormatOne), setMaxHeightTwo(!maxHeightTwo))
      : maxHeightTwo === false &&
        maxHeightOne === true &&
        pageFormatOne === true
      ? setMaxHeightTwo(!maxHeightTwo)
      : maxHeightTwo === true && maxHeightOne === true && pageFormatOne === true
      ? setMaxHeightTwo(!maxHeightTwo)
      : setPageFormatOne(!pageFormatOne);
  };

  const handleClickThree = () => {
    maxHeightThree === false &&
    maxHeightFour === false &&
    pageFormatTwo === false
      ? (setPageFormatTwo(!pageFormatTwo), setMaxHeightThree(!maxHeightThree))
      : maxHeightThree === true &&
        maxHeightFour === false &&
        pageFormatTwo === true
      ? (setPageFormatTwo(!pageFormatTwo), setMaxHeightThree(!maxHeightThree))
      : maxHeightThree === false &&
        maxHeightFour === true &&
        pageFormatTwo === true
      ? setMaxHeightThree(!maxHeightThree)
      : maxHeightThree === true &&
        maxHeightFour === true &&
        pageFormatTwo === true
      ? setMaxHeightThree(!maxHeightThree)
      : setPageFormatTwo(!pageFormatTwo);
  };

  const handleClickFour = () => {
    maxHeightFour === false &&
    maxHeightThree === false &&
    pageFormatTwo === false
      ? (setPageFormatTwo(!pageFormatTwo), setMaxHeightFour(!maxHeightFour))
      : maxHeightFour === true &&
        maxHeightThree === false &&
        pageFormatTwo === true
      ? (setPageFormatTwo(!pageFormatTwo), setMaxHeightFour(!maxHeightFour))
      : maxHeightFour === false &&
        maxHeightThree === true &&
        pageFormatTwo === true
      ? setMaxHeightFour(!maxHeightFour)
      : maxHeightFour === true &&
        maxHeightThree === true &&
        pageFormatTwo === true
      ? setMaxHeightFour(!maxHeightFour)
      : setPageFormatTwo(!pageFormatTwo);
  };

  const expandSections = async () => {
    return await Promise.all([
      setPageFormatOne(true),
      setPageFormatTwo(true),
      setMaxHeightOne(true),
      setMaxHeightTwo(true),
      setMaxHeightThree(true),
      setMaxHeightFour(true),
      setView(true),
    ]).then(() => {
      window.print();
    });
  };

  const expandViewSection = () => {
    setExpandView(!expandView);
  };

  return (
    <div className="page_wrapper">
      <Row>
        <Col>
          <div className="regionArea">
            <div className="assetHeader">
              <h1>{currentParams.case} Asset View</h1>
              <span>
                <button
                  aria-label="parents"
                  title="parents"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setParent(true);
                    setChild(false);
                    setParentChild(false);
                    setAll(false);
                  }}
                >
                  <ArrowRightAltIcon
                    style={{
                      transform: "rotate(180deg)",
                      color: parent === true ? "grey" : "white",
                    }}
                  />
                </button>

                <button
                  aria-label="children"
                  title="children"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setParent(false);
                    setChild(true);
                    setParentChild(false);
                    setAll(false);
                  }}
                >
                  <ArrowRightAltIcon
                    style={{
                      color: child === true ? "grey" : "white",
                    }}
                  />
                </button>
                <button
                  aria-label="parents and children"
                  title="parents and children"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setParent(false);
                    setChild(false);
                    setParentChild(true);
                    setAll(false);
                  }}
                >
                  <CompareArrowsIcon
                    style={{
                      color: parentChild === true ? "grey" : "white",
                    }}
                  />
                </button>

                <button
                  aria-label="View all parents and children"
                  title="View all parents and children"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setParent(false),
                      setChild(false),
                      setParentChild(false),
                      setAll(true);
                  }}
                >
                  <AppsIcon
                    style={{
                      color: all === true ? "grey" : "white",
                    }}
                  />
                </button>

                <button
                  aria-label="Print page"
                  title="Print page"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    expandSections();
                  }}
                >
                  <PrintIcon />
                </button>

                <button
                  aria-label="Expand and constrict section"
                  title="Expand and constrict section "
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    expandViewSection();
                  }}
                >
                  <OpenInFullIcon
                    style={{
                      color: expandView === false ? "grey" : "white",
                    }}
                  />
                </button>
              </span>
            </div>

            <Row>
              <Col>
                {currentParams.case ? (
                  <AssetView
                    currentParams={currentParams.case}
                    parent={parent}
                    child={child}
                    parentChild={parentChild}
                    all={all}
                    expandView={expandView}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {currentParams.case ? (
        <AssetInformation
          currentParams={currentParams.case}
          setView={setView}
          view={view}
        />
      ) : (
        ""
      )}

      <Row style={{ flexDirection: pageFormatOne ? "column" : "row" }}>
        <Col sm={pageFormatOne ? 12 : 6}>
          <div className="regioncomponent" id="associatedEI">
            <div className="assetHeader">
              <h2>{currentParams.case} Associated E&amp;I</h2>
              {maxHeightOne ? (
                <button
                  title="Expand Associated EI section"
                  aria-label="Expand Associated EI section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickOne();
                  }}
                >
                  <KeyboardArrowUpIcon />
                </button>
              ) : (
                <button
                  title="Constrict Associated EI section"
                  aria-label="Constrict Associated EI section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickOne();
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
            </div>

            <div
              className="regionContainer"
              style={{ maxHeight: maxHeightOne ? "100%" : "191px" }}
            >
              {currentParams.case ? (
                <AssociatedEI currentParams={currentParams.case} />
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>

        <Col sm={pageFormatOne ? 12 : 6}>
          <div className="regioncomponent" id="sampleFailures">
            <div className="assetHeader">
              <h2>{currentParams.case} Sample Failures</h2>
              {maxHeightTwo ? (
                <button
                  title="Expand Sample Failures section"
                  aria-label="Expand Sample Failures section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickTwo();
                  }}
                >
                  <KeyboardArrowUpIcon />
                </button>
              ) : (
                <button
                  title="Constrict Sample Failures section"
                  aria-label="Constrict Sample Failures section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickTwo();
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
            </div>

            <div
              className="regionContainer"
              style={{ maxHeight: maxHeightTwo ? "100%" : "191px" }}
            >
              {currentParams.case ? (
                <SampleFailures currentParams={currentParams.case} />
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ flexDirection: pageFormatTwo ? "column" : "row" }}>
        <Col sm={pageFormatTwo ? 12 : 6}>
          <div className="regioncomponent" id="samplingRequirements">
            <div className="assetHeader">
              <h2>{currentParams.case} Sampling Requirements</h2>
              {maxHeightThree ? (
                <button
                  title="Expand Sampling Requirements section"
                  aria-label="Expand Sampling Requirements section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickThree();
                  }}
                >
                  <KeyboardArrowUpIcon />
                </button>
              ) : (
                <button
                  title="Constrict Sampling Requirements section"
                  aria-label="Constrict Sampling Requirements section"
                  className="iconButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickThree();
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
            </div>

            <div
              className="regionContainer"
              style={{ maxHeight: maxHeightThree ? "100%" : "191px" }}
            >
              <SamplingRequirements currentParams={currentParams.case} />
            </div>
          </div>
        </Col>
        <Col sm={pageFormatTwo ? 12 : 6}></Col>
      </Row>
    </div>
  );
};

export default AssetCase;
