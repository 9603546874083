const SupplySamplesHelp = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Supply Samples Help</h1>
        </div>
        <div className="regionBody">
          Below are a list of all fields on the "Supply Samples" sheet in the
          data return explaining the required input, mandatory status and
          addtional description to help complete the data return. If you require
          further assistance please email{" "}
          <a href="mailto:regulator@dwqr.scot" className="ds_link">
            regulator@dwqr.scot
          </a>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SiteRef</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>
                  Mandatory (SiteRef needs to exist in Supply Details table)
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Supply Site Reference. Please see the note in the Site Details
                  table.
                  <br />
                  <br />
                  This is the field that connects the supply details table with
                  the sample data table. Please ensure that a system is named
                  consistently across the two (i.e. no spaces/ extra
                  characters).
                  <br />
                  <br />
                  Any PWS system that has been sampled needs to have a unique
                  record with the system information in the supply details
                  table.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">UPRN</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  The Unique Property Reference Number for the specific property
                  from which the sample was taken
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">ParameterCode</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  "State the parameter code for the sample taken. These are
                  listed in ""Parameter Codes"" worksheet in the Data Return
                  Template. The codes needs to be exactly as provided in the
                  parameter codes look up table. Codes with extra characters/
                  spaces will not be possible to be processed."{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SampleNumber</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  The number of the sample on the Local Authority's system
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SampleResult</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>
                  Number for all parameter codes but for taste and odour and lab
                  errors
                </td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  State the result for individual sample, ensuring that the
                  units of measurement are the same as those specified in The
                  2017 Regulations.
                  <br />
                  <br />
                  Ensure that this is a numeric field (i.e. do not include the
                  units or the &gt; or &lt; indicators).
                  <br />
                  <br />
                  The only parameters where text is allowed in this filed are
                  <br />
                  &nbsp;&nbsp;&nbsp;-the qualitative taste and the qualitative
                  odour. If samples were taken for taste and odour but could not
                  be analysed because they failed microbiological parameters
                  (Microbiological Failure), please use code <b>MF</b>
                  <br />
                  &nbsp;&nbsp;&nbsp;-the results of parameters for which samples
                  were taken, but results were not provided due to lab error.
                  For these samples, please use code <b>LE</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">&gt; or &lt; qualifier</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text from predefined list (&gt; or &lt;)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Only use if the sample result is greater than or less than
                  Limit of Detection. This will have been indicated on the
                  sample report the council received from the laboratory.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SampleDate</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Date</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Use this field to state the date the sample was taken.
                  (DD/MM/YYYY)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SampleReasonFlag</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This field should be used to indicate the reason for the
                  sample. Choose amongst the following:
                  <br />
                  <br />
                  - SR: Scheduled Regulatory
                  <br />
                  - IS: Investigation Sample
                  <br />
                  - GS: Grant follow up sample
                  <br />
                  - RS: Resample
                  <br />
                  - OT: Other
                  <br />
                  <br />
                  <br />
                  For Type B supplies any samples taken in accordance with Part
                  VIII of the 2006 Regulations should also be flagged ‘SR’.
                  <br />
                  For samples serving multiple purposes, list all of them and
                  separate them by a ""/"" (i.e. RS/IS)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">PCVFailFlag</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Use this field to indicate if the PCV standard or
                  specification has been exceeded. If this is the case, the
                  sample has failed. Use letter F for samples that have failed.
                  <br />
                  Leave blank if not applicable
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">CustFitFlag</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  If the failure has clearly been caused by customer fittings
                  (i.e. the domestic distribution system). Use letters CT
                  <br />
                  If not applicble, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Comments</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>
                  Mandatory for PCV fails or for parameters with the code
                  "OTHER"
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This field can be used for general comments. For example, it
                  can be used to comment on individual PCV failures. This should
                  include the reason attributed to the failure following
                  investigations and the proposed remedial action together with
                  timescales.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SupplySamplesHelp;
