import { useEffect } from "react";

const useOutsideClick = (ref, callback, loader) => {
  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    if (loader === false) {
      document.addEventListener("click", handleClick, true);
    }

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref, loader]);

  return ref;
};

export default useOutsideClick;
