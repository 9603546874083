import { NavLink } from "react-router-dom";
import { logout } from "../utils";

const TimeoutAlert = () => {
  return (
    <div className="timeoutContainer">
      <div className="regionArea timeoutChild">
        <div className="regionHeader">Session Expired</div>

        <div className="regionBody">
          <p>Your session has expired, please click the button below.</p>
          <NavLink to={"/login/type"} onClick={() => logout()}>
            <button>Login</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TimeoutAlert;
