import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessMessage = (props) => {
  return (
    <div className="regionArea" id="successMessage">
      <div
        className="assetHeader"
        style={{ backgroundColor: "#428542", borderColor: "#428542" }}
      >
        {props.heading}
        <span>
          <CheckCircleIcon style={{ color: "#fff" }} />
        </span>
      </div>

      <div className="regionBody" style={{ borderColor: "#428542" }}>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default SuccessMessage;
