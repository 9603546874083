import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

const SuccessMessage = (props) => {
  return (
    <div className="regionArea" id="successMessage">
      <div
        className="assetHeader"
        style={{ backgroundColor: "#428542", borderColor: "#428542" }}
      >
        Communication sent successfully.
        <span>
          <CheckCircleIcon style={{ color: "#fff" }} />
        </span>
      </div>

      <div className="regionBody" style={{ borderColor: "#428542" }}>
        <p>
          {props.selectedTemplateTitle} has been sent successfully,{" "}
          {moment().format("HH:mm A DD/MM/YY")}.
        </p>
      </div>
    </div>
  );
};

export default SuccessMessage;
