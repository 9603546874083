import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CalltoActionButton from "./CalltoActionButton";

const HazardManagement = ({
  hazards,
  newHazards,
  setNewHazards,
  failureElements,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionIndex, setSelectedOptionIndex] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [newHazardValue, setNewHazardValue] = useState("");

  const [validationMessage, setValidationMessage] = useState(null);

  // EDITING A HAZARDS FAILURE ELEMENTS

  function handleHazardState(e) {
    setSelectedOption(e.target.value);
    setSelectedOptionIndex(
      e.target[e.target.selectedIndex].getAttribute("data-index")
    );

    setValidationMessage(null);

    if (e.target.value !== "" && e.target.value !== "Add New Hazard") {
      const associatedFailureElements = hazards.filter((item) => {
        return item.hazard === e.target.value;
      });

      const newArray = [...failureElements].map((item) => ({
        ...item,
        checked: false,
      }));

      newArray.forEach((item) => {
        const match = associatedFailureElements[0].failure_elements.find(
          (el) => el.failure_element === item.failure_element
        );
        item.checked = match ? true : false;
      });

      setCheckedState(newArray);
    }
  }

  function handleOnChange(position, checkValue) {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        item.checked = checkValue;
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
  }

  function updateHazardFailureElements() {
    // gets the failure elements that are true in checked state
    const filterTrueValues = [...checkedState]
      .filter(({ checked }) => checked === true)
      .map(({ failure_element }) => ({ failure_element }));

    // iterates through newHazards array and if it matches the selected index
    // and sets the object of failure elements to filterTrueValues
    const settingNewFailureElements = newHazards.map((item, index) => {
      if (index == selectedOptionIndex) {
        item.failure_elements = filterTrueValues;
      }
      return item;
    });

    const sortFailureElements = settingNewFailureElements.sort((a, b) =>
      a.hazard.toLowerCase() < b.hazard.toLowerCase() ? -1 : 1
    );

    setNewHazards(sortFailureElements);
  }

  // CREATING A NEW HAZARD

  function createNewHazard(e) {
    setValidationMessage(null);
    setNewHazardValue(e.target.value);
  }

  function setValidationState() {
    const hasDuplicate = newHazards.filter((item) => {
      return item.hazard.toLowerCase() === newHazardValue.toLowerCase();
    });

    newHazardValue !== "" && hasDuplicate.length > 0
      ? setValidationMessage(
          "This hazard already exists, please update the below value."
        )
      : setValidationMessage(null);
  }

  async function updateHazards() {
    const createNewHazards = [
      ...newHazards,
      {
        hazard: newHazardValue,
        failure_elements: [],
      },
    ];

    const sortNewHazards = createNewHazards.sort((a, b) =>
      a.hazard.toLowerCase() < b.hazard.toLowerCase() ? -1 : 1
    );

    return setNewHazards(sortNewHazards);
  }

  // DELETE A HAZARD

  function deleteHazard() {
    const filterTrueValues = [...checkedState]
      .filter(({ checked }) => checked === true)
      .map(({ failure_element }) => ({ failure_element }));

    if (filterTrueValues.length > 0) {
      setValidationMessage(
        "You must disassociate all failure elements from this hazard before deleting!"
      );
    } else {
      setValidationMessage(null);
      const filteredHazards = newHazards.filter(
        (item) => item.hazard !== selectedOption
      );
      setNewHazards(filteredHazards);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  function resetState() {
    setSelectedOption("");
    setValidationMessage(null);
    setCheckedState([]);
    setSelectedOptionIndex("");
    setNewHazardValue("");
  }

  useEffect(() => {
    resetState();
  }, [newHazards, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label htmlFor="hazard" className="ds_label">
            Hazard Relationship Management
          </label>
          <div className="ds_select-wrapper">
            <select
              name="hazard"
              id="hazard"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => handleHazardState(e)}
            >
              <option value="">Select</option>
              {hazards
                ? hazards.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item.hazard}
                        data-index={index}
                      >
                        {item.hazard}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Hazard">Add New Hazard</option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {selectedOption && selectedOption !== "Add New Hazard" ? (
        <Row className="root_cause">
          <Col sm={6} style={{ display: "flex", flexDirection: "column" }}>
            {checkedState
              ? checkedState.map((item, index) => {
                  return (
                    <div
                      className="ds_checkbox  ds_checkbox--small"
                      key={index}
                    >
                      <input
                        className="ds_checkbox__input"
                        id={`hazard-checkbox-${index}`}
                        type="checkbox"
                        checked={item.checked}
                        name={item.failure_element}
                        value={item.failure_element}
                        onChange={(e) =>
                          handleOnChange(index, e.target.checked)
                        }
                      />
                      <label
                        name={item.failure_element}
                        id={`${index}-checkbox-hazard`}
                        htmlFor={`hazard-checkbox-${index}`}
                        className="ds_checkbox__label"
                      >
                        {item.failure_element}
                      </label>
                    </div>
                  );
                })
              : ""}
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Hazard" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newHazard">
              New Hazard Name
            </label>
            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}
              <input
                type="text"
                id="newHazard"
                className="ds_input"
                value={newHazardValue}
                onChange={(e) => createNewHazard(e)}
                onBlur={() => setValidationState()}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption !== "" && selectedOption !== "Add New Hazard" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="validation">
              <span>{validationMessage}</span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="root_cause">
        <Col sm={6}>
          <>
            {selectedOption !== "" && selectedOption !== "Add New Hazard" ? (
              <div className="multiple_button_container">
                <CalltoActionButton
                  submitFunction={deleteHazard}
                  name="Delete"
                />

                <CalltoActionButton
                  submitFunction={updateHazardFailureElements}
                  name="Save"
                />
              </div>
            ) : selectedOption !== "" &&
              selectedOption === "Add New Hazard" &&
              newHazardValue !== "" &&
              validationMessage === null ? (
              <div className="button_container">
                <CalltoActionButton
                  submitFunction={updateHazards}
                  name="Save"
                />
              </div>
            ) : (
              ""
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

export default HazardManagement;
