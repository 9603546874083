export const allGroups = [
  {
    name: "DWQ Admin",
    value: "DWQAdminPoolGroup",
  },
  {
    name: "DWQ User",
    value: "DWQUserPoolGroup",
  },
  {
    name: "Scottish Water Events",
    value: "SWEIUserPoolGroup",
  },
  {
    name: "Scottish Water Reg",
    value: "SWRegUserPoolGroup",
  },
  {
    name: "Aberdeen City",
    value: "ABC",
  },
  {
    name: "Aberdeenshire",
    value: "ABS",
  },
  {
    name: "Angus",
    value: "ANG",
  },
  {
    name: "Argyll and Bute",
    value: "AAB",
  },
  {
    name: "Clackmannanshire",
    value: "CLK",
  },
  {
    name: "Dumfries and Galloway",
    value: "DAG",
  },
  {
    name: "Dundee City",
    value: "DUN",
  },
  {
    name: "East Ayrshire",
    value: "EAY",
  },
  {
    name: "East Dunbartonshire",
    value: "EDB",
  },
  {
    name: "East Lothian",
    value: "ELO",
  },
  {
    name: "East Renfrewshire",
    value: "ERW",
  },
  {
    name: "City of Edinburgh",
    value: "EDH",
  },
  {
    name: "Comhairle nan Eilean Siar",
    value: "EIS",
  },
  {
    name: "Falkirk",
    value: "FKK",
  },
  {
    name: "Fife",
    value: "FFE",
  },
  {
    name: "Glasgow City",
    value: "GGW",
  },
  {
    name: "Highland",
    value: "HLD",
  },
  {
    name: "Inverclyde",
    value: "IVC",
  },
  {
    name: "Midlothian",
    value: "MLO",
  },
  {
    name: "Moray",
    value: "MRY",
  },
  {
    name: "North Ayrshire",
    value: "NAY",
  },
  {
    name: "North Lanarkshire",
    value: "NLK",
  },
  {
    name: "Orkney",
    value: "ORK",
  },
  {
    name: "Perth and Kinross",
    value: "PAK",
  },
  {
    name: "Renfrewshire",
    value: "RNW",
  },
  {
    name: "Scottish Borders",
    value: "BRD",
  },
  {
    name: "Shetland",
    value: "SHT",
  },
  {
    name: "South Ayrshire",
    value: "SAY",
  },
  {
    name: "South Lanarkshire",
    value: "SLK",
  },
  {
    name: "Stirling",
    value: "STG",
  },
  {
    name: "West Dunbartonshire",
    value: "WDB",
  },
  {
    name: "West Lothian",
    value: "WLO",
  },
];
