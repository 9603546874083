import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AccountContext } from "../Account";
import NotificationFilters from "../NotificationFilters";
import NotificationTable from "../NotificationTable";
import { dwqAdminOptions, dwqStandardOptions } from "./notifications-options";

const DWQnotifications = () => {
  const { configCredentials, userGroup } = useContext(AccountContext);

  const [options, setOptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [types, setTypes] = useState(null);
  const [status, setStatus] = useState(null);
  const [user, setUser] = useState(null);

  function updateNotificationQuery() {
    searchParams.get("view-type")
      ? setStatus(searchParams.get("view-type"))
      : setStatus("");

    searchParams.get("category")
      ? setTypes(searchParams.get("category"))
      : setTypes("");

    searchParams.get("assessor")
      ? setUser(searchParams.get("assessor"))
      : setUser("");
  }

  function categoryOptions() {
    if (userGroup === "DWQAdminPoolGroup") {
      setOptions(dwqAdminOptions);
    }
    if (userGroup === "DWQUserPoolGroup") {
      setOptions(dwqStandardOptions);
    }
  }

  useEffect(() => {
    updateNotificationQuery();
    categoryOptions();
  }, [configCredentials, search]);

  return (
    <div className="regionWrapper">
      <NotificationFilters
        options={options}
        refresh={refresh}
        setRefresh={setRefresh}
        statusCode={statusCode}
        setStatusCode={setStatusCode}
      />

      {types !== null && status !== null && user !== null && (
        <NotificationTable
          types={types}
          status={status}
          user={user}
          refresh={refresh}
          setStatusCode={setStatusCode}
        />
      )}
    </div>
  );
};

export default DWQnotifications;
