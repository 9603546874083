import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  itemsPerPage,
  setNumberOfRecordsVisited,
  data,
  page,
  setPage,
}) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(data / itemsPerPage));

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    setNumberOfRecordsVisited(page * itemsPerPage);
    setTotalPages(Math.ceil(data / itemsPerPage));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, data]);

  return (
    <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      pageCount={totalPages}
      onPageChange={changePage}
      containerClassName={"navigationButtons"}
      previousLinkClassName={"previousButton"}
      nextLinkClassName={"nextButton"}
      disabledClassName={"navigationDisabled"}
      activeClassName={"navigationActive"}
      forcePage={page}
    />
  );
};

export default Pagination;
