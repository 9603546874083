import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "./Account";
import { ParameterContext } from "../components/ParametersProvider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckBox from "./notifications/CheckBox";

const NotificationFilters = ({
  options,
  refresh,
  setRefresh,
  statusCode,
  setStatusCode,
}) => {
  const { permissions, userGroup, getUserGroups } = useContext(AccountContext);
  const { parameters } = useContext(ParameterContext);

  const history = useHistory();

  const [focus, setFocus] = useState(false);
  const [focusThree, setFocusThree] = useState(false);

  const [checkedState, setCheckedState] = useState([]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const getViewType = searchParams.get("view-type")
    ? searchParams.get("view-type")
    : "";
  const getCategory = searchParams.get("category")
    ? searchParams.get("category")
    : "";
  const getUser = searchParams.get("assessor")
    ? searchParams.get("assessor")
    : "";

  function changeRefreshState() {
    if (statusCode) {
      setRefresh(!refresh);
      setStatusCode(null);
    }
  }

  function handleCheckboxState() {
    const getUrl = searchParams.get("category").split(",");

    const checkBoxArray = [...options].map((item) => ({
      name: item,
      checked: false,
    }));

    checkBoxArray.forEach((item) => {
      const match = [...getUrl].find((el) => el === item.name);
      item.checked = match ? true : false;
    });

    setCheckedState(checkBoxArray);
  }

  function handleStateChange(position, checkValue) {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        item.checked = checkValue;
      }
      return item;
    });

    const filterTrueValues = [...updatedCheckedState]
      .filter(({ checked }) => checked === true)
      .map(({ name }) => name);

    setCheckedState(updatedCheckedState);

    history.push({
      pathname: "/notifications-hub",
      search: `?view-type=${
        getViewType === null ? "" : getViewType
      }&category=${filterTrueValues.join()}`,
    });
  }

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
    handleCheckboxState();
  }, [userGroup, options]);

  return (
    <div className="regionArea">
      <div className="assetHeader">
        <h1>Notifications</h1>
        <button
          title="Refresh Notifications"
          aria-label="Refresh Notifications"
          className="iconButton"
          onClick={(e) => {
            e.preventDefault();
            changeRefreshState();
          }}
        >
          <RefreshIcon style={{ color: statusCode ? "#fff" : "grey" }} />
        </button>
      </div>

      <div className="regionBody">
        <Row className="eventincidents_search">
          <Col sm={4}>
            <div id="selectViewType">
              <label className="ds_label" htmlFor="viewtype">
                View Type
              </label>

              <div className="ds_select-wrapper">
                <select
                  className="ds_select"
                  id="viewtype"
                  name="viewtype"
                  value={getViewType}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(e) => {
                    history.push({
                      pathname: "/notifications-hub",
                      search: `?view-type=${e.target.value}&category=${
                        getCategory === null ? "" : getCategory
                      }`,
                    });
                  }}
                >
                  <option value="">View All</option>
                  <option value="False">Unread</option>
                  <option value="True">Read</option>
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    style={{ fontSize: 30, color: focus ? "black" : "white" }}
                  />
                </div>
              </div>
            </div>
          </Col>

          {permissions.dwqPermissions.includes(userGroup) ? (
            <Col sm={4}>
              <div id="selectUser">
                <label className="ds_label" htmlFor="users">
                  User
                </label>

                <div className="ds_select-wrapper">
                  <select
                    className="ds_select"
                    id="users"
                    name="users"
                    value={getUser}
                    onFocus={() => setFocusThree(true)}
                    onBlur={() => setFocusThree(false)}
                    onChange={(e) => {
                      history.push({
                        pathname: "/notifications-hub",
                        search: `?view-type=${
                          getViewType === null ? "" : getViewType
                        }&category=${
                          getCategory === null ? "" : getCategory
                        }&assessor=${e.target.value}`,
                      });
                    }}
                  >
                    <option value="">My Notifications</option>
                    <option value="View All Assessors">
                      View all Assessors
                    </option>
                    {parameters &&
                      parameters.assessors.map((user, index) => {
                        return (
                          <option value={user.email} key={index}>
                            {user.name}
                          </option>
                        );
                      })}
                  </select>

                  <div className="ds_select-arrow-icon">
                    <KeyboardArrowDownIcon
                      style={{
                        fontSize: 30,
                        color: focusThree ? "black" : "white",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row>
          <p>
            <strong>Categories</strong>
          </p>
          {checkedState &&
            checkedState.map((checkbox, index) => {
              return (
                <Col sm={3} key={index}>
                  <CheckBox
                    index={index}
                    name={checkbox.name}
                    state={checkbox.checked}
                    handleStateChange={handleStateChange}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default NotificationFilters;
