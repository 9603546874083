import { useEffect } from "react";

const IncidentRiskIndexScore = ({
  eventCategory,
  assessmentActionLevelScore,
  assessedPopulation,
  assessedDuration,
  populationAffected,
  setIncidentRiskIndexScore,
  incidentRiskIndexScore,
}) => {
  useEffect(() => {
    calculateRiskIndexScore();
  }, [
    eventCategory,
    assessmentActionLevelScore,
    assessedPopulation,
    assessedDuration,
    populationAffected,
  ]);

  function calculateRiskIndexScore() {
    if (populationAffected === 0) {
      setIncidentRiskIndexScore(0);
    } else {
      setIncidentRiskIndexScore(
        (parseInt(eventCategory) *
          parseInt(assessmentActionLevelScore) *
          parseInt(assessedPopulation) *
          parseInt(assessedDuration)) /
          parseInt(populationAffected)
      );
    }
  }

  return (
    <div>
      <label className="ds_label" htmlFor="incidentRiskIndexScore">
        Incident Risk Index Score
      </label>
      <input
        className="ds_input"
        type="text"
        id="incidentRiskIndexScore"
        value={(incidentRiskIndexScore = incidentRiskIndexScore || 0)}
        readOnly
      />
    </div>
  );
};

export default IncidentRiskIndexScore;
