const Home = () => {
  return (
    <div className="regionArea">
      <div className="regionHeader">
        <h1>Home</h1>
      </div>

      <div className="regionBody">
        <p>Welcome to your data management system.</p>
      </div>
    </div>
  );
};

export default Home;
