import { Switch, HashRouter } from "react-router-dom";

// Views
import Login from "./views/Login";
import UpdatePassword from "./views/UpdatePassword";
import UploadDocument from "./views/UploadDocument";
import EventIncidents from "./views/EventIncidents";
import EventOverview from "./views/EventOverview";
import EventInformation from "./views/EventInformation";
import EventDataSample from "./views/EventDataSample";
import EventAssessment from "./views/EventAssessment";
import EventFiles from "./views/EventFiles";
import EventCommunication from "./views/EventCommunication";
import NotFound from "./views/NotFound";
import EventAssetCase from "./views/EventAssetCase";
import AssetCaseSearch from "./views/AssetCaseSearch";
import AssetCase from "./views/AssetCase";
import NotifTest from "./views/NotifTest";
import User from "./views/admin/User";
import ListUsers from "./views/admin/ListUsers";
import AuditTrail from "./views/admin/AuditTrail";
import PasswordManagement from "./views/admin/PasswordManagement";
import ClassificationManager from "./views/admin/ClassificationManager";
import HazardManager from "./views/admin/HazardManager";

// Components
import { Account } from "./components/Account";
import { NotificationsProvider } from "./components/NotificationsProvider";
import { ParametersProvider } from "./components/ParametersProvider";
import PublicRoute from "./components/PublicRouter";
import PrivateRoute from "./components/PrivateRoute";
import Footer from "./components/Footer";
import NotificationSettings from "./views/NotificationSettings";

import ForgottenPassword from "./views/ForgottenPassword";

import Callback from "./callback";

// LA Imports
import PreviousUploads from "./views/la/PreviousUploads";

// Help
import SupplySamplesHelp from "./views/help/SupplySamplesHelp";
import SupplyDetailsHelp from "./views/help/SupplyDetailsHelp";
import LACodesHelp from "./views/help/LACodesHelp";
import PWSZoneHelp from "./views/help/PWSZoneHelp";
import ParameterCodeHelp from "./views/help/ParameterCodeHelp";

import Home from "./views/Home";
import SpecificNotification from "./views/SpecificNotification";
import CategoryDeterminationMatrix from "./views/CategoryDeterminationMatrix";
import ShapeFiles from "./views/ShapeFiles";

// footer
import FooterRoute from "./components/FooterRoute";
import About from "./views/footer/About";
import Accessibility from "./views/footer/Accessibility";
import Cookies from "./views/footer/Cookies";
import Feedback from "./views/footer/Feedback";
import Privacy from "./views/footer/Privacy";

import { permissions } from "./utils/permissions";

// Main App component - Router
function App() {
  return (
    <div className="app_wrapper">
      <Account>
        <NotificationsProvider>
          <ParametersProvider>
            <HashRouter hashType="slash">
              <Switch>
                <PublicRoute
                  path="/login/:type"
                  component={Login}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/new-password"
                  component={UpdatePassword}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/"
                  component={Login}
                  restricted={false}
                  exact
                />

                <PublicRoute
                  path="/forgot-password"
                  component={ForgottenPassword}
                  restricted={false}
                  exact
                />

                <PublicRoute
                  path="/callback"
                  component={Callback}
                  restricted={false}
                  exact
                />

                <FooterRoute
                  path="/about"
                  pageTitle="About"
                  component={About}
                  restricted={false}
                  exact
                />
                <FooterRoute
                  path="/accessibility"
                  pageTitle="Accessibility"
                  component={Accessibility}
                  restricted={false}
                  exact
                />
                <FooterRoute
                  path="/cookies"
                  pageTitle="Cookies"
                  component={Cookies}
                  restricted={false}
                  exact
                />
                <FooterRoute
                  path="/feedback"
                  pageTitle="Feedback"
                  component={Feedback}
                  restricted={false}
                  exact
                />
                <FooterRoute
                  path="/privacy"
                  pageTitle="Privacy"
                  component={Privacy}
                  restricted={false}
                  exact
                />

                {/* DWQ Routes */}

                <PrivateRoute path="/home" component={Home} exact />

                <PrivateRoute
                  path="/upload-document/"
                  component={UploadDocument}
                  exact
                />

                <PrivateRoute
                  path="/shape-files"
                  component={ShapeFiles}
                  access={permissions.dwqPermissions}
                  exact
                />

                <PrivateRoute
                  path="/assetcase"
                  component={AssetCaseSearch}
                  access={permissions.dwqPermissions}
                  exact
                />

                <PrivateRoute
                  path="/assetcase/:case"
                  component={AssetCase}
                  access={permissions.dwqPermissions}
                  exact
                />

                <PrivateRoute
                  path="/notifications-hub"
                  component={NotifTest}
                  exact
                />

                <PrivateRoute
                  path="/notifications-hub/:notifID"
                  component={SpecificNotification}
                  exact
                />

                <PrivateRoute
                  path="/notifications-settings/"
                  component={NotificationSettings}
                  exact
                />

                {/*Event Routes*/}
                <PrivateRoute
                  path="/eventincidents"
                  component={EventIncidents}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-overview/:id"
                  component={EventOverview}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-information/:id"
                  component={EventInformation}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-data-sample/:id"
                  component={EventDataSample}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-assessment/:id"
                  component={EventAssessment}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-files/:id"
                  component={EventFiles}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-asset-case/:id"
                  component={EventAssetCase}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/event-communication/:id"
                  component={EventCommunication}
                  access={permissions.dwqPermissions}
                  exact
                />
                <PrivateRoute
                  path="/eventincidents/category-determination-matrix/:id"
                  component={CategoryDeterminationMatrix}
                  access={permissions.dwqPermissions}
                  exact
                />

                {/* LA Routes */}
                <PrivateRoute
                  path="/previous-uploads"
                  component={PreviousUploads}
                  access={permissions.laPermissions}
                  exact
                />

                {/*Help*/}
                <PrivateRoute
                  path="/la/help-advice"
                  component={SupplySamplesHelp}
                  access={permissions.laPermissions}
                  exact
                />
                <PrivateRoute
                  path="/help-advice/supply-samples-help"
                  component={SupplySamplesHelp}
                  access={permissions.laPermissions}
                  exact
                />
                <PrivateRoute
                  path="/help-advice/supply-details-help"
                  component={SupplyDetailsHelp}
                  access={permissions.laPermissions}
                  exact
                />
                <PrivateRoute
                  path="/help-advice/pws-zone-help"
                  component={PWSZoneHelp}
                  access={permissions.laPermissions}
                  exact
                />
                <PrivateRoute
                  path="/help-advice/la-codes-help"
                  component={LACodesHelp}
                  access={permissions.laPermissions}
                  exact
                />
                <PrivateRoute
                  path="/help-advice/parameter-code-help"
                  component={ParameterCodeHelp}
                  access={permissions.laPermissions}
                  exact
                />

                {/* Admin Routes */}
                <PrivateRoute
                  path="/admin/"
                  component=""
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />

                <PrivateRoute
                  path="/admin/audit-trail"
                  component={AuditTrail}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute
                  path="/admin/user/:id"
                  component={User}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute
                  path="/admin/user"
                  component={User}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute
                  path="/admin/list-users"
                  component={ListUsers}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute
                  path="/admin/password-management"
                  component={PasswordManagement}
                  exact
                />
                <PrivateRoute
                  path="/admin/classification-manager"
                  component={ClassificationManager}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute
                  path="/admin/hazard-manager"
                  component={HazardManager}
                  access={[permissions.dwqPermissions[0]]}
                  exact
                />
                <PrivateRoute component={NotFound} path="/not-found" />

                <PublicRoute path="*" component={Callback} />
              </Switch>
            </HashRouter>
            <Footer />
          </ParametersProvider>
        </NotificationsProvider>
      </Account>
    </div>
  );
}

export default App;
