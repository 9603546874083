import { useEffect, useState, useContext } from "react";
import ValidationError from "./ValidationError";
import { AccountContext } from "../Account";
import { NotificationContext } from "../NotificationsProvider";
import { CallBackend } from "../../utils/apiCall";
import moment from "moment";

const FilteredNotification = ({ data, currentParamsID }) => {
  const { refreshUser } = useContext(AccountContext);
  const { getDefaultNotificationSettings } = useContext(NotificationContext);

  const [error, setError] = useState(null);
  const [read, setRead] = useState("Mark as Read");

  useEffect(() => {}, [currentParamsID, data]);

  async function handleClick(e, id, date) {
    e.preventDefault();
    const body = {
      _id: id,
      IsRead: "True",
      Date: date
    };

    setRead("");

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        process.env.REACT_APP_GETNOTIFICATIONS + "/" + id,
        "PUT",
        configCredentials
      )
        .then(() => {
          getDefaultNotificationSettings();
        })
        .catch((err) => {
          setError(err);
        });
    });
  }

  return (
    <>
      {data ? (
        <div className="regionArea" key={data[0]._id}>
          <div className="regionHeader notifHeader">{data[0].Category}</div>

          <div className="regionBody">
            <div
              className={
                data[0].IsRead === true
                  ? "notification_read"
                  : "notification_unread"
              }
            >
              <div className="notification_item_list">
                <div className="notification_item_list_inner">
                  <ul>
                    <li>
                      {moment(parseInt(data[0].Date)).format(
                        "HH:mm a DD/MM/YY"
                      )}
                    </li>
                    <li>{data[0].Category}</li>
                    {data[0].Message === undefined ? (
                      <li></li>
                    ) : (
                      <li>
                        {data[0].Category !== "Assessment Report Upload"
                          ? `${data[0].Message} by ${data[0].Email}`
                          : data[0].Message}
                      </li>
                    )}
                    {data[0].Category === "Validation Errors" &&
                    data[0].Errors !== undefined ? (
                      <li>
                        <ValidationError props={data[0].Errors} />
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      {data[0].IsRead === true ? (
                        ""
                      ) : (
                        <a href="" onClick={(e) => handleClick(e, data[0]._id, data[0].Date)}>
                          {read}
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FilteredNotification;
