import { createContext, useContext, useEffect, useState } from "react";
import { CallBackend } from "../utils/apiCall";
import { AccountContext } from "../components/Account";

const NotificationContext = createContext();

const NotificationsProvider = (props) => {
  const { refreshUser, configCredentials } = useContext(AccountContext);

  const [notificationSettings, setNotificationSettings] = useState(null);
  const [settingsError, setSettingsError] = useState(null);

  const [notifications, setNotifications] = useState(null);
  const [notificationsError, setNotificationsError] = useState(null);

  async function getDefaultNotificationSettings() {
    await refreshUser().then((configCredentials) => {
      CallBackend({}, `notification_settings`, "GET", configCredentials)
        .then((response) => {
          getNotifications(response.default_category_filters.join());
          setNotificationSettings(response.default_category_filters);
        })
        .catch((error) => {
          setSettingsError(error);
        });
    });
  }

  async function getNotifications(defaultSettings) {
    const body = {};
    const additionalParams = {
      headers: {},
      queryParams: {
        page_key: "",
        date_key: "",
        types: defaultSettings,
        status: "False",
        user: "",
      },
    };

    await refreshUser().then((result) => {
      CallBackend(
        body,
        process.env.REACT_APP_GETNOTIFICATIONS,
        "GET",
        result,
        additionalParams
      )
        .then((response) => {
          setNotifications(response);
        })
        .catch((error) => {
          setNotificationsError(error);
        });
    });
  }

  useEffect(() => {
    if (configCredentials) {
      getDefaultNotificationSettings();
    }
  }, [configCredentials]);

  return (
    <NotificationContext.Provider
      value={{
        getDefaultNotificationSettings,
        getNotifications,
        notifications,
        setNotificationSettings,
        notificationSettings,
        settingsError,
        notificationsError,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export { NotificationsProvider, NotificationContext };
