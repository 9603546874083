import { useState, React, useContext, useEffect } from "react";
import { AccountContext } from "../../components/Account";
import { CallBackend } from "../../utils/apiCall";
import Form from "../../components/hazard-manager-components/Form";

const HazardManager = () => {
  const { refreshUser } = useContext(AccountContext);

  const [data, setData] = useState(null);
  const [dataSubmitted, setDataSubmitted] = useState(false);

  const [error, setError] = useState(null);

  async function getData() {
    await refreshUser().then((result) => {
      CallBackend({}, `params`, "GET", result)
        .then((response) => {
          setData(response);
        })
        .catch((err) => {
          setError(err);
        });
    });
  }

  useEffect(() => {
    getData();
  }, [dataSubmitted]);

  return (
    <div className="page_wrapper">
      {error && (
        <div className="regionArea">
          <div className="assetHeader">
            <h1>Error getting parameter data.</h1>
          </div>
          <div className="regionBody">
            <p>
              There was an error getting the parameter data. Please try again.
            </p>
          </div>
        </div>
      )}

      <div className="regionArea">
        <div className="assetHeader">
          <h1>Hazard Manager</h1>
        </div>
        <div className="regionBody">
          {data ? (
            <Form
              setDataSubmitted={setDataSubmitted}
              dataSubmitted={dataSubmitted}
              data={data}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HazardManager;
