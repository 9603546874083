import React from "react";

const SelectFilter = ({ name, state, setState, data, firstOption }) => {
  function handleChange(e, stateSet) {
    stateSet(e.target.value);
  }

  return (
    <>
      <label className="ds_label ov_label" htmlFor={name}>
        {name}
      </label>

      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          name={name}
          id={name}
          value={state}
          onChange={(e) => handleChange(e, setState)}
        >
          <option value="">{firstOption}</option>
          {data.map((item, index) => {
            return (
              <option value={item.code} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </>
  );
};

export default SelectFilter;
