import { useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "../../components/Account";
import SuccessMessage from "../../components/SuccessMessage";

const PasswordManagement = () => {
  const { getUserSession } = useContext(AccountContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [
    currentPasswordValidationMessage,
    setCurrentPasswordValidationMessage,
  ] = useState(null);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordValidationMessage, setNewPasswordValidationMessage] =
    useState(null);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage,
  ] = useState(null);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    currentPassword !== ""
      ? setCurrentPasswordValidationMessage(null)
      : setCurrentPasswordValidationMessage(
          "Please provide your current password."
        );
    newPassword !== ""
      ? setNewPasswordValidationMessage(null)
      : setNewPasswordValidationMessage("Please provide a new password.");
    confirmPassword !== ""
      ? setConfirmPasswordValidationMessage(null)
      : setConfirmPasswordValidationMessage(
          "Please provide a confirmation of your new password."
        );

    if (
      currentPassword &&
      newPassword &&
      confirmPassword &&
      newPassword === confirmPassword
    ) {
      getUserSession().then(({ user }) => {
        user.changePassword(currentPassword, newPassword, (err, result) => {
          if (err) {
            setError(true);
            setCurrentPasswordValidationMessage(err.message);
          } else {
            if (result) {
              setSuccess(true);
              setSuccessMessage("Password updated successfully");
              resetInputFields();
            }
          }
        });
      });
    }
    // logic for validation on fields
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== confirmPassword
    ) {
      setNewPasswordValidationMessage("Passwords do not match.");
      setConfirmPasswordValidationMessage("Passwords do not match.");
    }
  }

  const stateChanges = (
    e,
    state,
    setState,
    setValidationState,
    validationMessage,
    passwordMessage
  ) => {
    setValidationState(null);
    setState(e.target.value);
    if (state === "") {
      setValidationState(validationMessage);
    } else {
      if (passwordMessage !== undefined && checkPassword(state)) {
        setValidationState(null);
        setState(e.target.value);
      } else {
        if (passwordMessage !== undefined) {
          setValidationState(passwordMessage);
        }
      }
    }
  };

  function checkPassword(password) {
    let checkPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
    return checkPassword.test(password);
  }

  function resetInputFields() {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }

  return (
    <div className="page_wrapper">
      {success ? (
        <SuccessMessage heading="Password Updated" message={successMessage} />
      ) : (
        ""
      )}
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Password Reset</h1>
        </div>
        <div className="regionBody">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <p>
                  <strong>
                    This service is for standard accounts only. If you are a SSO
                    user please use the SSO reset password service.
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="userFields">
                  <div className="validationContainer">
                    <label
                      className="ds_label ov_label"
                      htmlFor="currentPassword"
                    >
                      Current Password
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          currentPasswordValidationMessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          currentPasswordValidationMessage !== null
                            ? "6px"
                            : "0px",
                      }}
                    >
                      {currentPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{currentPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          name="currentPassword"
                          id="currentPassword"
                          type="password"
                          className="ds_select"
                          value={currentPassword}
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              currentPassword,
                              setCurrentPassword,
                              setCurrentPasswordValidationMessage,
                              "Please provide your current password"
                            )
                          }
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="userFields">
                  <p>
                    Your password must be at least 10 characters and contain at
                    least one number, one uppercase letter and one lowercase
                    character.
                  </p>
                  <div className="validationContainer">
                    <label className="ds_label ov_label" htmlFor="newPassword">
                      New Password
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          newPasswordValidationMessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          newPasswordValidationMessage !== null ? "6px" : "0px",
                      }}
                    >
                      {newPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{newPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          name="newPassword"
                          id="newPassword"
                          type="password"
                          className="ds_select"
                          value={newPassword}
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              newPassword,
                              setNewPassword,
                              setNewPasswordValidationMessage,
                              "Please provide your new password",
                              "Your password does not match the above criteria"
                            )
                          }
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="userFields">
                  <div className="validationContainer">
                    <label
                      className="ds_label ov_label"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          confirmPasswordValidationMessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          confirmPasswordValidationMessage !== null
                            ? "6px"
                            : "0px",
                      }}
                    >
                      {confirmPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{confirmPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          name="confirmPassword"
                          id="confirmPassword"
                          type="password"
                          value={confirmPassword}
                          className="ds_select"
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              confirmPassword,
                              setConfirmPassword,
                              setConfirmPasswordValidationMessage,
                              "Please provide your new password",
                              "Your password does not match the above criteria"
                            )
                          }
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="upload_button"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordManagement;
