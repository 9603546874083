import { useState } from "react";
import { handleChange } from "../../utils/helpers";
import Alert from "../Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LAProxyUpload from "./LAProxyUpload";
import { localAuthorityData } from "../../views/help/all_local_authority_data";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ValidationContainer from "../ValidationContainer";

const DrinkingWaterQuality = (props) => {
  const [fileOptionValue, setFileOptionValue] = useState("");
  const [laOptionValue, setLAOptionValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [laValidationMessage, setLAValidationMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [focus, setFocus] = useState(false);
  const [laFocus, setLAFocus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const { description, title } = props;

  function successMessageOne(fileName) {
    return {
      title: "File Successfully Uploaded",
      message: (
        <>
          <p>
            File {fileName} successfully uploaded at{" "}
            {moment().format("HH:mm A DD/MM/YY")} and is awaiting confirmation.
          </p>
          <p>
            The system is currently validating the upload, you will be contacted
            about the file status.
          </p>
        </>
      ),
    };
  }

  return (
    <>
      {showSuccess && successMessage && (
        <Alert
          type="success"
          style={{
            backgroundColor: "#5eb135",
            borderColor: "#5eb135",
          }}
          icon={<CheckCircleIcon />}
          heading={successMessage.title}
          message={successMessage.message}
        />
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>{title}</h1>
        </div>

        <div className="regionBody">
          <p>{description}</p>

          <div className="upload_select_wrapper">
            <label htmlFor="LA" className="ds_label ov_label">
              Local Authority
            </label>

            <ValidationContainer validationMessage={laValidationMessage}>
              <div className="ds_select-upload-wrapper">
                <select
                  name="LA"
                  className="ds_select"
                  id="LA"
                  value={laOptionValue}
                  onFocus={() => setLAFocus(true)}
                  onBlur={() => setLAFocus(false)}
                  onChange={(e) => handleChange(e, setLAOptionValue)}
                >
                  <option value="">Select</option>
                  {localAuthorityData.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 35, color: laFocus ? "black" : "white" }}
                  />
                </div>
              </div>
            </ValidationContainer>
          </div>

          <div className="upload_select_wrapper">
            <label htmlFor="FileOption" className="ds_label ov_label">
              Upload Type
            </label>

            <ValidationContainer validationMessage={validationMessage}>
              <div className="ds_select-upload-wrapper">
                <select
                  name="File_Type_Option"
                  className="ds_select"
                  id="FileOption"
                  value={fileOptionValue}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(e) => handleChange(e, setFileOptionValue)}
                >
                  <option value="">Select</option>
                  <option value="supply_details">Supply Details</option>
                  <option value="supply_samples">Supply Samples</option>
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 35, color: focus ? "black" : "white" }}
                  />
                </div>
              </div>
            </ValidationContainer>
          </div>

          <LAProxyUpload
            path="la_file"
            fileType={fileOptionValue}
            laOptionValue={laOptionValue}
            setShowSuccess={setShowSuccess}
            setFileOptionValue={setFileOptionValue}
            setValidationMessage={setValidationMessage}
            setLAValidationMessage={setLAValidationMessage}
            organisation={laOptionValue}
            setLAOptionValue={setLAOptionValue}
            setSuccessMessage={setSuccessMessage}
            successMessageValue={successMessageOne}
          />
        </div>
      </div>
    </>
  );
};

export default DrinkingWaterQuality;
