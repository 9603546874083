import { useState } from "react";
import { handleChange } from "../../utils/helpers";
import Alert from "../Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SWEIBucketUpload from "./SWEIBucketUpload";
import BucketUpload from "./BucketUpload";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ValidationContainer from "../ValidationContainer";

const ScottishWater = (props) => {
  const [fileOptionValue, setFileOptionValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [eventValidationMessage, setEventValidationMessage] = useState("");
  const [eventNo, setEventNo] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [focus, setFocus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const { description, title } = props;

  function successMessageOne(fileName) {
    return {
      title: "File Successfully Uploaded",
      message: (
        <>
          <p>
            File {fileName} successfully uploaded at{" "}
            {moment().format("HH:mm A DD/MM/YY")} and is awaiting confirmation.
          </p>
          <p>
            The system is currently validating the upload, you will be contacted
            about the file status.
          </p>
        </>
      ),
    };
  }

  return (
    <>
      {showSuccess && successMessage && (
        <Alert
          type="success"
          style={{
            backgroundColor: "#5eb135",
            borderColor: "#5eb135",
          }}
          icon={<CheckCircleIcon />}
          heading={successMessage.title}
          message={successMessage.message}
        />
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>{title}</h1>
        </div>

        <div className="regionBody">
          <p>{description}</p>

          <div className="upload_select_wrapper">
            <label htmlFor="FileOption" className="ds_label ov_label">
              Upload Type
            </label>

            <ValidationContainer validationMessage={validationMessage}>
              <div className="ds_select-upload-wrapper">
                <select
                  name="FileOption"
                  className="ds_select"
                  id="FileOption"
                  value={fileOptionValue}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(e) => handleChange(e, setFileOptionValue)}
                >
                  <option value="">Select</option>
                  <option value="event_details">Event Details</option>
                  <option value="event_update">Event Updates</option>
                  <option value="incident_report">Incident Reports</option>
                  <option value="add_info">Additional Info</option>
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 35, color: focus ? "black" : "white" }}
                  />
                </div>
              </div>
            </ValidationContainer>
          </div>

          {fileOptionValue === "incident_report" ||
          fileOptionValue === "add_info" ? (
            <div className="validationContainer">
              <div className="upload_select_wrapper">
                <label htmlFor="eventNumber" className="ds_label ov_label">
                  Event Number
                </label>

                <ValidationContainer validationMessage={eventValidationMessage}>
                  <div className="ds_select-upload-wrapper">
                    <input
                      className="ds_input"
                      type="number"
                      name="eventNumber"
                      id="eventNumber"
                      value={eventNo}
                      onChange={(e) => handleChange(e, setEventNo)}
                    />
                  </div>
                </ValidationContainer>
              </div>
            </div>
          ) : (
            ""
          )}

          {fileOptionValue === "event_details" ||
          fileOptionValue === "event_update" ? (
            <BucketUpload
              path={"sw_file"}
              fileType={fileOptionValue}
              setShowSuccess={setShowSuccess}
              setFileOptionValue={setFileOptionValue}
              setValidationMessage={setValidationMessage}
              setSuccessMessage={setSuccessMessage}
              successMessageValue={successMessageOne}
            />
          ) : (
            <SWEIBucketUpload
              path={`sw_event_files/${eventNo}`}
              fileType={fileOptionValue}
              setShowSuccess={setShowSuccess}
              setFileOptionValue={setFileOptionValue}
              setValidationMessage={setValidationMessage}
              eventnumber={eventNo}
              setEventValidationMessage={setEventValidationMessage}
              setEventNo={setEventNo}
              setSuccessMessage={setSuccessMessage}
              successMessageValue={successMessageOne}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ScottishWater;
