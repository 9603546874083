import moment from "moment";
import { useEffect, useState } from "react";
import RestPagination from "./RestPagination";

const EventTable = ({
  data: propData,
  totalPages,
  pageNumber,
  setPageNumber,
}) => {
  // Single Event Overview

  const [data, setData] = useState(propData[0].Data);
  const [order, setOrder] = useState(false);

  function sortTable(e, keyName) {
    data.sort((a, b) => {
      const first =
        typeof a[keyName] === "string" ? a[keyName].toLowerCase() : a[keyName];
      const second =
        typeof b[keyName] === "string" ? b[keyName].toLowerCase() : b[keyName];

      if (first === null) {
        return 1;
      }
      if (second === null) {
        return -1;
      }
      if (first === second) {
        return 0;
      }

      return e.target.textContent && order
        ? first > second
          ? -1
          : 1
        : first < second
        ? -1
        : 1;
    });
  }

  const handleClick = (e, keyName) => {
    setOrder(!order);
    sortTable(e, keyName);
  };

  useEffect(() => {
    setData(propData[0].Data);
  }, [propData]);

  return (
    <>
      <table
        className="ov_recentactivity_table"
        width={"95%"}
        style={{ margin: "0px" }}
      >
        <thead>
          <tr>
            <th scope="col" onClick={(e) => handleClick(e, "site_name")}>
              Site Name
            </th>
            <th scope="col" onClick={(e) => handleClick(e, "event_number")}>
              Event No.
            </th>
            <th scope="col" onClick={(e) => handleClick(e, "area")}>
              Area
            </th>
            <th scope="col" onClick={(e) => handleClick(e, "assignee")}>
              DWQR Assignee
            </th>
            <th scope="col">Date of Event</th>
            <th scope="col">Last Update</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {propData[0].Data ? (
            propData[0].Data.map((element, index) => {
              return (
                <tr key={index}>
                  {/* Site Name */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.site_name
                          ? element.site_name
                          : "Site Name value empty"
                      }
                    >
                      {element.site_name}
                    </a>
                  </td>

                  {/* eventnumber field */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.event_number
                          ? element.event_number
                          : "Event Number value empty"
                      }
                    >
                      {element.event_number}
                    </a>{" "}
                  </td>

                  {/* Area field */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.area ? element.area : "Area value empty"
                      }
                    >
                      {element.area}
                    </a>{" "}
                  </td>

                  {/* Assignee field */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.assignee
                          ? element.assignee
                          : "DWQR Assignee value empty"
                      }
                    >
                      {element.assignee}
                    </a>
                  </td>

                  {/* Created date */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.date_of_event
                          ? element.date_of_event
                          : "Date of Event value empty"
                      }
                    >
                      {moment(element.date_of_event).format("DD/MM/YY")}
                    </a>
                  </td>

                  {/* Last updated */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.last_update
                          ? element.last_update
                          : "Last Update value empty"
                      }
                    >
                      {moment(element.last_update).format("DD/MM/YY")}
                    </a>
                  </td>

                  {/* Status */}
                  <td
                    style={{
                      backgroundColor:
                        element.category === "Incident"
                          ? "rgba(217,0,27,0.4)"
                          : element.category === "Potential Incident"
                          ? "rgba(245,154,35,0.4)"
                          : "",
                    }}
                  >
                    <a
                      href={`#/eventincidents/event-overview/${element.event_number}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        element.status ? element.status : "Status value empty"
                      }
                    >
                      {element.status}
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No data returned from search query</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="regionPaginate">
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default EventTable;
