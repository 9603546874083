import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../Account";
import { Row } from "react-bootstrap";
import AWS from "aws-sdk";
import Spinner from "../Spinner";
import moment from "moment";
import ValidationContainer from "../ValidationContainer";

const LAProxyUpload = (props) => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET;

  const { configCredentials, getUserName, activeUser } =
    useContext(AccountContext);

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [validateFileMessage, setValidateFileMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const myBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  async function uploadFile(e, file) {
    e.preventDefault();
    setDisabled(true);

    const prefix = Date.now();
    const newFileName = `${prefix}_${selectedFile.name}`;

    await getUserName().then((result) => {
      // Pass object to Metadata within params

      const objectData = {
        filename: newFileName,
        username: result,
        filetype: props.fileType,
        organisation: props.organisation,
        date: moment().format("DD/MM/YY"),
      };

      const params = {
        Body: file,
        Bucket: `${BUCKET_NAME}/${props.path}/${props.organisation}`,
        Key: newFileName,
        Metadata: objectData,
      };

      if (activeUser) {
        if (configCredentials !== null) {
          return myBucket
            .putObject(params)
            .on("success", (response) => {
              // Update state from here and display success message.
              if (response.httpResponse.statusCode === 200) {
                props.setShowSuccess(true);
                props.setFileOptionValue("");
                props.setLAOptionValue("");
                props.setSuccessMessage(props.successMessageValue(newFileName));
                setDisabled(false);
                setSpinner(false);
                resetFileInput();
              }
            })
            .send((err) => {
              if (err) {
                setSpinner(false);
              }
            });
        }
      }
    });
  }

  const resetFileInput = () => {
    inputRef.current.value = null;
    setSelectedFile(undefined);
  };

  useEffect(() => {
    let isSubscribed = true;
    props.fileType !== "" ? props.setValidationMessage("") : "";
    props.laOptionValue !== "" ? props.setLAValidationMessage("") : "";
    selectedFile !== undefined ? setValidateFileMessage("") : "";

    return () => {
      isSubscribed = false;
    };
  }, [selectedFile, props.fileType, props.laOptionValue]);

  return (
    <>
      {spinner ? <Spinner /> : ""}

      <Row>
        <div className="upload_file">
          <ValidationContainer validationMessage={validateFileMessage}>
            <div className="upload_file_wrapper">
              <input
                aria-label="Choose File"
                title="Choose File"
                className="uploadInput"
                id="uploaded-files"
                name="Upload"
                accept=".csv"
                aria-describedby="basic-addon1"
                type="file"
                ref={inputRef}
                onChange={handleFileInput}
              />
            </div>
          </ValidationContainer>

          <div className="upload_file_wrapper">
            <button
              disabled={disabled}
              className="upload_button"
              onClick={(e) => {
                props.fileType === "" &&
                selectedFile === undefined &&
                props.organisation === ""
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ),
                    props.setLAValidationMessage(
                      "Please select a Local Authority from the dropdown."
                    ),
                    setValidateFileMessage("Please select a file to upload"))
                  : props.fileType !== "" &&
                    selectedFile === undefined &&
                    props.organisation === ""
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"),
                    props.setLAValidationMessage(
                      "Please select a Local Authority from the dropdown."
                    ))
                  : props.fileType === "" &&
                    selectedFile !== undefined &&
                    props.organisation === ""
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ),
                    props.setLAValidationMessage(
                      "Please select a Local Authority from the dropdown."
                    ))
                  : props.fileType !== "" &&
                    selectedFile !== undefined &&
                    props.organisation === ""
                  ? (e.preventDefault(),
                    props.setLAValidationMessage(
                      "Please select a Local Authority from the dropdown."
                    ))
                  : props.fileType === "" &&
                    selectedFile === undefined &&
                    props.organisation !== ""
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ),
                    setValidateFileMessage("Please select a file to upload"))
                  : props.fileType !== "" &&
                    selectedFile === undefined &&
                    props.organisation !== ""
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"))
                  : props.fileType === "" &&
                    selectedFile !== undefined &&
                    props.organisation !== ""
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ))
                  : uploadFile(e, selectedFile);
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Row>
    </>
  );
};

export default LAProxyUpload;
