const Pagination = ({ pageNumber, getFirstPage, getNextPage, data }) => {
  return (
    <ul className="navigationButtons">
      <li className="previousButton">
        <a
          className="previousButton "
          tabIndex="-1"
          role="button"
          aria-disabled={pageNumber === 1}
          aria-label="First page"
          disabled={pageNumber === 1}
          rel="first"
          onClick={(e) => (getFirstPage(), e.preventDefault())}
        >
          First Page
        </a>
      </li>
      <li className="nextButton">
        <a
          className="nextButton"
          tabIndex="0"
          role="button"
          disabled={pageNumber === data.PageCount || (data.PageCount === undefined && data.LastEvaluatedKey === undefined)}
          aria-disabled={pageNumber === data.PageCount || (data.PageCount === undefined && data.LastEvaluatedKey === undefined)}
          aria-label="Next page"
          rel="next"
          onClick={(e) => (
            getNextPage(data.LastEvaluatedKey), e.preventDefault()
          )}
        >
          Next page
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
