import React from "react";
import { Route } from "react-router-dom";

// Public Route - If Logged Rediredt user to Upload Document
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  // console.log("PUBLIC ROUTE: " + window.location)
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
