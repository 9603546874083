const ValidationError = ({ props }) => {
  const getError = JSON.stringify(props["S"]);
  const errorJson = JSON.parse(JSON.parse(getError));

  return (
    <>
      {props !== undefined ? (
        <>
          <table className="validationTable">
            <thead>
              <tr>
                <th>Row Number</th>
                <th>Site Reference</th>
                <th>Error Description</th>
              </tr>
            </thead>
            <tbody>
              {errorJson.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.row_number}</td>
                    <td>{item.site_ref}</td>
                    <td>{item.error_desc}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ValidationError;
