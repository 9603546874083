import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CalltoActionButton from "../hazard-manager-components/CalltoActionButton";

const Areas = ({ areas, newAreas, setNewAreas, assessors, data }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [defaultAssessor, setDefaultAssessor] = useState("");

  function handleUpdateState(e) {
    setSelectedOption(e.target.value);
    setDefaultAssessor(
      e.target[e.target.selectedIndex].getAttribute("data-assessor")
    );
  }

  function updateAreaAssessor(e) {
    setDefaultAssessor(e.target.value);
  }

  function addDefaultAssessor() {
    newAreas.filter((item) => {
      if (item.area === selectedOption) {
        item.assessor_email = defaultAssessor;
      }
    });
    setNewAreas([...newAreas]);
  }

  function resetState() {
    setSelectedOption("");
    setDefaultAssessor("");
  }

  useEffect(() => {
    resetState();
  }, [newAreas, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label className="ds_label" htmlFor="areas">
            Area
          </label>
          <div className="ds_select-wrapper">
            <select
              name="areas"
              id="areas"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => handleUpdateState(e)}
            >
              <option value="">Select</option>
              {areas
                ? areas.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item.area}
                        data-assessor={item.assessor_email}
                      >
                        {item.area}
                      </option>
                    );
                  })
                : ""}
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>
      {selectedOption ? (
        <Row className="root_cause">
          <Col sm={6}>
            <p className="ds_label">Default Assessor: {defaultAssessor}</p>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {selectedOption ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="defaultAssessor">
              Select New Default Assessor
            </label>
            <div className="ds_select-wrapper">
              <select
                name="defaultAssessor"
                id="defaultAssessor"
                className="ds_select"
                value={defaultAssessor}
                onChange={(e) => updateAreaAssessor(e)}
              >
                {assessors
                  ? assessors.map((item, index) => {
                      return (
                        <option key={index} value={item.email}>
                          {item.email}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <CalltoActionButton
                submitFunction={addDefaultAssessor}
                name="Save"
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default Areas;
