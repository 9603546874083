export const localAuthorityPWSZonesData = [
  {
    name: "Aberdeen City - North East Scotland",
    code: "ABE_NES",
  },
  {
    name: "Aberdeenshire - North East Scotland",
    code: "ABD_NES",
  },
  {
    name: "Aberdeenshire - Tay",
    code: "ABD_TAY",
  },
  {
    name: "Angus - Tay",
    code: "ANS_TAY",
  },
  {
    name: "Argyll & Bute - Argyll",
    code: "AGB_ARG",
  },
  {
    name: "Argyll & Bute - Clyde",
    code: "AGB_CLY",
  },
  {
    name: "Argyll & Bute - Tay",
    code: "AGB_TAY",
  },
  {
    name: "Clackmannanshire - Forth",
    code: "CLK_FOR",
  },
  {
    name: "Dumfries & Galloway - Clyde",
    code: "DGY_CLY",
  },
  {
    name: "Dumfries & Galloway - Solway",
    code: "DGY_SOL",
  },
  {
    name: "Dundee City - Tay",
    code: "DND_TAY",
  },
  {
    name: "East Ayrshire - Clyde",
    code: "EAY_CLY",
  },
  {
    name: "East Ayrshire - Solway",
    code: "EAY_SOL",
  },
  {
    name: "East Dunbartonshire - Clyde",
    code: "EDU_CLY",
  },
  {
    name: "East Dunbartonshire - Forth",
    code: "EDU_FOR",
  },
  {
    name: "East Lothian - Forth",
    code: "ELN_FOR",
  },
  {
    name: "East Lothian - Tweed",
    code: "ELN_TWE",
  },
  {
    name: "East Renfrewshire - Clyde",
    code: "ERW_CLY",
  },
  {
    name: "Edinburgh, City of - Forth",
    code: "EDH_FOR",
  },
  {
    name: "Eilean Siar - West Highland",
    code: "ELS_WHI",
  },
  {
    name: "Falkirk - Forth",
    code: "FAL_FOR",
  },
  {
    name: "Fife - Forth",
    code: "FIF_FOR",
  },
  {
    name: "Fife - Tay",
    code: "FIF_TAY",
  },
  {
    name: "Glasgow City - Clyde",
    code: "GLG_CLY",
  },
  {
    name: "Highland - Argyll",
    code: "HLD_ARG",
  },
  {
    name: "Highland - North East Scotland",
    code: "HLD_NES",
  },
  {
    name: "Highland - North Highland",
    code: "HLD_NHI",
  },
  {
    name: "Highland - Tay",
    code: "HLD_TAY",
  },
  {
    name: "Highland - West Highland",
    code: "HLD_WHI",
  },
  {
    name: "Inverclyde - Clyde",
    code: "IVC_CLY",
  },
  {
    name: "Midlothian - Forth",
    code: "MLN_FOR",
  },
  {
    name: "Moray - North East Scotland",
    code: "MRY_NES",
  },
  {
    name: "Moray - North Highland",
    code: "MRY_NHI",
  },
  {
    name: "North Ayrshire - Clyde",
    code: "NAY_CLY",
  },
  {
    name: "North Lanarkshire - Clyde",
    code: "NLK_CLY",
  },
  {
    name: "North Lanarkshire - Forth",
    code: "NLK_FOR",
  },
  {
    name: "Orkney Islands - Orkney and Shetland",
    code: "ORK_ONS",
  },
  {
    name: "Perth & Kinross - Forth",
    code: "PKN_FOR",
  },
  {
    name: "Perth & Kinross - Tay",
    code: "PKN_TAY",
  },
  {
    name: "Renfrewshire - Clyde",
    code: "RFW_CLY",
  },
  {
    name: "Scottish Borders - Clyde",
    code: "SCB_CLY",
  },
  {
    name: "Scottish Borders - Forth",
    code: "SCB_FOR",
  },
  {
    name: "Scottish Borders - Solway",
    code: "SCB_SOL",
  },
  {
    name: "Scottish Borders - Tweed",
    code: "SCB_TWE",
  },
  {
    name: "Shetland Islands - Orkney and Shetland",
    code: "ZET_ONS",
  },
  {
    name: "South Ayrshire - Clyde",
    code: "SAY_CLY",
  },
  {
    name: "South Ayrshire - Solway",
    code: "SAY_SOL",
  },
  {
    name: "South Lanarkshire - Clyde",
    code: "SLK_CLY",
  },
  {
    name: "South Lanarkshire - Forth",
    code: "SLK_FOR",
  },
  {
    name: "South Lanarkshire - Solway",
    code: "SLK_SOL",
  },
  {
    name: "South Lanarkshire - Tweed",
    code: "SLK_TWE",
  },
  {
    name: "Stirling - Clyde",
    code: "STG_CLY",
  },
  {
    name: "Stirling - Forth",
    code: "STG_FOR",
  },
  {
    name: "Stirling - Tay",
    code: "STG_TAY",
  },
  {
    name: "West Dunbartonshire - Clyde",
    code: "WDU_CLY",
  },
  {
    name: "West Lothian - Forth",
    code: "WLN_FOR",
  },
];
