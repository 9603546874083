import { useState, useContext } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";
import { AccountContext } from "../components/Account";
import logo from "../assets/img/dwqr_logo.png";
import { imageClick } from "../utils/helpers";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [emailValidationmessage, setEmailValidationMessage] = useState(null);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState(null);

  const { authenticate } = useContext(AccountContext);
  const history = useHistory();

  const emailChange = (e, emailState, setEmailState, setValidationState) => {
    setValidationState(null);
    setEmailState(e.target.value);
    if (emailState === "") {
      setValidationState("Please provide a username.");
    } else {
      setValidationState(null);
      setEmailState(e.target.value);
    }
  };

  const stateChanges = (e, state, setState, setValidationState) => {
    setValidationState(null);
    setState(e.target.value);
    if (state === "") {
      setValidationState("Please provide a password.");
    } else {
      setValidationState(null);
      setState(e.target.value);
    }
  };

  const submitLogin = (event) => {
    event.preventDefault();

    email !== ""
      ? setEmailValidationMessage(null)
      : setEmailValidationMessage("Please provide a username.");
    password !== ""
      ? setPasswordValidationMessage(null)
      : setPasswordValidationMessage("Please provide a password.");

    if (email && password) {
      authenticate(email, password)
        .then(() => {
          return history.push(`/home`);
        })
        .catch((err) => {
          if (err.message === "User does not exist.") {
            setShowAlert(
              <p className="ds_question__error-message">
                {"Username or password doesn't exist."}
              </p>
            );
          } else {
            setShowAlert(
              <p className="ds_question__error-message">{err.message}</p>
            );
          }
          setErrorMsg("ds_question--error");
        });
    }
  };

  function OktaLogin(event) {
    event.preventDefault();
    let uri =
      process.env.REACT_APP_OKTA_DOMAIN +
      "client_id=" +
      process.env.REACT_APP_OKTA_CLIENT_ID +
      "&response_type=token&scope=email+openid&redirect_uri=" +
      process.env.REACT_APP_CALLBACK_URL;
    window.location = uri;
  }

  return (
    <main>
      <form onSubmit={submitLogin}>
        <div className="top-menu-bar"></div>
        <div className="login_container">
          <Col md={4}>
            <img
              className="publicLogo"
              src={logo}
              alt="Drinking Water Quality for Scotland"
              onClick={() => imageClick(history, "/login")}
            />
          </Col>

          <Col md={4} className="loginWrapper">
            <h1>Sign In</h1>

            <div className={`ds_question ${errorMsg}`}>
              {showAlert}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="validationContainer">
                  <label className="ds_label ov_label" htmlFor="username">
                    Username
                  </label>

                  <div
                    className="validationContainer"
                    style={{
                      borderLeft:
                        emailValidationmessage !== null
                          ? "6px solid #d32205"
                          : "none",
                      paddingLeft:
                        emailValidationmessage !== null ? "6px" : "0px",
                    }}
                  >
                    {emailValidationmessage !== null ? (
                      <div className="validation">
                        <span>{emailValidationmessage}</span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="ds_select-wrapper">
                      <input
                        className={`ds_input user-email`}
                        id="username"
                        aria-label="username"
                        name="username"
                        type="text"
                        onBlur={(e) =>
                          emailChange(
                            e,
                            email,
                            setEmail,
                            setEmailValidationMessage
                          )
                        }
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`ds_question ${errorMsg}`}>
              {showAlert}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="validationContainer">
                  <label className="ds_label ov_label" htmlFor="password">
                    Password
                  </label>

                  <div
                    className="validationContainer"
                    style={{
                      borderLeft:
                        passwordValidationMessage !== null
                          ? "6px solid #d32205"
                          : "none",
                      paddingLeft:
                        passwordValidationMessage !== null ? "6px" : "0px",
                    }}
                  >
                    {passwordValidationMessage !== null ? (
                      <div className="validation">
                        <span>{passwordValidationMessage}</span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="ds_select-wrapper">
                      <input
                        className={`ds_input user-password`}
                        id="password"
                        aria-label="password"
                        name="password"
                        type="password"
                        onBlur={(e) =>
                          stateChanges(
                            e,
                            password,
                            setPassword,
                            setPasswordValidationMessage
                          )
                        }
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <NavLink to={"/forgot-password"}>Forgot your password?</NavLink>

            <div className="login_buttons_container">
              <button className="ds_button login-btn">Login</button>
              <button
                className="ds_button ds_button--secondary"
                onClick={OktaLogin}
              >
                Scottish Government Single Sign-On
              </button>
            </div>
          </Col>
        </div>
      </form>
    </main>
  );
}

export default Login;
