import React from "react";

const Alert = ({ type, style, icon, heading, message }) => {
  return (
    <div className="regionArea" id={type}>
      <div
        className="assetHeader"
        style={{
          backgroundColor: style.backgroundColor,
          borderColor: style.borderColor,
        }}
      >
        <h1>{heading}</h1>
        <span>{icon}</span>
      </div>

      <div className="regionBody" style={{ borderColor: style.borderColor }}>
        {message}
      </div>
    </div>
  );
};

export default Alert;
