const ValidationContainer = ({ children, validationMessage }) => {
  return (
    <div
      className="validationContainer"
      style={{
        borderLeft: validationMessage ? "6px solid #d32205" : "none",
        paddingLeft: validationMessage ? "6px" : "0px",
      }}
    >
      {validationMessage !== "" ? (
        <div className="validation">
          <span>{validationMessage}</span>
        </div>
      ) : (
        <div></div>
      )}
      {children}
    </div>
  );
};

export default ValidationContainer;
