import { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "../Account";
import { CallBackend } from "../../utils/apiCall";
import Loader from "../Loader";

const AssetInformation = ({ currentParams, setView, view }) => {
  const { refreshUser } = useContext(AccountContext);

  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [isObjectEmpty, setIsObjectEmpty] = useState(true);
  const [treatmentProcess, setTreatmentProcess] = useState(null);
  const [healthBoards, setHealthBoards] = useState(null);
  const [localAuthority, setLocalAuthority] = useState(null);

  useEffect(() => {
    getData();
    setView(false);
  }, [currentParams]);

  const handleClick = (e) => {
    e.preventDefault();
    setView(!view);
  };

  function createLocalAuthorityString(response) {
    let newArray = "";

    if (response[0].Data.length > 0) {
      newArray = Object.values(response[0].Data)
        .map((value) => value.la_name)
        .join(", ");
    }
    // console.log(newArray);
    return setLocalAuthority(newArray);
  }

  function createHealthBoardString(response) {
    let newArray = "";

    if (response[0].Data.length > 0) {
      newArray = Object.values(response[0].Data)
        .map((value) => value.hb_name)
        .join(", ");
    }
    return setHealthBoards(newArray);
  }

  function createTreatmentProcess(response) {
    // Make a copy of the response and remove the below keys

    const newArray = structuredClone(response[0]);
    [
      "hb_name",
      "local_auth",
      "region",
      "site_ref",
      "site_name",
      "volume_out_capacity",
      "status",
    ].forEach((item) => delete newArray[item]);

    // make a new var that is equal to the nested object
    const cleanObject = newArray;
    // // check each key value and remove those that are null
    Object.keys(cleanObject).forEach((key) => {
      if (cleanObject[key] === null) {
        delete cleanObject[key];
      }
    });

    // if the length of the object is 0 then set state to true
    if (Object.keys(cleanObject).length > 0) {
      setIsObjectEmpty(false);
    } else {
      setIsObjectEmpty(true);
    }
    // update state of treatmentprocess to the object
    return setTreatmentProcess(cleanObject);
  }

  async function getData() {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `asset_info/${currentParams}`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response[0]);
          createTreatmentProcess(response[0].Data);
          createHealthBoardString(response[0].hb_name);
          createLocalAuthorityString(response[0].local_auth);
          setError(null);
        })
        .catch((error) => {
          setSpinner(false);
          setError(error);
        });
    });
  }

  return (
    <Row>
      <Col>
        <div className="regionArea" id="assetInformation">
          <div className="regionHeader">
            <h2>{currentParams} Asset Information</h2>
          </div>

          {newError !== null && newError.response === undefined ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : newError ? (
            newError.response.status >= 400 &&
            newError.response.status <= 599 ? (
              <div className="regionBody">
                <h2>
                  There has been a problem with the system, if this problem
                  persists please contact DWQ.
                </h2>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {spinner ? <Loader height={"156px"} /> : ""}

          {data && !spinner && newError === null ? (
            <div className="regionBody">
              <Row>
                <Col>
                  <table className="regionTable">
                    <tbody>
                      <tr>
                        <td>Asset name:</td>
                        <td>{data.Data[0].site_name}</td>
                      </tr>
                      <tr>
                        <td>Volume:</td>
                        <td>{data.Data[0].volume_out_capacity}</td>
                      </tr>
                      <tr>
                        <td>SW Area:</td>
                        <td>{data.Data[0].region}</td>
                      </tr>
                      <tr>
                        <td>SW ref No:</td>
                        <td>{data.Data[0].site_ref}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col>
                  <table className="regionTable">
                    <tbody>
                      <tr>
                        <td>Status:</td>
                        <td>{data.status}</td>
                      </tr>
                      <tr>
                        <td>Local Authority:</td>
                        {localAuthority ? <td>{localAuthority}</td> : <td></td>}
                      </tr>
                      <tr>
                        <td>Health Board:</td>
                        {healthBoards ? <td>{healthBoards}</td> : <td></td>}
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Row>
                <div className="treatmentProcessAnchor">
                  {isObjectEmpty === false ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        handleClick(e);
                      }}
                      className="ds_link"
                    >
                      View treatment process
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </Row>

              {view ? (
                <Row>
                  <Col>
                    <ul className="treatmentProcess">
                      {treatmentProcess &&
                        Object.keys(treatmentProcess).map((item, index) => {
                          return (
                            <li key={index}>
                              <span>{item}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
    </Row>
  );
};

export default AssetInformation;
