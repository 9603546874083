import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CalltoActionButton from "./CalltoActionButton";

const RootCauseManagement = ({
  failureElements,
  setNewFailureElements,
  rootCauses,
  newRootCauses,
  setNewRootCauses,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [newRootCauseValue, setNewRootCauseValue] = useState("");
  const [editRootCause, setEditRootCause] = useState("");

  const [validationMessage, setValidationMessage] = useState(null);

  // EDITING A ROOT CAUSE

  function handleRootCauseState(e) {
    setSelectedOption(e.target.value);
    setEditRootCause(e.target.value);

    setValidationMessage(null);
  }

  function addNewRootCause() {
    setValidationMessage(null);

    // CHECK ROOT CAUSE LIST FOR ANY DUPLICATES

    const checkRootCauses = [...newRootCauses].filter((item) => {
      item.root_cause === newRootCauseValue;
    });

    // IF FALSE THEN ADD VALUE TO END OF ARRAY AS AN OBJECT AND THEN SORT

    if (checkRootCauses.length === 0) {
      const updatedRootCauses = [
        ...newRootCauses,
        {
          root_cause: newRootCauseValue,
        },
      ];

      const sortRootCauses = updatedRootCauses.sort((a, b) =>
        a.root_cause.toLowerCase() < b.root_cause.toLowerCase() ? -1 : 1
      );

      setNewRootCauses(sortRootCauses);
    }
  }

  // CREATING A NEW ROOT CAUSE

  function createNewRootCause(e) {
    setValidationMessage(null);
    setNewRootCauseValue(e.target.value);
  }

  function setValidationState(value) {
    const hasDuplicate = newRootCauses.filter((item) => {
      return item.root_cause.toLowerCase() === value.toLowerCase();
    });

    if (selectedOption !== value) {
      value !== "" && hasDuplicate.length > 0
        ? setValidationMessage(
            "This root cause already exists, please update the value."
          )
        : setValidationMessage(null);
    }

    if (selectedOption === "Add New Root Cause") {
      value !== "" && hasDuplicate.length > 0
        ? setValidationMessage(
            "This root cause already exists, please update the below value."
          )
        : setValidationMessage(null);
    }
  }

  function updateEditState(e) {
    setValidationMessage(null);
    setEditRootCause(e.target.value);
  }

  async function updateRootCauseName() {
    const index = [...newRootCauses].findIndex((item) => {
      return item.root_cause === selectedOption;
    });

    if (index !== -1) {
      // first check and update all failure elements root cause to the new value
      failureElements.filter((item) => {
        item.associated.find((rc) => {
          if (rc.root_cause === selectedOption) {
            rc.root_cause = editRootCause;
          }
        });
      });
      setNewFailureElements([...failureElements]),
        // set the root cause value of the item to state value
        ([...newRootCauses][index].root_cause = editRootCause);
    }
    setNewRootCauses([...newRootCauses]);
  }

  // DELETE A ROOT CAUSE

  function deleteRootCause() {
    let newArrayOffRouteCauses = [];

    for (let f in failureElements) {
      for (let rc in failureElements[f].associated) {
        if (failureElements[f].associated[rc].root_cause === selectedOption) {
          newArrayOffRouteCauses.push(failureElements[f].failure_element);
        }
      }
    }

    if (newArrayOffRouteCauses.length > 0) {
      setValidationMessage(
        <>
          <p>
            You must disassociate all failure elements with this root cause
            before deleting!
          </p>
          {newArrayOffRouteCauses.length > 0 ? (
            <>
              <p>Associated Failure Elements</p>
              <ul>
                {newArrayOffRouteCauses.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </>
          ) : (
            ""
          )}
        </>
      );
    } else {
      setValidationMessage(null);
      const filteredRootCauses = newRootCauses.filter(
        (item) => item.root_cause !== selectedOption
      );
      setNewRootCauses(filteredRootCauses);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  function resetState() {
    setSelectedOption("");
    setValidationMessage(null);
    setNewRootCauseValue("");
    setEditRootCause("");
  }

  useEffect(() => {
    resetState();
  }, [newRootCauses, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label htmlFor="rootCauses" className="ds_label">
            Root Cause
          </label>
          <div className="ds_select-wrapper">
            <select
              name="rootCauses"
              id="rootCauses"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => handleRootCauseState(e)}
            >
              <option value="">Select</option>
              {rootCauses
                ? rootCauses.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item.root_cause}
                        data-index={index}
                      >
                        {item.root_cause}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Root Cause">Add New Root Cause</option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {selectedOption === "Add New Root Cause" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newRootCause">
              New Root Cause Name
            </label>
            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}
              <input
                type="text"
                id="newRootCause"
                className="ds_input"
                value={newRootCauseValue}
                onChange={(e) => createNewRootCause(e)}
                onBlur={() => setValidationState(newRootCauseValue)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {selectedOption !== "" && selectedOption !== "Add New Root Cause" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="editRootCause">
              Edit Root Cause Name
            </label>

            <input
              type="text"
              id="editRootCause"
              className="ds_input"
              value={editRootCause}
              onChange={(e) => updateEditState(e)}
              onBlur={(e) => setValidationState(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption !== "" && selectedOption !== "Add New Root Cause" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="validation">
              <span>{validationMessage}</span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="root_cause">
        <Col sm={6}>
          <>
            {selectedOption !== "" &&
            selectedOption !== "Add New Root Cause" ? (
              <div className="multiple_button_container">
                {validationMessage === null ? (
                  <CalltoActionButton
                    submitFunction={deleteRootCause}
                    name="Delete"
                  />
                ) : (
                  ""
                )}

                {editRootCause !== "" &&
                selectedOption !== editRootCause &&
                validationMessage === null ? (
                  <CalltoActionButton
                    submitFunction={updateRootCauseName}
                    name="Save"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : selectedOption !== "" &&
              selectedOption === "Add New Root Cause" &&
              newRootCauseValue !== "" &&
              validationMessage === null ? (
              <div className="button_container">
                <CalltoActionButton
                  submitFunction={addNewRootCause}
                  name="Save"
                />
              </div>
            ) : (
              ""
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

export default RootCauseManagement;
