import { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import AssetTable from "../components/AssetTable";
import { AccountContext } from "../components/Account";
import { CallBackend } from "../utils/apiCall.js";
import Loader from "../components/Loader";
import SearchIcon from "../assets/img/search.svg";

const AssetCaseSearch = () => {
  const { refreshUser } = useContext(AccountContext);

  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(null);
  const [newError, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("");
  const [region, setRegion] = useState("");
  const [siteRef, setSiteRef] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, [pageNumber, siteRef, region, searchField]);

  const tableHeads = [
    { id: 1, value: "site_type", text: "Asset Type" },
    { id: 2, value: "site_name", text: "Asset Name" },
    { id: 3, value: "region", text: "Area Code" },
  ];

  function resetAll(e) {
    e.preventDefault();
    setSiteRef("");
    setRegion("");
    setSearchField("");
    setSearchQuery("");
    setPageNumber(1);
  }

  function getSearchInput(e) {
    return setSearchQuery(e.target.value);
  }

  function getAssetType(e) {
    return (
      setSiteRef(e.target.value), setSearchField(searchQuery), setPageNumber(1)
    );
  }

  function getAssetArea(e) {
    return (
      setRegion(e.target.value), setSearchField(searchQuery), setPageNumber(1)
    );
  }

  function handleSearch() {
    return setSearchField(searchQuery), setPageNumber(1);
  }

  async function getData() {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
        region: region,
        site_ref: siteRef,
        search: searchField,
      },
    };

    await refreshUser().then((result) => {
      CallBackend(body, `asset`, "GET", result, additionalParams)
        .then((response) => {
          setSpinner(false);
          setData(response);
          setTotalPages(response[1].total_pages);
          setError(null);
        })
        .catch((error) => {
          if (error.response) {
            setError(error);
          }
          setSpinner(false);
        });
    });
  }

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Asset Case Search</h1>
        </div>

        <div className="regionBody">
          <Row className="eventincidents_search">
            <Col>
              <label className="ds_label" htmlFor="asset-type">
                Asset Type
              </label>

              <div className="ds_select-wrapper">
                <select
                  className="ds_select"
                  id="asset-type"
                  name="asset-type"
                  value={siteRef}
                  onChange={(e) => getAssetType(e)}
                >
                  <option value="">View All</option>
                  <option value="A">Abstraction Point</option>
                  <option value="W">Catchment</option>
                  <option value="R">Reservoir/Tower</option>
                  <option value="S">Supply Point</option>
                  <option value="Z">Supply Zones</option>
                  <option value="T">WTWs</option>
                </select>
                <span className="ds_select-arrow" aria-hidden="true"></span>
              </div>
            </Col>

            <Col>
              <label className="ds_label" htmlFor="asset-area">
                Area
              </label>

              <div className="ds_select-wrapper">
                <select
                  className="ds_select"
                  name="asset-area"
                  id="asset-area"
                  value={region}
                  onChange={(e) => getAssetArea(e)}
                >
                  <option value="">View All</option>
                  <option value="NORTH">North</option>
                  <option value="SOUTH">South</option>
                  <option value="EAST">East</option>
                  <option value="WEST">West</option>
                </select>
                <span className="ds_select-arrow" aria-hidden="true"></span>
              </div>
            </Col>

            <Col xs={6}>
              <label className="ds_label" htmlFor="search">
                Search
              </label>
              <div className="ds_select-wrapper">
                <input
                  className="ds_input "
                  type="text"
                  id="search"
                  name="search"
                  value={searchQuery}
                  onChange={(e) => getSearchInput(e)}
                  onKeyDown={(e) => (e.key === "Enter" ? handleSearch(e) : "")}
                />
                <button
                  className="ds_select-search"
                  onClick={handleSearch}
                  aria-label="search"
                >
                  <img
                    className="ds_searchIcon"
                    src={SearchIcon}
                    alt="search icon"
                  />
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <a className="ds_link" href="#" onClick={(e) => resetAll(e)}>
                Reset search filters
              </a>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <Row className="eventincidents_search">
          <div>
            {newError ? (
              newError.response.status >= 400 &&
              newError.response.status <= 599 ? (
                <h2>
                  There has been a problem with the system, if this problem
                  persists please contact DWQ.
                </h2>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {!spinner && data === "No results found for this event" ? (
              <h2>No results were returned from your search.</h2>
            ) : (
              ""
            )}

            {spinner ? <Loader height={"400px"} /> : ""}

            {data && data[0].Data && newError === null && !spinner ? (
              <AssetTable
                tableHeads={tableHeads}
                data={data[0].Data}
                totalPages={totalPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            ) : (
              noData
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default AssetCaseSearch;
