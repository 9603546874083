import { localAuthorityPWSZonesData } from "./local_authority_pws_zones";

const PWSZoneHelp = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>PWS Zones</h1>
        </div>

        <table className="la_codes_table">
          <thead>
            <tr>
              <th scope="col">PWS Zone Name</th>
              <th scope="col">PWS Zone Code</th>
            </tr>
          </thead>
          <tbody>
            {localAuthorityPWSZonesData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PWSZoneHelp;
