import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AccountContext } from "../Account";
import NotificationFilters from "../NotificationFilters";
import NotificationTable from "../NotificationTable";
import { swUserOptions } from "./notifications-options";

const SWnotifications = () => {
  const { configCredentials } = useContext(AccountContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [refresh, setRefresh] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  const [types, setTypes] = useState(null);
  const [status, setStatus] = useState(null);
  const [user, setUser] = useState(null);

  function updateNotificationQuery() {
    searchParams.get("view-type")
      ? setStatus(searchParams.get("view-type"))
      : setStatus("");

    searchParams.get("category")
      ? setTypes(searchParams.get("category"))
      : setTypes("");

    searchParams.get("assessor")
      ? setUser(searchParams.get("assessor"))
      : setUser("");
  }

  useEffect(() => {
    updateNotificationQuery();
  }, [configCredentials, search]);

  return (
    <>
      <div className="regionWrapper">
        <NotificationFilters
          options={swUserOptions}
          refresh={refresh}
          setRefresh={setRefresh}
          statusCode={statusCode}
          setStatusCode={setStatusCode}
        />

        {types !== null && status !== null && user !== null && (
          <NotificationTable
            types={types}
            status={status}
            user={user}
            refresh={refresh}
            setStatusCode={setStatusCode}
          />
        )}

        {/* {!data && error && <h1>Notifications could not be loaded.</h1>} */}
      </div>
    </>
  );
};

export default SWnotifications;
