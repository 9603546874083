import React from "react";

const CategoryDeterminationMatrix = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Category Determination Matrix</h1>
        </div>
        <div className="regionBody">
          <table
            style={{ width: "100%", margin: "4px", border: "1px solid black" }}
          >
            <tbody>
              <tr>
                <td
                  rowSpan="2"
                  colSpan="2"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    border: "1px solid black",
                  }}
                >
                  <b>Likelihood of negative impact on public confidence</b>
                </td>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <b>Severity</b>
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  5 to 11
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  12 to 19
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  20 to 28
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  29 to 37
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  38 to 43
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td>
                  Minor, isolated failure of Regulatory standards
                  <br />
                  Customer Supply Issue (Internal Plumbing)
                  <br />
                  Problem with Asset or WTW equipment not causing failure of
                  standards
                  <br />
                  Information supplied gives confidence issues have been
                  addressed
                  <br />
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Very Low
                  <br />1{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  2
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  3
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  4
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                  }}
                >
                  5
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td>
                  • Repeat minor failure of Regulatory Standards
                  <br />
                  • Single, non-minor failure of Regulatory Standards
                  <br />
                  • Repeat of problems with Asset or WTW process element
                  <br />
                  • Isolated, low level detection of Cryptosporidium Oocysts
                  <br />
                  • Burst main causing limited WQ impact
                  <br />
                  • Sampling errors
                  <br />
                  • Information supplied inadequate to make assessment requiring
                  further requests or points of clarification. <br />
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Low
                  <br />2{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  2
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  4
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                  }}
                >
                  6
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                    borderBottom: "2px solid black",
                    borderRight: "2px solid black",
                  }}
                >
                  8
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                    border: "1px solid black",
                    borderTop: "2px solid black",
                  }}
                >
                  10
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td>
                  • Any WTW process failure resulting in a breach or possible
                  breach of Regulatory standards
                  <br />
                  • Any WTW process resulting in supply of inadequately
                  disinfected water
                  <br />
                  • Repeated failures of Regulatory standard at consumer tap
                  <br />
                  • Impaired aesthetic quality in a significant area of supply
                  <br />
                  • Series of Cryptosporidium detections OR high level of
                  detections
                  <br />
                  • Loss of supply to a significant number of consumers
                  <br />
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Medium
                  <br />3{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  3
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                  }}
                >
                  6
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                    border: "1px solid black",
                    borderTop: "2px solid black",
                    borderLeft: "2px solid black",
                  }}
                >
                  9
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  12
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 153, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  15
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td>
                  • Affects significant proportion of supply zone OR significant
                  population
                  <br />
                  • Duration of impaired aesthetic quality persists &#62; 24 hrs
                  <br />
                  • Full disinfection process failure
                  <br />
                  • Serious and extended failure of WTW process elements
                  <br />
                  • Serious failure of operations or WTW procedure
                  <br />
                  • Alternative supplies to &#62; 1 property mobilised
                  <br />
                  • Restrictions on water use imposed
                  <br />
                  • Causing significant consumer concern or media interest
                  <br />
                  • Repeated events of similar issue
                  <br />
                  • Breach of security affecting Asset or equipment performance
                  <br />
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  High
                  <br />4{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  4
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                    borderRight: "2px solid black",
                  }}
                >
                  8
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  12
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 153, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  16
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgb(255, 97, 97)",
                    border: "1px solid black",
                  }}
                >
                  20
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td>
                  • Cases of illness in the community
                  <br />
                  • Gross failure of regulatory standards
                  <br />
                  • Major failure of operating procedures
                  <br />
                  • Political Significance - Ministerial briefing required
                  <br />
                  • National media Interest due to Water Quality
                  <br />
                  • Breech of Security causing possible contamination
                  <br />
                  • Repeated instances of restrictions on water use imposed
                  <br />
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Very High
                  <br />5
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                    border: "1px solid black",
                  }}
                >
                  5
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                    border: "1px solid black",
                    borderTop: "2px solid black",
                    borderLeft: "2px solid black",
                  }}
                >
                  10
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255, 153, 0, 1)",
                    border: "1px solid black",
                  }}
                >
                  15
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgb(255, 97, 97)",
                    border: "1px solid black",
                  }}
                >
                  20
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgb(255, 97, 97)",
                    border: "1px solid black",
                  }}
                >
                  25
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <table
            style={{ width: "100%", margin: "4px", border: "1px solid black" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "rgba(0, 255, 0, 1)",
                  }}
                >
                  0 - 4
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Event response 1
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Not significant
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "rgba(204, 255, 204, 1)",
                  }}
                >
                  5 - 8
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Event response 2
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Minor
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "rgba(255, 255, 0, 1)",
                  }}
                >
                  9 - 12
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Event response 3
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Significant
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "rgba(255, 153, 0, 1)",
                  }}
                >
                  13 - 19
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Event response 4
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Serious
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "rgb(255, 97, 97)",
                  }}
                >
                  20 - 25
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Event response 5
                </td>
                <td style={{ textAlign: "center", border: "1px solid black" }}>
                  Major
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CategoryDeterminationMatrix;
