const SelectElement = ({
  setValidationMessage,
  inputTitle,
  inputAttribute,
  inputState,
  setInputState,
  data,
}) => {
  return (
    <>
      <label className="ds_label ov_label" htmlFor={inputAttribute}>
        {inputTitle}
      </label>
      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          name={inputAttribute}
          id={inputAttribute}
          value={inputState}
          onChange={(e) =>
            setValidationMessage
              ? (setInputState(e.target.value), setValidationMessage(""))
              : setInputState(e.target.value)
          }
        >
          <option value=""></option>
          {data.map((group) => {
            return (
              <option key={group.value} value={group.value}>
                {group.name}
              </option>
            );
          })}
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </>
  );
};

export default SelectElement;
