import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../Account";
import { CallBackend } from "../../utils/apiCall";
import { handleChange } from "../../utils/helpers";
import RestPagination from "../RestPagination";
import Loader from "../Loader";

const SamplingRequirements = ({ currentParams }) => {
  const { refreshUser } = useContext(AccountContext);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);
  const [parameters, setParameters] = useState(null);
  const [parameter, setParameter] = useState("");
  const [years, setYears] = useState(null);
  const [year, setYear] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [order, setOrder] = useState(false);

  useEffect(() => {
    getData();
    getParameters();
    getYears();
  }, [currentParams, parameter, year, pageNumber]);

  async function getData() {
    setSpinner(true);

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
        param_code: parameter,
        reporting_year: year,
      },
    };

    await refreshUser().then((result) => {
      CallBackend(
        {},
        `sampling_requirements/${currentParams}`,
        "GET",
        result,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response);
          setTotalPages(response);
          setError(null);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          setSpinner(false);
        });
    });
  }

  async function getParameters() {
    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    await refreshUser().then((result) => {
      CallBackend({}, `dwq_sampling_params`, "GET", result, additionalParams)
        .then((response) => {
          setParameters(response);
          setError(null);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
        });
    });
  }

  async function getYears() {
    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    await refreshUser().then((result) => {
      CallBackend({}, `asset_reporting_year`, "GET", result, additionalParams)
        .then((response) => {
          setYears(response[0].Data);
          setError(null);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
        });
    });
  }

  function sortTable(e, keyName) {
    if (typeof data === "object") {
      data[0].Data.sort((a, b) => {
        const first = a[keyName];
        const second = b[keyName];

        return e.target.textContent && order
          ? first > second
            ? -1
            : 1
          : first < second
          ? -1
          : 1;
      });
    }
  }

  const handleClick = (e, keyName) => {
    setOrder(!order);
    sortTable(e, keyName);
  };

  return (
    <>
      {spinner ? <Loader height={"192px"} /> : ""}
      {newError !== null && newError.response === undefined ? (
        <h2>
          There has been a problem with the system, if this problem persists
          please contact DWQ.
        </h2>
      ) : newError ? (
        newError.response.status >= 400 && newError.response.status <= 599 ? (
          <h2>
            There has been a problem with the system, if this problem persists
            please contact DWQ.
          </h2>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {data && !spinner && newError === null ? (
        <table className="samplingRequirements">
          <thead>
            <tr>
              <th>
                <div className="stackHeadingElement">
                  <span>Year</span>
                  <select
                    title="year"
                    name="year"
                    id="year"
                    aria-label="year"
                    value={year}
                    onChange={(e) => (
                      handleChange(e, setYear), setPageNumber(1)
                    )}
                  >
                    <option value="">All</option>
                    {years
                      ? years.map((item, index) => {
                          return (
                            <option key={index} value={item.reporting_year}>
                              {item.reporting_year}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </th>

              <th>
                <div className="stackHeadingElement">
                  <span>Parameters</span>
                  <select
                    title="parameter"
                    name="parameter"
                    id="parameter"
                    aria-label="parameter"
                    value={parameter}
                    onChange={(e) => (
                      handleChange(e, setParameter), setPageNumber(1)
                    )}
                  >
                    <option value="">View all parameters</option>
                    {parameters
                      ? parameters[0].Data.map((item, index) => {
                          return (
                            <option key={index} value={item.parameter_code}>
                              {item.parameter_name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </th>

              <th onClick={(e) => handleClick(e, "annual_frequency")}>
                Annual Frequency
              </th>
              <th onClick={(e) => handleClick(e, "samples_taken")}>
                no. taken
              </th>
              <th>% Taken</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto" }} tabIndex="0">
            {data &&
            data !== "No Sampling Requirements for this site ref" &&
            data[0].Data.length > 0 ? (
              data[0].Data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.reporting_year}</td>
                    <td>{item.parameter_code_name}</td>
                    <td>{item.annual_frequency}</td>
                    <td>{item.samples_taken}</td>
                    <td>{item.samples_taken_percentage}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No Data</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className="regionPaginate">
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages[1].total_pages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SamplingRequirements;
