import { localAuthorityData } from "../views/help/all_local_authority_data";

export const permissions = {
  dwqPermissions: [{ code: "DWQAdminPoolGroup" }, { code: "DWQUserPoolGroup" }],
  laPermissions: localAuthorityData,
  swPermissions: [
    { code: "SWEIUserPoolGroup" },
    { code: "SWRegUserPoolGroup" },
  ],
};
