import { useState, useContext } from "react";
import { AccountContext } from "../Account";
import { NotificationContext } from "../NotificationsProvider";
import { CallBackend } from "../../utils/apiCall";
import ValidationError from "./ValidationError";
import moment from "moment-timezone";

const Notification = ({ props, types }) => {
  const { refreshUser } = useContext(AccountContext);
  const { getNotifications } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const [read, setRead] = useState("Mark as Read");

  async function handleClick(e, id, date) {
    e.preventDefault();

    setRead("");

    const body = {
      _id: id,
      IsRead: "True",
      Date: date,
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        process.env.REACT_APP_GETNOTIFICATIONS + "/" + id,
        "PUT",
        configCredentials
      )
        .then(() => {
          getNotifications(types);
        })
        .catch((error) => {
          setError(error);
          console.error(error);
        });
    });
  }

  return (
    <>
      {error !== null ? (
        <div className="regionArea">
          <div className="regionHeader">
            <h2>Error</h2>
          </div>
          <div className="regionBody">
            <strong>Error when trying to update notification to read.</strong>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="regionArea" key={props._id}>
        <div className="regionHeader notifHeader">
          <h2>{props.Category}</h2>
        </div>

        <div className="regionBody">
          <div
            className={
              props.IsRead === true
                ? "notification_read"
                : "notification_unread"
            }
          >
            <div className="notification_item_list">
              <div className="notification_item_list_inner">
                <ul>
                  <li>
                    {moment(parseInt(props.Date)).format("HH:mm a DD/MM/YY")}
                  </li>
                  <li>{props.Category}</li>
                  {props.Message === undefined ? (
                    <li></li>
                  ) : (
                    <li>
                      {props.Category !== "Assessment Report Upload"
                        ? `${props.Message} by ${props.Email}`
                        : props.Message}
                    </li>
                  )}
                  {props.Category === "Validation Errors" &&
                  props.Errors !== undefined ? (
                    <li>{<ValidationError props={props.Errors} />}</li>
                  ) : (
                    ""
                  )}
                  <li>
                    {props.IsRead === true ? (
                      ""
                    ) : (
                      <a
                        href=""
                        onClick={(e) => handleClick(e, props._id, props.Date)}
                      >
                        {read}
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
