export const filterData = (d, v, b, c) => {
  return d
    .filter((item) => {
      return v === "View All" ? d : b === item.IsRead;
    })
    .filter((item) => {
      return c === "Category" ? d : c === item.Category;
    });
};

export const filterAssets = (data, assetType, assetArea, assetSearch) => {
  return data
    .filter((item) => {
      return assetType === "View All"
        ? data
        : assetType === item.source_type_code;
    })
    .filter((item) => {
      return assetArea === "View All" ? data : assetArea === item.region;
    })
    .filter((item) => {
      return assetSearch === "" ? data : assetSearch === item.site_name;
    });
};

export const handleChange = (event, updateState) => {
  return updateState(event.target.value);
};

export const sortData = (order, key) => {
  return function (a, b) {
    if (a[key] === b[key]) {
      return 0;
    }

    // nulls sort after anything else
    if (a[key] === undefined) {
      return 1;
    }
    if (b[key] === undefined) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (order) {
      return a[key] < b[key] ? -1 : 1;
    }

    // if descending, highest sorts first
    return a[key] < b[key] ? 1 : -1;
  };
};

export const imageClick = (history, path) => {
  return history.push(path);
};

export const isOktaUser = (username) => {
  return username.includes("OktaSAML") ? true : false;
};

export function checkStringValueExists(
  data,
  valueKey,
  newValue,
  currentValue,
  setValidationMessage,
  message
) {
  return data.find((item) => {
    if (
      stringComparison(item[valueKey], newValue) &&
      !stringComparison(newValue, currentValue)
    ) {
      setValidationMessage(message);
      return true;
    }
    return false;
  });
}

export function stringComparison(stringValue, comparisonString) {
  return (
    stringValue !== undefined &&
    comparisonString !== undefined &&
    setLowerCase(stringValue) === setLowerCase(comparisonString)
  );
}

export function setLowerCase(value) {
  return value.toString().toLowerCase();
}
