import { NavLink } from "react-router-dom";

const Home = ({ notificationSettings }) => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET;

  function downloadFile(e) {
    e.preventDefault();

    const params = {
      Bucket: `${BUCKET_NAME}`,
      Key: `la_template/PWS_data_return_template.xlsx`,
    };

    const s3 = new AWS.S3();
    const filename = `PWS_data_return_template.xlsx`;

    s3.getObject(params, (err, data) => {
      if (err) throw err;

      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}`;
      link.click();
    });
  }

  return (
    <div className="regionArea">
      <div className="regionHeader">
        <h1>Home</h1>
      </div>

      <div className="regionBody">
        <p>Welcome to the Local Authority data upload portal</p>

        <ul className="home">
          <li>
            <NavLink to={"/upload-document/"} className="ds_link">
              Data Uploads
            </NavLink>
            <p>
              Upload the data return for the current year, please make sure you
              are using the most up to date{" "}
              <a href="#" onClick={(e) => downloadFile(e)} className="ds_link">
                data return excel file
              </a>
              .
            </p>
          </li>
          <li>
            <NavLink to={"/previous-uploads"} className="ds_link">
              Previous Uploads
            </NavLink>
            <p>
              Download previous years data uploads from this section. This will
              only display the final successful data upload for each year.
            </p>
          </li>
          {notificationSettings && (
            <li>
              <NavLink
                to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                className="ds_link"
              >
                Notifications
              </NavLink>
              <p>
                This area displays all system reminders, upload updates and
                error messages from data uploads.
              </p>
            </li>
          )}
        </ul>

        <h2 className="helpAdviceHeading">Help &amp; Advice</h2>
        <p style={{ marginBottom: "0rem" }}>
          Provides assistance for data returns broken into five sections
        </p>
        <ul className="home">
          <li>
            <NavLink
              to={"/help-advice/supply-samples-help"}
              className="ds_link"
            >
              Supply Samples Help
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/help-advice/supply-details-help"}
              className="ds_link"
            >
              Supply Details Help
            </NavLink>
          </li>
          <li>
            <NavLink to={"/help-advice/pws-zone-help"} className="ds_link">
              PWS Zone Help
            </NavLink>
          </li>
          <li>
            <NavLink to={"/help-advice/la-codes-help"} className="ds_link">
              LA Codes Help
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/help-advice/parameter-code-help"}
              className="ds_link"
            >
              Parameter Code Help
            </NavLink>
          </li>
        </ul>

        <p>
          If you need any additional assistance please contact us at{" "}
          <a href="mailto:help@dwqr.co.uk" className="ds_link">
            help@dwqr.co.uk
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
