import React, { createContext, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import AWS from "aws-sdk";
import jwt_decode from "jwt-decode";
import { localAuthorityData } from "../views/help/all_local_authority_data";

const AccountContext = createContext();

// This component is the provider of the App after the user is successfully authenticated.
const Account = (props) => {
  const [loggedUser, setLoggeduser] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [configCredentials, setConfigCredentials] = useState(null);
  const [activeUser, setActiveUser] = useState(null);

  const [userAttr, setUserAttr] = useState({});

  const permissions = {
    dwqPermissions: ["DWQAdminPoolGroup", "DWQUserPoolGroup"],
    laPermissions: localAuthorityData,
    swPermissions: ["SWEIUserPoolGroup", "SWRegUserPoolGroup"],
  };

  // Callback authentication method to login the user after call back from okta
  const authenticateUser = async () => {
    return await new Promise((resolve, reject) => {
      let loginMap = {};
      loginMap[
        "cognito-idp." +
          process.env.REACT_APP_REGION +
          ".amazonaws.com/" +
          Pool.getUserPoolId()
      ] = localStorage.getItem("jwt");
      AWS.config.region = process.env.REACT_APP_REGION;

      // Add the User's Id Token to the Cognito credentials login map.
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
        Logins: loginMap,
      });

      AWS.config.credentials.getPromise().then((err) => {
        if (err) {
          reject(err);
          console.error("Authenticate User " + err);
        } else {
          setConfigCredentials(AWS.config.credentials);
          resolve(AWS.config.credentials);
        }
      });
    });
  };

  const refreshUser = async () => {
    return await new Promise((resolve, reject) => {
      if (AWS.config.credentials === null) {
        let loginMap = {};
        loginMap[
          "cognito-idp." +
            process.env.REACT_APP_REGION +
            ".amazonaws.com/" +
            Pool.getUserPoolId()
        ] = localStorage.getItem("jwt");
        AWS.config.region = process.env.REACT_APP_REGION;

        // Add the User's Id Token to the Cognito credentials login map.
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
          Logins: loginMap,
        });

        AWS.config.credentials.getPromise().then((err) => {
          if (err) {
            reject(err);
          } else {
            checkForRefresh().then((result) => {
              resolve(result);
            });
          }
        });
      } else {
        AWS.config.credentials.getPromise().then((err) => {
          if (err) {
            reject(err);
          } else {
            checkForRefresh().then((result) => {
              resolve(result);
            });
          }
        });
      }
    });
  };

  const checkForRefresh = async () => {
    return await new Promise((resolve, reject) => {
      if (AWS.config.credentials.needsRefresh()) {
        AWS.config.credentials.refresh(function (error) {
          if (error) {
            reject(error);
          } else {
            AWS.config.credentials.get(function (newError) {
              if (newError) {
                reject(newError);
              } else {
                setConfigCredentials(AWS.config.credentials);
                resolve(AWS.config.credentials);
              }
            });
          }
        });
      } else {
        setConfigCredentials(AWS.config.credentials);
        resolve(AWS.config.credentials);
      }
    });
  };

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      try {
        const cognitoUser = Pool.getCurrentUser();
        if (cognitoUser != null) {
          return resolve(cognitoUser);
        }
        return resolve(cognitoUser);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getUserSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject();
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });
            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        reject();
      }
    });
  };

  // Standard cognito check Authentication to login a user based on username and password.
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          // returns Cognito User Session info, Including Access Token, Id Token & refresh token
          localStorage.setItem("jwt", data.getIdToken().jwtToken);
          authenticateUser().then((result) => {
            setUserGroup(
              data.getAccessToken().decodePayload()["cognito:groups"][0]
            );
            setLoggeduser(data.getIdToken().decodePayload()["email"]);
            resolve(data);
          });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email;

          setUserAttr({
            isFirstLogin: true,
            user: user,
            userAttr: userAttributes,
          });

          window.location.href = `${window.location.origin}/#/new-password`;
        },
      });
    });
  };

  //pull the current users user group from the JWT.
  const getUserGroups = async () => {
    return await new Promise((resolve, reject) => {
      try {
        const decoded = jwt_decode(localStorage.getItem("jwt"));
        var group = "NOTFOUND";
        decoded["cognito:groups"].forEach((element) => {
          group = element;
        });
        resolve(setUserGroup(group));
      } catch (err) {
        reject(err);
        console.error(err);
      }
    });
  };

  // pull the username (email) from the JWT
  const getUserName = async () => {
    return await new Promise((resolve, reject) => {
      try {
        const decoded = jwt_decode(localStorage.getItem("jwt"));
        setLoggeduser(decoded["email"]);
        resolve(decoded["email"]);
      } catch (err) {
        reject(err);
      }
    });
  };

  const isUserActive = async () => {
    return await new Promise((resolve, reject) => {
      try {
        const getTheIDToken = localStorage.getItem("jwt");

        const decoded = jwt_decode(getTheIDToken);

        if (Date.now() >= decoded.exp * 1000) {
          resolve(false);
        } else {
          return resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        getUserGroups,
        getUserName,
        authenticate,
        authenticateUser,
        isUserActive,
        configCredentials,
        userGroup,
        setLoggeduser,
        loggedUser,
        permissions,
        setActiveUser,
        activeUser,
        getSession,
        refreshUser,
        getUserSession,
        userAttr,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
