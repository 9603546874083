import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../Account";
import { CallBackend } from "../../utils/apiCall";
import { handleChange } from "../../utils/helpers";
import RestPagination from "../RestPagination";
import Loader from "../Loader";
import moment from "moment";

const SampleFailures = ({ currentParams }) => {
  const { refreshUser } = useContext(AccountContext);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);
  const [parameters, setParameters] = useState(null);
  const [parameter, setParameter] = useState("");
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getData();
    getParameterCodes();
  }, [currentParams, parameter, pageNumber]);

  async function getData() {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
        param_code: parameter,
      },
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `sample_failures/${currentParams}`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response);
          setTotalPages(response);
          setError(null);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          setSpinner(false);
        });
    });
  }

  async function getParameterCodes() {
    const body = {};

    let additionalParams = {
      headers: {},
      queryParams: {},
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `dwq_sample_failures_params`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setParameters(response[0].Data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  return (
    <>
      {spinner ? <Loader height={"192px"} /> : ""}
      {newError !== null && newError.response === undefined ? (
        <h2>
          There has been a problem with the system, if this problem persists
          please contact DWQ.
        </h2>
      ) : newError ? (
        newError.response.status >= 400 && newError.response.status <= 599 ? (
          <h2>
            There has been a problem with the system, if this problem persists
            please contact DWQ.
          </h2>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {data && !spinner && newError === null ? (
        <table className="sampleFailures">
          <thead>
            <tr>
              <th>Sample No</th>
              <th>Date</th>
              <th>
                <div className="stackHeadingElement">
                  <span>Parameters</span>
                  <select
                    title="parameters"
                    aria-label="parameters"
                    name="parameters"
                    id="parameters"
                    value={parameter}
                    onChange={(e) => (
                      handleChange(e, setParameter), setPageNumber(1)
                    )}
                  >
                    <option value="">View all parameters</option>

                    {parameters ? (
                      parameters.map((item, index) => {
                        return (
                          <option key={index} value={item.code}>
                            {item.parameter_value}
                          </option>
                        );
                      })
                    ) : (
                      <option>No Data</option>
                    )}
                  </select>
                </div>
              </th>
              <th>Value</th>
              <th>Reg?</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto" }} tabIndex="0">
            {data && data[0].Data.length > 0 ? (
              data[0].Data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.result_number}</td>
                    <td>{moment(item.sample_date).format("DD/MM/YY")}</td>
                    <td>{item.parameter_value}</td>
                    <td>{item.sample_result}</td>
                    <td>{item.reporting_purpose === "REG" ? "Yes" : ""}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No Data</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className="regionPaginate">
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages[1].total_pages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SampleFailures;
