import { useState } from "react";
import { NavLink } from "react-router-dom";
import { handleChange } from "../../utils/helpers";
import Alert from "../Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LABucketUpload from "./LABucketUpload";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ValidationContainer from "../ValidationContainer";

const LocalAuthority = (props) => {
  const [fileOptionValue, setFileOptionValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [focus, setFocus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const { description, title } = props;

  function successMessageOne(fileName) {
    return {
      title: "File Successfully Uploaded",
      message: (
        <>
          <p>
            File {fileName} successfully uploaded at{" "}
            {moment().format("HH:mm A DD/MM/YY")} and is awaiting confirmation.
          </p>
          <p>
            The system is currently validating the upload, you will be contacted
            about the file status.
          </p>
        </>
      ),
    };
  }

  return (
    <>
      {showSuccess && successMessage && (
        <Alert
          type="success"
          style={{
            backgroundColor: "#5eb135",
            borderColor: "#5eb135",
          }}
          icon={<CheckCircleIcon />}
          heading={successMessage.title}
          message={successMessage.message}
        />
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>{title}</h1>
        </div>

        <div className="regionBody">
          <p>{description}</p>

          <p>
            If you need any additional assistance please see the{" "}
            <NavLink
              to={"/help-advice/supply-samples-help"}
              className="ds_link"
            >
              help and advice
            </NavLink>{" "}
            section or contact us at{" "}
            <a href="mailto:help@dwqr.co.uk" className="ds_link">
              help@dwqr.co.uk
            </a>
          </p>

          <p>Please upload all Local Authority files in the following order</p>

          <ol>
            <li>Supply Details</li>
            <li>Supply Samples</li>
          </ol>

          <div className="upload_select_wrapper">
            <label htmlFor="FileOption" className="ds_label ov_label">
              Upload Type
            </label>

            <ValidationContainer validationMessage={validationMessage}>
              <div className="ds_select-upload-wrapper">
                <select
                  name="File_Type_Option"
                  className="ds_select"
                  id="FileOption"
                  value={fileOptionValue}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(e) => handleChange(e, setFileOptionValue)}
                >
                  <option value="">Select</option>
                  <option value="supply_details">Supply Details</option>
                  <option value="supply_samples">Supply Samples</option>
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 35, color: focus ? "black" : "white" }}
                  />
                </div>
              </div>
            </ValidationContainer>
          </div>

          <LABucketUpload
            path="la_file"
            fileType={fileOptionValue}
            setShowSuccess={setShowSuccess}
            setFileOptionValue={setFileOptionValue}
            setValidationMessage={setValidationMessage}
            organisation={props.organisation}
            setSuccessMessage={setSuccessMessage}
            successMessageValue={successMessageOne}
          />
        </div>
      </div>
    </>
  );
};

export default LocalAuthority;
