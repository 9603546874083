import { useState } from "react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import logo from "../assets/img/dwqr_logo.png";
import SuccessMessage from "../components/SuccessMessage";
import ErrorMessage from "../components/ErrorMessage";
import { imageClick } from "../utils/helpers";

const ForgottenPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showConfirmationCode, setShowConfirmationCode] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageTwo, setShowSuccessMessageTwo] = useState(false);
  const [emailValidationmessage, setEmailValidationMessage] = useState(null);
  const [confirmationCodeValidation, setConfirmationCodeValidation] =
    useState(null);
  const [newPasswordValidationMessage, setNewPasswordValidationMessage] =
    useState(null);
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage,
  ] = useState(null);

  const emailChange = (e, emailState, setEmailState, setValidationState) => {
    setValidationState(null);
    setEmailState(e.target.value);
    if (emailState === "") {
      setValidationState("Please provide a username.");
    } else {
      setValidationState(null);
      setEmailState(e.target.value);
    }
  };

  const stateChanges = (
    e,
    state,
    setState,
    setValidationState,
    validationMessage,
    passwordMessage
  ) => {
    setValidationState(null);
    setState(e.target.value);
    if (state === "") {
      setValidationState(validationMessage);
    } else {
      if (passwordMessage !== undefined && checkPassword(state)) {
        setValidationState(null);
        setState(e.target.value);
      } else {
        if (passwordMessage !== undefined) {
          setValidationState(passwordMessage);
        }
      }
    }
  };

  let poolData = {
    UserPoolId: process.env.REACT_APP_USERPOOLID,
    ClientId: process.env.REACT_APP_USERPOOLCLIENTID,
  };

  let userPool = new CognitoUserPool(poolData);

  let userData = {
    Username: email,
    Pool: userPool,
  };

  let cognitoUser = new CognitoUser(userData);

  function forgotPassword(e) {
    e.preventDefault();
    email !== ""
      ? setEmailValidationMessage(null)
      : setEmailValidationMessage("Please provide a username.");

    if (email) {
      const promise = new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: (result) => {
            if (result) {
              setShowConfirmationCode(true);
              setShowErrorMessage(false);
              setShowSuccessMessageTwo(true);
            }
            resolve(result);
          },
          onFailure: (err) => {
            setShowErrorMessage(true);
            setErrorMsg(err.message);
            reject(err);
          },
        });
      });
      return promise;
    }
  }

  function checkPassword(password) {
    let checkPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
    return checkPassword.test(password);
  }

  function resetPassword(e) {
    e.preventDefault();

    confirmationCode !== ""
      ? setConfirmationCodeValidation(null)
      : setConfirmationCodeValidation("Please provide the code you received.");
    newPassword !== ""
      ? setNewPasswordValidationMessage(null)
      : setNewPasswordValidationMessage("Please provide a new password.");
    confirmPassword !== ""
      ? setConfirmPasswordValidationMessage(null)
      : setConfirmPasswordValidationMessage(
          "Please provide a confirmation of your new password."
        );

    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== confirmPassword
    ) {
      setNewPasswordValidationMessage("Passwords do not match.");
      setConfirmPasswordValidationMessage("Passwords do not match.");
    }

    if (
      newPassword &&
      confirmPassword &&
      confirmationCode &&
      newPassword === confirmPassword
    ) {
      const promise = new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(confirmationCode, newPassword, {
          onSuccess: (res) => {
            resolve(res);
            setShowSuccessMessage(true);
            setShowSuccessMessageTwo(false);
            setShowConfirmationCode(false);
            setShowErrorMessage(false);
            setTimeout(() => {
              history.push("/");
            }, 5000);
          },
          onFailure: (err) => {
            reject(err);
            setShowErrorMessage(true);
            setErrorMsg(err.message);
            setShowSuccessMessage(false);
          },
          undefined,
        });
      });
      return promise;
    }
  }

  return (
    <main>
      <form>
        <div className="top-menu-bar"></div>
        <div className="login_container">
          <Col md={4}>
            <img
              className="publicLogo"
              src={logo}
              alt="Drinking Water Quality for Scotland"
              onClick={() => imageClick(history, "/login")}
            />
          </Col>

          {showErrorMessage ? (
            <Col md={4}>
              <ErrorMessage heading="Error" message={errorMsg} />
            </Col>
          ) : (
            ""
          )}

          <Col md={4} className="loginWrapper">
            <h1>Password Recovery</h1>
          </Col>

          {!showConfirmationCode && !showSuccessMessage ? (
            <Col md={4} className="loginWrapper">
              <p>
                <strong>
                  This service is for standard accounts only. If you are a SSO
                  user please use the SSO reset password service.
                </strong>
              </p>

              <div className={`ds_question ${errorMsg}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="validationContainer">
                    <label className="ds_label ov_label" htmlFor="email">
                      Username
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          emailValidationmessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          emailValidationmessage !== null ? "6px" : "0px",
                      }}
                    >
                      {emailValidationmessage !== null ? (
                        <div className="validation">
                          <span>{emailValidationmessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          className={`ds_input user-email`}
                          id="email"
                          aria-label="email"
                          name="email"
                          type="text"
                          value={email}
                          onBlur={(e) =>
                            emailChange(
                              e,
                              email,
                              setEmail,
                              setEmailValidationMessage
                            )
                          }
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <p>
                      You will be sent a verification code to input below.
                      Please check your junk mail in the case it does not land
                      in your inbox.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {!showConfirmationCode && !showSuccessMessage ? (
            <Col md={4}>
              <div className="login_buttons_forgot_container">
                <button
                  className="ds_button login-btn"
                  onClick={forgotPassword}
                >
                  Send
                </button>
              </div>
            </Col>
          ) : (
            ""
          )}

          {showSuccessMessageTwo && !showErrorMessage ? (
            <Col md={4}>
              <SuccessMessage
                heading="Verification code sent"
                message="A verification code has been sent to your email address."
              />
            </Col>
          ) : (
            ""
          )}

          {showSuccessMessage ? (
            <div style={{ height: "100vh" }}>
              <SuccessMessage
                heading="Successfully reset password"
                message="Your password has been successfully reset. You will now be redirected to the login page."
              />
            </div>
          ) : (
            ""
          )}

          {showConfirmationCode ? (
            <Col md={4} className="loginWrapper">
              <div className={`ds_question ${errorMsg}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="validationContainer">
                    <label className="ds_label ov_label" htmlFor="code">
                      Code
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          confirmationCodeValidation !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          confirmationCodeValidation !== null ? "6px" : "0px",
                      }}
                    >
                      {confirmationCodeValidation !== null ? (
                        <div className="validation">
                          <span>{confirmationCodeValidation}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          className={`ds_input user-email`}
                          id="code"
                          aria-label="code"
                          name="code"
                          type="text"
                          value={confirmationCode}
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              confirmationCode,
                              setConfirmationCode,
                              setConfirmationCodeValidation,
                              "Please provide your code"
                            )
                          }
                          onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {showConfirmationCode ? (
            <Col md={4} className="loginWrapper">
              <div className={`ds_question ${errorMsg}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="validationContainer">
                    <label className="ds_label ov_label" htmlFor="newPassword">
                      New Password
                    </label>

                    <p>
                      Your password must be at least 10 characters and contain
                      at least one number, one uppercase letter and one
                      lowercase character.
                    </p>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          newPasswordValidationMessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          newPasswordValidationMessage !== null ? "6px" : "0px",
                      }}
                    >
                      {newPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{newPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          className={`ds_input user-email`}
                          id="newPassword"
                          aria-label="newPassword"
                          name="newPassword"
                          type="password"
                          value={newPassword}
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              newPassword,
                              setNewPassword,
                              setNewPasswordValidationMessage,
                              "Please provide a new password",
                              "Your password does not match the above criteria"
                            )
                          }
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {showConfirmationCode ? (
            <Col md={4} className="loginWrapper">
              <div className={`ds_question ${errorMsg}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="validationContainer">
                    <label
                      className="ds_label ov_label"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          confirmPasswordValidationMessage !== null
                            ? "6px solid #d32205"
                            : "none",
                        paddingLeft:
                          confirmPasswordValidationMessage !== null
                            ? "6px"
                            : "0px",
                      }}
                    >
                      {confirmPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{confirmPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          className={`ds_input user-email`}
                          id="confirmPassword"
                          aria-label="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          value={confirmPassword}
                          onBlur={(e) =>
                            stateChanges(
                              e,
                              confirmPassword,
                              setConfirmPassword,
                              setConfirmPasswordValidationMessage,
                              "Please confirm your new password",
                              "Your password does not match the above criteria"
                            )
                          }
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {showConfirmationCode ? (
            <Col md={4}>
              <div className="login_buttons_forgot_container">
                <button className="ds_button login-btn" onClick={resetPassword}>
                  Submit
                </button>
              </div>
            </Col>
          ) : (
            ""
          )}
        </div>
      </form>
    </main>
  );
};

export default ForgottenPassword;
