import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../Account";
import { CallBackend } from "../../utils/apiCall";
import Loader from "../Loader";

const AssetView = ({
  currentParams,
  parent,
  child,
  parentChild,
  all,
  expandView,
}) => {
  const { refreshUser } = useContext(AccountContext);

  const [data, setData] = useState(null);
  const [viewTwo, setViewTwo] = useState(null);

  const [newError, setError] = useState(null);
  const [newErrorTwo, setErrorTwo] = useState(null);
  const [grandParents, setGrandParents] = useState(null);
  const [parents, setParents] = useState(null);
  const [children, setChildren] = useState(null);
  const [grandChildren, setGrandChildren] = useState(null);
  const [spinnerOne, setSpinnerOne] = useState(false);
  const [spinnerTwo, setSpinnerTwo] = useState(false);

  const [siteRefNameMap, setSiteRefNameMap] = useState(null);
  const [siteRefNameMapViewTwo, setSiteRefNameMapViewTwo] = useState(null);

  const [viewTwoGP, setViewTwoGP] = useState(null);
  const [viewTwoP, setViewTwoP] = useState(null);
  const [viewTwoA, setViewTwoA] = useState(null);
  const [viewTwoC, setViewTwoC] = useState(null);
  const [viewTwoGC, setViewTwoGC] = useState(null);

  const history = useHistory();

  const SingleEventOverview = (id) => {
    setError(null);
    history.push({
      pathname: "/assetcase/" + id,
    });
  };

  useEffect(() => {
    getData();
    if (all) {
      getViewData();
    }
  }, [currentParams, all]);

  async function getData() {
    setSpinnerOne(true);
    const body = {};

    await refreshUser().then((configCredentials) => {
      CallBackend(body, `asset_view/${currentParams}`, "GET", configCredentials)
        .then((response) => {
          setSpinnerOne(false);
          setData(response);
          setGrandParents(response[0].Data[0].grandparents);
          setParents(response[0].Data[0].parents);
          setChildren(response[0].Data[0].children);
          setGrandChildren(response[0].Data[0].grandchildren);
          setSiteRefNameMap(response[0].site_ref_name_map);
          setError(null);
        })
        .catch((error) => {
          setSpinnerOne(false);
          setError(error);
        });
    });
  }

  async function getViewData() {
    setSpinnerTwo(true);
    const body = {};

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `asset_view_2/${currentParams}`,
        "GET",
        configCredentials
      )
        .then((response) => {
          setSpinnerTwo(false);
          setViewTwo(response);
          setViewTwoGP(response[0].Data[0].level_5);
          setViewTwoP(response[0].Data[0].level_4);
          setViewTwoA(response[0].Data[0].level_3);
          setViewTwoC(response[0].Data[0].level_2);
          setViewTwoGC(response[0].Data[0].level_1);
          setSiteRefNameMapViewTwo(response[0].site_ref_name_map);
          setErrorTwo(null);
        })
        .catch((error) => {
          setSpinnerTwo(false);
          setErrorTwo(error);
        });
    });
  }

  function findName(siteRef, data) {
    return data[siteRef];
  }

  return (
    <>
      {!all && spinnerOne ? <Loader height={"270px"} /> : ""}
      {all && spinnerTwo ? <Loader height={"270px"} /> : ""}

      {newError !== null && newError.response !== undefined ? (
        newError.response.status >= 400 && newError.response.status <= 599 ? (
          <div className="regionBody">
            <h2>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </h2>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {newErrorTwo !== null && newErrorTwo.response !== undefined ? (
        newErrorTwo.response.status >= 400 &&
        newErrorTwo.response.status <= 599 ? (
          <div className="regionBody">
            <h2>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </h2>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <div className="regionBody">
        {data && siteRefNameMap && !spinnerOne && newError === null ? (
          <div
            className="assetTreeView"
            style={
              expandView === true && !all
                ? { maxHeight: "270px", height: "100%" }
                : { height: "100%" }
            }
          >
            {parent || parentChild ? (
              <ul>
                <li>Grandparents</li>
                {grandParents ? (
                  Object.values(grandParents).map((item, index) => {
                    return (
                      <li
                        className={`customToolTip ${
                          item.charAt(0) === "R"
                            ? "peach"
                            : item.charAt(0) === "Z"
                            ? "turquoise"
                            : item.charAt(0) === "A"
                            ? "green"
                            : item.charAt(0) === "T"
                            ? "purple"
                            : item.charAt(0) === "S"
                            ? "blue"
                            : item.charAt(0) === "W"
                            ? "yellow"
                            : ""
                        }
                        `}
                        style={{ cursor: "pointer" }}
                        key={`vo_grandparents_${index}`}
                        onClick={() => SingleEventOverview(item)}
                      >
                        <div>{item}</div>
                        <div className="customToolTip">
                          {findName(item, siteRefNameMap)}
                        </div>
                        <div className="customToolTipText">
                          {findName(item, siteRefNameMap)}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li></li>
                )}
              </ul>
            ) : (
              ""
            )}

            {parent || parentChild ? (
              <ul>
                <li>Parents</li>
                {parents ? (
                  Object.values(parents).map((item, index) => {
                    return (
                      <li
                        className={`customToolTip ${
                          item.charAt(0) === "R"
                            ? "peach"
                            : item.charAt(0) === "Z"
                            ? "turquoise"
                            : item.charAt(0) === "A"
                            ? "green"
                            : item.charAt(0) === "T"
                            ? "purple"
                            : item.charAt(0) === "S"
                            ? "blue"
                            : item.charAt(0) === "W"
                            ? "yellow"
                            : ""
                        }
                      `}
                        style={{
                          cursor: "pointer",
                        }}
                        key={`vo_parents_${index}`}
                        onClick={() => SingleEventOverview(item)}
                      >
                        <div>{item}</div>
                        <div className="customToolTip">
                          {findName(item, siteRefNameMap)}
                        </div>
                        <div className="customToolTipText">
                          {findName(item, siteRefNameMap)}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li></li>
                )}
              </ul>
            ) : (
              ""
            )}

            {child || parentChild || parent ? (
              <ul>
                <li>Asset</li>
                <li
                  style={{
                    border: "2px solid #333333",
                    maxHeight: "51px",
                    alignItems: "center",
                  }}
                  className={`customToolTip ${
                    currentParams.charAt(0) === "R"
                      ? "peach"
                      : currentParams.charAt(0) === "Z"
                      ? "turquoise"
                      : currentParams.charAt(0) === "A"
                      ? "green"
                      : currentParams.charAt(0) === "T"
                      ? "purple"
                      : currentParams.charAt(0) === "S"
                      ? "blue"
                      : currentParams.charAt(0) === "W"
                      ? "yellow"
                      : ""
                  }
                        `}
                  key={`vo_asset_${currentParams}`}
                >
                  {currentParams}
                </li>
              </ul>
            ) : (
              ""
            )}

            {child || parentChild ? (
              <ul>
                <li>Children</li>
                {children ? (
                  Object.values(children).map((item, index) => {
                    return (
                      <li
                        className={`customToolTip ${
                          item.charAt(0) === "R"
                            ? "peach"
                            : item.charAt(0) === "Z"
                            ? "turquoise"
                            : item.charAt(0) === "A"
                            ? "green"
                            : item.charAt(0) === "T"
                            ? "purple"
                            : item.charAt(0) === "S"
                            ? "blue"
                            : item.charAt(0) === "W"
                            ? "yellow"
                            : ""
                        }
                      `}
                        style={{ cursor: "pointer" }}
                        key={`vo_children_${index}`}
                        onClick={() => SingleEventOverview(item)}
                      >
                        <div>{item}</div>
                        <div className="customToolTip">
                          {findName(item, siteRefNameMap)}
                        </div>
                        <div className="customToolTipText">
                          {findName(item, siteRefNameMap)}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li></li>
                )}
              </ul>
            ) : (
              ""
            )}

            {child || parentChild ? (
              <ul>
                <li>Grandchildren</li>
                {grandChildren ? (
                  Object.values(grandChildren).map((item, index) => {
                    return (
                      <li
                        className={`customToolTip ${
                          item.charAt(0) === "R"
                            ? "peach"
                            : item.charAt(0) === "Z"
                            ? "turquoise"
                            : item.charAt(0) === "A"
                            ? "green"
                            : item.charAt(0) === "T"
                            ? "purple"
                            : item.charAt(0) === "S"
                            ? "blue"
                            : item.charAt(0) === "W"
                            ? "yellow"
                            : ""
                        }
                      `}
                        style={{ cursor: "pointer" }}
                        key={`vo_grandchildren_${index}`}
                        onClick={() => SingleEventOverview(item)}
                      >
                        <div>{item}</div>
                        <div className="customToolTip">
                          {findName(item, siteRefNameMap)}
                        </div>
                        <div className="customToolTipText">
                          {findName(item, siteRefNameMap)}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li></li>
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {all &&
        siteRefNameMapViewTwo &&
        viewTwo !== null &&
        !spinnerTwo &&
        newError === null ? (
          <div
            className="assetTreeView"
            style={
              expandView === true && all
                ? { maxHeight: "270px", height: "100%" }
                : { height: "100%" }
            }
          >
            {all ? (
              <ul>
                <li>Grandparents</li>
                {viewTwoGP !== null && viewTwoGP !== undefined
                  ? viewTwoGP.map((item, index) => {
                      return (
                        <>
                          {item !== null ? (
                            <li
                              className={`customToolTip ${
                                item.charAt(0) === "R"
                                  ? "peach"
                                  : item.charAt(0) === "Z"
                                  ? "turquoise"
                                  : item.charAt(0) === "A"
                                  ? "green"
                                  : item.charAt(0) === "T"
                                  ? "purple"
                                  : item.charAt(0) === "S"
                                  ? "blue"
                                  : item.charAt(0) === "W"
                                  ? "yellow"
                                  : ""
                              }
                            `}
                              style={{ cursor: "pointer" }}
                              key={`vt_grandparents_${index}`}
                              onClick={() => SingleEventOverview(item)}
                            >
                              <div>{item}</div>
                              <div className="customToolTip">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                              <div className="customToolTipText">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  : ""}
              </ul>
            ) : (
              ""
            )}

            {all ? (
              <ul>
                <li>Parents</li>
                {viewTwoP !== null && viewTwoP !== undefined
                  ? viewTwoP.map((item, index) => {
                      return (
                        <>
                          {item !== null ? (
                            <li
                              className={`customToolTip ${
                                item.charAt(0) === "R"
                                  ? "peach"
                                  : item.charAt(0) === "Z"
                                  ? "turquoise"
                                  : item.charAt(0) === "A"
                                  ? "green"
                                  : item.charAt(0) === "T"
                                  ? "purple"
                                  : item.charAt(0) === "S"
                                  ? "blue"
                                  : item.charAt(0) === "W"
                                  ? "yellow"
                                  : ""
                              }
                            `}
                              style={{ cursor: "pointer" }}
                              key={`vt_parents_${index}`}
                              onClick={() => SingleEventOverview(item)}
                            >
                              <div>{item}</div>
                              <div className="customToolTip">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                              <div className="customToolTipText">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  : ""}
              </ul>
            ) : (
              ""
            )}

            {all ? (
              <ul>
                <li>Asset</li>
                {viewTwoA !== null && viewTwoA !== undefined
                  ? viewTwoA.map((item, index) => {
                      return (
                        <>
                          {item !== null ? (
                            <li
                              className={`customToolTip ${
                                item.charAt(0) === "R"
                                  ? "peach"
                                  : item.charAt(0) === "Z"
                                  ? "turquoise"
                                  : item.charAt(0) === "A"
                                  ? "green"
                                  : item.charAt(0) === "T"
                                  ? "purple"
                                  : item.charAt(0) === "S"
                                  ? "blue"
                                  : item.charAt(0) === "W"
                                  ? "yellow"
                                  : ""
                              }
                            `}
                              style={{ cursor: "pointer" }}
                              key={`vt_asset_${index}`}
                              onClick={() => SingleEventOverview(item)}
                            >
                              <div>{item}</div>
                              <div className="customToolTip">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                              <div className="customToolTipText">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  : ""}
              </ul>
            ) : (
              ""
            )}

            {all ? (
              <ul>
                <li>Children</li>
                {viewTwoC !== null && viewTwoC !== undefined
                  ? viewTwoC.map((item, index) => {
                      return (
                        <>
                          {item !== null ? (
                            <li
                              className={`customToolTip ${
                                item.charAt(0) === "R"
                                  ? "peach"
                                  : item.charAt(0) === "Z"
                                  ? "turquoise"
                                  : item.charAt(0) === "A"
                                  ? "green"
                                  : item.charAt(0) === "T"
                                  ? "purple"
                                  : item.charAt(0) === "S"
                                  ? "blue"
                                  : item.charAt(0) === "W"
                                  ? "yellow"
                                  : ""
                              }
                            `}
                              style={{ cursor: "pointer" }}
                              key={`vt_children_${index}`}
                              onClick={() => SingleEventOverview(item)}
                            >
                              <div>{item}</div>
                              <div className="customToolTip">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                              <div className="customToolTipText">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  : ""}
              </ul>
            ) : (
              ""
            )}

            {all ? (
              <ul>
                <li>Grandchildren</li>
                {viewTwoGC !== null &&
                viewTwoGC !== undefined &&
                viewTwoGC[0] !== null
                  ? viewTwoGC.map((item, index) => {
                      return (
                        <>
                          {item !== null ? (
                            <li
                              className={`customToolTip ${
                                item.charAt(0) === "R"
                                  ? "peach"
                                  : item.charAt(0) === "Z"
                                  ? "turquoise"
                                  : item.charAt(0) === "A"
                                  ? "green"
                                  : item.charAt(0) === "T"
                                  ? "purple"
                                  : item.charAt(0) === "S"
                                  ? "blue"
                                  : item.charAt(0) === "W"
                                  ? "yellow"
                                  : ""
                              }
                            `}
                              style={{ cursor: "pointer" }}
                              key={`vt_grandchildren_${index}`}
                              onClick={() => SingleEventOverview(item)}
                            >
                              <div>{item}</div>
                              <div className="customToolTip">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                              <div className="customToolTipText">
                                {findName(item, siteRefNameMapViewTwo)}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  : ""}
              </ul>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div className="key">
          <ul>
            <li>
              <div className="square yellow"></div>
              Catchment
            </li>
            <li>
              <div className="square green"></div>
              Abstraction Point
            </li>
            <li>
              <div className="square purple"></div>
              Water Treatment Works
            </li>
            <li>
              <div className="square peach"></div>
              Service Reservoir
            </li>
            <li>
              <div className="square blue"></div>
              Supply Point
            </li>
            <li>
              <div className="square turquoise"></div>
              Supply Zone
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AssetView;
