import { CognitoUserPool } from "amazon-cognito-identity-js";

//  GET POOL DETAILS
const poolData = {
  UserPoolId: process.env.REACT_APP_USERPOOLID,
  ClientId: process.env.REACT_APP_USERPOOLCLIENTID,
  RedirectUriSignIn: process.env.REACT_APP_CALLBACK_URL,
};

export default new CognitoUserPool(poolData);
