import { useEffect } from "react";

const Failures = ({
  hazard,
  hazards,
  failures,
  setFailures,
  failure,
  setFailure,
  setRootCause,
  handleStateChange,
}) => {
  useEffect(() => {
    createFailures();
  }, [hazard, failure]);

  function createFailures() {
    const getFailures = hazards.filter((item) => item.hazard === hazard);

    getFailures.length === 0
      ? setFailures([{ failure_element: failure }])
      : setFailures(getFailures[0].failure_elements);
  }

  function handleRootValue(e, setHazard) {
    handleStateChange(e.target.value, setHazard);
    setRootCause("");
  }

  return (
    <div className="root_cause">
      <label className="ds_label" htmlFor="failureElements">
        Failure Elements
      </label>
      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          id="failureElements"
          value={failure}
          onChange={(e) => handleRootValue(e, setFailure)}
        >
          <option value="">Select</option>

          {failures &&
          failure !== "" &&
          failures.filter((item) => item.failure_element === failure) ? (
            <option value={failure}>{failure}</option>
          ) : (
            ""
          )}

          {failures &&
            failures
              .filter((item) => item.failure_element !== failure)
              .map((item, index) => {
                return (
                  <option key={index} value={item.failure_element}>
                    {item.failure_element}
                  </option>
                );
              })}
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </div>
  );
};

export default Failures;
