import { useState, useContext, useEffect } from "react";
import { NavLink, useParams, Prompt } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { CallBackendAssessment } from "../utils/apiCall";
import { AccountContext } from "../components/Account";
import { ParameterContext } from "../components/ParametersProvider";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Hazards from "../components/assessment-components/Hazards";
import Failures from "../components/assessment-components/Failures";
import RootCause from "../components/assessment-components/RootCause";
import SecondFactor from "../components/assessment-components/SecondFactor";
import SeverityScore from "../components/assessment-components/SeverityScore";
import SaveModal from "../components/assessment-components/SaveModal";
import EventCategory from "../components/assessment-components/EventCategory";
import AcceptabilityCalculator from "../components/assessment-components/AcceptabilityCalculator";
import IncidentRiskIndexScore from "../components/assessment-components/IncidentRiskIndexScore";
import RegCheckBox from "../components/assessment-components/RegCheckBox";
import SaveAssessment from "../components/assessment-components/SaveAssessment";

import LaunchIcon from "@mui/icons-material/Launch";
import Loader from "../components/Loader";

import moment from "moment";

// Events - E&I sample Data Component
const EventAssessment = () => {
  const currentParams = useParams();
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_ATTACHED;

  const { refreshUser } = useContext(AccountContext);
  const { parameters, errorParameters } = useContext(ParameterContext);

  const [displayModal, setDisplayModal] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [loader, setLoader] = useState(false);

  // API State

  const [assessmentData, setAssessmentData] = useState(null);

  const [failures, setFailures] = useState(null);
  const [rootCauses, setRootCauses] = useState(null);

  // Show 2nd factor when clicked
  const [showSecondFactor, setShowSecondFactor] = useState(false);

  // Show Acceptability fields when link clicked
  const [showAcceptability, setShowAcceptability] = useState(false);

  // Fields - Asset Information
  const [assessor, setAssessor] = useState("");
  const [dateReviewed, setDateReviewed] = useState("");
  const [dateEventClosed, setDateEventClosed] = useState("");

  const [hazard, setHazard] = useState("");
  const [secondHazard, setSecondHazard] = useState("");
  const [thirdHazard, setThirdHazard] = useState("");
  const [fourthHazard, setFourthHazard] = useState("");
  const [fifthHazard, setFifthHazard] = useState("");
  const [sixthHazard, setSixthHazard] = useState("");
  const [seventhHazard, setSeventhHazard] = useState("");
  const [eighthHazard, setEighthHazard] = useState("");
  const [ninthHazard, setNinthHazard] = useState("");
  const [tenthHazard, setTenthHazard] = useState("");

  const [failure, setFailure] = useState("");
  const [rootCause, setRootCause] = useState("");
  const [secondFactor, setSecondFactor] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState("");
  const [infoDueDate, setInfoDueDate] = useState("");
  const [comments, setComments] = useState("");

  // Fields Regulatory Compliance

  const [regFailureTrivial, setRegFailureTrivial] = useState("");
  const [regFailureOccur, setRegFailureOccur] = useState("");
  const [publicBuilding, setPublicBuilding] = useState("");

  // Fields Event Classification

  const [durationComponent, setDurationComponent] = useState("");
  const [acceptability, setAcceptability] = useState("");
  const [population, setPopulation] = useState("");
  const [causedSW, setCausedSW] = useState("");

  const [severityScore, setSeverityScore] = useState("");
  const [negativeImpact, setNegativeImpact] = useState("");
  const [eventCategory, setEventCategory] = useState(0);
  const [eventCategoryLabel, setEventCategoryLabel] = useState("");
  const [infoQuality, setInfoQuality] = useState("");
  const [potentialIncident, setPotentialIncident] = useState("");

  // Fields Incident Administration

  const [classifiedAsIncident, setClassifiedAsIncident] = useState("");
  const [incidentReportRequired, setIncidentReportRequired] = useState("");
  const [incidentDeclared, setIncidentDeclared] = useState("");
  const [incidentReportDueDate, setIncidentReportDueDate] = useState("");
  const [reasonForClassifying, setReasonForClassifying] = useState("");
  const [incidentReportReceivedDate, setIncidentReportReceivedDate] =
    useState("");
  const [
    incidentReportSentConsulteesDate,
    setIncidentReportSentConsulteesDate,
  ] = useState("");
  const [incidentComments, setIncidentComments] = useState("");
  const [assessedPopulation, setAssessedPopulation] = useState(0);
  const [assessedDuration, setAssessedDuration] = useState(0);
  const [assessmentActionLevelScore, setAssessmentActionLevelScore] =
    useState(0);
  const [assessmentActionLevelScoreLabel, setAssessmentActionLevelScoreLabel] =
    useState("");
  const [incidentRiskIndexScore, setIncidentRiskIndexScore] = useState(0);

  const [regulations, setRegulations] = useState("");

  const [assessmentApprovalDate, setAssessmentApprovalDate] = useState("");
  const [assessmentIssuedSWDate, setAssessmentIssuedSWDate] = useState("");
  const [assessmentPublishedWebDate, setAssessmentPublishedWebDate] =
    useState("");
  const [assessmentSentConsulteesDate, setAssessmentSentConsulteesDate] =
    useState("");
  const [scheduledLastActionDate, setScheduledLastActionDate] = useState("");
  const [lastActionCompleteDate, setLastActionCompleteDate] = useState("");
  const [incidentSignedOffDate, setIncidentSignedOffDate] = useState("");
  const [actionAcceptDate, setActionAcceptDate] = useState("");

  const [populationAffected, setPopulationAffected] = useState(0);

  // State for page behavior

  const [spinner, setSpinner] = useState(false);
  const [errorGetAssessment, setErrorGetAssessment] = useState(null);
  const [errorPostAssessment, setErrorPostAssessment] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");

  const [didStateChange, setDidStateChange] = useState(false);

  const textarea = document.querySelectorAll("textarea");
  textarea.forEach((element) => {
    element.style.height = `${element.scrollHeight}px`;
    element.style.overflow = `hidden`;
  });

  useEffect(() => {
    getAssessmentData();
  }, [currentParams.id]);

  async function getAssessmentData() {
    setSpinner(true);
    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    const body = {};

    await refreshUser().then((configCredentials) => {
      CallBackendAssessment(
        body,
        `assessment_update/${currentParams.id}`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          if (response.status === 200) {
            setSpinner(false);
          }
          setAssessmentData(response);
          setAssessor(response.data[0].Data[0].assessor);
          setDateReviewed(response.data[0].Data[0].date_reviewed);
          setDateEventClosed(response.data[0].Data[0].date_event_closed);
          setHazard(response.data[0].Data[0].hazard1);
          setSecondHazard(response.data[0].Data[0].hazard2);
          setThirdHazard(response.data[0].Data[0].hazard3);
          setFourthHazard(response.data[0].Data[0].hazard4);
          setFifthHazard(response.data[0].Data[0].hazard5);
          setSixthHazard(response.data[0].Data[0].hazard6);
          setSeventhHazard(response.data[0].Data[0].hazard7);
          setEighthHazard(response.data[0].Data[0].hazard8);
          setNinthHazard(response.data[0].Data[0].hazard9);
          setTenthHazard(response.data[0].Data[0].hazard10);
          setFailure(response.data[0].Data[0].failure_element);
          setRootCause(response.data[0].Data[0].root_cause);
          setInfoDueDate(response.data[0].Data[0].add_info_due_date);

          setSecondFactor(response.data[0].Data[0].second_factor);
          setAdditionalInfo(response.data[0].Data[0].add_info_required);
          setComments(response.data[0].Data[0].comments);

          setRegFailureTrivial(response.data[0].Data[0].reg_failure_trivial);
          setRegFailureOccur(response.data[0].Data[0].reg_failure_likely);
          setPublicBuilding(response.data[0].Data[0].priority_premises);

          setDurationComponent(response.data[0].Data[0].duration_component);
          setAcceptability(response.data[0].Data[0].acceptability);
          setPopulation(response.data[0].Data[0].population);
          setCausedSW(response.data[0].Data[0].caused_sw);
          setSeverityScore(response.data[0].Data[0].severity_score);
          setNegativeImpact(response.data[0].Data[0].negative_impact);
          setEventCategory(response.data[0].Data[0].event_category);
          setEventCategoryLabel(response.data[0].Data[0].event_category_label);
          setInfoQuality(response.data[0].Data[0].info_quality);
          setPotentialIncident(response.data[0].Data[0].potential_incident);

          setClassifiedAsIncident(
            response.data[0].Data[0].classified_as_incident
          );
          setIncidentReportRequired(
            response.data[0].Data[0].incident_report_required
          );
          setIncidentDeclared(response.data[0].Data[0].incident_declared);
          setIncidentReportDueDate(
            response.data[0].Data[0].incident_report_due_date
          );
          setReasonForClassifying(response.data[0].Data[0].classifying_reason);
          setIncidentReportReceivedDate(
            response.data[0].Data[0].incident_report_received_date
          );
          setIncidentReportSentConsulteesDate(
            response.data[0].Data[0].incident_report_sent_consultees
          );
          setIncidentComments(response.data[0].Data[0].incident_comments);
          setAssessedPopulation(response.data[0].Data[0].assessed_population);
          setAssessedDuration(response.data[0].Data[0].assessed_duration);
          setAssessmentActionLevelScore(
            response.data[0].Data[0].action_level_score
          );
          setAssessmentActionLevelScoreLabel(
            response.data[0].Data[0].action_level_label
          );
          setIncidentRiskIndexScore(response.data[0].Data[0].risk_index);

          let {
            reg4,
            reg4_3,
            reg10,
            reg11,
            reg12,
            reg13,
            reg14,
            reg15,
            reg17,
            reg18,
            reg19,
            reg20,
            reg21,
            reg29_1,
            reg29_2,
            reg29_7,
            reg30,
            reg32,
            reg33,
            reg38,
            custom_reg1,
            custom_reg2,
            custom_reg3,
            custom_reg4,
            custom_reg5,
            custom_reg6,
            custom_reg7,
            custom_reg8,
            custom_reg9,
            custom_reg10,
            custom_reg11,
            custom_reg12,
            custom_reg13,
            custom_reg14,
            custom_reg15,
            custom_reg16,
            custom_reg17,
            custom_reg18,
            custom_reg19,
            custom_reg20,
          } = response.data[0].Data[0];
          setRegulations({
            reg4,
            reg4_3,
            reg10,
            reg11,
            reg12,
            reg13,
            reg14,
            reg15,
            reg17,
            reg18,
            reg19,
            reg20,
            reg21,
            reg29_1,
            reg29_2,
            reg29_7,
            reg30,
            reg32,
            reg33,
            reg38,
            custom_reg1,
            custom_reg2,
            custom_reg3,
            custom_reg4,
            custom_reg5,
            custom_reg6,
            custom_reg7,
            custom_reg8,
            custom_reg9,
            custom_reg10,
            custom_reg11,
            custom_reg12,
            custom_reg13,
            custom_reg14,
            custom_reg15,
            custom_reg16,
            custom_reg17,
            custom_reg18,
            custom_reg19,
            custom_reg20,
          });

          setAssessmentApprovalDate(response.data[0].Data[0].approval_date);
          setAssessmentIssuedSWDate(response.data[0].Data[0].issued_to_sw_date);
          setAssessmentPublishedWebDate(
            response.data[0].Data[0].assessment_published
          );
          setAssessmentSentConsulteesDate(
            response.data[0].Data[0].assessment_sent_consultees
          );
          setScheduledLastActionDate(
            response.data[0].Data[0].scheduled_last_action_complete
          );
          setLastActionCompleteDate(
            response.data[0].Data[0].last_action_complete
          );
          setIncidentSignedOffDate(response.data[0].Data[0].signed_off);

          setPopulationAffected(response.data[0].Data[0].population_affected);

          setActionAcceptDate(response.data[0].Data[0].action_accept_date);

          setErrorGetAssessment(null);
        })
        .catch((error) => {
          console.error(error);
          setErrorGetAssessment(error);
          setSpinner(false);
        });
    });
  }

  async function handleClick(e) {
    e.preventDefault();

    const body = {
      event_number: parseInt(currentParams.id),
      assessor: assessor,
      hazard1: hazard,
      hazard2: secondHazard,
      hazard3: thirdHazard,
      hazard4: fourthHazard,
      hazard5: fifthHazard,
      hazard6: sixthHazard,
      hazard7: seventhHazard,
      hazard8: eighthHazard,
      hazard9: ninthHazard,
      hazard10: tenthHazard,
      root_cause: rootCause,
      date_reviewed:
        dateReviewed === "" || dateReviewed === "Invalid date"
          ? ""
          : moment(dateReviewed).format("DD/MM/YYYY"),
      date_event_closed:
        dateEventClosed === "" || dateEventClosed === "Invalid date"
          ? ""
          : moment(dateEventClosed).format("DD/MM/YYYY"),
      failure_element: failure,
      add_info_required: additionalInfo,
      add_info_due_date:
        infoDueDate === "" || infoDueDate === "Invalid date"
          ? ""
          : moment(infoDueDate).format("DD/MM/YYYY"),
      comments: escapeAndWrap(comments),
      reg_failure_trivial: regFailureTrivial,
      reg_failure_likely: regFailureOccur,
      priority_premises: publicBuilding,
      duration_component: durationComponent,
      acceptability: acceptability,
      population: population,
      caused_sw: causedSW,
      severity_score: severityScore,
      negative_impact: negativeImpact,
      event_category: eventCategory,
      info_quality: infoQuality,
      potential_incident: potentialIncident,
      incident_declared:
        incidentDeclared === "" || incidentDeclared === "Invalid date"
          ? ""
          : moment(incidentDeclared).format("DD/MM/YYYY"),
      classified_as_incident: classifiedAsIncident,
      incident_report_required: incidentReportRequired,
      incident_report_due_date:
        incidentReportDueDate === "" || incidentReportDueDate === "Invalid date"
          ? ""
          : moment(incidentReportDueDate).format("DD/MM/YYYY"),
      classifying_reason: reasonForClassifying,
      incident_report_received_date:
        incidentReportReceivedDate === "" ||
        incidentReportReceivedDate === "Invalid date"
          ? ""
          : moment(incidentReportReceivedDate).format("DD/MM/YYYY"),
      incident_report_sent_consultees:
        incidentReportSentConsulteesDate === "" ||
        incidentReportSentConsulteesDate === "Invalid date"
          ? ""
          : moment(incidentReportSentConsulteesDate).format("DD/MM/YYYY"),
      incident_comments: escapeAndWrap(incidentComments),
      assessed_population: assessedPopulation,
      assessed_duration: assessedDuration,
      action_level_score: assessmentActionLevelScore,
      risk_index: incidentRiskIndexScore,
      reg4: regulations.reg4,
      reg4_3: regulations.reg4_3,
      reg10: regulations.reg10,
      reg11: regulations.reg11,
      reg12: regulations.reg12,
      reg13: regulations.reg13,
      reg14: regulations.reg14,
      reg15: regulations.reg15,
      reg17: regulations.reg17,
      reg18: regulations.reg18,
      reg19: regulations.reg19,
      reg20: regulations.reg20,
      reg21: regulations.reg21,
      reg29_1: regulations.reg29_1,
      reg29_2: regulations.reg29_2,
      reg29_7: regulations.reg29_7,
      reg30: regulations.reg30,
      reg32: regulations.reg32,
      reg33: regulations.reg33,
      reg38: regulations.reg38,
      approval_date:
        assessmentApprovalDate === "" ||
        assessmentApprovalDate === "Invalid date"
          ? ""
          : moment(assessmentApprovalDate).format("DD/MM/YYYY"),
      issued_to_sw_date:
        assessmentIssuedSWDate === "" ||
        assessmentIssuedSWDate === "Invalid date"
          ? ""
          : moment(assessmentIssuedSWDate).format("DD/MM/YYYY"),
      assessment_published:
        assessmentPublishedWebDate === "" ||
        assessmentPublishedWebDate === "Invalid date"
          ? ""
          : moment(assessmentPublishedWebDate).format("DD/MM/YYYY"),
      assessment_sent_consultees:
        assessmentSentConsulteesDate === "" ||
        assessmentSentConsulteesDate === "Invalid date"
          ? ""
          : moment(assessmentSentConsulteesDate).format("DD/MM/YYYY"),
      scheduled_last_action_complete:
        scheduledLastActionDate === "" ||
        scheduledLastActionDate === "Invalid date"
          ? ""
          : moment(scheduledLastActionDate).format("DD/MM/YYYY"),
      last_action_complete:
        lastActionCompleteDate === "" ||
        lastActionCompleteDate === "Invalid date"
          ? ""
          : moment(lastActionCompleteDate).format("DD/MM/YYYY"),
      signed_off:
        incidentSignedOffDate === "" || incidentSignedOffDate === "Invalid date"
          ? ""
          : moment(incidentSignedOffDate).format("DD/MM/YYYY"),
      action_accept_date:
        incidentSignedOffDate === "" || incidentSignedOffDate === "Invalid date"
          ? ""
          : moment(incidentSignedOffDate).format("DD/MM/YYYY"),
      action_level_label: assessmentActionLevelScoreLabel,
      event_category_label: eventCategoryLabel,
      second_factor: secondFactor,
      custom_reg1: regulations.custom_reg1,
      custom_reg2: regulations.custom_reg2,
      custom_reg3: regulations.custom_reg3,
      custom_reg4: regulations.custom_reg4,
      custom_reg5: regulations.custom_reg5,
      custom_reg6: regulations.custom_reg6,
      custom_reg7: regulations.custom_reg7,
      custom_reg8: regulations.custom_reg8,
      custom_reg9: regulations.custom_reg9,
      custom_reg10: regulations.custom_reg10,
      custom_reg11: regulations.custom_reg11,
      custom_reg12: regulations.custom_reg12,
      custom_reg13: regulations.custom_reg13,
      custom_reg14: regulations.custom_reg14,
      custom_reg15: regulations.custom_reg15,
      custom_reg16: regulations.custom_reg16,
      custom_reg17: regulations.custom_reg17,
      custom_reg18: regulations.custom_reg18,
      custom_reg19: regulations.custom_reg19,
      custom_reg20: regulations.custom_reg20,
    };

    await refreshUser().then((configCredentials) => {
      setLoader(true);
      CallBackendAssessment(
        body,
        `assessment_update/${currentParams.id}`,
        "POST",
        configCredentials
      )
        .then((response) => {
          setLoader(false);
          setStatusCode(response.status);
          setErrorPostAssessment(null);
        })
        .catch((error) => {
          setDisplayModal(false);
          setErrorPostAssessment(error);
        });
    });
  }

  function handleRadio(value, state) {
    setDidStateChange(true);
    return state(parseInt(value));
  }

  function handleAssessmentScore(e) {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const score = option.getAttribute("data-score");

    setDidStateChange(true);

    setAssessmentActionLevelScoreLabel(e.target.value);
    setAssessmentActionLevelScore(parseInt(score));
  }

  function handleStateChange(value, updateState) {
    setDidStateChange(true);
    return updateState(value);
  }

  function downloadFile(e) {
    e.preventDefault();

    const params = {
      Bucket: `${BUCKET_NAME}`,
      Key: `gold/event_attachments/templates/DWQR Incident Assessment - Template - Assessment letter.docx`,
    };

    const s3 = new AWS.S3();
    const filename = `DWQR Incident Assessment - Template - Assessment letter.docx`;

    s3.getObject(params, (err, data) => {
      if (err) throw err;

      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}`;
      link.click();
    });
  }

  function escapeAndWrap(str) {
    str = str
      .replace(/\r|\u000D|\u000A|\u2028|\u2029/g, "[CR]")
      .replace(/\s+/g, " ")
      .trim();
    return str;
  }

  function formatValue(str) {
    return str.replace(/\[CR\]/g, "\n");
  }

  return (
    <div className="page_wrapper">
      {displayModal ? (
        <SaveModal
          setDisplayModal={setDisplayModal}
          setErrorGetAssessment={setErrorGetAssessment}
          statusCode={statusCode}
          setStatusCode={setStatusCode}
          loader={loader}
        />
      ) : (
        ""
      )}

      <form>
        <div className="regionArea">
          <div className="regionHeader space-between">
            <h1>DWQR Assessment</h1>
            <SaveAssessment
              setValidationMessage={setValidationMessage}
              setDisplayModal={setDisplayModal}
              additionalInfo={additionalInfo}
              infoDueDate={infoDueDate}
              handleClick={handleClick}
              setDidStateChange={setDidStateChange}
              spinner={spinner}
            />
          </div>

          {didStateChange && (
            <Prompt
              message={(location) => {
                return location.pathname.startsWith("/app")
                  ? true
                  : `WARNING: Unsaved data.  Any unsaved changes will be lost!`;
              }}
            />
          )}

          {spinner ? <Loader height={"400px"} /> : ""}

          {/* Get Assessment data from api error */}
          {errorGetAssessment ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : (
            ""
          )}

          {/* Get Parameters data from api error */}
          {errorParameters && errorParameters.response ? (
            errorParameters.response.status >= 400 &&
            errorParameters.response.status <= 599 ? (
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* Error message if post request fails */}
          {errorPostAssessment ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : (
            ""
          )}

          {assessmentData ? (
            <div className="regionBody">
              <Row>
                <Col sm={6}>
                  <div className="root_cause">
                    <label className="ds_label" htmlFor="assessor">
                      DWQR Assessor
                    </label>
                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        id="assessor"
                        name="assessor"
                        value={assessor}
                        onChange={(e) =>
                          handleStateChange(e.target.value, setAssessor)
                        }
                      >
                        <option value="">Select</option>

                        {parameters &&
                        assessor !== "" &&
                        parameters.assessors.filter(
                          (item) => item.name === assessor
                        ) ? (
                          <option value={assessor}>{assessor}</option>
                        ) : (
                          ""
                        )}
                        {parameters &&
                          parameters.assessors
                            .filter((item) => item.name !== assessor)
                            .map((item, index) => {
                              return <option key={index}>{item.name}</option>;
                            })}
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label className="ds_label " htmlFor="date-reviewed">
                        Date reviewed
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="date-reviewed"
                          id="date-reviewed"
                          value={dateReviewed}
                          onChange={(e) =>
                            handleStateChange(e.target.value, setDateReviewed)
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label className="ds_label " htmlFor="date-event-closed">
                        Date event closed
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="date-event-closed"
                          id="date-event-closed"
                          value={dateEventClosed}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setDateEventClosed
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  {parameters && (
                    <Hazards
                      hazards={parameters.hazards}
                      hazard={hazard}
                      setHazard={setHazard}
                      secondHazard={secondHazard}
                      thirdHazard={thirdHazard}
                      fourthHazard={fourthHazard}
                      fifthHazard={fifthHazard}
                      sixthHazard={sixthHazard}
                      seventhHazard={seventhHazard}
                      eighthHazard={eighthHazard}
                      ninthHazard={ninthHazard}
                      tenthHazard={tenthHazard}
                      setSecondHazard={setSecondHazard}
                      setThirdHazard={setThirdHazard}
                      setFourthHazard={setFourthHazard}
                      setFifthHazard={setFifthHazard}
                      setSixthHazard={setSixthHazard}
                      setSeventhHazard={setSeventhHazard}
                      setEighthHazard={setEighthHazard}
                      setNinthHazard={setNinthHazard}
                      setTenthHazard={setTenthHazard}
                      showSecondFactor={showSecondFactor}
                      setShowSecondFactor={setShowSecondFactor}
                      secondFactor={secondFactor}
                      setFailure={setFailure}
                      setRootCause={setRootCause}
                      handleStateChange={handleStateChange}
                    />
                  )}
                </Col>

                <Col sm={6}>
                  {parameters && hazard && (
                    <Failures
                      failures={failures}
                      failure={failure}
                      setFailure={setFailure}
                      setFailures={setFailures}
                      hazards={parameters.hazards}
                      hazard={hazard}
                      setRootCause={setRootCause}
                      handleStateChange={handleStateChange}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  {parameters && failure && (
                    <RootCause
                      rootCause={rootCause}
                      setRootCause={setRootCause}
                      roots={parameters.failure_elements}
                      failure={failure}
                      rootCauses={rootCauses}
                      setRootCauses={setRootCauses}
                      handleStateChange={handleStateChange}
                    />
                  )}
                </Col>

                <Col sm={6}>
                  {showSecondFactor || secondFactor !== "" ? (
                    <SecondFactor
                      setSecondFactor={setSecondFactor}
                      secondFactor={secondFactor}
                      secondfactors={parameters.second_factors}
                      handleStateChange={handleStateChange}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="ds_field-group">
                    <div
                      className="ds_checkbox  ds_checkbox--small"
                      style={{ marginTop: "34px" }}
                    >
                      <input
                        className="ds_checkbox__input"
                        id="potentialIncident"
                        type="checkbox"
                        checked={potentialIncident}
                        onChange={(e) =>
                          handleStateChange(
                            e.target.checked,
                            setPotentialIncident
                          )
                        }
                      />
                      <label
                        className="ds_checkbox__label"
                        htmlFor="potentialIncident"
                      >
                        Potential Incident
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="ds_field-group">
                    <div
                      className="ds_checkbox  ds_checkbox--small"
                      style={{ marginTop: "34px" }}
                    >
                      <input
                        className="ds_checkbox__input"
                        id="additionalInfo"
                        type="checkbox"
                        checked={additionalInfo}
                        onChange={(e) => (
                          handleStateChange(
                            e.target.checked,
                            setAdditionalInfo
                          ),
                          e.target.checked === false
                            ? handleStateChange("Invalid date", setInfoDueDate)
                            : ""
                        )}
                      />
                      <label
                        className="ds_checkbox__label additionalInfo_label"
                        htmlFor="additionalInfo"
                      >
                        Additional info required
                      </label>
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label className="ds_label " htmlFor="info-due-date">
                        Info due date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="info-due-date"
                          id="info-due-date"
                          value={infoDueDate}
                          onChange={(e) =>
                            handleStateChange(e.target.value, setInfoDueDate)
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>

                    {(additionalInfo &&
                      infoDueDate === "Invalid date" &&
                      validationMessage !== "") ||
                    (additionalInfo &&
                      infoDueDate === "" &&
                      validationMessage !== "") ? (
                      <div className="validation">
                        <span>{validationMessage}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <div className="root_cause">
                    <label className="ds_label" htmlFor="comments">
                      Comments
                    </label>

                    <textarea
                      className="ds_input"
                      type="text"
                      id="comments"
                      value={formatValue(comments)}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setComments)
                      }
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="regionArea">
          <div className="regionHeader space-between">
            <h2>Regulatory Compliance Assessment</h2>
            <SaveAssessment
              setValidationMessage={setValidationMessage}
              setDisplayModal={setDisplayModal}
              additionalInfo={additionalInfo}
              infoDueDate={infoDueDate}
              handleClick={handleClick}
              setDidStateChange={setDidStateChange}
              spinner={spinner}
            />
          </div>

          {spinner ? <Loader height={"400px"} /> : ""}

          {/* Get Assessment data from api error */}
          {errorGetAssessment ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : (
            ""
          )}

          {assessmentData ? (
            <div className="regionBody">
              <Row>
                <Col sm={4}>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="regFailureTrivial"
                      type="checkbox"
                      checked={regFailureTrivial}
                      onChange={(e) =>
                        handleStateChange(
                          e.target.checked,
                          setRegFailureTrivial
                        )
                      }
                    />
                    <label
                      className="ds_checkbox__label"
                      htmlFor="regFailureTrivial"
                    >
                      Reg Failure Trivial
                    </label>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="regFailureOccur"
                      type="checkbox"
                      checked={regFailureOccur}
                      onChange={(e) =>
                        handleStateChange(e.target.checked, setRegFailureOccur)
                      }
                    />
                    <label
                      className="ds_checkbox__label"
                      htmlFor="regFailureOccur"
                    >
                      Reg Failure Likely to Reccur
                    </label>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="publicBuilding"
                      type="checkbox"
                      checked={publicBuilding}
                      onChange={(e) =>
                        handleStateChange(e.target.checked, setPublicBuilding)
                      }
                    />
                    <label
                      className="ds_checkbox__label"
                      htmlFor="publicBuilding"
                    >
                      Public Building
                    </label>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="regionArea">
          <div className="regionHeader space-between">
            <h2>Event Classification</h2>
            <SaveAssessment
              setValidationMessage={setValidationMessage}
              setDisplayModal={setDisplayModal}
              additionalInfo={additionalInfo}
              infoDueDate={infoDueDate}
              handleClick={handleClick}
              setDidStateChange={setDidStateChange}
              spinner={spinner}
            />
          </div>

          {spinner ? <Loader height={"400px"} /> : ""}

          {/* Get Assessment data from api error */}
          {errorGetAssessment ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : (
            ""
          )}

          {assessmentData ? (
            <div className="regionBody">
              <Row>
                <Col>
                  <div className="assessmentLinks">
                    <NavLink
                      to={`/eventincidents/event-information/${currentParams.id}`}
                      target="_blank"
                      id="eventInformation"
                      className="ds_link"
                      rel="noopener noreferrer"
                    >
                      Event Information
                      <LaunchIcon
                        sx={{ fontSize: 18, margin: "0rem 0.5rem" }}
                      />
                    </NavLink>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h3 className="assessmentSubHeading">Duration Component</h3>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="purelyFailingSample"
                      type="radio"
                      name="durationComponent"
                      checked={durationComponent === 1}
                      value={1}
                      onChange={(e) =>
                        handleRadio(e.target.value, setDurationComponent)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="purelyFailingSample"
                    >
                      purely failing sample (1 point)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="lessThanSix"
                      type="radio"
                      name="durationComponent"
                      checked={durationComponent === 3}
                      value={3}
                      onChange={(e) =>
                        handleRadio(e.target.value, setDurationComponent)
                      }
                    />
                    <label className="ds_radio__label" htmlFor="lessThanSix">
                      less than 6h (3 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="moreThanSix"
                      type="radio"
                      name="durationComponent"
                      checked={durationComponent === 9}
                      value={9}
                      onChange={(e) =>
                        handleRadio(e.target.value, setDurationComponent)
                      }
                    />
                    <label className="ds_radio__label" htmlFor="moreThanSix">
                      more than 6h (9 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="moreThanSeventyTwo"
                      type="radio"
                      name="durationComponent"
                      checked={durationComponent === 15}
                      value={15}
                      onChange={(e) =>
                        handleRadio(e.target.value, setDurationComponent)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="moreThanSeventyTwo"
                    >
                      more than 72h (15 points)
                    </label>
                  </div>
                </Col>
                <Col sm={6}>
                  <h3 className="assessmentSubHeading">
                    Caused or Exacerbated by SW?
                  </h3>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="noPoints"
                      type="radio"
                      name="causedSW"
                      checked={causedSW === 0}
                      value={0}
                      onChange={(e) => handleRadio(e.target.value, setCausedSW)}
                    />
                    <label className="ds_radio__label" htmlFor="noPoints">
                      No (0 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="yesPoints"
                      type="radio"
                      name="causedSW"
                      checked={causedSW === 5}
                      value={5}
                      onChange={(e) => handleRadio(e.target.value, setCausedSW)}
                    />
                    <label className="ds_radio__label" htmlFor="yesPoints">
                      Yes (5 points)
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="assessmentLinks">
                    <a
                      href=""
                      target="_blank"
                      onClick={(e) => (
                        e.preventDefault(),
                        setShowAcceptability(!showAcceptability)
                      )}
                      className="ds_link"
                      rel="noopener noreferrer"
                    >
                      Acceptability Component (Consumer Contact Rate Graph)
                    </a>
                  </div>
                </Col>
              </Row>

              {showAcceptability ? (
                <Row>
                  <Col>
                    <AcceptabilityCalculator
                      setAcceptability={setAcceptability}
                      handleStateChange={handleStateChange}
                    />
                  </Col>

                  <Col></Col>
                </Row>
              ) : (
                ""
              )}
              <Row>
                <Col sm={6}>
                  <h3 className="assessmentSubHeading">
                    Acceptability based on consumer complaints
                  </h3>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilityOnePoint"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 1}
                      value={1}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilityOnePoint"
                    >
                      &gt; 0.1 (1 point)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilityTwoPoints"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 2}
                      value={2}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilityTwoPoints"
                    >
                      0.1 - 0.9 (2 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilityThreePoints"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 3}
                      value={3}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilityThreePoints"
                    >
                      1.0 - 1.9 (3 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilityFourPoints"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 4}
                      value={4}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilityFourPoints"
                    >
                      2.0 - 2.9 (4 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilityFivePoints"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 6}
                      value={6}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilityFivePoints"
                    >
                      3.0 - 10 (6 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="acceptabilitySixPoints"
                      type="radio"
                      name="acceptability"
                      checked={acceptability === 8}
                      value={8}
                      onChange={(e) =>
                        handleRadio(e.target.value, setAcceptability)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="acceptabilitySixPoints"
                    >
                      greater than 10 (8 points)
                    </label>
                  </div>
                </Col>
                <Col sm={6}>
                  <h3 className="assessmentSubHeading">Population Component</h3>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="populationThreePoints"
                      type="radio"
                      name="population"
                      checked={population === 3}
                      value={3}
                      onChange={(e) =>
                        handleRadio(e.target.value, setPopulation)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="populationThreePoints"
                    >
                      less than 4 (3 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="populationSixPoints"
                      type="radio"
                      name="population"
                      checked={population === 6}
                      value={6}
                      onChange={(e) =>
                        handleRadio(e.target.value, setPopulation)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="populationSixPoints"
                    >
                      4 to 100 (6 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="populationNinePoints"
                      type="radio"
                      name="population"
                      checked={population === 9}
                      value={9}
                      onChange={(e) =>
                        handleRadio(e.target.value, setPopulation)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="populationNinePoints"
                    >
                      101 to 10,000 (9 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="populationTwelevePoints"
                      type="radio"
                      name="population"
                      checked={population === 12}
                      value={12}
                      onChange={(e) =>
                        handleRadio(e.target.value, setPopulation)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="populationTwelevePoints"
                    >
                      10,001 to 50,000 (12 points)
                    </label>
                  </div>
                  <div className="ds_radio ds_radio--small">
                    <input
                      className="ds_radio__input"
                      id="populationFifteenPoints"
                      type="radio"
                      name="population"
                      checked={population === 15}
                      value={15}
                      onChange={(e) =>
                        handleRadio(e.target.value, setPopulation)
                      }
                    />
                    <label
                      className="ds_radio__label"
                      htmlFor="populationFifteenPoints"
                    >
                      more than 50,000 (15 points)
                    </label>
                  </div>
                  <h3 className="assessmentSubHeading">
                    Scottish Water Population affected value:
                    <span id="populationAffected">
                      {populationAffected !== null && populationAffected !== ""
                        ? ` ${populationAffected}`
                        : " Not set"}
                    </span>
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="assessmentLinks">
                    <NavLink
                      to={`/eventincidents/category-determination-matrix/${currentParams.id}`}
                      target="_blank"
                      className="ds_link"
                      rel="noopener noreferrer"
                    >
                      Category Determination Matrix
                      <LaunchIcon
                        sx={{ fontSize: 18, margin: "0rem 0.5rem" }}
                      />
                    </NavLink>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <SeverityScore
                    severityScore={severityScore}
                    setSeverityScore={setSeverityScore}
                    duration={durationComponent}
                    causedSW={causedSW}
                    acceptability={acceptability}
                    population={population}
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="negativeImpact">
                      Likelihood of negative impact on public confidence
                    </label>
                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        id="negativeImpact"
                        value={negativeImpact}
                        onChange={(e) =>
                          handleStateChange(e.target.value, setNegativeImpact)
                        }
                      >
                        <option value="">Select</option>
                        <option value="Very Low">Very Low</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                        <option value="Very High">Very High</option>
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <EventCategory
                    eventCategory={eventCategory}
                    eventCategoryLabel={eventCategoryLabel}
                    severityScore={severityScore}
                    negativeImpact={negativeImpact}
                    setEventCategoryLabel={setEventCategoryLabel}
                    setEventCategory={setEventCategory}
                    setDidStateChange={setDidStateChange}
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="informationQuality">
                      Information Quality
                    </label>
                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        id="informationQuality"
                        value={infoQuality}
                        onChange={(e) =>
                          handleStateChange(e.target.value, setInfoQuality)
                        }
                      >
                        <option value="">Select</option>
                        <option value="0">Excellent (0 points)</option>
                        <option value="1">Sufficient (1 points)</option>
                        <option value="2">Inadequate (2 points)</option>
                        <option value="5">
                          Contains Inaccuracies (5 points)
                        </option>
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="regionArea">
          <div className="regionHeader space-between">
            <h2>Incident Administration</h2>
            <SaveAssessment
              setValidationMessage={setValidationMessage}
              setDisplayModal={setDisplayModal}
              additionalInfo={additionalInfo}
              infoDueDate={infoDueDate}
              handleClick={handleClick}
              setDidStateChange={setDidStateChange}
              spinner={spinner}
            />
          </div>

          {spinner ? <Loader height={"400px"} /> : ""}

          {/* Get Assessment data from api error */}
          {errorGetAssessment ? (
            <div className="regionBody">
              <h2>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </h2>
            </div>
          ) : (
            ""
          )}

          {assessmentData ? (
            <div className="regionBody">
              <Row>
                <Col sm={6}>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="classifiedAsIncident"
                      type="checkbox"
                      checked={classifiedAsIncident}
                      onChange={(e) =>
                        handleStateChange(
                          e.target.checked,
                          setClassifiedAsIncident
                        )
                      }
                    />
                    <label
                      className="ds_checkbox__label"
                      htmlFor="classifiedAsIncident"
                    >
                      Classified as incident
                    </label>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="incidentReportRequired"
                      type="checkbox"
                      checked={incidentReportRequired}
                      onChange={(e) =>
                        handleStateChange(
                          e.target.checked,
                          setIncidentReportRequired
                        )
                      }
                    />
                    <label
                      className="ds_checkbox__label"
                      htmlFor="incidentReportRequired"
                    >
                      Incident report required
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="incidentDeclaredSWDate"
                      >
                        Incident declared to SW date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="incidentDeclaredSWDate"
                          id="incidentDeclaredSWDate"
                          value={incidentDeclared}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setIncidentDeclared
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="incidentReportDueDate"
                      >
                        Incident report due date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="incidentReportDueDate"
                          id="incidentReportDueDate"
                          value={incidentReportDueDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setIncidentReportDueDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="reasonForClassifying">
                      Reason for classifying
                    </label>
                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        id="reasonForClassifying"
                        value={reasonForClassifying}
                        onChange={(e) =>
                          handleStateChange(
                            e.target.value,
                            setReasonForClassifying
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option
                          key="One"
                          value="Compliance not restored promptly"
                        >
                          Compliance not restored promptly
                        </option>
                        <option
                          key="Two"
                          value="Serious or recurring quality issue"
                        >
                          Serious or recurring quality issue
                        </option>
                        <option
                          key="Three"
                          value="Significant loss of control of treatment process"
                        >
                          Significant loss of control of treatment process
                        </option>
                        <option
                          key="Four"
                          value="Failure of disinfection process"
                        >
                          Failure of disinfection process
                        </option>
                        <option
                          key="Five"
                          value="Failure of management process"
                        >
                          Failure of management process
                        </option>
                        <option
                          key="Six"
                          value="Alternative supplies issued to more than one property"
                        >
                          Alternative supplies issued to more than one property
                        </option>
                        <option key="Seven" value="Failure of asset">
                          Failure of asset
                        </option>
                        <option
                          key="Eight"
                          value="Issue causing significant consumer concern / media interest"
                        >
                          Issue causing significant consumer concern / media
                          interest
                        </option>
                        <option
                          key="Nine"
                          value="Significant contamination of treated water"
                        >
                          Significant contamination of treated water
                        </option>
                        <option key="Ten" value="Other">
                          Other
                        </option>
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>

                <Col sm={3}>
                  <div>
                    <span className="ds_label remove">Assessment Template</span>
                    <a
                      href="#"
                      className="ds_link"
                      onClick={(e) => downloadFile(e)}
                    >
                      Assessment Template <FileDownloadIcon />
                    </a>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="incidentReportReceivedDate"
                      >
                        Incident report received date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="incidentReportReceivedDate"
                          id="incidentReportReceivedDate"
                          value={incidentReportReceivedDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setIncidentReportReceivedDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="incidentReportSentConsulteesDate"
                      >
                        Incident report sent to consultees date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="incidentReportSentConsulteesDate"
                          id="incidentReportSentConsulteesDate"
                          value={incidentReportSentConsulteesDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setIncidentReportSentConsulteesDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <div>
                    <label className="ds_label" htmlFor="incidentComments">
                      Incident Comments
                    </label>
                    <textarea
                      className="ds_input"
                      type="text"
                      id="incidentComments"
                      value={formatValue(incidentComments)}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setIncidentComments)
                      }
                    ></textarea>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="assessedPopulation">
                      Assessed Population
                    </label>
                    <input
                      className="ds_input"
                      type="number"
                      id="assessedPopulation"
                      value={assessedPopulation}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setAssessedPopulation)
                      }
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="assessedDuration">
                      Assessed Duration (Hrs)
                    </label>
                    <input
                      className="ds_input"
                      type="number"
                      id="assessedDuration"
                      value={assessedDuration}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setAssessedDuration)
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div>
                    <label
                      className="ds_label"
                      htmlFor="assessmentActionLevelScore"
                    >
                      Assessment Action Level Score
                    </label>
                    <div className="ds_select-wrapper">
                      <select
                        className="ds_select"
                        id="assessmentActionLevelScore"
                        value={assessmentActionLevelScoreLabel}
                        onChange={(e) => handleAssessmentScore(e)}
                      >
                        <option value="">Select</option>
                        <option
                          key="Eleven"
                          value="Prosecution (5 points)"
                          data-score={5}
                        >
                          Prosecution (5 points)
                        </option>
                        <option
                          key="Twelve"
                          value="Caution (5 points)"
                          data-score={5}
                        >
                          Caution (5 points)
                        </option>
                        <option
                          key="Thirteen"
                          value="Warning letter (4 points)"
                          data-score={4}
                        >
                          Warning letter (4 points)
                        </option>
                        <option
                          key="Fourteen"
                          value="Enforcement/Legal instrument (4 points)"
                          data-score={4}
                        >
                          Enforcement/Legal instrument (4 points)
                        </option>
                        <option
                          key="Fifteen"
                          value="Legal instrument in place (4 points)"
                          data-score={4}
                        >
                          Legal instrument in place (4 points)
                        </option>
                        <option
                          key="Sixteen"
                          value="Prosecution Considered (4 points)"
                          data-score={4}
                        >
                          Prosecution Considered (4 points)
                        </option>
                        <option
                          key="Seventeen"
                          value="Recommendations made (3 points)"
                          data-score={3}
                        >
                          Recommendations made (3 points)
                        </option>
                        <option
                          key="Eighteen"
                          value="Suggestions made (2 points)"
                          data-score={2}
                        >
                          Suggestions made (2 points)
                        </option>
                        <option
                          key="Nineteen"
                          value="No Recs or Suggestions (1 point)"
                          data-score={1}
                        >
                          No Recs or Suggestions (1 point)
                        </option>
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <IncidentRiskIndexScore
                    eventCategory={eventCategory}
                    assessmentActionLevelScore={assessmentActionLevelScore}
                    assessedPopulation={assessedPopulation}
                    assessedDuration={assessedDuration}
                    populationAffected={populationAffected}
                    setIncidentRiskIndexScore={setIncidentRiskIndexScore}
                    incidentRiskIndexScore={incidentRiskIndexScore}
                  />
                </Col>
              </Row>

              <Row>
                <h3 className="assessmentSubHeading">Regulations</h3>

                {parameters &&
                  regulations &&
                  parameters.regulations_contravened
                    .sort((regA, regB) => {
                      // e.g Reg 10(2) or Reg 10_2 or Reg 10
                      let expr = /Reg (\d+)(?:(?:\((\d+)\))|_(\d+))?/;
                      let regAParts = regA.label.match(expr);
                      let regBParts = regB.label.match(expr);
                      let regAPrimaryNumber = parseInt(regAParts[1]);
                      let regBPrimaryNumber = parseInt(regBParts[1]);
                      if (regAPrimaryNumber == regBPrimaryNumber) {
                        let regASecondaryNumber =
                          regAParts[2] !== undefined
                            ? parseInt(regAParts[2])
                            : regAParts[3] !== undefined
                            ? parseInt(regAParts[3])
                            : 0;
                        let regBSecondaryNumber =
                          regBParts[2] !== undefined
                            ? parseInt(regBParts[2])
                            : regBParts[3] !== undefined
                            ? parseInt(regAParts[3])
                            : 0;
                        return regASecondaryNumber < regBSecondaryNumber
                          ? -1
                          : 1;
                      }
                      return regAPrimaryNumber < regBPrimaryNumber ? -1 : 1;
                    })
                    .map((regulation, i) => (
                      <Col sm={6} key={i}>
                        <RegCheckBox
                          regulation={regulation.column_name}
                          name={regulation.value}
                          handleStateChange={handleStateChange}
                          state={regulations[regulation.column_name]}
                          setState={(value) =>
                            setRegulations({
                              ...regulations,
                              [regulation.column_name]: value,
                            })
                          }
                        />
                      </Col>
                    ))}
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="assessmentApprovalDate"
                      >
                        Assessment Approval Date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="assessmentApprovalDate"
                          id="assessmentApprovalDate"
                          value={assessmentApprovalDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setAssessmentApprovalDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="assessmentIssuedSWDate"
                      >
                        Assessment issued to SW date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="assessmentIssuedSWDate"
                          id="assessmentIssuedSWDate"
                          value={assessmentIssuedSWDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setAssessmentIssuedSWDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="assessmentPublishedWebDate"
                      >
                        Assessment published on web date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="assessmentPublishedWebDate"
                          id="assessmentPublishedWebDate"
                          value={assessmentPublishedWebDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setAssessmentPublishedWebDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="assessmentSentConsulteesDate"
                      >
                        Assessment sent to consultees date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="assessmentSentConsulteesDate"
                          id="assessmentSentConsulteesDate"
                          value={assessmentSentConsulteesDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setAssessmentSentConsulteesDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="scheduledLastActionDate"
                      >
                        Scheduled last action completion date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="scheduledLastActionDate"
                          id="scheduledLastActionDate"
                          value={scheduledLastActionDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setScheduledLastActionDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="lastActionCompleteDate"
                      >
                        Last Action Complete date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="lastActionCompleteDate"
                          id="lastActionCompleteDate"
                          value={lastActionCompleteDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setLastActionCompleteDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="incidentSignedOffDate"
                      >
                        Incident Signed Off date
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="incidentSignedOffDate"
                          id="incidentSignedOffDate"
                          value={incidentSignedOffDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setIncidentSignedOffDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={3}></Col>
                <Col sm={3}>
                  <div className="root_cause">
                    <div data-module="ds-datepicker-from">
                      <label
                        className="ds_label "
                        htmlFor="actionAcceptanceDateBySW"
                      >
                        Action Acceptance Date By SW
                      </label>
                      <div className="ds_select-wrapper">
                        <input
                          name="actionAcceptanceDateBySW"
                          id="actionAcceptanceDateBySW"
                          value={actionAcceptDate}
                          onChange={(e) =>
                            handleStateChange(
                              e.target.value,
                              setActionAcceptDate
                            )
                          }
                          data-dateformat="dd/mm/yyyy"
                          placeholder="dd/mm/yyyy"
                          type="date"
                          className="ds_select"
                        />
                        <span className="ds_select-calendar"></span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={3}></Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default EventAssessment;
