import { useState, useEffect, useRef } from "react";
import NotificationSidebar from "../NotificationSidebar";
import { Badge } from "react-bootstrap";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const NotificationBadge = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    function closeMenu(e) {
      if (menuRef.current && !menuRef?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    }

    document.body.addEventListener("click", closeMenu);

    return () => document.body.removeEventListener("click", closeMenu);
  }, []);

  return (
    <>
      <div className="notification_header_icon" id="notificationsIcon">
        <span className="notification_icon">
          {data.Data && data.Data.length > 0 ? (
            <Badge pill bg="danger">
              {data.Data.length >= 25 ? "25+" : data.Data.length}
            </Badge>
          ) : (
            ""
          )}

          <button
            className="iconButton"
            title="Notifications"
            aria-label="Notifications"
            ref={menuRef}
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <NotificationsNoneIcon fontSize="medium" />
          </button>
        </span>
      </div>
      {data.Data && isOpen ? <NotificationSidebar data={data.Data} /> : ""}
    </>
  );
};

export default NotificationBadge;
