import { useContext, useEffect, useState } from "react";
import SettingsForm from "../components/notifications/SettingsForm";
import { AccountContext } from "../components/Account";
import { NotificationContext } from "../components/NotificationsProvider";
import {
  dwqAdminOptions,
  dwqStandardOptions,
  swUserOptions,
  laUserOptions,
} from "../components/notifications/notifications-options";
import { localAuthorityData } from "./help/all_local_authority_data";

const NotificationSettings = () => {
  const { getUserGroups, userGroup } = useContext(AccountContext);

  const {
    notificationSettings: data,
    setNotificationSettings: setData,
    settingsError: error,
  } = useContext(NotificationContext);

  const [userSettingOptions, setUserSettingOptions] = useState(null);

  function determineUserSettings() {
    if (userGroup === "DWQAdminPoolGroup") {
      setUserSettingOptions(dwqAdminOptions);
    }
    if (userGroup === "DWQUserPoolGroup") {
      setUserSettingOptions(dwqStandardOptions);
    }
    if (
      userGroup === "SWEIUserPoolGroup" ||
      userGroup === "SWRegUserPoolGroup"
    ) {
      setUserSettingOptions(swUserOptions);
    }
    if (
      localAuthorityData.find((organisation) => organisation.code === userGroup)
    ) {
      setUserSettingOptions(laUserOptions);
    }
  }

  useEffect(() => {
    if (userGroup === null) {
      getUserGroups();
    }
    determineUserSettings();
  }, [userGroup]);

  return (
    <div className="page_wrapper">
      {error && (
        <div className="regionArea">
          <div className="assetHeader">
            <h1>Error getting notification settings.</h1>
          </div>
          <div className="regionBody">
            <p>
              There was an error getting your notification settings. Please try
              again.
            </p>
          </div>
        </div>
      )}

      {data && userGroup && userSettingOptions && (
        <SettingsForm
          data={data}
          setData={setData}
          userSettingOptions={userSettingOptions}
        />
      )}
    </div>
  );
};

export default NotificationSettings;
