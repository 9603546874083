import spinner from "../assets/img/water.gif";

// Page Loader  - Spinner
const Spinner = () => {
  return (
    <div className="ds_spinner">
      <img src={spinner} alt="loading" />
    </div>
  );
};

export default Spinner;
