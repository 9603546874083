export const parameterHelpCodes = [
  {
    Code: "A001",
    Name: "Colour",
    Unit: "mg/l Pt/Co",
    Mbic: "I",
    PCV: "20",
  },
  {
    Code: "A002",
    Name: "Turbidity",
    Unit: "NTU",
    Mbic: "I",
    PCV: "Type A: Acceptable to consumers and no abnormal change\nType B: 4 NTU",
  },
  {
    Code: "A002B",
    Name: "Turbidity (On Line Monitor)",
    Unit: "NTU",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A003",
    Name: "Odour",
    Unit: "Dilution Number",
    Mbic: "I",
    PCV: "Acceptable to consumers and no abnormal change",
  },
  {
    Code: "A003C",
    Name: "Qualitative Odour - Nature",
    Unit: "Freetext",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A004",
    Name: "Taste",
    Unit: "Dilution Number",
    Mbic: "I",
    PCV: "Acceptable to consumers and no abnormal change",
  },
  {
    Code: "A004B",
    Name: "Qualitative Taste- Nature",
    Unit: "See comment",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A005",
    Name: "Temperature",
    Unit: "°C",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A006",
    Name: "Hydrogen ion (pH)",
    Unit: "pH Value",
    Mbic: "I",
    PCV: "6.5 to 9.5",
  },
  {
    Code: "A006C",
    Name: "Hydrogen ion (pH) (On Line Monitor)",
    Unit: "pH value",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A007",
    Name: "Sulphate",
    Unit: "mg/l  SO4",
    Mbic: "I",
    PCV: "250",
  },
  {
    Code: "A008",
    Name: "Magnesium (Total)",
    Unit: "mg/l Mg",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A008A",
    Name: "Magnesium (Dissolved)",
    Unit: "mg/l Mg",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A009",
    Name: "Sodium (Total)",
    Unit: "mg/l Na",
    Mbic: "I",
    PCV: "200",
  },
  {
    Code: "A009A",
    Name: "Sodium (Dissolved)",
    Unit: "mg/l Na",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A010",
    Name: "Potassium (Total)",
    Unit: "mg/l K",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A010A",
    Name: "Potassium (Dissolved)",
    Unit: "mg/l K",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A011",
    Name: "Dry residue",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A012",
    Name: "Nitrate (Total)",
    Unit: "mg/l  NO3",
    Mbic: "",
    PCV: "50",
  },
  {
    Code: "A012A",
    Name: "Nitrate (on Line Monitor)",
    Unit: "mg/l  NO3",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A013",
    Name: "Nitrite",
    Unit: "mg/l NO2",
    Mbic: "I",
    PCV: "0.5 at tap 0.1 ex works (if there is treatment)",
  },
  {
    Code: "A013C",
    Name: "Nitrite/Nitrate formula",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A014",
    Name: "Ammonium (Total)",
    Unit: "mg/l  NH4",
    Mbic: "I",
    PCV: "0.5",
  },
  {
    Code: "A014A",
    Name: "Ammonium (Total)",
    Unit: "mg/l  N",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A014B",
    Name: "Ammonia (Total)",
    Unit: "mg/l  NH3",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A016",
    Name: "Oxidisability as O2",
    Unit: "mg O2/l",
    Mbic: "I",
    PCV: "5",
  },
  {
    Code: "A017",
    Name: "Total organic carbon",
    Unit: "mg/l C",
    Mbic: "I",
    PCV: "No abnormal change",
  },
  {
    Code: "A017A",
    Name: "Dissolved Organic Carbon",
    Unit: "mg/l C",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A017B",
    Name: "Particulate Organic Carbon",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A018",
    Name: "Total Petroleum Hydrocarbons",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A018A",
    Name: "Total Hydrocarbons (Dissolved)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A019",
    Name: "Total Phenol as C6H6OH",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A020",
    Name: "Surfactants",
    Unit: "μg/l as Lauryl Sulphate",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A021",
    Name: "Aluminium (Total)",
    Unit: "μg/l Al",
    Mbic: "I",
    PCV: "200",
  },
  {
    Code: "A021A",
    Name: "Aluminium (Dissolved)",
    Unit: "μg/l Al",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A022",
    Name: "Iron (Total)",
    Unit: "μg/l  Fe",
    Mbic: "I",
    PCV: "200",
  },
  {
    Code: "A022A",
    Name: "Iron (Dissolved)",
    Unit: "μg/l  Fe",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A022B",
    Name: "Iron (on Line Monitor)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A023",
    Name: "Manganese (Total)",
    Unit: "μg/l  Mn",
    Mbic: "I",
    PCV: "50",
  },
  {
    Code: "A023A",
    Name: "Manganese (Dissolved)",
    Unit: "μg/l  Mn",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A024",
    Name: "Copper (Total)",
    Unit: "mg/l Cu",
    Mbic: "C",
    PCV: "2",
  },
  {
    Code: "A024B",
    Name: "Copper (Dissolved)",
    Unit: "mg/l Cu",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A025",
    Name: "Zinc (Total)",
    Unit: "μg/l  Zn",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A025A",
    Name: "Zinc (Dissolved)",
    Unit: "μg/l  Zn",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A026",
    Name: "Phosphorus",
    Unit: "μg/l P/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A027",
    Name: "Fluoride (Total)",
    Unit: "mg/l F",
    Mbic: "C",
    PCV: "1.5",
  },
  {
    Code: "A028",
    Name: "Silver as Ag",
    Unit: "ug/l Ag",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "A028A",
    Name: "Silver (Dissolved)",
    Unit: "ug/l Ag",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B001",
    Name: "Arsenic (Total)",
    Unit: "µg/l As",
    Mbic: "C",
    PCV: "10",
  },
  {
    Code: "B001B",
    Name: "Arsenic (Dissolved)",
    Unit: "μg/l As",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B002",
    Name: "Cadmium (Total)",
    Unit: "μg/l  Cd",
    Mbic: "C",
    PCV: "5",
  },
  {
    Code: "B002A",
    Name: "Cadmium (Dissolved)",
    Unit: "μg/l  Cd",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B003",
    Name: "Cyanide (Total)",
    Unit: "μg/l  Cd",
    Mbic: "C",
    PCV: "50",
  },
  {
    Code: "B003A",
    Name: "Cyanide (Dissolved)",
    Unit: "μg/l  CN",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B004",
    Name: "Chromium (Total)",
    Unit: "μg/l  Cd",
    Mbic: "C",
    PCV: "50",
  },
  {
    Code: "B004A",
    Name: "Chromium (Dissolved)",
    Unit: "μg/l  Cr",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B005",
    Name: "Mercury (Total)",
    Unit: "μg/l  Hg",
    Mbic: "C",
    PCV: "1",
  },
  {
    Code: "B005A",
    Name: "Mercury (Dissolved)",
    Unit: "μg/l  Hg",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B006",
    Name: "Nickel (Total)",
    Unit: "µg/l Ni",
    Mbic: "C",
    PCV: "20",
  },
  {
    Code: "B006B",
    Name: "Nickel (Dissolved)",
    Unit: "μg/l  Ni",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B007",
    Name: "Lead (Total)",
    Unit: "μg/l  Pb",
    Mbic: "C",
    PCV: "10",
  },
  {
    Code: "B007B",
    Name: "Lead (25)",
    Unit: "μg/l  Pb",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B007C",
    Name: "Lead (Dissolved)",
    Unit: "μg/l  Pb",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B008",
    Name: "Antimony (Total)",
    Unit: "μg/l  Sb",
    Mbic: "C",
    PCV: "5",
  },
  {
    Code: "B008B",
    Name: "Antimony (Dissolved)",
    Unit: "μg/l  Sb",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B009",
    Name: "Selenium (Total)",
    Unit: "μg/l  Se",
    Mbic: "C",
    PCV: "10",
  },
  {
    Code: "B010",
    Name: "Pesticides (Total by Calculation)",
    Unit: "μg/l ",
    Mbic: "C",
    PCV: "0.5",
  },
  {
    Code: "B011",
    Name: "Polycyclic Aromatic Hydrocarbons (PAH) - Total",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "B011A",
    Name: "Fluoranthene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B011B",
    Name: "Benzo[b]Fluoranthene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B011C",
    Name: "Benzo[k]Fluoranthene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B011D",
    Name: "Benzo[ghi]Perylene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B011E",
    Name: "Indeno[1 2 3-cd]Pyrene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B011F",
    Name: "Polycyclic Aromatic Hydrocarbons (Total by Calculation)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B020",
    Name: "Silica as SiO2",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "B021",
    Name: "Cobalt as Co",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C001",
    Name: "Coliform Bacteria",
    Unit: "No. /100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C001A",
    Name: "Coliform Bacteria (Indicator)",
    Unit: "No. /100ml",
    Mbic: "I",
    PCV: "0",
  },
  {
    Code: "C001B",
    Name: "Coliform Bacteria (Water in Bottles or Containers)",
    Unit: "No. /250ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C002",
    Name: "E. coli",
    Unit: "No. /100ml",
    Mbic: "MB",
    PCV: "0",
  },
  {
    Code: "C002B",
    Name: "E.Coli (Water in Bottles or Containers)",
    Unit: "No.  /250ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C003",
    Name: "Enterococci",
    Unit: "No. /100ml",
    Mbic: "MB",
    PCV: "0",
  },
  {
    Code: "C003B",
    Name: "Enterococci (Water in Bottles or Containers)",
    Unit: "No.  /250ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C004",
    Name: "Clostridium perfringens",
    Unit: "No. /100ml",
    Mbic: "I",
    PCV: "0",
  },
  {
    Code: "C005",
    Name: "Pseudomonas aeruginosa (Water in Bottles or Containers)",
    Unit: "No.  /250ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C006B",
    Name: "Background Count (37)",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C007B",
    Name: "Colony Counts After 3 Days At 22øc (Water in Bottles or Containers)",
    Unit: "No m/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C008",
    Name: "Colony Counts After 24 Hours At 37øc",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C012",
    Name: "Colony Counts After 7 Days At 22øc",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C013B",
    Name: "Colony Counts After 48 Hours At 37øc (Water in Bottles or Containers)",
    Unit: "No m/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C05A",
    Name: "Colony Counts After 3 Days At 22°C",
    Unit: "No./ ml",
    Mbic: "I",
    PCV: "No abnormal change",
  },
  {
    Code: "C05B",
    Name: "Colony Counts After 48 Hours At 37°C",
    Unit: "No./ ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C06A",
    Name: "Residual Disinfectant - Free",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C06B",
    Name: "Residual Disinfectant - Total",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C111",
    Name: "Cryptosporidium oocysts (per 10L)",
    Unit: "No. Oocysts/10l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C111A",
    Name: "Cryptosporidium oocysts (number detected)",
    Unit: "No",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C111B",
    Name: "Cryptosporidium oocysts (volume filtered)",
    Unit: "L",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C112A",
    Name: "Cladocera",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C112B",
    Name: "Copepoda",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C113",
    Name: "Giardia Number",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C113A",
    Name: "Giardia Per Litre",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C114",
    Name: "Rotifers",
    Unit: "Count",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C115",
    Name: "Legionella",
    Unit: "CFU/L",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "C116",
    Name: "Somatic coliphages",
    Unit: "pfu/100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D001",
    Name: "Conductivity",
    Unit: "μS/cm @ 20 ˚C",
    Mbic: "I",
    PCV: "2500",
  },
  {
    Code: "D001A",
    Name: "Anions (Total)",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D001B",
    Name: "Cations",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D001C",
    Name: "Ionic Balance",
    Unit: "%",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D002",
    Name: "Chloride",
    Unit: "mg/l Cl",
    Mbic: "I",
    PCV: "250",
  },
  {
    Code: "D003",
    Name: "Calcium (Total)",
    Unit: "mg/l Ca",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D003A",
    Name: "Calcium (Dissolved)",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D005",
    Name: "Boron (Total)",
    Unit: "mg/l B",
    Mbic: "C",
    PCV: "1",
  },
  {
    Code: "D005B",
    Name: "Boron (Dissolved)",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D006",
    Name: "Barium (Total)",
    Unit: "μg/l  Ba",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D007",
    Name: "Benzo[a]Pyrene (Total)",
    Unit: "μg/l ",
    Mbic: "C",
    PCV: "0.01",
  },
  {
    Code: "D008",
    Name: "Tetrachloromethane (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D009",
    Name: "Trichloroethene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D009B",
    Name: "Tetrachloroethene/Trichloroethene - Sum of 2 Substances",
    Unit: "μg/l ",
    Mbic: "C",
    PCV: "10",
  },
  {
    Code: "D010",
    Name: "Tetrachloroethene (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D011",
    Name: "Total Trihalomethanes",
    Unit: "μg/l ",
    Mbic: "C",
    PCV: "100",
  },
  {
    Code: "D011A",
    Name: "Trichloromethane-Chloroform (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D011B",
    Name: "Bromodichloromethane (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D011C",
    Name: "Dibromochloromethane-Chlorodibromomethane (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D011D",
    Name: "Tribromomethane - Bromoform (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D100",
    Name: "1 1 1-Trichloroethane (Total)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D101",
    Name: "Chlorate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D102",
    Name: "Chlorite",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D103",
    Name: "TCA (Trichloroacetic Acid - DBP)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D104",
    Name: "DCA (Dichloroacetic Acid - DBP)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D105",
    Name: "MCA (Monochloroacetic Acid - DBP)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D106",
    Name: "Bromochloroacetic acid (bca)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D107",
    Name: "Bromodichloroacetic acid (bdca)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D108",
    Name: "Dibromoacetic acid (dba)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D109",
    Name: "Dibromochloroacetic acid (dbca)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D110",
    Name: "Monobromoacetic acid (mba)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D111",
    Name: "Tribromoacetic acid (tca)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "D112",
    Name: "Haloacetic Acids (Total by Calculation)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E001",
    Name: "Total Hardness",
    Unit: "mg/l Ca",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E001A",
    Name: "Hardness, Calcium",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E001B",
    Name: "Hardness, Magnesium",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E001C",
    Name: "Hardness, Total",
    Unit: "mg/l CaC03",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E002",
    Name: "Alkalinity (HcO3)",
    Unit: "mg/l HCO3",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "E002A",
    Name: "Alkalinity (CaCO3)",
    Unit: "mg/l CaCO3",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F001",
    Name: "1 2-Dichloroethane (Total)",
    Unit: "μg/l ",
    Mbic: "C",
    PCV: "3",
  },
  {
    Code: "F002",
    Name: "Benzene (Total)",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "1",
  },
  {
    Code: "F002A",
    Name: "Benzene, 1,2,3,4-tetramethyl-",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F002B",
    Name: "Benzene, 1,3-diethyl-5-methyl-",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F002C",
    Name: "Benzene, 4-ethyl-1,2-dimethyl-",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F003",
    Name: "Bromate",
    Unit: "μg/l BrO3",
    Mbic: "C",
    PCV: "10",
  },
  {
    Code: "F004",
    Name: "Gross Alpha Activity",
    Unit: "Bq/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F005",
    Name: "Gross Beta Activity",
    Unit: "Bq/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F006",
    Name: "Tritium",
    Unit: "Bq/l",
    Mbic: "I",
    PCV: "100",
  },
  {
    Code: "F007",
    Name: "Total Indicative Dose (Gross Alpha and Beta)",
    Unit: "mSv/year",
    Mbic: "I",
    PCV: "0.1",
  },
  {
    Code: "F008",
    Name: "Acrylamide",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "F009",
    Name: "Vinyl Chloride",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.5",
  },
  {
    Code: "F010",
    Name: "Epichlorohydrin",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "F011",
    Name: "Radon",
    Unit: "Bq/l",
    Mbic: "I",
    PCV: "100",
  },
  {
    Code: "F012A",
    Name: "1,2,4-Trichlorobenzene",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F012B",
    Name: "1,3,5-Trichlorobenzene",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F012C",
    Name: "1,2,3-Trichlorobenzene",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F014",
    Name: "Organotin",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F015",
    Name: "Tributyltin",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F016",
    Name: "Triphenyltin",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F017",
    Name: "Volative Organic Compounds",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F018",
    Name: "Ethyl Benzene",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F019",
    Name: "Free CO2",
    Unit: "mg/l as CO2",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F020",
    Name: "DO (Dissolved Oxygen)",
    Unit: "mg/l as O2",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F020A",
    Name: "%SAT",
    Unit: "%",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F021",
    Name: "Soluble Reactive Phosphate as P",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F022",
    Name: "Total Algae",
    Unit: "Areal Units/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F022A",
    Name: "Total Other Algae",
    Unit: "Areal Units/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F023",
    Name: "Total Algae",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F023A",
    Name: "Total Other Algae",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F024",
    Name: "Total Zooplankton",
    Unit: "No. ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F025",
    Name: "Orthoxylene",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F026",
    Name: "Paraxylene",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F027",
    Name: "Toluene",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F028",
    Name: "Xylene - All ( Meta, Para, Ortho)",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F029",
    Name: "Bromide",
    Unit: "µg/l Br",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F030",
    Name: "Molybdenum (Total)",
    Unit: "µg/l Mo",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F032",
    Name: "(not used)",
    Unit: "-",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F033",
    Name: "Perfluorooctane Acid",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F033A",
    Name: "Perfluorooctane Sulfonate",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034",
    Name: "Algology - Blue Green Algae",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034A",
    Name: "Algology - Green Algae",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034B",
    Name: "Algology - Diatoms",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034C",
    Name: "Algology - Chrysophytes",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034D",
    Name: "Algology - Unicellular Flagellates",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F034E",
    Name: "Algology - Other Taxa",
    Unit: "Cells/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F035",
    Name: "Salmonella",
    Unit: "No. 1/ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F036",
    Name: "Uranium (Total)",
    Unit: "µg/l U",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F036A",
    Name: "Uranium 234",
    Unit: "µg/l U",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F036B",
    Name: "Uranium 235",
    Unit: "µg/l U",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F036C",
    Name: "Uranium 238",
    Unit: "µg/l U",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F037",
    Name: "Nitrogen (Total) Oxidised",
    Unit: "mg/l  NO3",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F038",
    Name: "Orthophosphate",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F039",
    Name: "Dichloromethane",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F040",
    Name: "UV Absorption",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F041",
    Name: "Lithium as Li",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F042",
    Name: "Strontium as Sr",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F043",
    Name: "Vanadium as V",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F044",
    Name: "Total Dissolved/suspended Solids",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F045",
    Name: "BOD (ATU)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F046",
    Name: "CHL_A",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047",
    Name: "naphthalene",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047A",
    Name: "Naphthalene, 1,2,3,4-tetrahydro-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047B",
    Name: "Naphthalene, 1,4-dimethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047C",
    Name: "Naphthalene, 1,5-dimethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047D",
    Name: "Naphthalene, 1-ethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047E",
    Name: "Naphthalene, 2,3-dimethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047F",
    Name: "Naphthalene, 2,6-dimethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F047G",
    Name: "Naphthalene, 2,7-dimethyl-",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F048",
    Name: "OD254",
    Unit: "abs/m",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F049",
    Name: "Chlorophyll",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F050",
    Name: "Geosmin",
    Unit: "ng/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F051",
    Name: "2 Methly Iso Borneol",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F052",
    Name: "(not used)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F053",
    Name: "Dimethlybenzenes (1,2/1,3/1,4-xylene isomers)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F054",
    Name: "Dimethlybenzenes (1,3/1,4-xylene isomers)",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F055",
    Name: "Anthracene",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F056",
    Name: "Pentabromodiphenylether",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F057",
    Name: "N-Nitrosodimethylamine",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F058",
    Name: "C10- 13 Chloroalkanes",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F059",
    Name: "Di(2-ethylhexyl)phthalate",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F060",
    Name: "PhaeophytinA",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F061",
    Name: "Microcystin",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F061A",
    Name: "Microcystin-LR",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F062",
    Name: "Methylisoborneol",
    Unit: "ng/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F063",
    Name: "Radium 226",
    Unit: "Bq/kg",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F064",
    Name: "1,3 Dichlorobenzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F065",
    Name: "1,6,7-Trimethylnaphthalene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F066",
    Name: "2,3,-dihydro-4,7-dimethyl-1H-Indene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F067",
    Name: "2-Ethylhexyl diphenyl phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F068",
    Name: "2-Methylnaphthalene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F069",
    Name: "Benz[a]anthracene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F070",
    Name: "Benzyl alcohol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F071",
    Name: "Benzyl Butyl Phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F072",
    Name: "Chrysene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F073",
    Name: "Hexanedioic acid, bis(2-ethylhexyl) ester",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F074",
    Name: "Phenanthrene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F075",
    Name: "Pyrene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F076",
    Name: "Cumene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F077",
    Name: "Decane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F078",
    Name: "Hepetane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F079",
    Name: "Octane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F080",
    Name: "Phenanthracene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F081",
    Name: "Tetradecane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F082",
    Name: "Fungi",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F083",
    Name: "Sulphide mg/l",
    Unit: "mg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F083A",
    Name: "Sulphide ug/l",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F084",
    Name: "Tin (Dissolved)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F085",
    Name: "(Isopropylphenyl)diphenyl phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F086",
    Name: "2-Ethylhexanoic Acid",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F087",
    Name: "4-t-Butylbenzoic acid",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F088",
    Name: "Acetophenone derivatives",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F089",
    Name: "Benzophenone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F090",
    Name: "Benzylmethyl phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F091",
    Name: "Bisphenol A",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F092",
    Name: "Caffeine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F093",
    Name: "Crotamiton",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F094",
    Name: "Dichlorobenzoic Acid",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F095",
    Name: "Diethyltoluamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F096",
    Name: "Di-t-butylnitrophenol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F097",
    Name: "Ibuprofen",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F098",
    Name: "Kodaflex",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F099",
    Name: "N,N-Diethylformamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F100",
    Name: "Naproxen",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F101",
    Name: "n-Lauryldimethylamine (antioxidant)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F102",
    Name: "Sulphonamides",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F103",
    Name: "t-Butylbenzoic acid",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F104",
    Name: "Tetraacetylethylenediamine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F105",
    Name: "Total Steroids",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F106",
    Name: "Tri(2-butoxyethyl) phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F107",
    Name: "Tris-(2-chloroethyl) phosphate TCEP",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F108",
    Name: "Tri(chloropropyl) phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F109",
    Name: "Benzothiazole",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F110",
    Name: "(Isopropylphenyl)isocyanate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F111",
    Name: "Cholestanol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F112",
    Name: "Cholesterol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F113",
    Name: "Bis(2-ethylhexyl) phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F114",
    Name: "Total Phthalate Esters",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F115",
    Name: "Butylmethyl phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F116",
    Name: "1,3,5-Trimethylbenzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F117",
    Name: "1,2,4-Trimethylbenzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F118",
    Name: "sec-Butylbenzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F119",
    Name: "n-Butylbenzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F120",
    Name: "1-methyl-2-propyl-Benzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F121",
    Name: "1,8-Dimethylnaphthalene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F122",
    Name: "1,2,4,5-tetramethyl-Benzene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F123",
    Name: "1,2,3,4-tetrahydro-Naphthalene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F124",
    Name: "Caprolactam",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F125",
    Name: "Cyclohexanone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F126",
    Name: "Di-n-butyl phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F127",
    Name: "4-Nitrophenol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F128",
    Name: "Diethylene glycol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F129",
    Name: "Isoporone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F130",
    Name: "N,N-Diethyl-m-toluamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F131",
    Name: "Sulfur (S8)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F132",
    Name: "9,19-Cyclolanostan-3-ol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F133",
    Name: "Acetate (3.beta)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F134",
    Name: "1-Methyl-2-pyrrolidinone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F135",
    Name: "Tetrahydrofuran",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F136",
    Name: "(3H)-Isobenzofuranone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F137",
    Name: "N-Nitrosomorpholine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F138",
    Name: "Dibenzofuran",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F139",
    Name: "N,N,N',N'-tetraacetylethylenediamine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F140",
    Name: "Benzenesulfonamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F141",
    Name: "N-Butyl-benzenesulfonamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F142",
    Name: "UV Transmission",
    Unit: "% transmission",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F143",
    Name: "Butylated Hydroxytoluene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F144",
    Name: "Phthalic Anhydride",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F145",
    Name: "Benzoic acid, 3,5-bis(1,1-dimethyllethyl)4-hydroxy",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F146",
    Name: "Carbamazepine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F147",
    Name: "Lupenone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F148",
    Name: "Chloroxylenol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F149",
    Name: "2,4,7,9-Tetramethyl-5-decyne-4,7-diol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F150",
    Name: "2-Propanol,1-chloro-,phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F151",
    Name: "Tris (1,3-dichloroisopropyl) phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F152",
    Name: "Triphenyl phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F153",
    Name: "1,4-Dioxane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F154",
    Name: "Carbazole",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F155",
    Name: "Vanillin",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F156",
    Name: "2-Ethylhexyl salicylate",
    Unit: "ug/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F157",
    Name: "Beryllium",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F158",
    Name: "Thallium",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F159",
    Name: "Titanium",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F160",
    Name: "1,2 dibromoethane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F161",
    Name: "Diclofenac",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F162",
    Name: "2-Ethyl-5,5-dimethyl-1,3-dioxacyclohexane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F163",
    Name: "2-Ethyl-4-methyl-1,3-dioxacyclopentane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F188",
    Name: "Amobarbitol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F189",
    Name: "Chloral Hydrate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F190",
    Name: "3-chloro-dichlormethyl-5-hydroxy-2(5H)-furanone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F191",
    Name: "Chloropicrin",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F192",
    Name: "Formaldehyde",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F193",
    Name: "Hydrogen Peroxide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F194",
    Name: "Cyanogen Chloride",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F195",
    Name: "n-Dimethylnitrosamine",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F196",
    Name: "2-ethyl-5,5-dimethyl-1,3-dioxane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F197",
    Name: "2-ethyl-4-methyl-1,3-dioxolane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F198",
    Name: "Phosphate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F199",
    Name: "Triethyl Phosphate (TEP)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F200",
    Name: "4,4-Methylenebis (phenylisocyanate)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F201",
    Name: "Propan-2-yl hexadecanoate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F202",
    Name: "Fluoren-9-one",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F203",
    Name: "Dibenzothiophene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F204",
    Name: "2,4,6-Tribromophenol (TBP)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F205",
    Name: "1-Hydroxycyclohexyl phenyl ketone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F206",
    Name: "Indane",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F207",
    Name: "Benzene Sulfonalide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F208",
    Name: "Diphenyl Sulfone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F209",
    Name: "Diisobutyl phthalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F210",
    Name: "1H-Benzotriazole ",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F211",
    Name: "1H-Benzotriazole-5 methyl",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F212",
    Name: "1_1-Dichloroethene ",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F213",
    Name: "Cedrol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F214",
    Name: "4-Heptanone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F215",
    Name: "2-Ethyl-1-hexanol",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F216",
    Name: "Decamethylcyclopentasiloxane (D5)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F217",
    Name: "Dodecamethylcyclohexasiloxane (D6)",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F218",
    Name: "Triethyl citrate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F219",
    Name: "Coumarin",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F220",
    Name: "Triphenylphosphine oxide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F221",
    Name: "Neophytadiene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F222",
    Name: "Phenethyl isocyanate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F223",
    Name: "Homosalate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F224",
    Name: "Dimethyl Disulfide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F225",
    Name: "Dimethyl Trisulfide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F226",
    Name: "2-Methylmercaptobenzothiazole",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F227",
    Name: "2-Methoxynaphthalene",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F228",
    Name: "Alpha Iso Methylionone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F229",
    Name: "p-Benzoquinone",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F230",
    Name: "2,6,6-trimethyl-2-cyclohexene-1,4-dione",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F231",
    Name: "2,2,4-Trimethyl-1,3-pentanediol diisobutyrate",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F232",
    Name: "Ethanone, 1,1’-(1,4-phenylene)bis-",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F233",
    Name: "2,6-Dichlorobenzamide",
    Unit: "μg/l ",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F234",
    Name: "PFASs - Total",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235",
    Name: "Sum of PFASs",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235A",
    Name: "PFAS- Perfluorobutanoic acid (PFBA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235B",
    Name: "PFAS- Perfluoropentanoic acid (PFPA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235C",
    Name: "PFAS- Perfluorohexanoic acid (PFHxA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235D",
    Name: "PFAS- Perfluoroheptanoic acid (PFHpA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235E",
    Name: "PFAS- Perfluorooctanoic acid (PFOA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235F",
    Name: "PFAS- Perfluorononanoic acid (PFNA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235G",
    Name: "PFAS- Perfluorodecanoic acid (PFDA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235H",
    Name: "PFAS- Perfluoroundecanoic acid (PFUnDA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235I",
    Name: "PFAS- Perfluorododecanoic acid (PFDoDA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235J",
    Name: "PFAS- Perfluorotridecanoic acid (PFTrDA)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235K",
    Name: "PFAS- erfluorobutanesulfonic acid (PFBS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235L",
    Name: "PFAS- Perfluoropentanesulfonic acid (PFPS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235M",
    Name: "PFAS- Perfluorohexanesulfonic acid (PFHxS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235N",
    Name: "PFAS- erfluoroheptane sulfonic acid (PFHpS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235O",
    Name: "PFAS- Perfluorooctanesulfonic acid (PFOS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235P",
    Name: "PFAS- Perfluorononane sulfonic acid (PFNS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235Q",
    Name: "PFAS- Perfluorodecane sulfonic acid (PFDS)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235R",
    Name: "PFAS- Perfluoroundecane sulfonic acid",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235S",
    Name: "PFAS- Perfluorododecane sulfonic acid",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "F235T",
    Name: "PFAS- Perfluorotridecane sulfonic acid",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G001",
    Name: "Blue-green unicells (from colonies)",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G002",
    Name: "Blue-green unicells (from fragmented col",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G003",
    Name: "Closteriopsis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G004",
    Name: "Gomphosphaeria naegliana (in colonies)",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G005",
    Name: "Lagerheimia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G006",
    Name: "Monoraphidium/Ankistrodesmus/Koliella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G007",
    Name: "Tetrastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G008",
    Name: "All filamentous blue-greens",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G009",
    Name: "Anabaena sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G010",
    Name: "Aphanizomenon flos-aquae",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G011",
    Name: "Aphanizomenon/Oscillatoria spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G012",
    Name: "Aphanothece/Aphanocapsa spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G013",
    Name: "Colonial Cyanobacteria",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G014",
    Name: "Merismopedia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G015",
    Name: "Oscillatoria sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G016",
    Name: "Pseudanabaena sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G017",
    Name: "Actinastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G018",
    Name: "Ankistrodesmus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G019",
    Name: "Botryococcus braunii",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G020",
    Name: "Closterium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G021",
    Name: "Coelastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G022",
    Name: "Colonial Chlorophyta",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G023",
    Name: "Cosmarium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G024",
    Name: "Crucigenia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G025",
    Name: "Crucigenia spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G026",
    Name: "Dictyosphaerium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G027",
    Name: "Elakatothrix sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G028",
    Name: "Filamentous Chlorophyta",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G029",
    Name: "Kirchneriella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G030",
    Name: "Micractinium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G031",
    Name: "Monoraphidium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G032",
    Name: "Monoraphidium/Koliella spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G033",
    Name: "Oocystis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G034",
    Name: "Pediastrum boryanum",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G035",
    Name: "Pediastrum duplex",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G036",
    Name: "Pediastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G037",
    Name: "Scenedesmus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G038",
    Name: "Scenedesmus spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G039",
    Name: "Sphaerocystis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G040",
    Name: "Sphaerocystis/Pseudosphaerocystis spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G041",
    Name: "Tetraedron caudatum",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G042",
    Name: "Tetraedron sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G043",
    Name: "Asterionella formosa",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G044",
    Name: "Aulacoseira granulata",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G045",
    Name: "Aulacoseira sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G046",
    Name: "Aulacoseira spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G047",
    Name: "Centrales (all taxa)",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G048",
    Name: "Cocconeis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G049",
    Name: "Cyclotella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G050",
    Name: "Cyclotella/Stephanodiscus spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G051",
    Name: "Cymbella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G052",
    Name: "Diatoma elongatum",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G053",
    Name: "Diatoma sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G054",
    Name: "Fragilaria sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G055",
    Name: "Gomphonema sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G056",
    Name: "Melosira varians",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G057",
    Name: "Meridion circulare",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G058",
    Name: "Meridion sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G059",
    Name: "Nitzschia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G060",
    Name: "Other Centrales",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G061",
    Name: "Other Pennales",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G062",
    Name: "Pennales (all taxa)",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G063",
    Name: "Stephanodiscus hantzschii",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G064",
    Name: "Surirella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G065",
    Name: "Synedra sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G066",
    Name: "Tabellaria fenestrata",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G067",
    Name: "Tabellaria fenestrata var. asterionelloi",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G068",
    Name: "Tabellaria flocculosa",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G069",
    Name: "Chrysophyte unicells",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G070",
    Name: "Dinobryon sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G071",
    Name: "Mallomonas acrokomos",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G072",
    Name: "Mallomonas caudata",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G073",
    Name: "Mallomonas sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G074",
    Name: "Cryptomonas sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G075",
    Name: "Cryptomonas spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G076",
    Name: "Cryptomonas/large flagellates",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G077",
    Name: "Gymnodinium/Peridinium spp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G078",
    Name: "Large flagellate species",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G079",
    Name: "Rhodomonas minuta",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G080",
    Name: "Trachelomonas sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G081",
    Name: "Small unicells/flagellates",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G082",
    Name: "Unicellular flagellates (all taxa)",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G083",
    Name: "Colonial Chrysophytes",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G084",
    Name: "Glenodinium sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G085",
    Name: "Stichococcus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G086",
    Name: "Synura sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G087",
    Name: "Volvox sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G088",
    Name: "Achnanthes sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G089",
    Name: "Amphora sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G090",
    Name: "Anacystis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G091",
    Name: "Ankyra sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G092",
    Name: "Bacillaria sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G093",
    Name: "Caloneis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G094",
    Name: "Chlamydomonas sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G095",
    Name: "Chlorella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G096",
    Name: "Cladophora sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G097",
    Name: "Crucigeniella sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G098",
    Name: "Cymatopleura sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G099",
    Name: "Eunotia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G100",
    Name: "Frustulia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G101",
    Name: "Gyrosigma sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G102",
    Name: "Microcystis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G103",
    Name: "Navicula sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G104",
    Name: "Nostoc sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G105",
    Name: "Pandorina sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G106",
    Name: "Phacotus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G107",
    Name: "Phacus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G108",
    Name: "Pinnularia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G109",
    Name: "Rhoicosphenia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G110",
    Name: "Rhopalodia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G111",
    Name: "Selenastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G112",
    Name: "Spirogyra sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G113",
    Name: "Staurastrum sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G114",
    Name: "Stauroneis sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G115",
    Name: "Ulothrix sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G116",
    Name: "Zygnema sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G117",
    Name: "Staurodesmus sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G118",
    Name: "Chlorococcum sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G119",
    Name: "Coenococcus sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G120",
    Name: "Euastrum sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G121",
    Name: "Gleocystis sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G122",
    Name: "Golenkinia sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G123",
    Name: "Gomphosphaeria sp",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G124",
    Name: "Tribonema sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G125",
    Name: "Chroococcus sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "G126",
    Name: "Euglena sp.",
    Unit: "count/unit vol.",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P000",
    Name: "Other Pesticides",
    Unit: "µg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P001",
    Name: "Pesticides Aldicarb",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P002",
    Name: "Pesticides Aldrin",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.03",
  },
  {
    Code: "P003",
    Name: "Pesticides Alpha-HCH",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P004",
    Name: "Pesticides Atrazine (Total)",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P005",
    Name: "Pesticides Azinphos methyl",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P006",
    Name: "Pesticides Bentazone (Total)",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P007",
    Name: "Pesticides Beta-HCH",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P008",
    Name: "Pesticides Bromoxynil (Total)",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P009",
    Name: "Pesticides Carbaryl",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P010",
    Name: "Pesticides Carbetamide",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P011",
    Name: "Pesticides Carbophenothion",
    Unit: "µg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P012",
    Name: "Pesticides Chlordane",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P013",
    Name: "Pesticides Chlorfenvinphos (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P014",
    Name: "Pesticides Chlortoluron (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P015",
    Name: "Pesticides Chlorothalonil",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P016",
    Name: "Pesticides Chlorpropham (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P017",
    Name: "Pesticides Chlorpyrifos (Total) (Chlorpyriphos Ethyl)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P018",
    Name: "Pesticides Clopyralid (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P019",
    Name: "Pesticides Chlorpyriphos Methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P020",
    Name: "Pesticides 2 4-D (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P021",
    Name: "Pesticides Dalapon",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P022",
    Name: "Pesticides Delta-HCH",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P023",
    Name: "Pesticides Demeton-S-Methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P024",
    Name: "Pesticides Diazinon (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P025",
    Name: "Pesticides Dicamba (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P026",
    Name: "Pesticides Dichlorprop (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P027",
    Name: "Pesticides Dichlorvos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P028",
    Name: "Pesticides Dieldrin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.03",
  },
  {
    Code: "P029",
    Name: "Pesticides Dimethoate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P030",
    Name: "Pesticides Diquat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P031",
    Name: "Pesticides Disulfoton",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P032",
    Name: "Pesticides Diuron (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P033",
    Name: "Pesticides Endosulfan Total",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P034",
    Name: "Pesticides Endrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P035",
    Name: "Pesticides EPTC",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P036",
    Name: "Pesticides Fenitrothion",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P037",
    Name: "Pesticides Fenpropimorph (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P038",
    Name: "Pesticides Fluazifop-butyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P039",
    Name: "Pesticides Flutriafol",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P040",
    Name: "Pesticides Fluroxypyr",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P041",
    Name: "Pesticides Gamma-HCH (Lindane)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P042",
    Name: "Pesticides Glyphosate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P043",
    Name: "Pesticides Heptachlor (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.03",
  },
  {
    Code: "P044",
    Name: "Pesticides Heptachlor Epoxide - Total (Trans, CIS) (Heptachlor Epoxide)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.03",
  },
  {
    Code: "P045",
    Name: "Pesticides Hexachlorobenzene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P046",
    Name: "Pesticides Iprodione",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P047",
    Name: "Pesticides Isodrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P048",
    Name: "Pesticides Isoproturon (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P049",
    Name: "Pesticides Ioxynil (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P050",
    Name: "(not used)",
    Unit: "",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P051",
    Name: "Pesticides Linuron (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P052",
    Name: "Pesticides Malathion",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P053",
    Name: "Pesticides MCPP(Mecoprop) (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P054",
    Name: "Pesticides MCPA (Total) 4-chloro-o-tolyloxyacetic acid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P055",
    Name: "Pesticides MCPB (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P056",
    Name: "Pesticides Methiocarb",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P057",
    Name: "Pesticides Methoxychlor",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P058",
    Name: "Pesticides Paraquat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P059",
    Name: "Pesticides Parathion (Parathion ethyl)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P060",
    Name: "Pesticides Pentachlorophenol (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P061",
    Name: "Pesticides Phorate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P062",
    Name: "Pesticides Phosalone",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P063",
    Name: "Pesticides Prochloraz",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P064",
    Name: "Pesticides Pirimicarb",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P065",
    Name: "Pesticides Pirimiphos Ethyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P066",
    Name: "Pesticides Propazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P067",
    Name: "Pesticides Propham",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P068",
    Name: "Pesticides Propiconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P069",
    Name: "Pesticides Propetamphos (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P070",
    Name: "Pesticides Prometryne",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P071",
    Name: "Pesticides Propyzamide (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P072",
    Name: "Pesticides Pirimiphos Methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P073",
    Name: "Pesticides Simazine (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P074",
    Name: "Pesticides 2,3,6-Tba",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P075",
    Name: "Pesticides TCA",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P076",
    Name: "Pesticides 2,4,5-T",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P077",
    Name: "Pesticides Terbutryn",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P078",
    Name: "Pesticides Triadimefon",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P079",
    Name: "Pesticides Tri-allate (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P080",
    Name: "Pesticides Triazophos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P081",
    Name: "Pesticides Trifluralin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P082",
    Name: "Pesticides 2 4-DB (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P083",
    Name: "Pesticides Aminotriazole (amitrole)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P084",
    Name: "Pesticides Benomyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P085",
    Name: "Pesticides Bifenox",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P086",
    Name: "Pesticides Bromacil",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P087",
    Name: "Pesticides Carbofuran",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P088",
    Name: "Pesticides Chlorbufam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P089",
    Name: "Pesticides Chlordane-Alpha",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P090",
    Name: "Pesticides Chlordane-Gamma",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P091",
    Name: "Pesticides Crufomate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P092",
    Name: "Pesticides Cyanazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P093",
    Name: "Pesticides Cyfluthrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P094",
    Name: "Pesticides Cypermethrin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P095",
    Name: "Pesticides Deltamethrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P096",
    Name: "Pesticides Demeton",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P097",
    Name: "Pesticides Desmetryn",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P098",
    Name: "Pesticides Dichlobenil (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P099",
    Name: "Pesticides Difenzoquat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P100",
    Name: "Pesticides Dinocap",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P101",
    Name: "Pesticides Endosulfan A  (alpha-Endosulfan)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P102",
    Name: "Pesticides Endosulfan B  (beta-Endosulfan)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P103",
    Name: "Pesticides Epsilon-HCH",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P104",
    Name: "Pesticides Eulan",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P105",
    Name: "Pesticides Fenoprop",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P106",
    Name: "Pesticides Fenthion",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P107",
    Name: "Pesticides Fluazifop",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P108",
    Name: "Pesticides Hexachlorobutadiene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P109",
    Name: "Pesticides Lenacil",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P110",
    Name: "Pesticides Mecarbam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P111",
    Name: "Pesticides Metoxuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P112",
    Name: "Pesticides Mevinphos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P113",
    Name: "Pesticides Monuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P114",
    Name: "Pesticides op'-DDD (TDE)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P115",
    Name: "Pesticides Dichlorodiphenyldichloroethyle op'-DDE",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P116",
    Name: "Pesticides Trichloro-2(2chlorophenyl)2eth op'-DDT",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P117",
    Name: "Pesticides Pebulate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P118",
    Name: "Pesticides Pendimethalin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P119",
    Name: "Pesticides Permethrin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P120",
    Name: "Pesticides Permethrin-cis",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P121",
    Name: "Pesticides Permethrin-trans",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P122",
    Name: "Pesticides Picloram (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P123",
    Name: "Pesticides Dichlorodiphenyldichlorethane pp'-DDD TDE",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P124",
    Name: "Pesticides 1,1-dichloro-2,2-bis-ethane pp'-DDE",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P125",
    Name: "Pesticides 1,1,1-trichloro-2,2-ethane pp'-DDT",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P126",
    Name: "Pesticides Propachlor",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P127",
    Name: "Pesticides Propoxur",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P128",
    Name: "Pesticides Pyrethrins",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P129",
    Name: "Pesticides Sodium Chlorate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P130",
    Name: "Pesticides Tecnazene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P131",
    Name: "Pesticides Triclopyr (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P132",
    Name: "Pesticides Trietazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P133",
    Name: "Pesticides Asulam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P134",
    Name: "Pesticides PCB - Arochlor 1254",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P135",
    Name: "Pesticides PCB - Arochlor 5442",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P136",
    Name: "Pesticides Flumethrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P137",
    Name: "Pesticides PCB - Total",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P138",
    Name: "Pesticides Benazolin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P139",
    Name: "Pesticides Vinclozolin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P140",
    Name: "Pesticides Dichlofluanid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P141",
    Name: "Pesticides Methidathion",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P142",
    Name: "Pesticides Chlormephos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P143",
    Name: "Pesticides Fenchlorphos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P144",
    Name: "Pesticides Trichloronat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P145",
    Name: "Pesticides Bromophos-ethyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P146",
    Name: "Pesticides Dicloran (Dichlorane)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P147",
    Name: "Pesticides Parathion-methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P148",
    Name: "Pesticides Ethion (Diethion)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P149",
    Name: "Pesticides PCB - Arochlor 1260",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P150",
    Name: "Pesticides Carbendazim (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P151",
    Name: "Pesticides PCB - Arochlor 1250",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P152",
    Name: "Pesticides Metribuzin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P153",
    Name: "Pesticides PCT - Arochlor 4465",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P154",
    Name: "Pesticides Oxadixyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P155",
    Name: "Pesticides Maneb (a Dithiocarbamate Fungicide)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P156",
    Name: "Pesticides Alpha-cypermethrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P157",
    Name: "Pesticides Diflufenican (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P158",
    Name: "Pesticides Fenvalerate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P159",
    Name: "Pesticides Flusilazole (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P160",
    Name: "Pesticides Imazapyr",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P161",
    Name: "Pesticides Tridemorph",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P162",
    Name: "Pesticides Chloridazon",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P163",
    Name: "Pesticides Chlormequat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P164",
    Name: "Pesticides Flamprop-M-Isopropyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P165",
    Name: "Pesticides Mepiquat",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P166",
    Name: "Pesticides Isoxaben",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P167",
    Name: "Pesticides Methabenzthiazuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P168",
    Name: "Pesticides Fenpropidin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P169",
    Name: "Pesticides Triphenyltin (fentin)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P170",
    Name: "Pesticides Flucofuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P171",
    Name: "Pesticides Sulcofuron (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P172",
    Name: "Pesticides Trichlorobenzene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P173",
    Name: "Pesticides Terbuthylazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P174",
    Name: "Pesticides Bendiocarb",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P175",
    Name: "Pesticides Chlorthal (Chlorthal Dimethyl) DCPA",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P176",
    Name: "Pesticides Heptenophos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P177",
    Name: "Pesticides Tebuconazole (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P178",
    Name: "Pesticides Diflubenzuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P179",
    Name: "Pesticides Quintozene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P180",
    Name: "Pesticides Aziprotryne",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P181",
    Name: "Pesticides Chloroxuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P182",
    Name: "Pesticides Desethylatrazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P183",
    Name: "Pesticides Fenoxaprop",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P184",
    Name: "Pesticides Fenoxaprop-P (Fenoxaprop-P-Ethyl)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P185",
    Name: "Pesticides Fenuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P186",
    Name: "Pesticides Flamprop-M",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P187",
    Name: "Pesticides Mancozeb (a Dithiocarbamate Funficide)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P188",
    Name: "Pesticides Monolinuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P189",
    Name: "Pesticides Tebuthiuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P190",
    Name: "Pesticides Thiabendazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P191",
    Name: "Pesticides PCT - Total",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P192",
    Name: "Pesticides Captan",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P193",
    Name: "(not used)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P194",
    Name: "Pesticides Metamitron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P195",
    Name: "Pesticides PCB Congener 101",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P196",
    Name: "Pesticides PCB Congener 118",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P197",
    Name: "Pesticides PCB Congener 138",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P198",
    Name: "Pesticides PCB Congener 153",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P199",
    Name: "Pesticides PCB Congener 180",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P200",
    Name: "(not used)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P201",
    Name: "Pesticides PCB Congener 28",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P202",
    Name: "Pesticides PCB Congener 52",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P203",
    Name: "Pesticides Metazachlor",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P204",
    Name: "Pesticides Fonofos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P205",
    Name: "Pesticides Diclofop",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P206",
    Name: "Pesticides Etrimfos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P207",
    Name: "Pesticides Cyproconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P208",
    Name: "Pesticides Imazaquin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P209",
    Name: "Pesticides Metalaxyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P210",
    Name: "Pesticides Metsulfuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P211",
    Name: "Pesticides Dimethyl tetrachloroterephthalate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P212",
    Name: "Pesticides Atrazine Desisopropyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P213",
    Name: "(not used)",
    Unit: "",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P214",
    Name: "(not used)",
    Unit: "",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P215",
    Name: "(not used)",
    Unit: "",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "P216",
    Name: "Pesticides Lambda-Cyhalothrin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P217",
    Name: "Pesticides Epoxyconazole (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P218",
    Name: "Pesticides Kresoxim-methyl (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P219",
    Name: "Pesticides Napropamide",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P220",
    Name: "Pesticides Fentin Hydroxides as TPTin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P221",
    Name: "Pesticides Ethofumersate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P222",
    Name: "Pesticides Ametryn",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P223",
    Name: "Pesticides Cyromazine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P224",
    Name: "Pesticides Phenmedipham",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P225",
    Name: "Pesticides Triforine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P226",
    Name: "Pesticides Metaldehyde",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P227",
    Name: "Pesticides- Thifensulfuron-methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P228",
    Name: "Pesticides- Tribenuron-methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P229",
    Name: "Pesticides Trifloxystrobin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P230",
    Name: "Pesticides Flufenacet",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P231",
    Name: "Pesticides Boscalid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P232",
    Name: "Pesticides Aminopyralid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P233",
    Name: "Pesticides Glufosinate-ammonia",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P234",
    Name: "Pesticides Mesosulfuron-methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P235",
    Name: "Pesticides Mesotrione",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P236",
    Name: "Pesticides Trinexapac-ethyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P237",
    Name: "Pesticides Desthioprothioconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P238",
    Name: "Pesticides Propamocarb",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P239",
    Name: "Pesticides Spiroxamine",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P240",
    Name: "Pesticides Alachlor",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P241",
    Name: "Pesticides Pentachlorobenzene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P242",
    Name: "Pesticides Prothioconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P243",
    Name: "Pesticides Prosulfocarb",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P244",
    Name: "Pesticides Quinmerac",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P245",
    Name: "Pesticides Dimethenamid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P246",
    Name: "Pesticides Clomazone",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P247",
    Name: "Pesticides Difenconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P248",
    Name: "Pesticides Flurtamone",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P249",
    Name: "Pesticides Mecoprop-P",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P250",
    Name: "Pesticides Iodosulfuron methyl sodium",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P251",
    Name: "Pesticides 2,6-Diisopropylnaphthalene",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P252",
    Name: "Pesticides Florasulam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P253",
    Name: "Pesticides Clothianidin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P254",
    Name: "Pesticides Fosthiazate",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P255",
    Name: "Pesticides Fluoxastrobin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P256",
    Name: "Pesticides Silthiofam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P257",
    Name: "Pesticides Fluopicolide",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P258",
    Name: "Pesticides Thiamethoxam",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P259",
    Name: "Pesticides Triadimenol",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P260",
    Name: "Pesticides Azoxystrobin (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P261",
    Name: "Pesticides Ethoprophos",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P262",
    Name: "Pesticides Hexachlorocyclohexane (HCH)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P263",
    Name: "Pesticides Oxamyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P264",
    Name: "Pesticides Propamocarb hydrochloride",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P265",
    Name: "Pesticides Dimethenamid - P",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P266",
    Name: "Pesticides Cymoxanil",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P267",
    Name: "Pesticides Folpet",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P268",
    Name: "Pesticides Dimethochlor",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P269",
    Name: "Pesticides Maleic hydrazide",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P270",
    Name: "Pesticides Nicosulfuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P271",
    Name: "Pesticides Flupyrsulfon-methyl",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P272",
    Name: "Pesticides Amidosulfuron",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P273",
    Name: "Pesticides Aminomethylphosphonic acid",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P274",
    Name: "Pesticides 3-(difluoromethyl)-1-methyl-N-[2-(3',4',5'-trifluorophenyl)phenyl]pyrazole-4-carboxamide",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P275",
    Name: "Pesticides 2(3H)-Benzothiazolone",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P276",
    Name: "Pesticides Penthiopyrad",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P277",
    Name: "Pesticides Furfural",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P278",
    Name: "Pesticides Cyprodinil (Total)",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P279",
    Name: "Pesticides Bixafen",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P280",
    Name: "Pesticides Mepiquat Chloride",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P281",
    Name: "Pesticides Metconazole",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "P282",
    Name: "Pesticides Pyraclostrobin",
    Unit: "μg/l",
    Mbic: "C",
    PCV: "0.1",
  },
  {
    Code: "Q001",
    Name: "Phenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003",
    Name: "4-n-Nonylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003A",
    Name: "Nonyl Phenolethoxyalate (Total)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003B",
    Name: "Octyl Phenol (Octylphenol)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003C",
    Name: "Octyl Phenolethoxyalate (Total)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003D",
    Name: "4-tert-Nonylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q003E",
    Name: "Nonyl Phenol (all isomers) (Nonylphenol)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q004",
    Name: "2-Chlorophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q005",
    Name: "4-Chorophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q006",
    Name: "2_4-Dichlorophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q007",
    Name: "2_5-Dimethylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q008",
    Name: "3_5-Dimethylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q009",
    Name: "3-Methylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q010",
    Name: "4-Methylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q011",
    Name: "2_4_6-Trichlorophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q017",
    Name: "Methyl Tertiary Amyl Ether (TAME)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q018",
    Name: "Methyl Tertiery Butyl Ether (MTBE)",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q019",
    Name: "2_4_5-Trichlorophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q020",
    Name: "2-Methylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q021",
    Name: "Chloromethylphenol 4 3",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q022",
    Name: "2_4-Dimethylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q023",
    Name: "2-Bromophenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q024",
    Name: "3-methyl-1,1-Biphenyl",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q025",
    Name: "Total Phenols",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q027",
    Name: "24-Methylene",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "Q028",
    Name: "2,4 -di-tert-butylphenol",
    Unit: "μg/l",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "X001",
    Name: "Presumptive Coliform Bacteria",
    Unit: "No. /100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "X002",
    Name: "Presumptive E.coli",
    Unit: "No. /100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "X003",
    Name: "Presumptive Enterococci",
    Unit: "No. /100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "X004",
    Name: "Presumptive Clostridium Perfringens",
    Unit: "No. /100ml",
    Mbic: "",
    PCV: "",
  },
  {
    Code: "OTHER",
    Name: "Other unspecified parameter",
    Unit: "",
    Mbic: "",
    PCV: "",
  },
];
