import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import EventIncidentsHeader from "./EventIncidentsHeader";
import NotificationBadge from "./notifications/NotificationBadge";
import { NotificationContext } from "./NotificationsProvider";
import MainResponsiveNav from "./responsive-nav/MainResponsiveNav";

const Header = ({ location, access, userGroup }) => {
  const currentParams = useParams();
  const { notifications: data } = useContext(NotificationContext);
  const [headerNotifications, setHeaderNotifications] = useState(null);

  useEffect(() => {
    setHeaderNotifications(data);
  }, [data]);

  return (
    <header className="top-menu-bar">
      <div>
        {currentParams.id &&
        location.pathname.includes("eventincidents") &&
        access &&
        access.find((item) => item.code === userGroup) ? (
          <EventIncidentsHeader id={currentParams.id} />
        ) : (
          ""
        )}
      </div>

      {headerNotifications ? (
        <NotificationBadge data={headerNotifications} />
      ) : (
        ""
      )}

      {location && (
        <MainResponsiveNav
          id={currentParams.id}
          location={location.pathname}
          access={access}
          userGroup={userGroup}
        />
      )}
    </header>
  );
};

export default Header;
