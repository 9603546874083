import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../Account";
import { CallBackend } from "../../utils/apiCall";
import RestPagination from "../RestPagination";
import Loader from "../Loader";
import moment from "moment";

const AssociatedEI = ({ currentParams }) => {
  const { refreshUser } = useContext(AccountContext);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [order, setOrder] = useState(false);
  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getData();
  }, [currentParams, pageNumber]);

  function sortTable(e, keyName) {
    if (typeof data === "object") {
      data[0].Data.sort((a, b) => {
        const first =
          typeof a[keyName] === "string"
            ? a[keyName].toLowerCase()
            : a[keyName];
        const second =
          typeof b[keyName] === "string"
            ? b[keyName].toLowerCase()
            : b[keyName];

        return e.target.textContent && order
          ? first > second
            ? -1
            : 1
          : first < second
          ? -1
          : 1;
      });
    }
  }

  const handleClick = (e, keyName) => {
    setOrder(!order);
    sortTable(e, keyName);
  };

  async function getData() {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
      },
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `associated_ei_info/${currentParams}`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response);
          setTotalPages(response);
          setError(null);
        })
        .catch((error) => {
          setSpinner(false);
          setError(error);
        });
    });
  }

  return (
    <>
      {spinner ? <Loader height={"192px"} /> : ""}

      {newError !== null && newError.response === undefined ? (
        <h2>
          There has been a problem with the system, if this problem persists
          please contact DWQ.
        </h2>
      ) : newError ? (
        newError.response.status >= 400 && newError.response.status <= 599 ? (
          <h2>
            There has been a problem with the system, if this problem persists
            please contact DWQ.
          </h2>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {data !== undefined && !spinner && newError === null ? (
        <table className="associatedEI">
          <thead>
            <tr>
              <th onClick={(e) => handleClick(e, "category")}>Category</th>
              <th onClick={(e) => handleClick(e, "event_number")}>Event No.</th>
              <th onClick={(e) => handleClick(e, "date_of_event")}>Date</th>
              <th onClick={(e) => handleClick(e, "hazard")}>Hazard</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto" }} tabIndex="0">
            {data !== "No results found for this site ref" &&
            data !== null &&
            data[0].Data !== undefined &&
            data[0].Data.length > 0 ? (
              data[0].Data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.category}</td>
                    <td>{item.event_number}</td>
                    <td>{moment(item.date_of_event).format("DD/MM/YY")}</td>
                    <td>{item.hazard}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No Data</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className="regionPaginate">
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages[1].total_pages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AssociatedEI;
