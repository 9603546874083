import React, { useState, useEffect, useContext } from "react";
import { useHistory, NavLink } from "react-router-dom";
import logo from "../assets/img/dwq_logo.png";
import { AccountContext } from "./Account";
import { NotificationContext } from "./NotificationsProvider";
import { logout } from "../utils";
import { imageClick } from "../utils/helpers";

// Sidebar Menu
const Sidebar = () => {
  const history = useHistory();

  const { getUserGroups, userGroup, permissions } = useContext(AccountContext);
  const { notificationSettings, getDefaultNotificationSettings } =
    useContext(NotificationContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [showAdministration, setShowAdministration] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  // a function that will display nested list items in the sidebar navigation depending on the url
  function showNavItems() {
    const urlString = window.location.href.split("/").pop();
    const allNotifications = ["notifications-hub", "notifications-settings"];
    const allUsers = ["password-management"];
    const dwqAdmin = [
      "list-users",
      "audit-trail",
      "hazard-manager",
      "classification-manager",
    ];
    const laUsers = [
      "supply-samples-help",
      "supply-details-help",
      "pws-zone-help",
      "la-codes-help",
      "parameter-code-help",
    ];
    if (userGroup) {
      allUsers.includes(urlString) ? setShowAdministration(true) : "";
      allNotifications.includes(urlString) ? setShowNotifications(true) : "";
    }
    if (userGroup === "DWQAdminPoolGroup") {
      dwqAdmin.includes(urlString) ? setShowAdministration(true) : "";
    }
    if (
      permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      )
    ) {
      laUsers.includes(urlString) ? setShowHelp(true) : "";
    }
  }

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
    notificationSettings === null ? getDefaultNotificationSettings() : "";
    showNavItems();
  }, [userGroup, notificationSettings]);

  return (
    <nav className={`ds_sidebar`}>
      <div className="ds_sideba_logo">
        <img
          src={logo}
          alt="DWQR Logo"
          className="publicLogo"
          onClick={() => imageClick(history, "/home")}
        />
      </div>
      {permissions.dwqPermissions.includes(userGroup) ? (
        <div id="DWQ">
          <ul className="ds_side-navigation__list">
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/home"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Home</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/eventincidents"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Event &amp; Incidents</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/upload-document/"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Data Submissions</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/shape-files"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Files</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/assetcase"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Asset Case</span>
              </NavLink>
            </li>
            {notificationSettings && (
              <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                  className="ds_side-navigation__link"
                >
                  <span className="indent">Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul
                    className="ds_side-navigation__list"
                    id="dwq_notifications"
                  >
                    <li className="ds_side-navigation__item">
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}
            <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={
                  userGroup === "DWQAdminPoolGroup"
                    ? "/admin/audit-trail"
                    : "/admin/password-management"
                }
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul
                  className="ds_side-navigation__list"
                  id="dwq_system_management"
                >
                  {userGroup === "DWQAdminPoolGroup" ? (
                    <>
                      <li className="ds_side-navigation__item">
                        <NavLink
                          to={"/admin/audit-trail"}
                          className="ds_side-navigation__link"
                          activeClassName="ds_current"
                        >
                          <span className="indent">Audit Trail</span>
                        </NavLink>
                      </li>
                      <li className="ds_side-navigation__item">
                        <NavLink
                          to={"/admin/list-users"}
                          className="ds_side-navigation__link"
                          activeClassName="ds_current"
                        >
                          <span className="indent">User Management</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li className="ds_side-navigation__item">
                    <NavLink
                      to={"/admin/password-management"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Password Management</span>
                    </NavLink>
                  </li>
                  {userGroup === "DWQAdminPoolGroup" ? (
                    <>
                      <li className="ds_side-navigation__item">
                        <NavLink
                          to={"/admin/classification-manager"}
                          className="ds_side-navigation__link"
                          activeClassName="ds_current"
                        >
                          <span className="indent">Classification Manager</span>
                        </NavLink>
                      </li>
                      <li className="ds_side-navigation__item">
                        <NavLink
                          to={"/admin/hazard-manager"}
                          className="ds_side-navigation__link"
                          activeClassName="ds_current"
                        >
                          <span className="indent">Hazard Manager</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                ""
              )}
            </li>

            <li className="ds_side-navigation__item">
              <NavLink
                to={"/login/:type"}
                onClick={() => logout()}
                className="ds_side-navigation__link"
              >
                <span className="indent">Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}

      {permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        <div id="LA">
          <ul className="ds_side-navigation__list">
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/home"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Home</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/upload-document/"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Data Uploads</span>
              </NavLink>
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/previous-uploads"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Previous Uploads</span>
              </NavLink>
            </li>
            {notificationSettings && (
              <li className="ds_side-navigation__item">
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                  className="ds_side-navigation__link"
                >
                  <span className="indent">Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul
                    className="ds_side-navigation__list"
                    id="la_notifications"
                  >
                    <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}
            <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={"/admin/password-management"}
                className="ds_side-navigation__link"
              >
                <span className="indent">System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul
                  className="ds_side-navigation__list"
                  id="la_system_management"
                >
                  <li className="ds_side-navigation__item">
                    <NavLink
                      to={"/admin/password-management"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Password Management</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
              <NavLink
                onClick={() => setShowHelp(!showHelp)}
                to={"/help-advice/supply-samples-help"}
                className="ds_side-navigation__link"
              >
                <span className="indent">Help Advice</span>
              </NavLink>

              {showHelp ? (
                <ul className="ds_side-navigation__list" id="help_advice_nav">
                  <li className="ds_side-navigation__item">
                    <NavLink
                      to={"/help-advice/supply-samples-help"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Supply Samples Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/supply-details-help"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Supply Details Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/pws-zone-help"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">PWS Zone Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/la-codes-help"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">LA Codes Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/parameter-code-help"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Parameter Code Help</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/login/:type"}
                onClick={() => logout()}
                className="ds_side-navigation__link"
              >
                <span className="indent">Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}

      {permissions.swPermissions.includes(userGroup) ? (
        <div id="SW">
          <ul className="ds_side-navigation__list">
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/home"}
                className="ds_side-navigation__link"
                activeClassName="ds_current"
              >
                <span className="indent">Home</span>
              </NavLink>
            </li>
            {userGroup === "SWEIUserPoolGroup" ? (
              <li className="ds_side-navigation__item">
                <NavLink
                  to={"/upload-document/"}
                  className="ds_side-navigation__link"
                  activeClassName="ds_current"
                >
                  <span className="indent">E&amp;I Data Uploads</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {userGroup === "SWRegUserPoolGroup" ? (
              <li className="ds_side-navigation__item">
                <NavLink
                  to={"/upload-document/"}
                  className="ds_side-navigation__link"
                  activeClassName="ds_current"
                >
                  <span className="indent">Regulatory Data Uploads</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {notificationSettings && (
              <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                  className="ds_side-navigation__link"
                >
                  <span className="indent">Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul
                    className="ds_side-navigation__list"
                    id="sw_notifications"
                  >
                    <li className="ds_side-navigation__item">
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        className="ds_side-navigation__link"
                        activeClassName="ds_current"
                      >
                        <span className="indent">Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}
            <li className="ds_side-navigation__item  ds_side-navigation__item--has-children">
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={"/admin/password-management"}
                className="ds_side-navigation__link"
              >
                <span className="indent">System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul
                  className="ds_side-navigation__list"
                  id="sw_system_management"
                >
                  <li className="ds_side-navigation__item">
                    <NavLink
                      to={"/admin/password-management"}
                      className="ds_side-navigation__link"
                      activeClassName="ds_current"
                    >
                      <span className="indent">Password Management</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li className="ds_side-navigation__item">
              <NavLink
                to={"/login/:type"}
                onClick={() => logout()}
                className="ds_side-navigation__link"
              >
                <span className="indent">Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </nav>
  );
};

export default Sidebar;
