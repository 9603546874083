import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AccountContext } from "../components/Account";
import Loader from "../components/Loader";
import moment from "moment";
import { CallBackend } from "../utils/apiCall";
import { handleChange } from "../utils/helpers";
import RestPagination from "../components/RestPagination";

const EventDataSample = () => {
  const { refreshUser } = useContext(AccountContext);
  const currentParams = useParams();

  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);
  const [order, setOrder] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [parameter, setParameter] = useState("");
  const [status, setStatus] = useState("");

  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [paramError, setParamError] = useState(null);

  const sortTable = (e) => {
    return e.target.textContent === "Sample Number" && order === false
      ? setData(
          data.sort((a, b) => (a.result_number < b.result_number ? -1 : 1))
        )
      : e.target.textContent === "Sample Number" && order === true
      ? setData(
          data.sort((a, b) => (a.result_number > b.result_number ? -1 : 1))
        )
      : e.target.textContent === "Asset Name" && order === false
      ? setData(data.sort((a, b) => (a.asset_name < b.asset_name ? -1 : 1)))
      : e.target.textContent === "Asset Name" && order === true
      ? setData(data.sort((a, b) => (a.asset_name > b.asset_name ? -1 : 1)))
      : e.target.textContent === "Date" && order === false
      ? setData(data.sort((a, b) => (a.sample_date < b.sample_date ? -1 : 1)))
      : e.target.textContent === "Date" && order === true
      ? setData(data.sort((a, b) => (a.sample_date > b.sample_date ? -1 : 1)))
      : "";
  };

  const handleClick = (e) => {
    setOrder(!order);
    sortTable(e);
  };

  async function getData() {
    setSpinner(true);

    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_number: pageNumber,
        param_code: parameter,
        status: status,
      },
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `sample_data/${currentParams.id}`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response[0].Data);
          setTotalPages(response[1].total_pages);
          setError(null);
        })
        .catch((error) => {
          setSpinner(false);
          setError(error);
        });
    });
  }

  async function getParameterCodes() {
    const body = {};

    let additionalParams = {
      headers: {},
      queryParams: {},
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `dwq_sample_failures_params`,
        "GET",
        configCredentials,
        additionalParams
      )
        .then((response) => {
          setParameters(response[0].Data);
        })
        .catch((error) => {
          setParamError(error);
        });
    });
  }

  useEffect(() => {
    getData();
    getParameterCodes();
  }, [currentParams.id, parameter, pageNumber, status]);

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Samples - Event {currentParams.id}</h1>
        </div>
        {spinner ? <Loader height={"192px"} /> : ""}
        {newError !== null && newError.response === undefined ? (
          <h2>
            There has been a problem with the system, if this problem persists
            please contact DWQ.
          </h2>
        ) : newError ? (
          newError.response.status >= 400 && newError.response.status <= 599 ? (
            <h2>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </h2>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="tableContainer">
          {!spinner && newError === null ? (
            <table className="sampleData">
              <thead>
                <tr>
                  <th onClick={(e) => handleClick(e)} scope="col">
                    Sample Number
                  </th>
                  <th onClick={(e) => handleClick(e)} scope="col">
                    Asset Name
                  </th>
                  <th onClick={(e) => handleClick(e)} scope="col">
                    Date
                  </th>
                  <th scope="col">
                    <label htmlFor="parameter">Parameters</label>
                    <select
                      aria-label="Parameters"
                      style={{ maxWidth: "300px" }}
                      name="parameter"
                      id="parameter"
                      value={parameter}
                      onChange={(e) => {
                        handleChange(e, setParameter), setPageNumber(1);
                      }}
                    >
                      <option value="">View all parameters</option>
                      {parameters
                        ? parameters.map((item, index) => {
                            return (
                              <option key={index} value={item.code}>
                                {item.parameter_value}
                              </option>
                            );
                          })
                        : ""}

                      {paramError && parameters === null && (
                        <option value="">
                          There has been a problem with the system
                        </option>
                      )}
                    </select>
                  </th>
                  <th scope="col">Value</th>
                  <th scope="col">
                    <label htmlFor="status">Status</label>
                    <select
                      aria-label="Status"
                      name="status"
                      id="status"
                      value={status}
                      onChange={(e) => {
                        handleChange(e, setStatus), setPageNumber(1);
                      }}
                    >
                      <option value="">Status</option>
                      <option value="Pass">Pass</option>
                      <option value="Fail">Fail</option>
                    </select>
                  </th>
                  <th>REG?</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                data !== null &&
                data !== "No results returned for this event" ? (
                  data.map((element) => {
                    return (
                      <tr key={element.result_number}>
                        <td>{element.result_number}</td>
                        <td>{element.asset_name}</td>
                        <td>
                          {moment(element.sample_date).format(
                            "HH:MM A DD/MM/YYYY"
                          )}
                        </td>
                        <td>{element.parameter_code}</td>
                        <td>{element.value}</td>
                        <td>
                          {element.status === "Pass"
                            ? "Pass"
                            : element.status === "Fail"
                            ? "Fail"
                            : ""}
                        </td>
                        <td>
                          {element.reporting_purpose === "REG" ? "Yes" : ""}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td>No results returned for this event</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EventDataSample;
