import { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AccountContext } from "../../components/Account";
import { CallBackend } from "../../utils/apiCall";
import { Col, Row } from "react-bootstrap";
import LaunchIcon from "@mui/icons-material/Launch";

const Assessors = ({ assessors, setNewAssessors, areas, data }) => {
  const { refreshUser } = useContext(AccountContext);

  const [selectedOption, setSelectedOption] = useState("");
  const [admins, setAdmins] = useState(null);
  const [selectedAdminEmail, setSelectedAdminEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);

  const [error, setError] = useState(null);

  function createAdminsList(adminsFromAPI, assessorsProp) {
    return adminsFromAPI
      .filter((item1) => {
        return !assessorsProp.some((item2) => {
          return item1.email === item2.email;
        });
      })
      .filter((item) => item.group === "DWQAdminPoolGroup");
  }

  function addNewAssessor(e) {
    setValidationMessage(null);
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const name = option.getAttribute("data-name");
    const adminIndex = idx - 1;
    setSelectedAdminEmail(e.target.value);

    if (!name) {
      setValidationMessage(
        <p>
          This user requires a full name to be an assessor, please click the
          following link to update:{" "}
          <NavLink
            to={`/admin/user/${admins[adminIndex]["username"]}`}
            target="_blank"
            rel="noreferrer noopener"
            id="userEmail"
            className="ds_link"
          >
            {admins[adminIndex]["email"]}
            <LaunchIcon sx={{ fontSize: 18, margin: "0rem 0.5rem" }} />
          </NavLink>
        </p>
      );
    } else {
      setNewAssessors([
        ...assessors,
        {
          email: e.target.value,
          name: name,
        },
      ]);
    }
  }

  function removeAssessor(e) {
    setSelectedAdminEmail("");
    setSelectedOption(e.target.value);
    setValidationMessage(null);

    const defaultAreas = [];

    const checkDefaultAssessor = areas.filter((item) => {
      if (item.assessor_email === e.target.value) {
        defaultAreas.push(item.area);
        return item;
      }
    });

    if (checkDefaultAssessor.length > 0) {
      setValidationMessage(
        <>
          <p>
            Please remove this user as a default assessor from all areas before
            deleting.
          </p>
          <p>
            Associated Areas:
            {defaultAreas.map((item, index) => {
              return (
                <span key={index}>
                  {" "}
                  {item}
                  {index < defaultAreas.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </p>
        </>
      );
    } else {
      const removedAssessors = assessors.filter(
        (item) => item.email !== e.target.value
      );

      setNewAssessors(removedAssessors);
    }
  }

  // Get all admins from cognito that will be displayed in new assessor field
  async function getData() {
    await refreshUser().then((result) => {
      CallBackend({}, `cognito_user`, "GET", result)
        .then((response) => {
          setAdmins(createAdminsList(response.users, assessors));
        })
        .catch((error) => {
          setError(error);
        });
    });
  }

  async function resetState() {
    setSelectedOption("");
    setSelectedAdminEmail("");
    setValidationMessage(null);
  }

  useEffect(() => {
    resetState();
    getData();
  }, [assessors, data]);

  return (
    <>
      {error && (
        <Row>
          <Col sm={6}>
            <div className="regionArea">
              <div className="assetHeader">
                <h1>Error getting users.</h1>
              </div>
              <div className="regionBody">
                There was an error getting users.
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row className="root_cause">
        <Col sm={6}>
          <label className="ds_label" htmlFor="assessor">
            Assessor
          </label>

          <div className="ds_select-wrapper">
            <select
              name="assessor"
              id="assessor"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => removeAssessor(e)}
            >
              <option value="">Select</option>
              {assessors
                ? assessors.map((item, index) => {
                    return (
                      <option key={index} value={item.email}>
                        {item.email}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Assessor">Add New Assessor</option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {validationMessage ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="validation">
              <span>{validationMessage}</span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Assessor" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newAssessor">
              Add New Assessor
            </label>
            <div className="ds_select-wrapper">
              <select
                name="newAssessor"
                id="newAssessor"
                className="ds_select"
                value={selectedAdminEmail}
                onChange={(e) => addNewAssessor(e)}
              >
                <option value="">Select</option>
                {admins
                  ? admins.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.email}
                          data-name={item.full_name}
                        >
                          {item.email}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption !== "Add New Assessor" &&
      selectedOption !== "" &&
      !validationMessage ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <button>Delete</button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Assessor" &&
      selectedAdminEmail !== "" &&
      !validationMessage ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <button>Save</button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default Assessors;
