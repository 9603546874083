import { localAuthorityData } from "./all_local_authority_data";

const LACodesHelp = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>LA Codes</h1>
        </div>

        <table className="la_codes_table">
          <thead>
            <tr>
              <th scope="col">PWS Zone Name</th>
              <th scope="col">LA Prefix</th>
            </tr>
          </thead>
          <tbody>
            {localAuthorityData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LACodesHelp;
