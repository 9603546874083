import { Route, Redirect } from "react-router-dom";
import PageLayout from "../views/PageLayout";
import { isLogin } from "../utils";

const PrivateRoute = ({ component, access, ...rest }) => {
  return (
    // Show the component only when the user is logged in and have the correct permissions
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <PageLayout component={component} {...props} access={access} />
        ) : (
          <Redirect to="/login/type" />
        )
      }
    />
  );
};

export default PrivateRoute;
