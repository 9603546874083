import { useState, React, useContext, useEffect } from "react";
import { useHistory, useParams, NavLink } from "react-router-dom";
import { CallBackend } from "../../utils/apiCall";
import { Row, Col } from "react-bootstrap";
import Alert from "../../components/Alert";
import { AccountContext } from "../../components/Account";
import { allGroups } from "../help/all_groups_data";
import SelectElement from "../../components/SelectElement";
import InputElement from "../../components/InputElement";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Login Function
function ListUsers() {
  const { refreshUser } = useContext(AccountContext);
  const currentParams = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [searchGroup, setSearchGroup] = useState("");
  const [searchText, setSearchText] = useState("");
  const [order, setOrder] = useState(false);
  const history = useHistory();

  async function getData() {
    await refreshUser().then((result) => {
      CallBackend({}, `cognito_user`, "GET", result)
        .then((response) => {
          setData(response["users"]);
        })
        .catch((error) => {
          setError(error);
        });
    });
  }

  useEffect(() => {
    getData();
  }, [currentParams]);

  const handleClick = (id) => {
    history.push({
      pathname: "/admin/user/" + id,
    });
  };

  const filterGroup = (item) => {
    var shouldInclude = false;
    if (searchGroup != null && searchGroup != "") {
      if (item.group.toLowerCase() === searchGroup.toLowerCase()) {
        shouldInclude = true;
      }
    } else {
      return item;
    }
    if (shouldInclude) {
      return item;
    }
  };

  const filterSearch = (item) => {
    var shouldInclude = false;
    if (searchText != null && searchText != undefined && searchText != "") {
      if (item.email != null || item.full_name != null) {
        if (
          (item.email &&
            item.email.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.full_name &&
            item.full_name.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          shouldInclude = true;
        }
      }
    } else {
      return item;
    }
    if (shouldInclude) {
      return item;
    }
  };

  const handleSearch = (event) => {
    setSearchText(event);
  };

  const handleSearchGroup = (event) => {
    setSearchGroup(event);
  };

  const sortTable = (e) => {
    return e.target.textContent === "Name" && order === false
      ? setData(
          data.sort((a, b) =>
            a.full_name &&
            a.full_name !== "" < b.full_name &&
            b.full_name !== ""
              ? -1
              : 1
          )
        )
      : e.target.textContent === "Name" && order === true
      ? setData(
          data.sort((a, b) =>
            a.full_name &&
            a.full_name !== "" > b.full_name &&
            b.full_name !== ""
              ? -1
              : 1
          )
        )
      : e.target.textContent === "Email" && order === false
      ? setData(
          data.sort((a, b) =>
            a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1
          )
        )
      : e.target.textContent === "Email" && order === true
      ? setData(
          data.sort((a, b) =>
            a.email.toLowerCase() > b.email.toLowerCase() ? -1 : 1
          )
        )
      : "";
  };

  const handleSort = (e) => {
    setOrder(!order);
    sortTable(e);
  };

  return (
    <div className="page_wrapper">
      <form>
        <div className="regionArea">
          <div className="regionHeader">
            <h1>Search For User</h1>
          </div>

          <div className="regionBody">
            <Row className="eventincidents_search">
              <Col>
                <SelectElement
                  inputTitle={"Role"}
                  inputAttribute={"userType"}
                  inputState={searchGroup}
                  setInputState={handleSearchGroup}
                  data={allGroups}
                />
              </Col>
              <Col>
                <InputElement
                  inputTitle={"Name"}
                  inputAttribute={"userName"}
                  inputType={"text"}
                  inputState={searchText}
                  setInputState={handleSearch}
                  disableElement={false}
                  placeholder="Search"
                  displayLabel={false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginTop: "8px" }}>
                  <NavLink to={"/admin/user"} className="ds_link">
                    Add New User
                  </NavLink>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </form>
      <Row>
        <Col>
          {error ? (
            <Alert
              type="error"
              style={{
                backgroundColor: "#07437e",
                borderColor: "#07437e",
              }}
              icon={<ErrorOutlineIcon />}
              heading={"Error getting users"}
              message={
                error.response === true
                  ? error.response.data
                  : "Unfortunately there was an error in the system. Please try again."
              }
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
      {!error ? (
        <Row>
          <Col>
            <table className="user_management_table">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th onClick={(e) => handleSort(e)} scope="col">
                    Name
                  </th>
                  <th onClick={(e) => handleSort(e)} scope="col">
                    Email
                  </th>
                  <th scope="col">Communication Email</th>
                  <th scope="col">Role</th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data
                    .filter((item) => {
                      return filterSearch(item);
                    })
                    .filter((item) => {
                      return filterGroup(item);
                    })
                    .map((element) => {
                      return (
                        <tr
                          key={element["username"]}
                          onClick={(e) => handleClick(element["username"])}
                        >
                          <td>{element["username"]}</td>
                          <td>{element["full_name"]}</td>
                          <td>{element["email"]}</td>
                          <td>{element["communication_email"]}</td>
                          <td>{element["group"]}</td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td>Loading Users....</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
}

export default ListUsers;
