import { useEffect, useState } from "react";

const AcceptabilityCalculator = ({ setAcceptability, handleStateChange }) => {
  const [sizeOfPopulation, setSizeOfPopulation] = useState(0);
  const [numberOfWQContacts, setNumberOfWQContacts] = useState(0);
  const [contactsPerThousand, setContactsPerThousand] = useState(0);

  useEffect(() => {
    updateContactsPerThousand();
  }, [sizeOfPopulation, numberOfWQContacts]);

  function updateContactsPerThousand() {
    if (
      (sizeOfPopulation == 0 && numberOfWQContacts == 0) ||
      (sizeOfPopulation == 0 && numberOfWQContacts !== "") ||
      (sizeOfPopulation === "" && numberOfWQContacts === "") ||
      (sizeOfPopulation !== "" && numberOfWQContacts === "") ||
      (sizeOfPopulation === "" && numberOfWQContacts !== "")
    ) {
      return setContactsPerThousand(0), updateAcceptability(0);
    } else {
      setContactsPerThousand(numberOfWQContacts / (sizeOfPopulation / 1000));
      updateAcceptability(numberOfWQContacts / (sizeOfPopulation / 1000));
    }
  }

  function updateAcceptability(contacts) {
    if (contacts <= 0.1) {
      handleStateChange(1, setAcceptability);
    } else if (contacts >= 0.1 && contacts <= 0.9) {
      handleStateChange(2, setAcceptability);
    } else if (contacts >= 1.0 && contacts <= 1.9) {
      handleStateChange(3, setAcceptability);
    } else if (contacts >= 2.0 && contacts <= 2.9) {
      handleStateChange(4, setAcceptability);
    } else if (contacts >= 3.0 && contacts <= 10) {
      handleStateChange(6, setAcceptability);
    } else if (contacts > 10) {
      handleStateChange(8, setAcceptability);
    }
  }

  return (
    <div>
      <div>
        <label className="ds_label" htmlFor="sizeOfPopulation">
          Size of Population
        </label>
        <input
          type="number"
          id="sizeOfPopulation"
          className="ds_input"
          value={sizeOfPopulation}
          onChange={(e) => setSizeOfPopulation(e.target.value)}
        />
      </div>

      <div>
        <label className="ds_label" htmlFor="numberOfWQContacts">
          No of WQ contacts
        </label>
        <input
          type="number"
          id="numberOfWQContacts"
          className="ds_input"
          value={numberOfWQContacts}
          onChange={(e) => setNumberOfWQContacts(e.target.value)}
        />
      </div>

      <div>
        <label className="ds_label" htmlFor="contactsPerThousand">
          No of Contacts per 1000
        </label>
        <input
          type="number"
          id="contactsPerThousand"
          className="ds_input"
          value={contactsPerThousand}
          placeholder="Not Calculated"
          disabled
        />
      </div>
    </div>
  );
};

export default AcceptabilityCalculator;
