import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CalltoActionButton from "./CalltoActionButton";

const FailureElementManagement = ({
  failureElements,
  newFailureElements,
  setNewFailureElements,
  hazards,
  rootCauses,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionIndex, setSelectedOptionIndex] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [newFailureElementValue, setNewFailureElementValue] = useState("");

  const [validationMessage, setValidationMessage] = useState(null);

  // EDITING A HAZARDS FAILURE ELEMENTS

  function handleFailureElementState(e) {
    setSelectedOption(e.target.value);
    setSelectedOptionIndex(
      e.target[e.target.selectedIndex].getAttribute("data-index")
    );

    setValidationMessage(null);

    if (e.target.value !== "" && e.target.value !== "Add New Failure Element") {
      const associatedRootCauses = failureElements.filter((item) => {
        return item.failure_element === e.target.value;
      });

      const newArray = [...rootCauses].map((item) => ({
        ...item,
        checked: false,
      }));

      newArray.forEach((item) => {
        const match = associatedRootCauses[0].associated.find(
          (el) => el.root_cause === item.root_cause
        );
        item.checked = match ? true : false;
      });

      const sortRootCauses = newArray.sort((a, b) =>
        a.root_cause.toLowerCase() < b.root_cause.toLowerCase() ? -1 : 1
      );

      setCheckedState(sortRootCauses);
    }
  }

  function handleOnChange(position, checkValue) {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        item.checked = checkValue;
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
  }

  function updateFailureElementRootCauses() {
    setValidationMessage(null);
    const filterTrueValues = [...checkedState]
      .filter(({ checked }) => checked === true)
      .map(({ root_cause }) => ({ root_cause }));

    const settingNewRootCauses = newFailureElements.map((item, index) => {
      if (index == selectedOptionIndex) {
        item.associated = filterTrueValues;
      }
      return item;
    });

    const sortFailureElements = settingNewRootCauses.sort((a, b) =>
      a.failure_element.toLowerCase() < b.failure_element.toLowerCase() ? -1 : 1
    );

    setNewFailureElements(sortFailureElements);
  }

  // CREATING A NEW FAILURE ELEMENT

  function createNewFailureElement(e) {
    setValidationMessage(null);
    setNewFailureElementValue(e.target.value);
  }

  function setValidationState() {
    const hasDuplicate = newFailureElements.filter((item) => {
      return (
        item.failure_element.toLowerCase() ===
        newFailureElementValue.toLowerCase()
      );
    });

    newFailureElementValue !== "" && hasDuplicate.length > 0
      ? setValidationMessage(
          "This failure element already exists, please update the below value."
        )
      : setValidationMessage(null);
  }

  async function updateFailureElement() {
    return setNewFailureElements([
      ...newFailureElements,
      {
        failure_element: newFailureElementValue,
        associated: [],
      },
    ]);
  }

  // DELETE A FAILURE ELEMENT

  function deleteFailureElement(e) {
    // e.preventDefault();
    const filterTrueValues = [...checkedState]
      .filter(({ checked }) => checked === true)
      .map(({ failure_element }) => ({ failure_element }));

    // GO THROUGH ALL HAZARDS AND CHECK IF A TRUE VALUE FOR THIS FAILURE ELEMENT IS RETURNED
    let newArrayoffailures = [];

    for (let h in hazards) {
      for (let fe in hazards[h].failure_elements) {
        if (
          hazards[h].failure_elements[fe].failure_element === selectedOption
        ) {
          newArrayoffailures.push(hazards[h].hazard);
        }
      }
    }

    if (filterTrueValues.length > 0 || newArrayoffailures.length > 0) {
      setValidationMessage(
        <>
          <p>
            You must disassociate all root causes with this failure element and
            disassociate this failure element with all hazards before deleting!
          </p>
          {newArrayoffailures.length > 0 ? (
            <>
              <p>Associated Hazards</p>
              <ul>
                {newArrayoffailures.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </>
          ) : (
            ""
          )}
        </>
      );
    } else {
      setValidationMessage(null);
      const filteredFailureElements = newFailureElements.filter(
        (item) => item.failure_element !== selectedOption
      );
      setNewFailureElements(filteredFailureElements);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  function resetState() {
    setSelectedOption("");
    setValidationMessage(null);
    setCheckedState([]);
    setSelectedOptionIndex("");
    setNewFailureElementValue("");
  }

  useEffect(() => {
    resetState();
  }, [newFailureElements, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label htmlFor="failureElements" className="ds_label">
            Failure Element Relationship Management
          </label>
          <div className="ds_select-wrapper">
            <select
              name="failureElements"
              id="failureElements"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => handleFailureElementState(e)}
            >
              <option value="">Select</option>
              {failureElements
                ? failureElements.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item.failure_element}
                        data-index={index}
                      >
                        {item.failure_element}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Failure Element">
                Add New Failure Element
              </option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {selectedOption && selectedOption !== "Add New Failure Element" ? (
        <Row className="root_cause">
          <Col sm={6} style={{ display: "flex", flexDirection: "column" }}>
            {checkedState
              ? checkedState.map((item, index) => {
                  return (
                    <div
                      className="ds_checkbox  ds_checkbox--small"
                      key={index}
                    >
                      <input
                        className="ds_checkbox__input"
                        id={`failure-checkbox-${index}`}
                        type="checkbox"
                        checked={item.checked}
                        name={item.root_cause}
                        value={item.root_cause}
                        onChange={(e) =>
                          handleOnChange(index, e.target.checked)
                        }
                      />
                      <label
                        name={item.root_cause}
                        id={`${index}-checkbox-failure`}
                        htmlFor={`failure-checkbox-${index}`}
                        className="ds_checkbox__label"
                      >
                        {item.root_cause}
                      </label>
                    </div>
                  );
                })
              : ""}
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Failure Element" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newFailureElement">
              New Failure Element Name
            </label>
            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}
              <input
                type="text"
                id="newFailureElement"
                className="ds_input"
                value={newFailureElementValue}
                onChange={(e) => createNewFailureElement(e)}
                onBlur={() => setValidationState()}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption !== "" && selectedOption !== "Add New Failure Element" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="validation">
              <span>{validationMessage}</span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="root_cause">
        <Col sm={6}>
          <>
            {selectedOption !== "" &&
            selectedOption !== "Add New Failure Element" ? (
              <div className="multiple_button_container">
                {validationMessage === null ? (
                  <CalltoActionButton
                    submitFunction={deleteFailureElement}
                    name="Delete"
                  />
                ) : (
                  ""
                )}

                <CalltoActionButton
                  submitFunction={updateFailureElementRootCauses}
                  name="Save"
                />
              </div>
            ) : selectedOption !== "" &&
              selectedOption === "Add New Failure Element" &&
              newFailureElementValue !== "" &&
              validationMessage === null ? (
              <div className="button_container">
                <CalltoActionButton
                  submitFunction={updateFailureElement}
                  name="Save"
                />
              </div>
            ) : (
              ""
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

export default FailureElementManagement;
