export const localAuthorityData = [
  {
    name: "Aberdeen City",
    code: "ABC",
  },
  {
    name: "Aberdeenshire",
    code: "ABS",
  },
  {
    name: "Angus",
    code: "ANG",
  },
  {
    name: "Argyll and Bute",
    code: "AAB",
  },
  {
    name: "Clackmannanshire",
    code: "CLK",
  },
  {
    name: "Dumfries and Galloway",
    code: "DAG",
  },
  {
    name: "Dundee City",
    code: "DUN",
  },
  {
    name: "East Ayrshire",
    code: "EAY",
  },
  {
    name: "East Dunbartonshire",
    code: "EDB",
  },
  {
    name: "East Lothian",
    code: "ELO",
  },
  {
    name: "East Renfrewshire",
    code: "ERW",
  },
  {
    name: "City of Edinburgh",
    code: "EDH",
  },
  {
    name: "Comhairle nan Eilean Siar",
    code: "EIS",
  },
  {
    name: "Falkirk",
    code: "FKK",
  },
  {
    name: "Fife",
    code: "FFE",
  },
  {
    name: "Glasgow City",
    code: "GGW",
  },
  {
    name: "Highland",
    code: "HLD",
  },
  {
    name: "Inverclyde",
    code: "IVC",
  },
  {
    name: "Midlothian",
    code: "MLO",
  },
  {
    name: "Moray",
    code: "MRY",
  },
  {
    name: "North Ayrshire",
    code: "NAY",
  },
  {
    name: "North Lanarkshire",
    code: "NLK",
  },
  {
    name: "Orkney",
    code: "ORK",
  },
  {
    name: "Perth and Kinross",
    code: "PAK",
  },
  {
    name: "Renfrewshire",
    code: "RNW",
  },
  {
    name: "Scottish Borders",
    code: "BRD",
  },
  {
    name: "Shetland",
    code: "SHT",
  },
  {
    name: "South Ayrshire",
    code: "SAY",
  },
  {
    name: "South Lanarkshire",
    code: "SLK",
  },
  {
    name: "Stirling",
    code: "STG",
  },
  {
    name: "West Dunbartonshire",
    code: "WDB",
  },
  {
    name: "West Lothian",
    code: "WLO",
  },
];
