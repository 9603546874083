import { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CallBackend } from "../utils/apiCall";
import { AccountContext } from "../components/Account";
import Loader from "../components/Loader";
import moment from "moment";

// Events - E&I sample Data Component
const EventInformation = () => {
  const { refreshUser } = useContext(AccountContext);
  const currentParams = useParams();

  // State
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);
  const [newError, setError] = useState(null);

  // autosize textarea elements to the size of the content.
  const textarea = document.querySelectorAll("textarea");
  textarea.forEach((element) => {
    element.style.height = `${element.scrollHeight}px`;
    element.style.overflow = `hidden`;
  });

  async function getData() {
    setSpinner(true);

    const body = {};

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `event_information/${currentParams.id}`,
        "GET",
        configCredentials
      )
        .then((response) => {
          setSpinner(false);
          setData(response);
          setError(null);
        })
        .catch((error) => {
          setSpinner(false);
          if (error.response) {
            setError(error);
          }
        });
    });
  }

  useEffect(() => {
    getData();
  }, [currentParams.id]);

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Event Information - Event {currentParams.id}</h1>
        </div>

        {spinner ? <Loader height={"156px"} /> : ""}

        {newError ? (
          newError.response.status >= 400 && newError.response.status <= 599 ? (
            <h2>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </h2>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="regionBody">
          {data && typeof data === "object" && data[0].Data.length !== 0 ? (
            <form className="eventInformation">
              {data[0].Data[0].updates.length > 0
                ? data[0].Data[0].updates.map((item, index) => {
                    return (
                      <Row key={index}>
                        <Col sm={3}>
                          <div>
                            <label className="ds_label" htmlFor="update-date">
                              Note Update Date
                            </label>
                            <input
                              className="ds_input"
                              type="text"
                              id="update-date"
                              value={
                                (item.event_update_date =
                                  item.event_update_date || "")
                              }
                              readOnly
                            />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div>
                            <label className="ds_label" htmlFor="update-note">
                              Event Note
                            </label>

                            <textarea
                              className="ds_input"
                              type="text"
                              id="update-note"
                              value={
                                (item.event_update = item.event_update || "")
                              }
                              readOnly
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    );
                  })
                : ""}

              <Row className="eventincidents_search">
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="notificationreason">
                      Notification Reason
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="notificationreason"
                      value={
                        (data[0].Data[0].notification_reason =
                          data[0].Data[0].notification_reason || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="eventregion">
                      Area
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="eventregion"
                      value={
                        (data[0].Data[0].region = data[0].Data[0].region || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="sitetype">
                      Site Type
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="sitetype"
                      value={
                        data[0].Data[0].site_ref.charAt(0) === "Z"
                          ? "Z - Supply Zone"
                          : data[0].Data[0].site_ref.charAt(0) === "R"
                          ? "R - Reservoir/Tower"
                          : data[0].Data[0].site_ref.charAt(0) === "A"
                          ? "A - Abstraction Point"
                          : data[0].Data[0].site_ref.charAt(0) === "T"
                          ? "T - WTW"
                          : data[0].Data[0].site_ref.charAt(0) === "S"
                          ? "S - Supply Point"
                          : data[0].Data[0].site_ref.charAt(0) === "W"
                          ? "W - Catchment"
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="populationaffected">
                      Population Affected
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="populationaffected"
                      value={
                        (data[0].Data[0].population_affected =
                          data[0].Data[0].population_affected || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <label className="ds_label" htmlFor="likelycause">
                      Likely Cause
                    </label>
                    <textarea
                      className="ds_input"
                      type="text"
                      id="likelycause"
                      value={
                        (data[0].Data[0].likely_cause =
                          data[0].Data[0].likely_cause || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="medadvice">
                    Medical Advice
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    height="100px"
                    value={
                      (data[0].Data[0].medical_advice =
                        data[0].Data[0].medical_advice || "")
                    }
                    id="medadvice"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="actioninf">
                    Action Taken
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].action_to_protect_consumers =
                        data[0].Data[0].action_to_protect_consumers || "")
                    }
                    id="actioninf"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="actionsrectify">
                    Actions to Rectify
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].actions_to_rectify =
                        data[0].Data[0].actions_to_rectify || "")
                    }
                    id="actionsrectify"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="othernotif">
                    Any Other Notif
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].any_other_info =
                        data[0].Data[0].any_other_info || "")
                    }
                    id="othernotif"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="sampledescription">
                    Sample Description
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].sample_description =
                        data[0].Data[0].sample_description || "")
                    }
                    id="sampledescription"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="standardsbreach">
                    Standards Breach
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].standards_breach =
                        data[0].Data[0].standards_breach || "")
                    }
                    id="standardsbreach"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="wholesomenesseffect">
                    Wholesomeness Effect
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].wholesomeness_effect =
                        data[0].Data[0].wholesomeness_effect || "")
                    }
                    id="wholesomenesseffect"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <div>
                  <label className="ds_label" htmlFor="prioritypremises">
                    Priority Premises
                  </label>
                  <textarea
                    className="ds_input"
                    type="text"
                    value={
                      (data[0].Data[0].priority_premises =
                        data[0].Data[0].priority_premises || "")
                    }
                    id="prioritypremises"
                    readOnly
                  />
                </div>
              </Row>
              <Row>
                <Col>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="Treatment"
                      type="checkbox"
                      checked={
                        data[0].Data[0].treatment_failure === "Y" ? 1 : 0
                      }
                      readOnly
                    />

                    <label className="ds_checkbox__label" htmlFor="Treatment">
                      Treatment Failure
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="smrv"
                      type="checkbox"
                      checked={data[0].Data[0].smrv_exceeded === "Y" ? 1 : 0}
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="smrv">
                      Chemical/Bact SMRV
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="crypto"
                      type="checkbox"
                      checked={data[0].Data[0].cryto_positive === "Y" ? 1 : 0}
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="crypto">
                      Crypto
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="potmediaatt"
                      type="checkbox"
                      checked={
                        data[0].Data[0].potential_media_attention === "Y"
                          ? 1
                          : 0
                      }
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="potmediaatt">
                      Pot Media Att
                    </label>
                  </div>
                </Col>

                <Col>
                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="pcv"
                      type="checkbox"
                      checked={
                        data[0].Data[0].regulatory_pcv_fail === "Y" ? 1 : 0
                      }
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="pcv">
                      PCV Contravention
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="telemetry"
                      type="checkbox"
                      checked={data[0].Data[0].telemetry_failed === "Y" ? 1 : 0}
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="telemetry">
                      Telemetry or alarm failure
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="algalbloom"
                      type="checkbox"
                      checked={
                        data[0].Data[0].algal_bloom_present === "Y" ? 1 : 0
                      }
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="algalbloom">
                      Algal Bloom
                    </label>
                  </div>

                  <div className="ds_checkbox  ds_checkbox--small">
                    <input
                      className="ds_checkbox__input"
                      id="snarl"
                      type="checkbox"
                      checked={data[0].Data[0].snarl_exceeded === "Y" ? 1 : 0}
                      readOnly
                    />
                    <label className="ds_checkbox__label" htmlFor="snarl">
                      Chemical / Bacterial SNARL
                    </label>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div>
                    <label className="ds_label" htmlFor="outcome">
                      Outcome Contained
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="outcome"
                      value={
                        (data[0].Data[0].outcome_contained =
                          data[0].Data[0].outcome_contained || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="eventsiteellipse">
                      Site Reference No
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="eventsiteellipse"
                      value={
                        (data[0].Data[0].site_ref =
                          data[0].Data[0].site_ref || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="dataevent">
                      Date of event
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="dataevent"
                      value={moment(data[0].Data[0].date_of_event).format(
                        "DD/MM/YY"
                      )}
                      readOnly
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="datnotif">
                      Date of Notification
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="datnotif"
                      value={moment(data[0].Data[0].date_notified).format(
                        "DD/MM/YY"
                      )}
                      readOnly
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="consumer">
                      Consumer Contacts
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="consumer"
                      value={data[0].Data[0].consumer_contacts}
                      readOnly
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <label className="ds_label" htmlFor="nis">
                      NIS Event
                    </label>
                    <input
                      className="ds_input"
                      type="text"
                      id="nis"
                      value={
                        (data[0].Data[0].nis_implications =
                          data[0].Data[0].nis_implications || "")
                      }
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
            </form>
          ) : data !== null ? (
            <h2 id="NoInfo">No information returned for this event...</h2>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default EventInformation;
