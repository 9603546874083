import { NavLink } from "react-router-dom";

const EventIncidentsHeader = ({ id }) => {
  // Main page Header - Navigation bar
  return (
    <div
      role="navigation"
      aria-label="contextual-navigation"
      className="ds_site-navigation"
      id="contextualNavigation"
    >
      <ul className="ds_site-navigation__list">
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-overview/" + id}
            className="ds_site-navigation__link"
          >
            Overview
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-data-sample/" + id}
            className="ds_site-navigation__link"
          >
            Sample Data
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-information/" + id}
            className="ds_site-navigation__link"
          >
            Event Information
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-assessment/" + id}
            className="ds_site-navigation__link"
          >
            Assessment
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-files/" + id}
            className="ds_site-navigation__link"
          >
            Files
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-asset-case/" + id}
            className="ds_site-navigation__link"
          >
            Asset Case
          </NavLink>
        </li>
        <li className="ds_site-navigation__item">
          <NavLink
            to={"../event-communication/" + id}
            className="ds_site-navigation__link"
          >
            Communication
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default EventIncidentsHeader;
