export const organisationData = [
  { name: "Scottish Water", code: "SW" },
  { name: "Drinking Water Quality", code: "DWQ" },
];

export const typeData = [
  { name: "Asset Details", code: "asset_details" },
  { name: "Customer Contact Details", code: "customer_contacts" },
  { name: "Health Boards", code: "health_boards" },
  { name: "Local Authorities", code: "local_authorities" },
  { name: "Non-standard parameters", code: "non_standard_parameters" },
  { name: "Out of Service", code: "out_of_service" },
  { name: "Parent/Child Relationship", code: "parent_child" },
  { name: "Sample Data", code: "sample_data" },
  { name: "Sample Point Codes", code: "sample_point_codes" },
  { name: "Scheduled Sample Shortfall", code: "scheduled_sample_shortfall" },
  { name: "Supply Point Sampling", code: "supply_point_sampling" },
  { name: "Event Details", code: "event_details" },
  { name: "Event Updates", code: "event_update" },
  { name: "Incident Reports", code: "incident_report" },
  { name: "Additional Info", code: "add_info" },
  { name: "Supply Details", code: "supply_details" },
  { name: "Supply Samples", code: "supply_samples" },
  { name: "Assessment Report", code: "assessment_report" },
];
export const statusData = [
  { name: "Successful", code: "SUCCESS" },
  { name: "Failed", code: "FAILURE" },
];
