import React from "react";

const Privacy = () => {
  return (
    <>
      <p>
        This system does not use personal data of citizens. The personal data of
        users, used in this system, is limited to the following to enable user
        management and contact. These users are DWQ users as well as users
        operating in a professional capacity to provide regulatory data from
        their organisation to DWQ.
      </p>

      <h2>Legal Basis and purpose for processing your personal data</h2>
      <p>
        The data provided to support this purpose is to directly support the
        Drinking Water Quality Regulator’s statutory obligations based on the
        Water Industry (Scotland) Act 2002. More information on this and the
        wider data protection policy in place for DWQ can be found on our main
        web site{" "}
        <a
          className="ds_link"
          href="https://dwqr.scot/privacy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          DWQR-Privacy
        </a>
        .
      </p>

      <h2>Categories of personal data you give to us</h2>
      <p>
        The personal data of users, used in this system, is limited to the
        following to enable user management and contact:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
      </ul>

      <h2>Information we pass to third parties and other data sharing</h2>
      <p>
        To facilitate your use of our Services, we may have to share your
        personal data with third parties who help us provide elements of our
        Services to you. We will provide your personal data to third parties
        when they need the data to perform particular functions in delivering
        our Services to you or as part of our regulatory compliance. These
        include third party digital suppliers who provide solution and support
        services to DWQ (These suppliers currently consist of Exception, Amazon
        Web Services and Okta)
      </p>

      <h2>Data security</h2>
      <p>
        Once it is within our control, we will do our utmost to ensure your
        personal data is processed in a way that ensures appropriate security
        from unauthorised or unlawful processing, accidental loss, destruction,
        or damage.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator of a breach DWQR
        we are legally required to do so.
      </p>

      <h2>Retaining your personal information</h2>
      <p>
        We will retain your personal data for as long as necessary to fulfil the
        purposes we collected it for i.e. access to this system.
      </p>
      <p>
        To determine appropriate retention periods for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>
      <p>
        In some circumstances we may anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes, we may use or store this information indefinitely without
        further notice to you.
      </p>
      <p>
        In some circumstances you can ask us to delete your data: see Right of
        Erasure below for further information.
      </p>

      <h2>Your data protection rights</h2>
      <p>
        Withdraw Consent – You have the right to withdraw that consent at any
        time.
      </p>
      <p>
        Right to be Informed – You have the right to be told how your personal
        information will be used. This policy document is intended to be a clear
        and transparent description of how your data may be used.
      </p>
      <p>
        Right of Access – You can write to us asking what information we hold on
        you and to request a copy of that information. This is called a Subject
        Access Request. From 25 May 2018, we will have 30 days, from the date we
        receive your request, to respond to you. The request will proceed once
        we are satisfied you have the right to see the requested records and we
        have successfully confirmed your identity. Right of Erasure – From 25
        May 2018, you have the right to be forgotten (i.e. to have your
        personally identifiable data deleted).
      </p>
      <p>
        Right of Rectification – If you believe our records are inaccurate you
        have the right to ask for those records concerning you to be updated.
        This enables you to have any incomplete or inaccurate data we hold about
        you corrected. We may need to verify the accuracy of the new data
        provided to us.
      </p>
      <p>
        Right to Restrict Processing – Given the very limited nature of how the
        personal data is used there is no capability to restrict the processing
        of your data as this would equate to erasure, detailed above.
      </p>
      <p>
        Right to Data Portability – Given the very limited nature of how the
        personal data is used i.e. as contact data, there is no value nor
        capability to provide this to you in a structured, commonly used,
        machine-readable format.
      </p>
    </>
  );
};

export default Privacy;
