const SupplyDetailsHelp = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Supply Details Help</h1>
        </div>
        <div className="regionBody">
          Below are a list of all fields on the "Supply Details" sheet in the
          data return explaining the required input, mandatory status and
          additional description to help complete the data return. If you
          require further assistance please email{" "}
          <a href="mailto:regulator@dwqr.scot" className="ds_link">
            regulator@dwqr.scot
          </a>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">ReportingCalendarYear</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">The calendar year pertaining to the dataset</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">PWSzoneCode</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This is the Zone Code for the PWS zone the supply system
                  belongs to. Choose the appropriate code from the provided list
                  of codes in the PWS Zone LookUp page.
                  <br />
                  The PWS Zone Code consists of a three letter Local Authority
                  code plus a three letter Zone Code.
                  <br />
                  In order to determine which supply zone a supply system
                  belongs to , please map the supply against the shapefiles
                  provided on the DWQR website.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SiteRef</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This is the Unique Site Reference, consisting of: <br />
                  1) Local Authority prefix – Three letter code supplied by DWQR
                  in the look up table in the LA Codes LookUp page.
                  <br />
                  2) Your unique identifier for each private supply system
                  <br />
                  <br />
                  Your unique reference should be used for all samples taken
                  from properties supplied by this source.
                  <br />
                  Note: The unique identifier can be the coding/numbering system
                  your LA currently uses. The code should be unique to that
                  source, for the lifetime of the supply. Each system needs to
                  have UNIQUE identifier. Duplicate site reference in this table
                  will not be accepted
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SupplyName</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This is the name of source or location name - the source is
                  the point of abstraction from a watercourse or loch, or the
                  site name where the spring, well or borehole is located.
                  <br />
                  <br />
                  If a system has multiple sources, please enter the primary
                  source
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Latitude</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  The latitude coordinate of the source of the private supply
                  system. If a system has multiple sources, please enter the
                  primary source latitude.
                  <br />
                  <br />
                  If the source location is unknown, use the best estimate
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Longitude</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  The longitude coordinate of the source of the private supply
                  system. If a system has multiple sources, please enter the
                  primary source longitude.
                  <br />
                  <br />
                  If the source location is unknown, use the best estimate
                  <br />
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">RegulatoryClassification</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Classification of the private supply: Regulated or Type B
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Regulation2(1)status</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  The flag WD should be used to denote supplies that are solely
                  used for washing plant or during distillation of spirits under
                  regulation 2(1). If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">RegulatedReason</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This should be used to provide a brief description stating why
                  the supply has been classified as Regulated. A Regulated
                  supply
                  <br />
                  <br />
                  provides ≥10m3 a day or
                  <br />
                  serves ≥ 50 persons or
                  <br />
                  supplies a commercial or public activity (e.g., large
                  population supplied, the source supplies a restaurant, holiday
                  let, etc.) or
                  <br />
                  supplies rented accommodation
                  <br />
                  <br />
                  Leave blank for Type B supplies
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">TouristAccommodation</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number – integer</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Number of properties served by the supply that provide tourist
                  accommodation on a commercial basis (e.g. Guest Houses, B&Bs,
                  Self Catering cottages, campsites). Treat a campsite as one
                  property (as you would do for a Guest House or B&B)
                  <br />
                  <br />
                  If a ""Tourist Accommodation"" property also serves or
                  prepares food on a commercial basis (i.e. has a restaurant),
                  count it under both the ""Tourist Accommodation"" category and
                  the ""Purpose Industrial Food"" category.
                  <br />
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">PurposeDomestic</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number – integer</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Number of properties served by the supply that constitute
                  domestic premises.
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">PurposeIndustrialFood</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number – integer</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Number of properties served by the supply that serve or
                  prepare food on a commercial basis (e.g. inns, cafes, food
                  manufacturers).
                  <br />
                  <br />
                  If the property also acts as Tourist Accommodation (i.e.
                  restaurant in a hotel), count it under both the ""Tourist
                  Accommodation"" category and the ""Purpose Industrial Food""
                  category.
                  <br />
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">OtherCommercial</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number – integer</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Number of commercial premises served by supply not included in
                  any of the above categories.
                  <br />
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Population</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  For Tourist Accommodation properties, list the maximum
                  occupancy of the property, plus the maximum number of
                  employees working on any one time.
                  <br />
                  <br />
                  Example 1: If supply serves a campsite,has 5 plots that are
                  big enough for one 3-man tent each and occupies a maximum of 2
                  employees at any one time, the population should be 3x5+2=17
                  <br />
                  For Food Properties, list the estimated maximum number people
                  served on a given day, plus the maximum number of employees
                  working on any one time.
                  <br />
                  <br />
                  Example 2: A supply serves a restaurant that occupies a
                  maximum of 5 employees at any one time and serves a maximum of
                  60 people a day. The population is 60+5=65
                  <br />
                  For Tourist Properties that also serve food on a commercial
                  basis (i.e. Hotel with a restaurant). List the maximum list
                  the maximum occupancy of the property, plus the maximum number
                  of people served by the property on a single day other than
                  the property residents, plus the maximum number of employees
                  working on any one time.
                  <br />
                  <br />
                  Example 3: A supply serves a hotel that has a restaurant. The
                  hotel occupies a maximum of 5 employees at any one time, the
                  restaurant occupies a maximum of 6 employees at any one time,
                  the hotel has a maximum capacity of 60 people and the
                  restaurant serves a maximum of 100 people a day, but only 40%
                  of it is estimated to not be guests staying at the hotel. The
                  population is: 5+6+60+100x0.4= 111
                  <br />
                  For Domestic properties, list the maximum number of people
                  occupying the property. If true numbers are not known,
                  estimate of the maximum number of people who may be supplied
                  on any one day using the estimate of 2.15 people per property.
                  <br />
                  <br />
                  Example 4: A supply serves 5 domestic properties, 2 with 4
                  people in each household, 2 with 3 people in each household
                  and one unknown. The population is 2x4+2x3+1x2.15=16.15
                  <br />
                  For Commercial properties, list the maximum number of people
                  working ar any one time.
                  <br />
                  <br />
                  Example 5: A supply serves a shop, what is the maximum number
                  of 6 people working at any one time. The population should be
                  6.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">VolumeOut</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Estimate of the maximum daily average volume of water supplied
                  by private supply in cubic metres per day.
                  <br />
                  <br />
                  Use estimate of 0.2m3/d per person (200 litres), if true
                  volume is not known.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SourceType</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This field is used to state what type the raw water source is.
                  Use the following codes:
                  <br />
                  - SB- Surface - Watercourse
                  <br />
                  - SL- Surface - Loch
                  <br />
                  - SR- Surface - Rainwater
                  <br />
                  - GB- Groundwater - Borehole
                  <br />
                  - GW- Groundwater - Well
                  <br />
                  - GS- Groundwater - Spring
                  <br />
                  - UN- Unknown
                  <br />
                  <br />
                  If there are multiple source types, list them all separated by
                  a ""/"" (i.e. SB/SL)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">SWFlag</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  This field should be completed where a source has water
                  originating from or is influenced by surface water. Use
                  letters SI
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">NumberInvestigations</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Use this field to indicate the total number of investigations
                  carried out in relation to the supply under part 6 of the 2017
                  Regulations (for Regulated supplies) or under Sec 76 notices
                  under the 1980 Act (for Type B supplies)
                  <br />
                  <br />
                  If not applicable, leave blank.{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">NumberEnforcementNotices</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Number</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Use this field to indicate the total number of enforcement
                  notices served in relation to the supply under Part 8 of the
                  2017 regulations.
                  <br />
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="regionArea">
        <div className="regionBody">
          <table style={{ width: "100%" }} className="helpAdviceTable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Field Name in Return template</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">ExemptionRadon</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  <b>Field type</b>
                </td>
                <td>
                  <b>Mandatory/ Desirable</b>
                </td>
              </tr>
              <tr>
                <td>Text (from pre-defined list)</td>
                <td>Mandatory if applicable (leave blank otherwise)</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Field Description</b>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Use this field to indicate whether an exemption has been
                  granted for radon under Schedule 3 Part E of the 2017
                  Regulations. An exemption has only been granted for supplies
                  that have received a waiver. If there is a supply that should
                  have a waiver but does not (i.e. supplies with sources that
                  are outside the high risk radon areas or supplies with sources
                  that are surface water), please enagage with DWQR to have a
                  waiver issued.
                  <br />
                  <br />
                  Use letters RADX for exempt supplies (that have a waiver
                  issued).
                  <br />
                  <br />
                  If not applicable, leave blank.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SupplyDetailsHelp;
