import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { isLogin } from "../../utils";

const PageLayout = ({ pageTitle, component: Component }) => {
  return (
    <>
      <div className="footer-top-menu-bar"></div>
      <div className="ds_wrapper flex-flex-direction">
        <nav style={{ display: "flex" }}>
          <a className="footerLoginLink ds_link" href="/#/">
            <ChevronLeftIcon></ChevronLeftIcon>Back to login
          </a>
          {isLogin() ? (
            <a className="footerLoginLink ds_link" href="/#/home">
              Home
            </a>
          ) : (
            ""
          )}
        </nav>

        <div className="regionAreaFooter">
          <header className="regionHeader">
            <h1 className="staticHeading">{pageTitle}</h1>
          </header>

          <main className="regionBodyFooter">{Component}</main>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
