export function isValidEmail(email) {
  // The email validation regex from the HTML5 specification https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
  return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email
  );
}

export function isValidCommaSeparatedEmailString(emails) {
  return emails
    .split(",")
    .map((e) => e.trim())
    .every((email) => isValidEmail(email));
}

export function formatCommaSeparatedEmailString(value) {
  return value
    .split(",")
    .map((email) => email.trim())
    .join(",");
}
