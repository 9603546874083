import footerGov from "../assets/img/footer_logo.jpg";

// Footer Block
const Footer = () => {
  return (
    <footer
      className="ds_site-footer  ds_reversed"
      style={{ backgroundColor: "#0065bd", borderTop: "none" }}
    >
      <div className="ds_wrapper">
        <div className="ds_site-footer__content">
          <ul
            className="ds_site-footer__site-items"
            style={{ borderBottom: "1px solid hsla(0,0%,100%,.2)" }}
          >
            <li className="ds_site-items__item">
              <a href="#/about">About</a>
            </li>
            <li className="ds_site-items__item">
              <a href="#/accessibility">Accessibility</a>
            </li>
            <li className="ds_site-items__item">
              <a href="#/cookies">Cookies</a>
            </li>
            <li className="ds_site-items__item">
              <a href="#/feedback">Feedback</a>
            </li>
            <li className="ds_site-items__item">
              <a href="#/privacy">Privacy</a>
            </li>
          </ul>

          <div className="ds_site-footer__copyright">
            <p>
              &copy; Crown Copyright. All content is available under the Open
              Government Licence v3.0, except for all graphic assets and
              otherwise stated.
            </p>
          </div>

          <div className="ds_site-footer__org logo_footer_container">
            <a
              className="ds_site-footer__org-link"
              title="The Scottish Government"
              href="http://www.gov.scot/"
            >
              <img
                className="ds_site-footer__org-logo"
                src={footerGov}
                alt="gov.scot"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
