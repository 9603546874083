import { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ContextualNav from "./ContextualNav";
import MainNavigation from "./MainNavigation";

const MainResponsiveNav = ({ id, location, access, userGroup }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    function closeMenu(e) {
      if (menuRef.current && !menuRef?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.body.addEventListener("click", closeMenu);
    return () => document.body.removeEventListener("click", closeMenu);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {(id &&
        location &&
        location.includes(`eventincidents/`) &&
        windowWidth <= 1300) ||
      windowWidth <= 767 ? (
        <>
          <div className="notification_header_icon" id="menu">
            <span className="notification_icon">
              <button
                className="iconButton"
                title="Responsive Menu"
                aria-label="Responsive Menu"
                ref={menuRef}
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu();
                }}
              >
                <MenuIcon />
              </button>
            </span>
          </div>

          {isOpen && (
            <div className="contextual_navbar__wrapper">
              <div className="context_nav">
                {id &&
                location.includes(`eventincidents/`) &&
                access &&
                access.find((item) => item.code === userGroup) ? (
                  <ContextualNav id={id} />
                ) : (
                  ""
                )}
                {windowWidth <= 767 && <MainNavigation />}
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default MainResponsiveNav;
