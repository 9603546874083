const ErrorMessage = (props) => {
  return (
    <div className="regionArea" id="errorMessage">
      <div className="assetHeader">{props.heading}</div>

      <div className="regionBody">
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
