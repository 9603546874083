import moment from "moment";
import { useState } from "react";
import { typeData } from "./AuditFilterOptions";

const AuditTable = ({ data }) => {
  const [order, setOrder] = useState(false);

  function sortTable(e, keyName) {
    data.Data.sort((a, b) => {
      const first = a[keyName].toLowerCase();
      const second = b[keyName].toLowerCase();

      return e.target.textContent && order
        ? first > second
          ? -1
          : 1
        : first < second
        ? -1
        : 1;
    });
  }

  function handleClick(e, keyName) {
    setOrder(!order);
    sortTable(e, keyName);
  }

  function findType(item) {
    return typeData
      .filter((type) => type.code === item)
      .map((fileType) => fileType.name);
  }

  function updateStatus(item) {
    return item === "SUCCESS"
      ? "Successful"
      : item === "FAILED"
      ? "Failed"
      : item === "FAILURE_VALIDATION"
      ? "Failed Validation"
      : "";
  }

  function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  return (
    <>
      <table
        className="audit_management_table"
        width={"95%"}
        style={{ margin: "0px" }}
      >
        <thead>
          <tr>
            <th onClick={(e) => handleClick(e, "Filename")}>File Name</th>
            <th onClick={(e) => handleClick(e, "Organisation")}>
              Organisation
            </th>
            <th onClick={(e) => handleClick(e, "Username")}>Uploaded by</th>
            <th>Type</th>
            <th>Size</th>
            <th>Date of Event</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data && data.Data.length > 0 && typeData ? (
            data.Data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.Filename}</td>
                  <td>{item.Organisation}</td>
                  <td>{item.Username}</td>
                  <td>{findType(item.Filetype)}</td>
                  <td>{bytesToSize(item.Filesize)}</td>
                  <td>{moment(item.Datetime).format("HH:mm DD/MM/YY")}</td>
                  <td>{updateStatus(item.Status)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>
                Sorry, no audit results were returned from the above filters.
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default AuditTable;
