import { useState, useEffect } from "react";
import RestPagination from "./RestPagination";

const AssetTable = ({
  tableHeads,
  data: propData,
  totalPages,
  pageNumber,
  setPageNumber,
}) => {
  const [order, setOrder] = useState(false);
  const [data, setData] = useState(propData);

  useEffect(() => {
    setData(propData);
  }, [propData]);

  function sortTable(e, keyName) {
    data.sort((a, b) => {
      const first = a[keyName];
      const second = b[keyName];

      if (first === null) {
        return 1;
      }
      if (second === null) {
        return -1;
      }
      if (first === second) {
        return 0;
      }

      return e.target.textContent && order
        ? first.toLowerCase() > second.toLowerCase()
          ? -1
          : 1
        : first.toLowerCase() < second.toLowerCase()
        ? -1
        : 1;
    });
  }

  const handleClick = (e, keyName) => {
    setOrder(!order);
    sortTable(e, keyName);
  };

  return (
    <>
      <table
        className="assetSearchTable"
        width={"95%"}
        style={{ margin: "0px" }}
      >
        <thead>
          <tr>
            {tableHeads.map((item) => {
              return (
                <th
                  key={item.id}
                  onClick={(e) => handleClick(e, item.value)}
                  scope="col"
                >
                  {item.text}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data !== "No results returned for this id" ? (
            data.map((item) => {
              return (
                <tr key={item.site_ref}>
                  <td>
                    <a
                      href={`#/assetcase/${item.site_ref}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        item.site_type
                          ? item.site_type
                          : "Asset Type value empty"
                      }
                    >
                      {item.site_type}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`#/assetcase/${item.site_ref}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        item.site_name
                          ? item.site_name
                          : "Site Name value empty"
                      }
                    >
                      {item.site_name}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`#/assetcase/${item.site_ref}`}
                      target="_blank"
                      rel="noopener"
                      className="tableLink"
                      aria-label={
                        item.site_region
                          ? item.site_region
                          : "Area Code value empty"
                      }
                    >
                      {item.region}
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td></td>
              <td>Sorry, no assets returned from the above search.</td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>

      <div>
        {totalPages ? (
          <RestPagination
            pageNumber={pageNumber}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AssetTable;
