import { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";

const SaveAssessment = ({
  setValidationMessage,
  setDisplayModal,
  additionalInfo,
  infoDueDate,
  handleClick,
  setDidStateChange,
  spinner,
}) => {
  useEffect(() => {}, [additionalInfo, infoDueDate]);

  function handleSave(e) {
    handleClick(e);
    setDisplayModal(true);
    setDidStateChange(false);
  }

  return (
    <button
      className="iconButton"
      title="save"
      aria-label="save"
      onClick={(e) => {
        e.preventDefault();
        spinner
          ? undefined
          : (additionalInfo === true && infoDueDate === "Invalid date") ||
            (additionalInfo === true && infoDueDate === "")
          ? setValidationMessage("You must select a due date!")
          : handleSave(e);
      }}
    >
      <SaveIcon
        className="save"
        style={{
          color: spinner ? "grey" : "#fff",
          cursor: spinner ? "auto" : "pointer",
        }}
      />
    </button>
  );
};

export default SaveAssessment;
