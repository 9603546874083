import { NavLink } from "react-router-dom";

const ContextualNav = ({ id }) => {
  return (
    <nav aria-label="contextual-navigation">
      <p>Event & Incidents</p>
      <ul>
        <li>
          <NavLink to={"../event-overview/" + id} activeClassName="ds_current">
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../event-data-sample/" + id}
            activeClassName="ds_current"
          >
            Sample Data
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../event-information/" + id}
            activeClassName="ds_current"
          >
            Event Information
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../event-assessment/" + id}
            activeClassName="ds_current"
          >
            Assessment
          </NavLink>
        </li>
        <li>
          <NavLink to={"../event-files/" + id} activeClassName="ds_current">
            Files
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../event-asset-case/" + id}
            activeClassName="ds_current"
          >
            Asset Case
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"../event-communication/" + id}
            activeClassName="ds_current"
          >
            Communication
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default ContextualNav;
