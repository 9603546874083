import { useContext, useEffect, useState } from "react";
import Notification from "./notifications/Notification";
import { CallBackendAssessment } from "../utils/apiCall";
import { AccountContext } from "./Account";
import Loader from "../components/Loader";

const NotificationTable = ({ types, status, user, refresh, setStatusCode }) => {
  const { refreshUser } = useContext(AccountContext);
  const [pageNumber, setPageNumber] = useState(1);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const [pageKey, setPageKey] = useState("");
  const [dateKey, setDateKey] = useState("");

  async function getData() {
    setSpinner(true);
    const body = {};

    const additionalParams = {
      headers: {},
      queryParams: {
        page_key: pageKey,
        date_key: dateKey,
        types: types,
        status: status,
        user: user,
      },
    };

    await refreshUser().then((result) => {
      CallBackendAssessment(
        body,
        process.env.REACT_APP_GETNOTIFICATIONS,
        "GET",
        result,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response.data);
          setStatusCode(response.status);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          setSpinner(false);
        });
    });
  }

  function getNextPage(lastEvaluatedKey) {
    // Need to request an additional 25 items from the notifications api.
    setPageKey(lastEvaluatedKey[0]);
    setDateKey(lastEvaluatedKey[1]);
    setPageNumber(pageNumber + 1);
    window.scrollTo(0, 0);
  }

  function getFirstPage() {
    setPageKey("");
    setDateKey("");
    setPageNumber(1);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    getData();
  }, [types, status, user, dateKey, pageKey, refresh]);

  return (
    <>
      {/* Loading */}
      {spinner ? <Loader height={"400px"} /> : ""}

      {/* Data Returned */}
      {!spinner && data && types
        ? data.Data.map((element) => {
            return (
              <Notification props={element} key={element._id} types={types} />
            );
          })
        : ""}

      {/* Data returned is 0 */}
      {!spinner && data !== null && data.Data.length === 0 ? (
        <div className="regionArea">
          <div className="regionHeader">
            <h2>No notifications</h2>
          </div>
          <div className="regionBody">
            <p>You currently have no new notifications.</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Error Message */}
      {!spinner && error !== null && data === null ? (
        <div className="regionArea">
          <div className="regionHeader">
            <h2>Error getting notifications</h2>
          </div>
          <div className="regionBody">
            <h2>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </h2>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Pagination */}
      <div>
        {data && (
          <ul className="navigationButtons">
            <li className="previousButton">
              <a
                className="previousButton "
                tabIndex="-1"
                role="button"
                aria-disabled={pageNumber === 1}
                aria-label="First page"
                disabled={pageNumber === 1}
                rel="first"
                onClick={(e) => (getFirstPage(), e.preventDefault())}
              >
                First Page
              </a>
            </li>
            <li className="nextButton">
              <a
                className="nextButton"
                tabIndex="0"
                role="button"
                disabled={data.LastEvaluatedKey === undefined}
                aria-disabled={data.LastEvaluatedKey === undefined}
                aria-label="Next page"
                rel="next"
                onClick={(e) => (
                  getNextPage(data.LastEvaluatedKey), e.preventDefault()
                )}
              >
                Next page
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default NotificationTable;
