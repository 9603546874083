import React from "react";

const Cookies = () => {
  return (
    <p>
      A cookie is a small text file used by web service to write information to
      your computer or smartphone for use later. This site does not use cookies
      except for the use of user session cookies to support single sign on for
      DWQR users. There are no non-essential cookies in use on this site
      therefore there is no option to modify the use of cookies on this site.
    </p>
  );
};

export default Cookies;
