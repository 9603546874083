let apigClientFactory = require("aws-api-gateway-client").default;

export const CallBackend = async (
  body,
  path,
  method,
  credentials,
  additionalParams
) => {
  const promise = new Promise((resolve, reject) => {
    let apigClient = apigClientFactory.newClient({
      invokeUrl: process.env.REACT_APP_APIROOT,
      accessKey: credentials.accessKeyId,
      secretKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: process.env.REACT_APP_REGION,
    });

    let params = {};

    apigClient
      .invokeApi(params, path, method, additionalParams, body)
      .then((results) => {
        resolve(results.data);
      })
      .catch(function (err) {
        reject(err);
        console.error(err);
      });
  });
  return promise;
};

export const CallBackendAssessment = async (
  body,
  path,
  method,
  credentials,
  additionalParams
) => {
  const promise = new Promise((resolve, reject) => {
    let apigClient = apigClientFactory.newClient({
      invokeUrl: process.env.REACT_APP_APIROOT,
      accessKey: credentials.accessKeyId,
      secretKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: process.env.REACT_APP_REGION,
    });

    let params = {};

    apigClient
      .invokeApi(params, path, method, additionalParams, body)
      .then((results) => {
        resolve(results);
      })
      .catch(function (err) {
        reject(err);
        console.error(err);
      });
  });
  return promise;
};
