import spinner from "../assets/img/water.gif";

const Loader = ({ height }) => {
  return (
    <div style={{ height: height }} className="ds_loader">
      <img src={spinner} alt="Loading" />
    </div>
  );
};

export default Loader;
