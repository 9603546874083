import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Col } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { AccountContext } from "../components/Account";
import TimeoutAlert from "../components/TimeoutAlert";
import { useLocation } from "react-router-dom";
import AccessDenied from "../components/AccessDenied";

export default function PageLayout({ component: Component, access }) {
  const {
    isUserActive,
    getSession,
    setActiveUser,
    activeUser,
    userGroup,
    getUserGroups,
  } = useContext(AccountContext);

  const location = useLocation();

  useEffect(() => {
    getSession()
      .then((result) => {
        return isUserActive(result);
      })
      .then((result) => {
        setActiveUser(result);
      });
    userGroup === null ? getUserGroups() : "";
  }, [activeUser, location, userGroup]);

  return (
    <div className="ds_wrapper">
      <div className="ds_content_sidebar customRow">
        {activeUser === false ? <TimeoutAlert /> : ""}
        <Col md={3} className="sidebar__wrapper">
          <Sidebar />
        </Col>

        <Col md={9}>
          <div className="right_large_doc">
            <Header location={location} userGroup={userGroup} access={access} />
            <main>
              {access === undefined ||
              (access && access.find((item) => item.code === userGroup)) ? (
                <Component />
              ) : (
                <AccessDenied />
              )}
            </main>
          </div>
        </Col>
      </div>
    </div>
  );
}
