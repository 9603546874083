import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import logo from "../assets/img/dwqr_logo.png";
import { AccountContext } from "../components/Account";
import SuccessMessage from "../components/SuccessMessage";
import ErrorMessage from "../components/ErrorMessage";

const UpdatePassword = () => {
  const history = useHistory();
  const { userAttr } = useContext(AccountContext);

  const [errorMsg, setErrorMsg] = useState(null);
  const [newPassword, setNewPassword] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [newPasswordValidationMessage, setNewPasswordValidationMessage] =
    useState(null);

  const passwordChange = (
    e,
    passwordState,
    setPasswordState,
    setValidationState,
    validationMessage,
    passwordMessage
  ) => {
    setValidationState(null);
    setPasswordState(e.target.value);
    if (passwordState === "") {
      setValidationState(validationMessage);
    } else {
      if (passwordMessage !== undefined && checkPassword(passwordState)) {
        setValidationState(null);
        setPasswordState(e.target.value);
      } else {
        if (passwordMessage !== undefined) {
          setValidationState(passwordMessage);
        }
      }
    }
  };

  function checkPassword(password) {
    let checkPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
    return checkPassword.test(password);
  }

  function changePassword(e, newPassword) {
    e.preventDefault();
    const cognitoUser = userAttr.user;
    const userAttributes = userAttr.userAttr;
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: () => {
        setShowSuccessMessage(true);
        setShowErrorMessage(false);
        setTimeout(() => {
          history.push("/");
        }, 5000);
      },
      onFailure: (err) => {
        setShowErrorMessage(true);
        setErrorMsg(err.message);
      },
    });
  }

  return (
    <div>
      <form>
        <div className="top-menu-bar"></div>
        <div className="login_container">
          <Col md={4}>
            <img
              width="100%"
              src={logo}
              alt="Drinking Water Quality for Scotland"
            />
          </Col>

          {showErrorMessage ? (
            <Col md={4}>
              <ErrorMessage heading="Error" message={errorMsg} />
            </Col>
          ) : (
            ""
          )}

          {showSuccessMessage ? (
            <div style={{ height: "100vh" }}>
              <SuccessMessage
                heading="Successfully changed password"
                message="Your password has been successfully changed. You will now be redirected to the login page."
              />
            </div>
          ) : (
            ""
          )}

          {!showSuccessMessage ? (
            <Col md={4} className="loginWrapper">
              <h5>Change Password</h5>

              <p>
                <strong>
                  This service is for standard accounts only. If you are a SSO
                  user please use the SSO reset password service.
                </strong>
              </p>

              <div className={`ds_question ${errorMsg}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="validationContainer">
                    <label className="ds_label ov_label" htmlFor="email">
                      New Password
                    </label>

                    <div
                      className="validationContainer"
                      style={{
                        borderLeft:
                          newPasswordValidationMessage !== null
                            ? "6px solid red"
                            : "none",
                        paddingLeft:
                          newPasswordValidationMessage !== null ? "6px" : "0px",
                      }}
                    >
                      {newPasswordValidationMessage !== null ? (
                        <div className="validation">
                          <span>{newPasswordValidationMessage}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="ds_select-wrapper">
                        <input
                          className={`ds_input user-email`}
                          id="password"
                          aria-label="password"
                          name="password"
                          type="password"
                          value={newPassword}
                          onBlur={(e) =>
                            passwordChange(
                              e,
                              newPassword,
                              setNewPassword,
                              setNewPasswordValidationMessage,
                              "Please provide a new password",
                              "Your password does not match the above criteria"
                            )
                          }
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {!showSuccessMessage ? (
            <Col md={4}>
              <div className="login_buttons_forgot_container">
                <button
                  className="ds_button login-btn"
                  onClick={(e) => changePassword(e, newPassword)}
                >
                  Submit
                </button>
              </div>
            </Col>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdatePassword;
