import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const Email = ({ name, email, data, id }) => {
  const [validationMessage, setValidationMessage] = useState(null);

  const [newEmail, setNewEmail] = useState(email[0].value);

  function isValidEmail(newEmail) {
    return /\S+@\S+\.\S+/.test(newEmail);
  }

  function updateEmail(e, setState) {
    setValidationMessage(null);
    setState(e.target.value);

    if (e.target.value.length === 0) {
      setValidationMessage("Please provide an email.");
    } else if (!isValidEmail(e.target.value)) {
      setValidationMessage("Email is invalid.");
    }
  }

  function checkEmail(e) {
    if (e.target.value.length === 0) {
      setValidationMessage("Please provide an email.");
    } else if (!isValidEmail(e.target.value)) {
      setValidationMessage("Email is invalid.");
    }
  }

  function submitEmail() {
    email[0].value = newEmail;
  }

  function resetState() {
    setValidationMessage(null);
    setNewEmail(email[0].value);
  }

  useEffect(() => {
    resetState();
  }, [email, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label className="ds_label" htmlFor={id}>
            {name}
          </label>

          <div
            className="validationContainer"
            style={{
              borderLeft: validationMessage !== null ? "6px solid red" : "none",
              paddingLeft: validationMessage !== null ? "6px" : "0px",
            }}
          >
            {validationMessage !== null ? (
              <div className="validation">
                <span>{validationMessage}</span>
              </div>
            ) : (
              <div></div>
            )}

            <div className="ds_select-wrapper">
              <input
                type="email"
                name={id}
                id={id}
                className="ds_input"
                value={newEmail}
                onBlur={(e) => checkEmail(e)}
                onChange={(e) => updateEmail(e, setNewEmail)}
              />
            </div>
          </div>
        </Col>
      </Row>

      {newEmail !== email[0].value && !validationMessage ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <button onClick={submitEmail}>Save</button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default Email;
