import { useState, useContext, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { CallBackend } from "../utils/apiCall";
import { AccountContext } from "../components/Account";
import AWS from "aws-sdk";
import Loader from "../components/Loader";
import moment from "moment";
import SuccessMessage from "../components/communications/SuccessMessage";
import {
  isValidCommaSeparatedEmailString,
  formatCommaSeparatedEmailString,
} from "../utils/emailValidation";

// No Found Page Component
const EventCommunication = () => {
  const { refreshUser } = useContext(AccountContext);

  const currentParams = useParams();

  const BUCKET_NAME = process.env.REACT_APP_BUCKET_ATTACHED;

  let btnRef = useRef();

  const [selectedTemplateTitle, setSelectedTemplateTitle] = useState(``);
  const [selectedTemplate, setSelectedTemplate] = useState(``);

  const [selectedTemplateEmail, setSelectedTemplateEmail] = useState(``);
  const [comments, setComments] = useState("");

  const [fileAttachments, setFileAttachments] = useState([]);
  const [fileAttachment, setFileAttachment] = useState("");

  const [validationMessage, setValidationMessage] = useState("");

  const [spinner, setSpinner] = useState(false);

  const [logData, setLogData] = useState(null);
  const [logError, setLogError] = useState(null);
  const [postError, setPostError] = useState(null);
  const [filesError, setFilesError] = useState(null);

  const [messageSent, setMessageSent] = useState(false);

  const [templateOne] =
    useState(`Subject: Re. Event ${currentParams.id} at [asset name] request for additional information.

DWQR are requesting further information regarding event ${currentParams.id} at [asset name]. Could you please supply the following by [date further event information required]
  
[free text box to be filled in by assessor]
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateTwo] =
    useState(`Subject: Reminder Re. Event ${currentParams.id} at [asset name] request for additional information.

This is a reminder that DWQR requested further information regarding event ${currentParams.id} at [asset name] by [date further event information required]. This information has not been received. Please could you supply the following as soon as possible.

[include text from previous email]

Yours sincerely,

[Assessor]

On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateThree] =
    useState(`Subject:  Event ${currentParams.id} at [asset name] has been closed.

DWQR have assessed ${currentParams.id} at [asset name] as [event category] and it is now closed.
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateFourA] =
    useState(`Subject:  Event ${currentParams.id} at [asset name] has been declared an incident.

[Assessor]  has declared event ${currentParams.id} at  [asset name] an incident. This is due to [reason for classifying]  It is provisionally classified as [event category]  and a detailed report is required by [incident report due date].  Health and Environmental Health stakeholders have been copied into this correspondence.
  
[free text box for additional information]
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateFourB] =
    useState(`Subject:  Event ${currentParams.id} at [asset name] has been declared an incident.

[Assessor]  has declared event ${currentParams.id} at  [asset name] an incident. This is due to [reason for classifying]  It is provisionally classified as [event category].  Health and Environmental Health stakeholders have been copied into this correspondence.
  
[free text box for additional information]
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateFive] =
    useState(`Subject: Reminder Re. Incident ${currentParams.id} at [asset name] request for detailed report.

This is a reminder that DWQR requested a detailed report regarding event ${currentParams.id} at [asset name] by [Incident report due date]. This report has not been received. Please could you supply it as soon as possible.
  
[include text from previous email]
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateSix] =
    useState(`Subject: Request for Incident Assessment feedback: Incident ${currentParams.id} at [asset name].

Please find attached the assessment of the water quality incident ${currentParams.id} at [asset name] that happened on [event date].
  
If you have any feedback or queries about the assessment please get in touch.
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateEight] =
    useState(`Subject: Assessment Report for  Incident ${currentParams.id} at [asset name].

Please find attached the assessment of the water quality incident ${currentParams.id} at [asset name]  that occured on [event date].
  
If you have any queries about the assessment please get in touch.
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateNine] =
    useState(`Subject: Reminder Re. Confirmation that Assessment Report for  Incident  ${currentParams.id} at [asset name] has been received.

This is a reminder that DWQR wrote to you on [assessment issued to SW date] with the Assessment Report for Incident ${currentParams.id} at [asset name]. Can you confirm receipt of this report as soon as possible, please?
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateEleven] =
    useState(`Subject: Action [action number]: Incident ${currentParams.id} at [asset name] is overdue.

Action [action number]: Incident ${currentParams.id} at [asset name] is overdue. Can you please update DWQR on the action status.
  
Yours sincerely,

[Assessor]
  
On behalf of the Drinking Water Quality Regulator for Scotland`);

  const [templateTwelve] =
    useState(`Subject:  Incident ${currentParams.id} at [asset name] has been signed off.

Water Quality Incident ${currentParams.id} at [asset name] - [Event date]
  
DWQR has assessed the water quality incident ${currentParams.id} at [asset name] that occurred in [event date] and is satisfied that Scottish Water has no outstanding actions arising from this. Consequently, I am able to  sign off the incident as closed.
  
Yours sincerely,

[Assessor]
  
(Water Specialist Name )
On behalf of the Drinking Water Quality Regulator for Scotland`);

  function handleTemplateState(e) {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const title = option.getAttribute("data-template-title");
    const email = option.getAttribute("data-template-email");

    setSelectedTemplate(e.target.value);
    setSelectedTemplateTitle(title);
    setSelectedTemplateEmail(email);
    setValidationMessage("");
  }

  function handleStateChange(value, updateState) {
    return updateState(value);
  }

  function checkEmailValue(emailValue, setState) {
    if (!isValidCommaSeparatedEmailString(emailValue)) {
      setValidationMessage(
        "Please provide a valid email or set of emails separated by commas."
      );
    } else {
      setValidationMessage("");
      setState(emailValue);
    }
  }

  async function getLogData() {
    setSpinner(true);

    const body = {};

    await refreshUser().then((configCredentials) => {
      CallBackend(body, `logging/${currentParams.id}`, "GET", configCredentials)
        .then((response) => {
          setLogData(response);
          setSpinner(false);
          setValidationMessage(response);
        })
        .catch((error) => {
          setSpinner(false);
          console.log(error);
          if (error.response) {
            setLogError(error);
          }
        });
    });
  }

  async function postCommData() {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }

    const body = {
      user: process.env.REACT_APP_COMMUNICATION,
      date: moment(new Date()).format("HH:mm a DD/MM/YYYY"),
      template_used: selectedTemplateTitle,
      comments: comments,
      destination_address: formatCommaSeparatedEmailString(
        selectedTemplateEmail
      ),
      message: selectedTemplate,
      attachment_location: fileAttachment,
    };

    await refreshUser().then((configCredentials) => {
      CallBackend(
        body,
        `communications/${currentParams.id}`,
        "POST",
        configCredentials
      )
        .then(() => {
          setMessageSent(true);
          setSpinner(false);
          resetState();
          getLogData();
        })
        .catch((error) => {
          setSpinner(false);
          setPostError(error);
        });
    });
  }

  async function getAssociatedFiles() {
    const s3 = new AWS.S3({
      params: { Bucket: BUCKET_NAME },
    });

    await refreshUser().then((configCredentials) => {
      if (configCredentials) {
        const params = {
          Bucket: `${BUCKET_NAME}`,
          Prefix: `gold/event_attachments/${currentParams.id}/`,
        };

        s3.listObjectsV2(params, function (err, data) {
          if (err) {
            setFilesError(err);
          } else {
            getAssociatedFileNames(data.Contents);
          }
        });
      }
    });
  }

  function getAssociatedFileNames(data) {
    const allNames = [];

    if (data.length > 0) {
      data.forEach((item) => {
        if (item.Key.substring(item.Key.lastIndexOf("/") + 1)) {
          allNames.push(item.Key.substring(item.Key.lastIndexOf("/") + 1));
        }
      });
    }

    return setFileAttachments(allNames);
  }

  function resetState() {
    setComments("");
    setSelectedTemplate("");
    setFileAttachment("");
    setSelectedTemplateEmail("");
  }

  useEffect(() => {
    getLogData();
    getAssociatedFiles();
  }, [currentParams.id]);

  return (
    <div className="page_wrapper">
      {postError && (
        <div className="regionArea">
          <div className="regionHeader">Error</div>

          <div className="regionBody">
            <Row>
              <Col>
                <h2>
                  There has been a problem with the system, if this problem
                  persists please contact DWQ.
                </h2>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {filesError && (
        <div className="regionArea">
          <div className="regionHeader">Error</div>

          <div className="regionBody">
            <Row>
              <Col>
                <h2>
                  There has been a problem with the system, if this problem
                  persists please contact DWQ.
                </h2>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {messageSent && (
        <SuccessMessage selectedTemplateTitle={selectedTemplateTitle} />
      )}

      <div
        className="regionArea"
        aria-label={`Send Communication - Event ${currentParams.id}`}
      >
        <div className="regionHeader">
          <h1>Send Communication - Event {currentParams.id}</h1>
        </div>

        <div className="regionBody">
          <Row>
            <Col sm={5}>
              <label className="ds_label" htmlFor="template">
                Select Template
              </label>
              <div className="ds_select-wrapper">
                <select
                  name="template"
                  id="template"
                  className="ds_select"
                  value={selectedTemplate}
                  onChange={(e) => (
                    handleTemplateState(e, setSelectedTemplate),
                    setMessageSent(false)
                  )}
                >
                  <option
                    value=""
                    data-template-title=""
                    data-template-email=""
                  >
                    Select
                  </option>
                  <option
                    value={templateOne}
                    data-template-title="Template 1 - Request further event information"
                    data-template-email=""
                  >
                    Template 1 - Request further event information
                  </option>
                  <option
                    value={templateTwo}
                    data-template-title="Template 2 - Reminder for request for further event
                    information"
                    data-template-email=""
                  >
                    Template 2 - Reminder for request for further event
                    information
                  </option>
                  <option
                    value={templateThree}
                    data-template-title="Template 3 - Event closed"
                    data-template-email=""
                  >
                    Template 3 - Event closed
                  </option>
                  <option
                    value={templateFourA}
                    data-template-title="Template 4a - Declare incident with request for report"
                    data-template-email=""
                  >
                    Template 4a - Declare incident with request for report
                  </option>
                  <option
                    value={templateFourB}
                    data-template-title="Template 4b - Declare incident"
                    data-template-email=""
                  >
                    Template 4b - Declare incident
                  </option>
                  <option
                    value={templateFive}
                    data-template-title="Template 5 - Reminder for detailed report"
                    data-template-email=""
                  >
                    Template 5 - Reminder for detailed report
                  </option>
                  <option
                    value={templateSix}
                    data-template-title="Template 6 - Request for assessment report feedback"
                    data-template-email=""
                  >
                    Template 6 - Request for assessment report feedback
                  </option>
                  <option
                    value={templateEight}
                    data-template-title="Template 8 - Assessment report"
                    data-template-email=""
                  >
                    Template 8 - Assessment report
                  </option>
                  <option
                    value={templateNine}
                    data-template-title="Template 9 - Reminder for confirmation of receipt of
                    assessment report"
                    data-template-email=""
                  >
                    Template 9 - Reminder for confirmation of receipt of
                    assessment report
                  </option>
                  <option
                    value={templateEleven}
                    data-template-title="Template 11 - Action overdue"
                    data-template-email=""
                  >
                    Template 11 - Action overdue
                  </option>
                  <option
                    value={templateTwelve}
                    data-template-title="Template 12 - Incident sign off"
                    data-template-email=""
                  >
                    Template 12 - Incident sign off
                  </option>
                </select>
                <span className="ds_select-arrow" aria-hidden="true"></span>
              </div>
            </Col>

            <Col sm={4}>
              <label className="ds_label" htmlFor="email">
                To:
              </label>
              <div className="ds_select-wrapper">
                <input
                  type="email"
                  multiple
                  id="email"
                  className="ds_select"
                  style={{ padding: "0 14px 0px 14px" }}
                  value={selectedTemplateEmail}
                  onChange={(e) => {
                    handleStateChange(e.target.value, setSelectedTemplateEmail);
                  }}
                  onBlur={(e) => {
                    checkEmailValue(e.target.value, setSelectedTemplateEmail);
                  }}
                />
              </div>
            </Col>
          </Row>

          {selectedTemplate !== "" && (
            <>
              <Row>
                <Col sm={12}>
                  <div style={{ marginTop: "1.5rem" }}>
                    <label
                      className="ds_label communicationTemplate"
                      htmlFor="templateTitle"
                    >
                      <h2>{selectedTemplateTitle}</h2>
                    </label>

                    <textarea
                      name="templateTitle"
                      type="text"
                      id="templateTitle"
                      style={{ width: "100%", padding: "14px 14px" }}
                      value={selectedTemplate}
                      rows="16"
                      onChange={(e) =>
                        handleStateChange(e.target.value, setSelectedTemplate)
                      }
                    ></textarea>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <div className="root_cause">
                    <label className="ds_label" htmlFor="comments">
                      Comments
                    </label>

                    <textarea
                      name="comments"
                      type="text"
                      id="comments"
                      style={{ width: "100%", padding: "14px 14px" }}
                      value={comments}
                      rows="4"
                      onChange={(e) =>
                        handleStateChange(e.target.value, setComments)
                      }
                    ></textarea>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <div>
                    <label className="ds_label" htmlFor="attachment">
                      Attach a file
                    </label>

                    <div className="ds_select-wrapper">
                      <select
                        name="attachment"
                        id="attachment"
                        className="ds_select"
                        value={fileAttachment}
                        onChange={(e) =>
                          handleStateChange(e.target.value, setFileAttachment)
                        }
                      >
                        <option value="">Select</option>

                        {fileAttachments.length > 0 ? (
                          fileAttachments.map((item, index) => {
                            return (
                              <option
                                key={index}
                                value={`gold/event_attachments/${currentParams.id}/${item}`}
                              >
                                {item}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">
                            No files have been uploaded to this event
                          </option>
                        )}
                      </select>
                      <span
                        className="ds_select-arrow"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <button
                    style={{ padding: "8px 3rem" }}
                    className="upload_button"
                    ref={btnRef}
                    onClick={(e) => {
                      selectedTemplate === ""
                        ? (e.preventDefault(),
                          setValidationMessage(
                            "Please select a Template from the dropdown."
                          ))
                        : !selectedTemplateEmail ||
                          !isValidCommaSeparatedEmailString(
                            selectedTemplateEmail
                          )
                        ? (e.preventDefault(),
                          setValidationMessage(
                            "Please provide a valid email or set of emails separated by commas."
                          ))
                        : validationMessage === ""
                        ? postCommData()
                        : "";
                    }}
                  >
                    Send
                  </button>

                  <div className="upload_file">
                    {validationMessage ? (
                      <div
                        className="validation"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginTop: "1rem",
                        }}
                      >
                        {validationMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Row>
            </>
          )}
        </div>
      </div>

      <div className="regionArea" aria-label="Log">
        <div className="regionHeader">
          <h2>Log</h2>
        </div>

        {spinner ? <Loader height={"200px"} /> : ""}

        {logData && logData.length > 0 && (
          <div className="logBody">
            {logData.map((item, index) => {
              return (
                <div key={index} className="logSection">
                  <Row className="logSubSection">
                    <Col sm={3}>
                      {moment(item.Date, "DD/MM/YYYY HH:mm:ss").format(
                        "HH:mm A DD/MM/YYYY"
                      )}
                    </Col>
                    <Col sm={6}>{item.Template_used}</Col>
                  </Row>

                  <Row className="logSubSection">
                    <Col>
                      <p>
                        <strong>Email: </strong> {item.Message}
                      </p>
                    </Col>
                  </Row>
                  {item.Comments ? (
                    <Row className="logSubSection">
                      <Col>
                        <p>
                          <strong>Comments: </strong> {item.Comments}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {item.Attachment_location ? (
                    <Row>
                      <Col>
                        <p>
                          <strong>Attachment: </strong>
                          {item.Attachment_location}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <Row>
                    <Col>
                      <p>
                        <strong>Destination: </strong>
                        {item.Name.split(",").join(", ")}
                      </p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        )}

        {logData && logData.length === 0 && (
          <div className="regionBody">
            <Row>
              <Col>
                <p>No communications have been sent for this event.</p>
              </Col>
            </Row>
          </div>
        )}

        {logError ? (
          <div className="regionBody">
            <Row>
              <Col>
                <h2>
                  There has been a problem with the system, if this problem
                  persists please contact DWQ.
                </h2>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EventCommunication;
