import { NavLink } from "react-router-dom";
import moment from "moment-timezone";

// Display Notification on the sidebar
const SingleNotification = ({ id, message, date, category }) => {
  const formatNewDate = moment(parseInt(date)).format("HH:mm a DD/MM/YY");

  return (
    <div className="notifications">
      <NavLink
        className="notificationLink"
        to={
          id !== undefined ? "/notifications-hub/" + id : "/notifications-hub/"
        }
      >
        <div className="notifications_list">
          <div className="notification_unread">
            <div className="notification_title">
              {message === "No unread notifications" && <h5>{message}</h5>}
              {category}
            </div>
            <div className="notification_item_list">
              <div className="notification_item_list_inner">
                <ul>
                  <li>{date ? formatNewDate : ""}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default SingleNotification;
