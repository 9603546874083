import { Col, Row } from "react-bootstrap";
import DateFilter from "./DateFilter";
import SelectFilter from "./SelectFilter";
import { localAuthorityData } from "../../views/help/all_local_authority_data";
import { organisationData, typeData, statusData } from "./AuditFilterOptions";

const AuditFilters = (props) => {
  const allGroups = [...organisationData, ...localAuthorityData];

  return (
    <>
      <Row className="eventincidents_search">
        <Col sm={3}>
          <SelectFilter
            name="Organisation"
            setState={props.setOrganisation}
            state={props.organisation}
            data={allGroups}
            firstOption="View all Organisations"
          />
        </Col>
        <Col sm={3}>
          <SelectFilter
            name="Type"
            setState={props.setType}
            state={props.type}
            data={typeData}
            firstOption="View all types"
          />
        </Col>
        <Col sm={3}>
          <SelectFilter
            name="Status"
            setState={props.setStatus}
            state={props.status}
            data={statusData}
            firstOption="View all"
          />
        </Col>
      </Row>
      <Row className="eventincidents_search">
        <Col sm={3}>
          <DateFilter
            name="From date"
            setState={props.setFromDate}
            state={props.fromDate}
            attributeName="fromDate"
            messageOne="The date cannot be more than today"
            messageTwo="The date cannot be more than 'To date'"
            toDateState={props.toDate}
          />
        </Col>
        <Col sm={3}>
          <DateFilter
            name="To date"
            setState={props.setToDate}
            state={props.toDate}
            attributeName="toDate"
            messageOne="The date cannot be less than 'From Date'"
            fromDateState={props.fromDate}
          />
        </Col>
      </Row>
    </>
  );
};

export default AuditFilters;
