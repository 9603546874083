import React from "react";

const Feedback = () => {
  return (
    <p>
      This solution is owned and operated by the Drinking Water Quality
      Regulator for Scotland (DWQR). If you wish to provide any feedback on this
      system, please refer to contact us page on our main web site{" "}
      <a
        className="ds_link"
        href="https://dwqr.scot/contact-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        DWQR-Contact-Us
      </a>
    </p>
  );
};

export default Feedback;
