import React, { useState } from "react";
import { handleChange } from "../../utils/helpers";
import BucketUpload from "./BucketUpload";
import Alert from "../Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ValidationContainer from "../ValidationContainer";

const SWRegulatory = (props) => {
  const [fileOptionValue, setFileOptionValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [focus, setFocus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const { description, title } = props;

  function successMessageOne(fileName) {
    return {
      title: "Shape File Successfully Uploaded",
      message: (
        <>
          <p>
            Shape File {fileName} successfully uploaded at{" "}
            {moment().format("HH:mm A DD/MM/YY")}.
          </p>
          <p>
            The file is currently being scanned for malware and should appear in
            the shape files tab momentarily.
          </p>
        </>
      ),
    };
  }

  function successMessageTwo(fileName) {
    return {
      title: "File Successfully Uploaded",
      message: (
        <>
          <p>
            File {fileName} successfully uploaded at{" "}
            {moment().format("HH:mm A DD/MM/YY")} and is awaiting confirmation.
          </p>
          <p>
            The system is currently validating the upload, you will be contacted
            about the file status.
          </p>
        </>
      ),
    };
  }

  return (
    <>
      {showSuccess && successMessage && (
        <Alert
          type="success"
          style={{
            backgroundColor: "#5eb135",
            borderColor: "#5eb135",
          }}
          icon={<CheckCircleIcon />}
          heading={successMessage.title}
          message={successMessage.message}
        />
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>{title}</h1>
        </div>

        <div className="regionBody">
          <p>{description}</p>

          <div className="upload_select_wrapper">
            <p>Please upload all regulatory files in the following order.</p>

            <ol>
              <li>Asset Details</li>
              <li>Customer Contact</li>
              <li>Health Boards</li>
              <li>Local Authorities</li>
              <li>Non-Standard parameters</li>
              <li>Out of Service</li>
              <li>Parent/Child Relationship</li>
              <li>Sample Data</li>
              <li>Sample Point Codes</li>
              <li>Scheduled Sample Shortfall</li>
              <li>Supply Point Sampling</li>
            </ol>

            <p>
              <strong>
                When uploading Shape Files, please disregard the above order.
              </strong>
            </p>

            <label htmlFor="FileOption" className="ds_label ov_label">
              Upload Type
            </label>

            <ValidationContainer validationMessage={validationMessage}>
              <div className="ds_select-upload-wrapper">
                <select
                  name="FileOption"
                  className="ds_select"
                  id="FileOption"
                  value={fileOptionValue}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  onChange={(e) => handleChange(e, setFileOptionValue)}
                >
                  <option value="">Select</option>
                  <option value="asset_details">Asset Details</option>
                  <option value="customer_contact_details">
                    Customer Contact
                  </option>
                  <option value="health_boards">Health Boards</option>
                  <option value="local_authorities">Local Authorities</option>
                  <option value="non_standard_parameters">
                    Non-standard parameters
                  </option>
                  <option value="out_of_service_assets">Out of Service</option>
                  <option value="parent_child">
                    Parent/Child Relationship
                  </option>
                  <option value="sample_data">Sample Data</option>
                  <option value="sample_point_codes">Sample Point Codes</option>
                  <option value="scheduled_sample_shortfall">
                    Scheduled Sample Shortfall
                  </option>
                  <option value="shape_files">Shape File</option>
                  <option value="supply_point_sampling">
                    Supply Point Sampling
                  </option>
                </select>

                <div className="ds_select-arrow-icon">
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 35, color: focus ? "black" : "white" }}
                  />
                </div>
              </div>
            </ValidationContainer>
          </div>

          <BucketUpload
            path={
              fileOptionValue === "shape_files" ? "sw_shape_file" : "sw_file"
            }
            fileType={fileOptionValue}
            setShowSuccess={setShowSuccess}
            setFileOptionValue={setFileOptionValue}
            setValidationMessage={setValidationMessage}
            setSuccessMessage={setSuccessMessage}
            successMessageValue={
              fileOptionValue === "shape_files"
                ? successMessageOne
                : successMessageTwo
            }
          />
        </div>
      </div>
    </>
  );
};

export default SWRegulatory;
