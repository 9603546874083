import { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "../Account";
import { NotificationContext } from "../NotificationsProvider";
import { CallBackend } from "../../utils/apiCall";

import CalltoActionButton from "../hazard-manager-components/CalltoActionButton";
import CheckBox from "../notifications/CheckBox";

const SettingsForm = ({ data, setData, userSettingOptions: checkboxes }) => {
  const { refreshUser } = useContext(AccountContext);
  const { getDefaultNotificationSettings } = useContext(NotificationContext);

  const [checkedState, setCheckedState] = useState([]);
  const [error, setError] = useState(null);

  function handleCheckboxState() {
    const checkBoxArray = [...checkboxes].map((item) => ({
      name: item,
      checked: false,
    }));

    checkBoxArray.forEach((item) => {
      const match = [...data].find((el) => el === item.name);
      item.checked = match ? true : false;
    });

    setCheckedState(checkBoxArray);
  }

  function handleStateChange(position, checkValue) {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        item.checked = checkValue;
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
  }

  function updateCheckboxState() {
    const filterTrueValues = [...checkedState]
      .filter(({ checked }) => checked === true)
      .map(({ name }) => name);

    setData(filterTrueValues);
  }

  async function submitSettings(e) {
    e.preventDefault();

    await refreshUser().then((result) => {
      CallBackend(
        { default_category_filters: data },
        "notification_settings",
        "PUT",
        result
      )
        .then(() => {
          getDefaultNotificationSettings();
          if (error) {
            setError(null);
          }
        })
        .catch((error) => {
          setError(error);
        });
    });
  }

  useEffect(() => {
    handleCheckboxState();
  }, [data]);
  return (
    <>
      {error && (
        <Row>
          <Col sm={12}>
            <div className="regionArea">
              <div className="assetHeader">
                <h1>Error saving changes.</h1>
              </div>
              <div className="regionBody">
                There was an error when saving your changes.
              </div>
            </div>
          </Col>
        </Row>
      )}
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Notifications Settings</h1>
        </div>

        <div className="regionBody">
          <Row className="eventincidents_search">
            <Col sm={12}>
              <form onSubmit={(e) => submitSettings(e)}>
                <p>
                  <strong>
                    Select which categories you would like to view in your
                    notifications.
                  </strong>
                </p>

                {data &&
                  checkedState &&
                  checkedState.map((checkbox, index) => {
                    return (
                      <CheckBox
                        key={index}
                        index={index}
                        name={checkbox.name}
                        state={checkbox.checked}
                        handleStateChange={handleStateChange}
                      />
                    );
                  })}

                <div className="multiple_button_container">
                  <CalltoActionButton
                    submitFunction={updateCheckboxState}
                    name="Save"
                  />
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;
