import { useContext, useEffect } from "react";
import { AccountContext } from "../components/Account";
import { NotificationContext } from "../components/NotificationsProvider";
import { useLocation } from "react-router-dom";
import SWHome from "./sw/Home";
import LAHome from "./la/Home";
import DWQHome from "./dwq/Home";
import GroupError from "../components/GroupError";

const Home = () => {
  const { permissions, userGroup, getUserGroups } = useContext(AccountContext);
  const { notificationSettings } = useContext(NotificationContext);

  const location = useLocation();

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
  }, [userGroup, location]);

  return (
    <div className="page_wrapper">
      {permissions.dwqPermissions.includes(userGroup) ? <DWQHome /> : ""}
      {permissions.swPermissions.includes(userGroup) ? (
        <SWHome notificationSettings={notificationSettings} />
      ) : (
        ""
      )}
      {permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        <LAHome notificationSettings={notificationSettings} />
      ) : (
        ""
      )}

      {permissions.dwqPermissions.includes(userGroup) ||
      permissions.swPermissions.includes(userGroup) ||
      permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        ""
      ) : (
        <GroupError />
      )}
    </div>
  );
};

export default Home;
