import { useEffect, useContext, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { AccountContext } from "../components/Account";
import { Col } from "react-bootstrap";
import SearchIcon from "../assets/img/search.svg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

const EventFiles = () => {
  const currentParams = useParams();
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_ATTACHED;
  const { configCredentials, getUserName } = useContext(AccountContext);

  const [attachedFiles, setAttachedFiles] = useState([]);

  const [showSuccess, setShowSuccess] = useState(false);

  const [checkbox, setCheckbox] = useState(false);

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [putError, setPutError] = useState(null);
  const [getError, setGetError] = useState(null);
  const [downloadError, setDownloadError] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    if (showSuccess) {
      setShowSuccess(false);
    }
  };

  const s3 = new AWS.S3();

  // Post request to send files to the s3 bucket storing their metadata
  async function uploadFile(e, file) {
    e.preventDefault();

    await getUserName().then((result) => {
      if (file) {
        setAttachedFiles([]);
        const objectData = {
          filename: file.name,
          eventnumber: currentParams.id,
          uploadDate: moment().format("DD/MM/YY"),
          username: result,
          filetype: checkbox ? "assessment_report" : file.type,
        };

        const params = {
          Body: file,
          Bucket: `${BUCKET_NAME}/gold/event_attachments/${currentParams.id}`,
          Key: selectedFile.name,
          Metadata: objectData,
        };

        if (configCredentials !== null) {
          return s3
            .putObject(params)
            .on("success", (response) => {
              // Update state from here and display success message.
              if (response.httpResponse.statusCode === 200) {
                resetFileInput();
                getAttachedFiles();
              }
            })
            .send((err) => {
              if (err) {
                console.error(err);
                setPutError(err);
                setSpinner(false);
              }
            });
        }
      }
    });
  }

  // Get request to pull back the attached files from the s3 bucket
  function getAttachedFiles() {
    let files = [];

    if (configCredentials) {
      const params = {
        Bucket: `${BUCKET_NAME}`,
        Prefix: `gold/event_attachments/${currentParams.id}/`,
      };

      // Get list of the attached files
      s3.listObjectsV2(params, function (err, data) {
        if (err) throw setGetError(err);

        // for each file use the key in the params to request the metadata for each file
        data.Contents.forEach((item) => {
          const otherParams = {
            Bucket: `${BUCKET_NAME}`,
            Key: `${item.Key}`,
          };

          s3.headObject(otherParams, function (err, data) {
            if (err) throw setGetError(err);
            // If filename in the metadata doesnt exist then dont run
            if (data.Metadata.filename) {
              // Push the file metadata into state.
              files.push(data.Metadata);
              return setAttachedFiles([...new Set(files)]);
              // return setAttachedFiles((current) => [...current, data.Metadata]);
            }
          });
        });
      });
    }
  }

  function downloadFile(filename) {
    const params = {
      Bucket: `${BUCKET_NAME}`,
      Key: `gold/event_attachments/${currentParams.id}/${filename}`,
    };

    s3.getObject(params, (err, data) => {
      if (err) throw setDownloadError(err);

      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}`;
      link.click();
    });
  }

  const resetFileInput = () => {
    inputRef.current.value = null;
    setSelectedFile(undefined);
    setCheckbox(false);
    setSpinner(false);
    setShowSuccess(true);
  };

  function handleSearch() {
    setSearchInput(searchQuery);
  }

  useEffect(() => {
    getAttachedFiles();
  }, [configCredentials, currentParams.id]);

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>File Upload - Event {currentParams.id}</h1>
        </div>
        <div className="regionBody">
          <p>
            <strong>
              If a file with the same name already exists it will be
              overwritten.
            </strong>
          </p>
          <div className="confirmContainer">
            <input
              type="checkbox"
              checked={checkbox}
              onChange={() => setCheckbox(!checkbox)}
              name="confirmAssessment"
              id="confirmAssessment"
            />
            <label htmlFor="confirmAssessment">
              Please tick if the file is an Assessment Report.
            </label>
          </div>
          <div className="uploadContainer">
            <input
              id="uploaded-files"
              name="Upload"
              aria-label="Upload"
              aria-describedby="basic-addon1"
              type="file"
              ref={inputRef}
              onChange={handleFileInput}
            />

            <button
              disabled={selectedFile === null}
              aria-disabled={selectedFile === null}
              onClick={(e) => {
                uploadFile(e, selectedFile);
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </div>

      {putError && (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            Error uploading file to Event - {currentParams.id}
          </div>

          <div className="regionBody">
            <p>
              There has been an error while attempting to upload the file to
              Event - {currentParams.id}.
            </p>
            <p>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </p>
          </div>
        </div>
      )}

      {getError && (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            Error retrieving files for Event - {currentParams.id}
          </div>

          <div className="regionBody">
            <p>
              There has been an error while attempting to retrieve files for
              Event - {currentParams.id}.
            </p>
            <p>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </p>
          </div>
        </div>
      )}

      {downloadError && (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            Error downloading file from Event - {currentParams.id}
          </div>

          <div className="regionBody">
            <p>
              There has been an error while attempting to download the file from
              Event - {currentParams.id}.
            </p>
            <p>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </p>
          </div>
        </div>
      )}

      {showSuccess !== false ? (
        <div className="regionArea" id="successMessage">
          <div
            className="assetHeader"
            style={{ backgroundColor: "#5eb135", borderColor: "#5eb135" }}
          >
            File Successfully Uploaded to Event {currentParams.id}
            <span>
              <CheckCircleIcon style={{ color: "#fff" }} />
            </span>
          </div>

          <div className="regionBody" style={{ borderColor: "#5eb135" }}>
            <p>
              File successfully uploaded at{" "}
              {moment().format("HH:mm A DD/MM/YY")}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h2>Files</h2>
        </div>
        <div className="regionBody">
          <Col sm={5}>
            <div className="ds_select-wrapper">
              <input
                className="ds_input "
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                style={{ margin: "6px 6px 6px 0px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : "")}
              />
              <button
                className="ds_select-search"
                onClick={() => handleSearch()}
                aria-label="search"
              >
                <img
                  className="ds_searchIcon"
                  src={SearchIcon}
                  alt="search icon"
                />
              </button>
            </div>
          </Col>
        </div>
        <table className="fileUploadTable">
          <thead>
            <tr>
              <th>File Name</th>
              <th>File Type</th>
              <th>Uploaded By</th>
              <th>Upload Date</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {attachedFiles &&
              attachedFiles
                .filter((item) => {
                  return searchInput === ""
                    ? attachedFiles
                    : item.filename.includes(searchInput);
                })
                .sort((a, b) => (a.uploaddate < b.uploaddate ? -1 : 1))
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.filename}</td>
                      <td>{item.filetype}</td>
                      <td>{item.username}</td>
                      <td>{item.uploaddate}</td>
                      <td onClick={() => downloadFile(item.filename)}>
                        <FileDownloadIcon />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventFiles;
