import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const Regulations = ({
  regulations,
  newRegulations,
  setNewRegulations,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [editedRegulation, setEditedRegulation] = useState("");
  const [newRegulationNumber, setNewRegulationNumber] = useState("");
  const [newRegulationName, setNewRegulationName] = useState("");

  const [unallocated, setUnallocated] = useState([]);
  const [unallocatedValue, setUnallocatedValue] = useState("");

  const [validationMessage, setValidationMessage] = useState(null);

  function setState(e, state) {
    state(e.target.value);
  }

  function setStateNumber(e, state) {
    if (e.target.value.match(/^[0-9_]+$|^$/) != null) {
      state(e.target.value);
    }
  }

  function setRegulationsState(e) {
    setSelectedOption(e.target.value);
    setEditedRegulation(e.target.value);
  }

  async function editAndSetRegulation() {
    const index = [...newRegulations].findIndex((item) => {
      return item.description === selectedOption;
    });

    if (index !== -1) {
      // set the description value of the item to state value
      [...newRegulations][index].description = editedRegulation;
      // set the value of the item to concat of label and state value
      [...newRegulations][index].value = `${
        [...newRegulations][index].label
      }: ${editedRegulation}`;

      setNewRegulations([...newRegulations]);
    }
  }

  function setValidationState() {
    const reg = newRegulations.filter((item) => {
      return item.label.includes(newRegulationNumber);
    });

    newRegulationNumber !== "" && reg.length > 0
      ? setValidationMessage(
          "This Regulation Number already exists, please update the below value."
        )
      : setValidationMessage(null);
  }

  async function createRegulation() {
    const newRegulation = {
      label: `Reg ${newRegulationNumber}`,
      description: newRegulationName,
      value: `Reg ${newRegulationNumber}: ${newRegulationName}`,
      column_name: unallocatedValue,
    };

    const regulationExists = newRegulations.find(
      (item) => item.column_name === newRegulation.column_name
    );

    if (regulationExists) {
      setNewRegulations([...newRegulations]);
    } else {
      setNewRegulations([...newRegulations, newRegulation]);
    }
  }

  function setUnallocatedArray() {
    let arrayLength = 20;

    const alreadyAllocatedValues = regulations.filter((item) =>
      item.column_name.includes("custom_reg")
    );

    const customFieldArray = Array.from({ length: arrayLength }).map(
      (item, index) => ({
        name: `Custom Field Regulation ${index + 1}`,
        value: `custom_reg${index + 1}`,
      })
    );

    const newUnallocatedArray = customFieldArray.filter((item1) => {
      return !alreadyAllocatedValues.find((item2) => {
        return item2.column_name === item1.value;
      });
    });

    setUnallocated(newUnallocatedArray);
  }

  async function resetState() {
    setSelectedOption("");
    setNewRegulationNumber("");
    setNewRegulationName("");
    setEditedRegulation("");
    setUnallocatedValue("");
    setValidationMessage(null);
  }

  async function submitForm(submitFunction) {
    await submitFunction();
  }

  useEffect(() => {
    validationMessage ? "" : resetState();
    setUnallocatedArray();
  }, [regulations, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label className="ds_label" htmlFor="regulations">
            Regulations Contravened
          </label>
          <div className="ds_select-wrapper">
            <select
              name="regulations"
              id="regulations"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => setRegulationsState(e)}
            >
              <option value="">Select</option>
              {regulations
                ? regulations.map((item, index) => {
                    let label = item.value;
                    if (item.column_name.startsWith("custom_reg")) {
                      const customRegulationNumber =
                        item.column_name.match(/custom_reg(\d)+/)[1];
                      label = `${item.value} (Custom Regulation Field ${customRegulationNumber})`;
                    }

                    return (
                      <option key={index} value={item.description}>
                        {label}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Value">Add New Value</option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {selectedOption !== "" && selectedOption !== "Add New Value" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="regulation">
              Edit Value Name
            </label>
            <input
              type="text"
              id="regulation"
              className="ds_input"
              value={editedRegulation}
              onChange={(e) => setState(e, setEditedRegulation)}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Value" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newRegulationNumber">
              Select Unallocated value
            </label>
            <div className="ds_select-wrapper">
              <select
                name="unallocated"
                id="unallocated"
                className="ds_select"
                value={unallocatedValue}
                onChange={(e) => setState(e, setUnallocatedValue)}
              >
                <option value="">Select</option>
                {unallocated
                  ? unallocated.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Value" && unallocatedValue !== "" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newRegulationNumber">
              New Regulation Number
            </label>

            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}
              <input
                type="text"
                id="newRegulationNumber"
                className="ds_input"
                value={newRegulationNumber}
                onChange={(e) => setStateNumber(e, setNewRegulationNumber)}
                onBlur={() => setValidationState()}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption === "Add New Value" &&
      unallocatedValue !== "" &&
      newRegulationNumber !== "" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="newRegulation">
              New Regulation Name
            </label>
            <input
              type="text"
              id="newRegulation"
              className="ds_input"
              value={newRegulationName}
              onChange={(e) => setState(e, setNewRegulationName)}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editedRegulation !== "" && editedRegulation !== "Add New Value" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <button onClick={() => submitForm(editAndSetRegulation)}>
                Update
              </button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {selectedOption === "Add New Value" &&
      newRegulationNumber !== "" &&
      newRegulationName !== "" &&
      validationMessage === null ? (
        <Row className="root_cause">
          <Col sm={6}>
            <div className="button_container">
              <button onClick={() => submitForm(createRegulation)}>Save</button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default Regulations;
