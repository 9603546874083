import React from "react";

const Accessibility = () => {
  return (
    <>
      <p>
        The system is not used by the general public and as such is designed to
        meet the business needs of the users. There are a number of areas of the
        system that utilise small fonts to facilitate the display of large
        volumes of information.
      </p>
      <p>The system is designed to be compliant with WCAG 2.1 AA.</p>

      <h2>What to do if you cannot access parts of this website.</h2>

      <p>
        If you need to report an accessibility issue with this system, please
        contact us via the contact-us page on our main web site{" "}
        <a
          className="ds_link"
          href="https://dwqr.scot/contact-us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          DWQR-Contact-Us
        </a>
      </p>
    </>
  );
};

export default Accessibility;
