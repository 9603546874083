const InputElement = ({
  setValidationMessage,
  inputTitle,
  inputAttribute,
  inputType,
  inputState,
  setInputState,
  checkFormat,
  disableElement,
  placeholder,
  displayLabel,
}) => {
  return (
    <>
      <label
        className={`ds_label ov_label ${displayLabel ? "" : "remove"}`}
        htmlFor={inputAttribute}
      >
        {inputTitle}
      </label>
      <div className="ds_select-wrapper">
        <input
          name={inputAttribute}
          id={inputAttribute}
          type={inputType}
          className="ds_select"
          value={inputState}
          onBlur={(e) =>
            checkFormat
              ? checkFormat(e, inputState, setInputState, setValidationMessage)
              : ""
          }
          placeholder={placeholder}
          onChange={(e) => setInputState(e.target.value)}
          disabled={disableElement}
        />
      </div>
    </>
  );
};

export default InputElement;
