import { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "../../components/Account";
import { CallBackend } from "../../utils/apiCall";
import Assessors from "./Assessors";
import Regulations from "./Regulations";
import Areas from "./Areas";
import Email from "./Email";
import { ParameterContext } from "../ParametersProvider";

const Form = ({ data, submittedAssessor, setSubmittedAssessor }) => {
  const { refreshUser } = useContext(AccountContext);
  const { getParameters } = useContext(ParameterContext);

  // This state used in assessor dropdown
  const [assessors, setAssessors] = useState(data.assessors);
  const [areas, setAreas] = useState(data.areas);
  const [regulations, setRegulations] = useState(data.regulations_contravened);
  const [newSWRegEmail, setNewSWRegEmail] = useState(
    data.scottish_water_group_email
  );
  const [newSWEIEmail, setNewSWEIEmail] = useState(
    data.scottish_water_ei_email
  );
  // This state is used to be sent in the body
  const [newAssessors, setNewAssessors] = useState(data.assessors);
  const [newAreas, setNewAreas] = useState(data.areas);
  const [newRegulations, setNewRegulations] = useState(
    data.regulations_contravened
  );

  const [error, setError] = useState(null);

  async function handleSubmit() {
    const body = {
      assessors: newAssessors,
      areas: newAreas,
      hazards: data.hazards,
      regulations_contravened: newRegulations,
      failure_elements: data.failure_elements,
      root_causes: data.root_causes,
      scottish_water_group_email: newSWRegEmail,
      scottish_water_ei_email: newSWEIEmail,
      second_factors: data.second_factors,
    };

    await refreshUser().then((result) => {
      CallBackend(body, "params", "PUT", result)
        .then(() => {
          setSubmittedAssessor(!submittedAssessor);
          if (error) {
            setError(null);
          }
          getParameters();
        })
        .catch((error) => {
          setError(error);
        });
    });
  }

  useEffect(() => {
    setAssessors(data.assessors);
    setRegulations(data.regulations_contravened);
    setAreas(data.areas);
    setNewSWRegEmail(data.scottish_water_group_email);
    setNewSWEIEmail(data.scottish_water_ei_email);
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {error && (
        <Row>
          <Col sm={6}>
            <div className="regionArea">
              <div className="assetHeader">
                <h1>Error saving changes.</h1>
              </div>
              <div className="regionBody">
                There was an error when saving your changes.
              </div>
            </div>
          </Col>
        </Row>
      )}
      {assessors ? (
        <Assessors
          assessors={assessors}
          setNewAssessors={setNewAssessors}
          areas={areas}
          data={data}
        />
      ) : (
        ""
      )}
      {areas ? (
        <Areas
          areas={areas}
          newAreas={newAreas}
          setNewAreas={setNewAreas}
          assessors={assessors}
          data={data}
        />
      ) : (
        ""
      )}
      {regulations ? (
        <Regulations
          regulations={regulations}
          newRegulations={newRegulations}
          setNewRegulations={setNewRegulations}
          data={data}
        />
      ) : (
        ""
      )}
      {newSWRegEmail ? (
        <Email
          name="Scottish Water Regulatory Email"
          email={newSWRegEmail}
          data={data}
          id="swRegEmail"
        />
      ) : (
        ""
      )}

      {newSWEIEmail ? (
        <Email
          name="Scottish Water E&I Email"
          email={newSWEIEmail}
          data={data}
          id="swEIEmail"
        />
      ) : (
        ""
      )}
    </form>
  );
};

export default Form;
