import { useEffect, useState, useContext } from "react";
import Loader from "../components/Loader";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AccountContext } from "../components/Account";
import { CallBackend } from "../utils/apiCall";
import moment from "moment";
import RecentActivity from "../components/RecentActivity";

// Component to Display the E&I Overview
const EventOverview = () => {
  const currentParams = useParams();

  const { refreshUser } = useContext(AccountContext);

  const [data, setData] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);

  const [recentData, setRecentData] = useState(null);
  const [recentSpinner, setRecentSpinner] = useState(false);
  const [recentError, setRecentError] = useState(null);

  useEffect(() => {
    getData();
    getRecentData();
  }, [currentParams.id]);

  async function getData() {
    setSpinner(true);
    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    const body = {};

    await refreshUser().then((result) => {
      CallBackend(
        body,
        `event_overview/${currentParams.id}`,
        "GET",
        result,
        additionalParams
      )
        .then((response) => {
          setSpinner(false);
          setData(response[0].Data[0]);
          setError(null);
        })
        .catch((error) => {
          console.error(error.response);
          setSpinner(false);
          setError(error);
        });
    });
  }

  async function getRecentData() {
    setRecentSpinner(true);
    const additionalParams = {
      headers: {},
      queryParams: {},
    };

    const body = {};

    await refreshUser().then((result) => {
      CallBackend(
        body,
        `recent_event_activity/${currentParams.id}`,
        "GET",
        result,
        additionalParams
      )
        .then((response) => {
          setRecentSpinner(false);
          setRecentData(response);
          setError(null);
        })
        .catch((error) => {
          console.error(error.response);
          setRecentSpinner(false);
          setRecentError(error);
        });
    });
  }

  return (
    <div className="page_wrapper">
      {data === null && error && error.response !== undefined && (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            Error connecting to Event {currentParams.id} Overview
          </div>

          <div className="regionBody">
            <p>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </p>
          </div>
        </div>
      )}

      {error === null && (
        <div
          className="regionArea"
          aria-label={`Event ${currentParams.id} Overview`}
        >
          <div className="regionHeader">
            <h1>Event {currentParams.id} Overview</h1>
          </div>
          {spinner ? <Loader height="400px" /> : ""}
          {data && !spinner && (
            <div className="regionBody">
              <Row>
                <Col>
                  <label className="ds_label ov_label" htmlFor="eventsite">
                    Site Name
                  </label>

                  <input
                    className="ov_input"
                    id="eventsite"
                    type="text"
                    value={data.site_name.toUpperCase()}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <label className="ds_label ov_label" htmlFor="eventarea">
                    Area
                  </label>

                  <input
                    className="ov_input"
                    id="eventarea"
                    type="text"
                    value={data.area}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="ds_label ov_label" htmlFor="eventno">
                    Event No.
                  </label>

                  <input
                    className="ov_input"
                    id="eventno"
                    type="text"
                    value={data.event_number}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <label className="ds_label ov_label" htmlFor="dwqassignee">
                    DWQR Assignee
                  </label>

                  <input
                    className="ov_input"
                    id="dwqassignee"
                    type="text"
                    value={data.assessor}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="ds_label ov_label" htmlFor="doevent">
                    Date of Event
                  </label>

                  <input
                    className="ov_input"
                    id="doevent"
                    type="text"
                    value={moment(data.date_of_event).format("DD/MM/YY")}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <label className="ds_label ov_label" htmlFor="lastupdate">
                    Date of Last Update
                  </label>

                  <input
                    className="ov_input"
                    id="lastupdate"
                    type="text"
                    value={moment(data.event_update_date).format("DD/MM/YY")}
                    disabled={true}
                  />
                </Col>
              </Row>
              {data.date_closed && (
                <Row>
                  <Col>
                    <label className="ds_label ov_label" htmlFor="dateclosed">
                      Date Closed
                    </label>

                    <input
                      className="ov_input"
                      id="dateclosed"
                      type="text"
                      value={moment(data.date_closed).format("DD/MM/YY")}
                      disabled={true}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              )}
            </div>
          )}
        </div>
      )}

      {recentData === null &&
        recentError &&
        recentError.response !== undefined && (
          <div className="regionArea" id="successMessage">
            <div className="assetHeader">
              Error connecting to Recent Activity
            </div>

            <div className="regionBody">
              <p>
                There has been a problem with the system, if this problem
                persists please contact DWQ.
              </p>
            </div>
          </div>
        )}

      {recentSpinner ? <Loader height="400px" /> : ""}
      {recentData && <RecentActivity data={recentData} />}
    </div>
  );
};

export default EventOverview;
