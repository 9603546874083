import jwt_decode from "jwt-decode";
const TOKEN_KEY = 'jwt';

// Once User logged in get the idToken
export const login = (jwt) => {
    //localStorage.setItem(TOKEN_KEY, jwt.idToken.jwtToken);
}

// Application Logout function
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.clear();
    //window.location('/login');
}


// Check if the user still logged in
export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}

export const getUserGroup = () => {
    const getUserGroup = jwt_decode(localStorage.getItem('jwt'));
    const currentUserGroup = getUserGroup['cognito:groups'][0];
    console.log(currentUserGroup);
    return currentUserGroup
}