import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AccountContext } from "../Account";
import { NotificationContext } from "../NotificationsProvider";
import { logout } from "../../utils";

const MainNavigation = () => {
  const { getUserGroups, userGroup, permissions } = useContext(AccountContext);
  const { notificationSettings, getDefaultNotificationSettings } =
    useContext(NotificationContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [showAdministration, setShowAdministration] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  function showNavItems() {
    const urlString = window.location.href.split("/").pop();
    const allNotifications = ["notifications-hub", "notifications-settings"];
    const allUsers = ["password-management"];
    const dwqAdmin = [
      "list-users",
      "audit-trail",
      "hazard-manager",
      "classification-manager",
    ];
    const laUsers = [
      "supply-samples-help",
      "supply-details-help",
      "pws-zone-help",
      "la-codes-help",
      "parameter-code-help",
    ];
    if (userGroup) {
      allUsers.includes(urlString) ? setShowAdministration(true) : "";
      allNotifications.includes(urlString) ? setShowNotifications(true) : "";
    }
    if (userGroup === "DWQAdminPoolGroup") {
      dwqAdmin.includes(urlString) ? setShowAdministration(true) : "";
    }
    if (
      permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      )
    ) {
      laUsers.includes(urlString) ? setShowHelp(true) : "";
    }
  }

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
    notificationSettings === null ? getDefaultNotificationSettings() : "";
    showNavItems();
  }, [userGroup]);

  return (
    <>
      {permissions.dwqPermissions.includes(userGroup) ? (
        <nav
          id="dwq-responsive-main-menu"
          aria-label="dwq-responsive-main-menu"
        >
          <p>Main Navigation</p>
          <ul>
            <li>
              <NavLink to={"/home"} activeClassName="ds_current">
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/eventincidents"} activeClassName="ds_current">
                <span>Event &amp; Incidents</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/upload-document/"} activeClassName="ds_current">
                <span>Data Submissions</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/shape-files"} activeClassName="ds_current">
                <span>Files</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/assetcase"} activeClassName="ds_current">
                <span>Asset Case</span>
              </NavLink>
            </li>
            {notificationSettings && (
              <li>
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                >
                  <span>Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul id="dwq_notifications">
                    <li>
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}

            <li>
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={
                  userGroup === "DWQAdminPoolGroup"
                    ? "/admin/audit-trail"
                    : "/admin/password-management"
                }
                activeClassName="ds_current"
              >
                <span>System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul id="dwq_system_management">
                  {userGroup === "DWQAdminPoolGroup" ? (
                    <>
                      <li>
                        <NavLink
                          to={"/admin/audit-trail"}
                          activeClassName="ds_current"
                        >
                          <span>Audit Trail</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/admin/list-users"}
                          activeClassName="ds_current"
                        >
                          <span>User Management</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li>
                    <NavLink
                      to={"/admin/password-management"}
                      activeClassName="ds_current"
                    >
                      <span>Password Management</span>
                    </NavLink>
                  </li>
                  {userGroup === "DWQAdminPoolGroup" ? (
                    <>
                      <li>
                        <NavLink
                          to={"/admin/classification-manager"}
                          activeClassName="ds_current"
                        >
                          <span>Classification Manager</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/admin/hazard-manager"}
                          activeClassName="ds_current"
                        >
                          <span>Hazard Manager</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                ""
              )}
            </li>

            <li>
              <NavLink to={"/login/:type"} onClick={() => logout()}>
                <span>Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        ""
      )}

      {permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        <nav id="LA">
          <ul>
            <li>
              <NavLink to={"/home"} activeClassName="ds_current">
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/upload-document/"} activeClassName="ds_current">
                <span>Data Uploads</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/previous-uploads"} activeClassName="ds_current">
                <span>Previous Uploads</span>
              </NavLink>
            </li>
            {notificationSettings && (
              <li>
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                >
                  <span>Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul id="la_notifications">
                    <li>
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}
            <li>
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={"/admin/password-management"}
              >
                <span>System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul id="dwq_notifications">
                  <li>
                    <NavLink
                      to={"/admin/password-management"}
                      activeClassName="ds_current"
                    >
                      <span>Password Management</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li>
              <NavLink
                onClick={() => setShowHelp(!showHelp)}
                to={"/help-advice/supply-samples-help"}
              >
                <span>Help Advice</span>
              </NavLink>

              {showHelp ? (
                <ul id="help_advice_nav">
                  <li>
                    <NavLink
                      to={"/help-advice/supply-samples-help"}
                      activeClassName="ds_current"
                    >
                      <span>Supply Samples Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/supply-details-help"}
                      activeClassName="ds_current"
                    >
                      <span>Supply Details Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/pws-zone-help"}
                      activeClassName="ds_current"
                    >
                      <span>PWS Zone Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/la-codes-help"}
                      activeClassName="ds_current"
                    >
                      <span>LA Codes Help</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/help-advice/parameter-code-help"}
                      activeClassName="ds_current"
                    >
                      <span>Parameter Code Help</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li>
              <NavLink to={"/login/:type"} onClick={() => logout()}>
                <span>Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        ""
      )}

      {permissions.swPermissions.includes(userGroup) ? (
        <nav id="SW">
          <ul>
            <li>
              <NavLink to={"/home"} activeClassName="ds_current">
                <span>Home</span>
              </NavLink>
            </li>
            {userGroup === "SWEIUserPoolGroup" ? (
              <li>
                <NavLink to={"/upload-document/"} activeClassName="ds_current">
                  <span>E&amp;I Data Uploads</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {userGroup === "SWRegUserPoolGroup" ? (
              <li>
                <NavLink to={"/upload-document/"} activeClassName="ds_current">
                  <span>Regulatory Data Uploads</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {notificationSettings && (
              <li>
                <NavLink
                  onClick={() => setShowNotifications(!showNotifications)}
                  to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                >
                  <span>Notifications</span>
                </NavLink>

                {showNotifications ? (
                  <ul id="sw_notifications">
                    <li>
                      <NavLink
                        to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Hub</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/notifications-settings"}
                        activeClassName="ds_current"
                      >
                        <span>Notifications Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            )}
            <li>
              <NavLink
                onClick={() => setShowAdministration(!showAdministration)}
                to={"/admin/password-management"}
              >
                <span>System Management</span>
              </NavLink>

              {showAdministration ? (
                <ul id="dwq_notifications">
                  <li>
                    <NavLink
                      to={"/admin/password-management"}
                      activeClassName="ds_current"
                    >
                      <span>Password Management</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li>
              <NavLink to={"/login/:type"} onClick={() => logout()}>
                <span>Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        ""
      )}
    </>
  );
};

export default MainNavigation;
