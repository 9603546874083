import { useState, React, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CallBackendAssessment, CallBackend } from "../../utils/apiCall.js";
import { Row, Col } from "react-bootstrap";
import { AccountContext } from "../../components/Account";
import { ParameterContext } from "../../components/ParametersProvider.js";
import { allGroups } from "../help/all_groups_data";
import { isOktaUser, checkStringValueExists } from "../../utils/helpers.js";
import InputElement from "../../components/InputElement.js";
import ValidationContainer from "../../components/ValidationContainer.js";
import SelectElement from "../../components/SelectElement.js";
import Prompt from "./Prompt.js";
import Alert from "../../components/Alert.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function User() {
  const history = useHistory();
  const { refreshUser, getUserName } = useContext(AccountContext);
  const { parameters } = useContext(ParameterContext);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [communication_email, setCommunicationEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [updateFullname, setUpdateFullname] = useState("");
  const [group, setGroup] = useState("");
  const [foundGroup, setFoundGroup] = useState(false);
  const [response, setResponse] = useState(null);
  const currentParams = useParams();

  // field validation messages
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);
  const [
    communicationEmailValidationMessage,
    setCommunicationEmailValidationMessage,
  ] = useState(null);
  const [fullnameValidationMessage, setFullnameValidationMessage] =
    useState(null);
  const [groupValidationMessage, setGroupValidationMessage] = useState("");

  // Success Message
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [deleteUserPrompt, setDeleteUserPrompt] = useState(false);

  function checkValidation() {
    if (updateFullname === "") {
      setFullnameValidationMessage("Please provide the users fullname.");
    }
    if (updateEmail === "") {
      setEmailValidationMessage("Please provide the users email address.");
    }
    if (communication_email === "") {
      setCommunicationEmailValidationMessage(
        "Please provide the users communication email address."
      );
    }
    if (!foundGroup || group === "") {
      setGroupValidationMessage(
        "Please provide the group the user should be assigned to."
      );
    }
  }

  function findGroup() {
    return allGroups.find((item) => {
      if (item.value === group) {
        setFoundGroup(true);
      }
    });
  }

  //add new user apicall
  async function handleNewUser(e) {
    e.preventDefault();
    checkValidation();
    const userExist = checkStringValueExists(
      response,
      "email",
      updateEmail,
      undefined,
      setEmailValidationMessage,
      "This email already exists!"
    );

    if (
      updateFullname &&
      updateEmail &&
      communication_email &&
      group &&
      !userExist
    ) {
      const body = {
        username: updateEmail.toLowerCase(),
        communication_email: communication_email.toLowerCase(),
        full_name: updateFullname,
        group: group,
      };

      await refreshUser().then((result) => {
        CallBackendAssessment(body, "cognito_user", "POST", result)
          .then((response) => {
            if (response.status === 200) {
              setSuccessMessage(true);
              setErrorMessage(false);
              setCommunicationEmailValidationMessage(null);
              setEmailValidationMessage(null);
              setSuccess({
                heading: "User Successfully Created",
                message: response.data,
              });
              setFoundGroup(false);
            }
          })
          .catch(() => {
            setSuccessMessage(false);
            setErrorMessage(true);
            setError({
              heading: "User not created",
              message:
                "Unfortunately your user has not been created due to an error in the system.  Please check to see if this user already exists.",
            });
          });
      });
    }
  }

  async function handleUserUpdate(e) {
    e.preventDefault();
    checkValidation();
    const userExist = checkStringValueExists(
      response,
      "email",
      updateEmail,
      email,
      setEmailValidationMessage,
      "This email already exists!"
    );
    const checkEmail = checkStringValueExists(
      parameters.assessors,
      "email",
      email,
      updateEmail,
      setEmailValidationMessage,
      "Please remove this user as an assessor before updating their email address."
    );
    const checkName = checkStringValueExists(
      parameters.assessors,
      "name",
      fullname,
      updateFullname,
      setFullnameValidationMessage,
      "Please remove this user as an assessor before updating their name."
    );

    if (
      updateFullname !== "" &&
      updateEmail !== "" &&
      communication_email !== "" &&
      group !== "" &&
      foundGroup &&
      !checkEmail &&
      !checkName &&
      !userExist
    ) {
      const payload = {
        body: [
          {
            Name: "custom:full_name",
            Value: updateFullname,
          },
          {
            Name: "email",
            Value: updateEmail,
          },
          {
            Name: "custom:communication_email",
            Value: communication_email,
          },
        ],
        group: group,
      };

      const additionalParams = {
        queryParams: { username: currentParams.id },
      };
      await refreshUser().then((result) => {
        CallBackendAssessment(
          payload,
          "cognito_user",
          "PUT",
          result,
          additionalParams
        )
          .then((response) => {
            if (response.status === 200) {
              setSuccessMessage(true);
              setSuccess({
                heading: "User Updated Successfully",
                message: response.data,
              });
              setCommunicationEmailValidationMessage(null);
            }
          })
          .catch(() => {
            setSuccessMessage(false);
            setErrorMessage(true);
            setError({
              heading: "Error updating user",
              message:
                "Unfortunately your user has not been updated successfully due to an error in the system.  Please try again.",
            });
          });
      });
    }
  }

  async function getData() {
    if (currentParams.id) {
      let additionalParams = {
        headers: {},
        queryParams: { user_id: currentParams.id },
      };

      await refreshUser().then((result) => {
        CallBackend({}, `cognito_user`, "GET", result, additionalParams)
          .then((response) => {
            setResponse(response["users"]);
            response["users"]
              ? response["users"].filter((item) => {
                  if (item.username == currentParams.id) {
                    setData(item);
                    setEmail(item.email);
                    setUpdateEmail(item.email);
                    if (item.communication_email) {
                      setCommunicationEmail(item.communication_email);
                    }
                    if (item.group) {
                      setGroup(item.group);
                    }
                    if (item.full_name) {
                      setFullname(item.full_name);
                      setUpdateFullname(item.full_name);
                    }
                    findGroup();
                  } else {
                    const user = response.users.find(
                      (item) => item.username === currentParams.id
                    );
                    if (user === undefined) {
                      setErrorMessage(true);
                      setError({
                        heading: "User does not exist",
                        message:
                          "No user was returned, this user does not exist.",
                      });
                    }
                  }
                })
              : null;
          })
          .catch(() => {
            setSuccessMessage(false);
            setErrorMessage(true);
            setError({
              heading: "Error getting user",
              message:
                "Sorry, there was an error getting the user, please try again.",
            });
          });
      });
    }
  }

  async function getUserData() {
    await refreshUser().then((result) => {
      CallBackend({}, `cognito_user`, "GET", result)
        .then((response) => {
          setResponse(response["users"]);
        })
        .catch(() => {
          setSuccessMessage(false);
          setErrorMessage(true);
          setError({
            heading: "Error getting users",
            message:
              "Sorry, there was an error getting users, please try again or refresh the page.",
          });
        });
    });
  }

  async function handleDelete(e) {
    e.preventDefault();
    let isAssessor = parameters.assessors.find((item) => {
      return item.email.toLowerCase() === email.toLowerCase();
    });

    await getUserName().then((result) => {
      if (result === email) {
        setDeleteUserPrompt(false);
        setErrorMessage(true);
        setError({
          heading: "Warning",
          message: "You cannot delete your own account.",
        });
      } else if (isAssessor) {
        setDeleteUserPrompt(false);
        setErrorMessage(true);
        setError({
          heading: "Cannot delete user!",
          message:
            "This user cannot be deleted as they are an assessor, please remove them from the assessor parameters before deleting.",
        });
      } else {
        let additionalParams = {
          queryParams: { username: currentParams.id },
        };

        refreshUser().then((result) => {
          CallBackendAssessment(
            {},
            "cognito_user",
            "DELETE",
            result,
            additionalParams
          )
            .then((response) => {
              if (response.status === 200) {
                setSuccessMessage(true);
                setSuccess({
                  heading: "User Deleted",
                  message: "User has been deleted successfully.",
                });
                setDeleteUserPrompt(false);
              }
            })
            .catch(() => {
              setSuccessMessage(false);
              setDeleteUserPrompt(false);
              setErrorMessage(true);
              setError({
                heading: "Error deleting user",
                message:
                  "Unfortunately the user has not been deleted successfully due to an error in the system.  Please try again.",
              });
            });
        });
      }
    });
  }

  function isValidEmail(userEmail) {
    return /\S+@\S+\.\S+/.test(userEmail);
  }

  const emailChange = (e, emailState, setEmailState, setValidationState) => {
    setValidationState(null);
    setEmailState(e.target.value);
    if (!isValidEmail(e.target.value)) {
      setValidationState("Email is invalid");
    } else if (emailState === "") {
      setValidationState("Please provide the users email address.");
    } else {
      setValidationState(null);
      setEmailState(e.target.value);
    }
  };

  const stateChanges = (e, state, setState, setValidationState) => {
    setValidationState(null);
    setState(e.target.value);
    if (state === "") {
      setValidationState("Please provide the users fullname.");
    } else {
      setValidationState(null);
      setState(e.target.value);
    }
  };

  function handleClick() {
    history.push("/admin/list-users");
  }

  useEffect(() => {
    getData();
  }, [currentParams]);

  useEffect(() => {
    getUserData();
  }, [!currentParams]);

  // Should be able to remove this once groups from Okta are added to cognito
  useEffect(() => {
    findGroup();
  }, [group]);

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="regionArea">
            {deleteUserPrompt && (
              <Prompt
                setDeleteUserPrompt={setDeleteUserPrompt}
                handleDelete={handleDelete}
              />
            )}

            {errorMessage && error && (
              <Alert
                type="error"
                style={{
                  backgroundColor: "#07437e",
                  borderColor: "#07437e",
                }}
                icon={<ErrorOutlineIcon />}
                heading={error.heading}
                message={error.message}
              />
            )}

            {successMessage && success && (
              <Alert
                type="success"
                style={{
                  backgroundColor: "#428542",
                  borderColor: "#428542",
                }}
                icon={<CheckCircleIcon />}
                heading={success.heading}
                message={success.message}
              />
            )}

            {successMessage === false && errorMessage === false && (
              <div className="regionHeader">
                <h1>{currentParams.id ? "Update User" : "Add New User"}</h1>
              </div>
            )}

            <div>
              {(currentParams.id && data && !successMessage && !errorMessage) ||
              (!currentParams.id && !successMessage && !errorMessage) ? (
                <div className="regionBody">
                  <Row className="eventincidents_search">
                    {currentParams.id && isOktaUser(data.username) && (
                      <Col xs={12}>
                        <p style={{ fontWeight: "bold" }}>
                          This is an Okta user profile, you will not be able to
                          update the users Email. If required please do so via
                          Okta.
                        </p>
                      </Col>
                    )}
                    <Col xs={12} md={6}>
                      <div className="userFields">
                        <ValidationContainer
                          validationMessage={fullnameValidationMessage}
                        >
                          <InputElement
                            setValidationMessage={setFullnameValidationMessage}
                            inputTitle={"Full Name"}
                            inputAttribute={"fullName"}
                            inputType={"text"}
                            inputState={updateFullname}
                            setInputState={setUpdateFullname}
                            checkFormat={stateChanges}
                            disableElement={false}
                            placeholder=""
                            displayLabel={true}
                          />
                        </ValidationContainer>
                      </div>
                    </Col>
                  </Row>
                  <Row className="eventincidents_search">
                    <Col xs={12} md={6}>
                      <div className="userFields">
                        <ValidationContainer
                          validationMessage={emailValidationMessage}
                        >
                          <InputElement
                            setValidationMessage={setEmailValidationMessage}
                            inputTitle={"Email"}
                            inputAttribute={"email"}
                            inputType={"email"}
                            inputState={updateEmail}
                            setInputState={setUpdateEmail}
                            checkFormat={emailChange}
                            disableElement={
                              currentParams.id
                                ? isOktaUser(data.username)
                                : false
                            }
                            placeholder=""
                            displayLabel={true}
                          />
                        </ValidationContainer>
                      </div>
                    </Col>
                  </Row>
                  <Row className="eventincidents_search">
                    <Col xs={12} md={6}>
                      <div className="userFields">
                        <ValidationContainer
                          validationMessage={
                            communicationEmailValidationMessage
                          }
                        >
                          <InputElement
                            setValidationMessage={
                              setCommunicationEmailValidationMessage
                            }
                            inputTitle={"Communication Email"}
                            inputAttribute={"communicationEmail"}
                            inputType={"email"}
                            inputState={communication_email}
                            setInputState={setCommunicationEmail}
                            checkFormat={emailChange}
                            disableElement={false}
                            placeholder=""
                            displayLabel={true}
                          />
                        </ValidationContainer>
                      </div>
                    </Col>
                  </Row>
                  <Row className="eventincidents_search">
                    <Col xs={12} md={6}>
                      <div className="userFields">
                        <ValidationContainer
                          validationMessage={groupValidationMessage}
                        >
                          <SelectElement
                            setValidationMessage={setGroupValidationMessage}
                            inputTitle={"Role"}
                            inputAttribute={"userType"}
                            inputState={group}
                            setInputState={setGroup}
                            data={allGroups}
                          />
                        </ValidationContainer>
                      </div>
                    </Col>
                  </Row>
                  {!currentParams.id && !successMessage && !errorMessage ? (
                    <Row>
                      <Col xs={12} md={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            className="ds_button--cancel"
                            onClick={handleClick}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="upload_button"
                            type="submit"
                            onClick={handleNewUser}
                          >
                            Add New User
                          </button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} md={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            onClick={() => setDeleteUserPrompt(true)}
                            className="ds_button--cancel"
                            type="button"
                          >
                            Delete User
                          </button>
                          <div>
                            <button
                              className="ds_button--cancel"
                              onClick={handleClick}
                              type="button"
                              style={{ marginRight: "12px" }}
                            >
                              Cancel
                            </button>
                            <button
                              className="upload_button"
                              type="submit"
                              onClick={handleUserUpdate}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              ) : (
                !successMessage &&
                !errorMessage && (
                  <div className="regionBody">
                    <p>Loading User Data .... </p>
                  </div>
                )
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default User;
