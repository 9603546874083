const NotFound = () => {
  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">Not Found</div>
        <div className="regionBody">
          <p>No page was found at this address.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
