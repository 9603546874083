import { useEffect } from "react";
import ReactPaginate from "react-paginate";

const RestPagination = ({ totalPages, pageNumber, setPageNumber }) => {
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  useEffect(() => {}, [totalPages, pageNumber]);

  return (
    <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      pageCount={totalPages}
      onPageChange={changePage}
      containerClassName={"navigationButtons"}
      previousLinkClassName={"previousButton"}
      nextLinkClassName={"nextButton"}
      disabledClassName={"navigationDisabled"}
      activeClassName={"navigationActive"}
      forcePage={pageNumber - 1}
    />
  );
};

export default RestPagination;
