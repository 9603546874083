import { useEffect, useContext, useState } from "react";
import { AccountContext } from "../../components/Account";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const PreviousUploads = () => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [getError, setGetError] = useState(null);
  const BUCKET_NAME = process.env.REACT_APP_BUCKET;
  const { configCredentials, userGroup } = useContext(AccountContext);

  const s3 = new AWS.S3();

  function getAttachedFiles() {
    let files = [];

    if (configCredentials) {
      const params = {
        Bucket: `${BUCKET_NAME}`,
        Prefix: `la_file/${userGroup}/`,
      };

      // Get list of the attached files
      s3.listObjectsV2(params, function (err, data) {
        if (err) throw setGetError(err);

        // for each file use the key in the params to request the metadata for each file
        data.Contents.forEach((item) => {
          const otherParams = {
            Bucket: `${BUCKET_NAME}`,
            Key: `${item.Key}`,
          };

          s3.headObject(otherParams, function (err, data) {
            if (err) throw setGetError(err);
            // If filename in the metadata doesnt exist then dont run
            if (data.Metadata.filename) {
              // Push the file metadata into state.
              files.push(data.Metadata);
              return setAttachedFiles([...new Set(files)]);
              // return setAttachedFiles((current) => [...current, data.Metadata]);
            }
          });
        });
      });
    }
  }

  function downloadFile(filename) {
    const params = {
      Bucket: `${BUCKET_NAME}`,
      Key: `la_file/${userGroup}/${filename}`,
    };

    s3.getObject(params, (err, data) => {
      if (err) throw setDownloadError(err);

      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}`;
      link.click();
    });
  }

  useEffect(() => {
    getAttachedFiles();
  }, [configCredentials, userGroup]);

  return (
    <div className="page_wrapper">
      {getError && (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            <h1>Error retrieving files</h1>
          </div>

          <div className="regionBody">
            <p>There has been an error while attempting to retrieve files.</p>
            <p>
              There has been a problem with the system, if this problem persists
              please contact DWQ.
            </p>
          </div>
        </div>
      )}

      {attachedFiles.length === 0 && getError === null ? (
        <div className="regionArea" id="successMessage">
          <div className="assetHeader">
            <h1>No files for this Local Authority</h1>
          </div>

          <div className="regionBody">
            <p>
              There are currently no files uploaded for this Local Authority.
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>Previous Submissions</h1>
        </div>

        <table className="fileUploadTable">
          <thead>
            <tr>
              <th scope="col">File Name</th>
              <th scope="col">File Type</th>
              <th scope="col">Uploaded By</th>
              <th scope="col">Upload Date</th>
              <th scope="col">Download</th>
            </tr>
          </thead>
          <tbody>
            {attachedFiles &&
              attachedFiles
                .sort((a, b) => (a.date > b.date ? -1 : 1))
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.filename}</td>
                      <td>{item.filetype}</td>
                      <td>{item.username}</td>
                      <td>{item.date}</td>
                      <td onClick={() => downloadFile(item.filename)}>
                        <FileDownloadIcon />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviousUploads;
