import { useEffect } from "react";

const SeverityScore = ({
  severityScore,
  setSeverityScore,
  duration,
  causedSW,
  acceptability,
  population,
}) => {
  useEffect(() => {
    setSeverityScore(duration + causedSW + acceptability + population);
  }, [duration, causedSW, acceptability, population]);

  return (
    <div>
      <label className="ds_label" htmlFor="severityScore">
        Severity Score
      </label>
      <input
        type="text"
        id="severityScore"
        disabled={true}
        className="ds_input"
        value={severityScore}
      />
    </div>
  );
};

export default SeverityScore;
