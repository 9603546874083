import { useEffect, useContext, useState } from "react";
import { AccountContext } from "./Account";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { sortData } from "../utils/helpers";

const BucketFiles = ({
  path,
  BUCKET_NAME,
  setError,
  setErrorMessage,
  dataOrder,
  tableHeadings,
  bucketKeys,
}) => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const { configCredentials, userGroup } = useContext(AccountContext);

  const s3 = new AWS.S3();

  function getAttachedFiles() {
    let files = [];

    if (configCredentials) {
      const params = {
        Bucket: `${BUCKET_NAME}`,
        Prefix: `${path}`,
      };

      // Get list of the attached files
      s3.listObjectsV2(params, function (err, data) {
        if (err)
          throw (
            (setError(err),
            setErrorMessage(
              <>
                <p>
                  There has been an error while attempting to retrieve shape
                  files.
                </p>
                <p>Please refresh and try again.</p>
              </>
            ))
          );

        // for each file use the key in the params to request the metadata for each file
        data.Contents.forEach((item) => {
          const otherParams = {
            Bucket: `${BUCKET_NAME}`,
            Key: `${item.Key}`,
          };

          s3.headObject(otherParams, function (err, data) {
            if (err)
              throw (
                (setError(err),
                setErrorMessage(
                  <>
                    <p>
                      There has been an error while attempting to display shape
                      files.
                    </p>
                    <p>Please refresh and try again.</p>
                  </>
                ))
              );
            // If filename in the metadata doesnt exist then dont run
            if (data.Metadata.filename) {
              // Push the file metadata into state.
              files.push(data.Metadata);
              return setAttachedFiles([...new Set(files)]);
            }
          });
        });
      });
    }
  }

  function downloadFile(filename) {
    const params = {
      Bucket: `${BUCKET_NAME}`,
      Key: `${path}${filename}`,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        throw (
          (setError(err),
          setErrorMessage(
            <>
              <p>
                There has been an error while attempting to download this shape
                file.
              </p>
              <p>Please refresh and try again.</p>
            </>
          ))
        );
      } else {
        setError(null), setErrorMessage(null);
      }

      let blob = new Blob([data.Body], { type: data.ContentType });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filename}`;
      link.click();
    });
  }

  useEffect(() => {
    getAttachedFiles();
  }, [configCredentials, userGroup]);

  return (
    <table className="fileUploadTable">
      <thead>
        <tr>
          {tableHeadings.map((item) => {
            return (
              <th scope="col" key={item}>
                {item}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {attachedFiles &&
          attachedFiles.sort(sortData(dataOrder, bucketKeys[2])).map((item) => {
            return (
              <tr key={item["filename"]}>
                {bucketKeys.map((itemValue) => {
                  return (
                    <>
                      {itemValue !== "download" ? (
                        <td key={item[itemValue]}>{item[itemValue]}</td>
                      ) : (
                        <td
                          key={item[itemValue]}
                          onClick={() => downloadFile(item[bucketKeys[0]])}
                        >
                          <FileDownloadIcon />
                        </td>
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default BucketFiles;
