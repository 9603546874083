import { useEffect, useState } from "react";

const RootCause = ({
  rootCause,
  setRootCause,
  roots,
  failure,
  rootCauses,
  setRootCauses,
  handleStateChange,
}) => {
  useEffect(() => {
    createRootCause();
  }, [failure, rootCause]);

  function createRootCause() {
    const getRootCauses = roots.filter(
      (item) => item.failure_element === failure
    );

    getRootCauses.length === 0
      ? setRootCauses([{ root_cause: "" }])
      : setRootCauses(getRootCauses[0].associated);
  }

  return (
    <div className="root_cause">
      <label className="ds_label" htmlFor="rootCause">
        Root Cause
      </label>
      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          id="rootCause"
          value={rootCause}
          onChange={(e) => handleStateChange(e.target.value, setRootCause)}
        >
          <option value="">Select</option>

          {rootCauses &&
          rootCause !== "" &&
          rootCauses.filter((item) => item.root_cause === rootCause) ? (
            <option value={rootCause}>{rootCause}</option>
          ) : (
            ""
          )}

          {rootCauses &&
            rootCauses[0].root_cause !== "" &&
            rootCauses
              .filter((item) => item.root_cause !== rootCause)
              .map((item, index) => {
                return (
                  <option key={index} value={item.root_cause}>
                    {item.root_cause}
                  </option>
                );
              })}
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </div>
  );
};

export default RootCause;
