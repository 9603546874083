import React, { useContext, useEffect } from "react";
import { AccountContext } from "../components/Account";
import { useLocation } from "react-router-dom";
import DrinkingWaterQuality from "../components/uploads/DrinkingWaterQuality";
import ScottishWater from "../components/uploads/ScottishWater";
import LocalAuthority from "../components/uploads/LocalAuthority";
import SWRegulatory from "../components/uploads/SWRegulatory";
import GroupError from "../components/GroupError";

// Upload Document  Components
const UploadDocument = () => {
  const { getUserGroups, userGroup, permissions } = useContext(AccountContext);

  const location = useLocation();

  useEffect(() => {
    userGroup === null ? getUserGroups() : "";
  }, [userGroup, location]);

  return (
    <div className="page_wrapper todo_upload">
      <form>
        {permissions.dwqPermissions.includes(userGroup) ? (
          <DrinkingWaterQuality
            title="LA Proxy Upload"
            description="Select the local authority and type of data you wish to upload, choose the corresponding file and press the upload button. You will receive a message to clarify the file has been successfully uploaded."
          />
        ) : (
          ""
        )}

        {permissions.laPermissions.find(
          (organisation) => organisation.code === userGroup
        ) ? (
          <LocalAuthority
            title="File Upload"
            description="Select this current years private water source upload and press the upload button. You will receive a message to clarify the file has been succesfully uploaded. If there are any issues with the return we will contact you to inform you and provide a detailed list of errors that need corrected."
            organisation={userGroup}
          />
        ) : (
          ""
        )}

        {userGroup === "SWEIUserPoolGroup" ? (
          <ScottishWater
            title="File Upload E&amp;I"
            description="Select the type of data you wish to upload, choose the corresponding file and press the upload button. You will receive a message to clarify the file has been successfully uploaded. If there are any issues with the return we will contact you to inform you and provide a detailed list of errors that need corrected."
          />
        ) : (
          ""
        )}

        {userGroup === "SWRegUserPoolGroup" ? (
          <SWRegulatory
            title="File Upload Regulatory"
            description="Select the type of data you wish to upload, choose the corresponding file and press the upload button. You will receive a message to clarify the file has been successfully uploaded. If there are any issues with the return we will contact you to inform you and provide a detailed list of errors that need corrected."
          />
        ) : (
          ""
        )}
      </form>

      {permissions.dwqPermissions.includes(userGroup) ||
      permissions.swPermissions.includes(userGroup) ||
      permissions.laPermissions.find(
        (organisation) => organisation.code === userGroup
      ) ? (
        ""
      ) : (
        <GroupError />
      )}
    </div>
  );
};
export default UploadDocument;
