import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../Account";
import { Row } from "react-bootstrap";
import AWS from "aws-sdk";
import Spinner from "../Spinner";
import moment from "moment";
import ValidationContainer from "../ValidationContainer";

const BucketUpload = (props) => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET;

  const { configCredentials, getUserName, activeUser } =
    useContext(AccountContext);

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [validateFileMessage, setValidateFileMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [comments, setComments] = useState("");

  const myBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  async function uploadFile(e, file) {
    e.preventDefault();
    setDisabled(true);

    const prefix = Date.now();
    const newFileName = `${prefix}_${selectedFile.name}`;

    await getUserName().then((result) => {
      const objectData = {
        filename: newFileName,
        filecomments: comments,
        uploadDate: moment().format("DD/MM/YY"),
        username: result,
        filetype: props.fileType,
      };

      const params = {
        Body: file,
        Bucket: `${BUCKET_NAME}/${props.path}`,
        Key: newFileName,
        Metadata: objectData,
      };

      // Pass object to Metadata within params

      if (activeUser) {
        if (configCredentials !== null) {
          return myBucket
            .putObject(params)
            .on("success", (response) => {
              // Update state from here and display success message.
              if (response.httpResponse.statusCode === 200) {
                props.setShowSuccess(true);
                props.setFileOptionValue("");
                setSpinner(false);
                props.setSuccessMessage(props.successMessageValue(newFileName));
                setDisabled(false);
                resetFileInput();
              }
            })
            .send((err) => {
              if (err) {
                console.error(err);
                setSpinner(false);
              }
            });
        }
      }
    });
  }

  const resetFileInput = () => {
    if (inputRef.current !== null) {
      inputRef.current.value = null;
    }
    setSelectedFile(undefined);
  };

  useEffect(() => {
    let isSubscribed = true;

    props.fileType !== "" ? props.setValidationMessage("") : "";
    selectedFile !== undefined ? setValidateFileMessage("") : "";

    return () => {
      isSubscribed = false;
    };
  }, [selectedFile, props.fileType]);

  return (
    <>
      {spinner ? <Spinner /> : ""}

      <Row>
        <div className="upload_file">
          <ValidationContainer validationMessage={validateFileMessage}>
            <div className="upload_file_wrapper">
              <input
                className="uploadInput"
                title="Upload"
                aria-label="Upload"
                id="uploaded-files"
                name="Upload"
                accept={props.fileType !== "shape_files" ? ".csv" : ""}
                aria-describedby="basic-addon1"
                type="file"
                ref={inputRef}
                onChange={handleFileInput}
              />
            </div>
          </ValidationContainer>

          <div className="upload_file_wrapper">
            <button
              disabled={disabled}
              className="upload_button"
              onClick={(e) => {
                // If a file type hasnt been selected and a file hasnt been selected for upload
                props.fileType === "" && selectedFile === undefined
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ),
                    setValidateFileMessage("Please select a file to upload"))
                  : props.fileType !== "" && selectedFile === undefined
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"))
                  : props.fileType === "" && selectedFile !== undefined
                  ? (e.preventDefault(),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ))
                  : uploadFile(e, selectedFile);
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Row>
    </>
  );
};

export default BucketUpload;
