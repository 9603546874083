import { createContext, useContext, useState, useEffect } from "react";
import { CallBackend } from "../utils/apiCall";
import { AccountContext } from "../components/Account";

const ParameterContext = createContext();

const ParametersProvider = (props) => {
  const { refreshUser, configCredentials } = useContext(AccountContext);

  const [parameters, setParameters] = useState(null);
  const [errorParameters, setErrorParameters] = useState(null);

  const getParameters = async () => {
    await refreshUser().then((result) => {
      CallBackend({}, `params`, "GET", result, {})
        .then((response) => {
          setParameters(response);
        })
        .catch((error) => {
          setErrorParameters(error);
        });
    });
  };

  useEffect(() => {
    if (configCredentials) {
      getParameters();
    }
  }, [configCredentials]);

  return (
    <ParameterContext.Provider
      value={{
        parameters,
        errorParameters,
        getParameters,
      }}
    >
      {props.children}
    </ParameterContext.Provider>
  );
};

export { ParametersProvider, ParameterContext };
