import React from "react";
import { Route } from "react-router-dom";
import PageLayout from "../views/footer/PageLayout";

// Footer Route
const FooterRoute = ({
  pageTitle,
  component: Component,
  restricted,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PageLayout
          {...props}
          pageTitle={pageTitle}
          component={<Component />}
        />
      )}
    />
  );
};

export default FooterRoute;
