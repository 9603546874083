import { logout } from "../utils";
import { useHistory } from "react-router-dom";

const GroupError = () => {
  const history = useHistory();

  return (
    <div className="regionArea">
      <div className="regionHeader">Incorrect Group assigned</div>

      <div className="regionBody">
        <h4>Please contact DWQ</h4>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => (logout(), history.push(`#/login/type`))}
            className="ds_button login-btn"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupError;
