import { Col, Row } from "react-bootstrap";
import Alert from "./Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AccessDenied = () => {
  return (
    <div className="page_wrapper">
      <Row>
        <Col>
          <Alert
            type="accessDenied"
            style={{
              backgroundColor: "#07437e",
              borderColor: "#07437e",
            }}
            icon={<ErrorOutlineIcon />}
            heading="Access Denied"
            message={
              <>
                <p>
                  You do not have the required permissions to view this page.
                </p>
                <p>
                  Please contact DWQ to update your permissions if you should
                  have access to this page.
                </p>
              </>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default AccessDenied;
