import { useState } from "react";
import moment from "moment";
import Pagination from "./Pagination";

const RecentActivity = ({ data }) => {
  const [itemsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [numberOfRecordsVisited, setNumberOfRecordsVisited] = useState(
    page * itemsPerPage
  );

  function formatValue(str) {
    return str.replace(/\[CR\]/g, " ");
  }

  return (
    <>
      <div className="regionArea" aria-label="Activity Log">
        <div className="regionHeader">
          <h2>Activity Log</h2>
        </div>

        <table className="recent_activity_table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
              <th scope="col">Username</th>
            </tr>
          </thead>
          <tbody>
            {data
              .slice(
                numberOfRecordsVisited,
                numberOfRecordsVisited + itemsPerPage
              )
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {moment(parseInt(item.date) * 1000).format(
                        "HH:mm a DD/MM/YY"
                      )}
                    </td>
                    <td>{formatValue(item.event_notification)}</td>
                    <td>{item.username ? item.username : "Unknown"}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <Pagination
        itemsPerPage={15}
        data={data.length}
        page={page}
        setPage={setPage}
        setNumberOfRecordsVisited={setNumberOfRecordsVisited}
      />
    </>
  );
};

export default RecentActivity;
