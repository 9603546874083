import { useState } from "react";
import { parameterHelpCodes } from "./parameter_codes";
import SearchIcon from "../../assets/img/search.svg";
import Pagination from "../../components/Pagination";

const ParameterCodeHelp = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(150);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfRecordsVisited, setNumberOfRecordsVisited] = useState(
    pageNumber * itemsPerPage
  );

  function resetAll(e) {
    e.preventDefault();
    setSearchField("");
    setSearchQuery("");
    setPageNumber(0);
  }

  function getSearchInput(e) {
    return setSearchQuery(e.target.value);
  }

  function handleSearch() {
    return setSearchField(searchQuery), setPageNumber(0);
  }

  const data = parameterHelpCodes.filter((item) => {
    return searchField == ""
      ? parameterHelpCodes
      : item.Name.toLowerCase().includes(searchField.toLowerCase());
  });

  return (
    <div className="page_wrapper">
      <div className="regionArea">
        <div className="regionHeader">
          <h1>Parameter Code Lookup</h1>
        </div>
        <div className="regionBody">
          <p>
            Below are a list of all codes for parameters, use the search bar to
            lookup a parameter by name. If you require further assistance please
            email{" "}
            <a href="mailto:regulator@dwqr.scot" className="ds_link">
              regulator@dwqr.scot
            </a>
          </p>
          <label
            className="ov_label"
            htmlFor="search"
            style={{ display: "none" }}
          >
            Search
          </label>
          <div className="ds_select-wrapper">
            <input
              className="ds_input"
              type="text"
              name="search"
              id="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => getSearchInput(e)}
              onKeyDown={(e) => (e.key === "Enter" ? handleSearch(e) : "")}
            />
            <button
              className="ds_select-search"
              onClick={() => handleSearch()}
              aria-label="Search"
            >
              <img className="ds_searchIcon" src={SearchIcon} alt="Search" />
            </button>

            <span className="visually-hidden">Search</span>
          </div>

          <a href="#" onClick={(e) => resetAll(e)} className="ds_link">
            Reset search filters
          </a>
        </div>

        <div className="regionArea">
          <table className="la_parameter_codes">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Unit</th>
                <th scope="col">MB/ I/C*</th>
                <th scope="col">PCV</th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(
                  numberOfRecordsVisited,
                  numberOfRecordsVisited + itemsPerPage
                )
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.Code}</td>
                      <td>{item.Name}</td>
                      <td>{item.Unit}</td>
                      <td>{item.Mbic}</td>
                      <td>{item.PCV}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {data && (
        <Pagination
          itemsPerPage={itemsPerPage}
          data={data.length}
          page={pageNumber}
          setPage={setPageNumber}
          setNumberOfRecordsVisited={setNumberOfRecordsVisited}
          numberOfRecordsVisited={numberOfRecordsVisited}
        />
      )}
    </div>
  );
};

export default ParameterCodeHelp;
