import { useState, useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import { AccountContext } from "../../components/Account";
import { CallBackend } from "../../utils/apiCall";
import AuditFilters from "../../components/auditTrail-components/AuditFilters";
import AuditTable from "../../components/auditTrail-components/AuditTable";
import Pagination from "./Pagination";

const AuditTrail = () => {
  const { refreshUser } = useContext(AccountContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [organisation, setOrganisation] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const apiQueryParams = {
    organisation: organisation,
    filetype: type,
    status: status,
    from: fromDate,
    to: toDate,
    page: pageNumber,
  };

  async function getData() {
    let apiParams = Object.fromEntries(
      Object.entries(apiQueryParams).filter(
        ([_, value]) => value !== "" && value !== null
      )
    );

    const additionalParams = {
      headers: {},
      queryParams: apiParams,
    };

    await refreshUser().then((result) => {
      CallBackend({}, `audit_trail`, "GET", result, additionalParams)
        .then((response) => {
          setData(response);
          setError(null);
        })
        .catch((err) => {
          setError(err);
        });
    });
  }

  function getNextPage() {
    setPageNumber(pageNumber + 1);
    window.scrollTo(0, 0);
  }

  function getFirstPage() {
    setPageNumber(1);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    getData();
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber == 1) {
      getData();
    } else {
      getFirstPage();
    }
  }, [organisation, type, status, fromDate, toDate]);

  return (
    <div className="page_wrapper">
      {error && (
        <div className="regionArea">
          <div className="assetHeader">
            <h1>Error getting audit data.</h1>
          </div>
          <div className="regionBody">
            <p>There was an error getting the audit data. Please try again.</p>
          </div>
        </div>
      )}

      <div className="regionArea">
        <div className="assetHeader">
          <h1>Audit Trail</h1>
        </div>
        <div className="regionBody">
          <AuditFilters
            organisation={organisation}
            setOrganisation={setOrganisation}
            type={type}
            setType={setType}
            status={status}
            setStatus={setStatus}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
        </div>
      </div>

      <Row className="eventincidents_search">
        <div>{data ? <AuditTable data={data} /> : ""}</div>
      </Row>

      <Row>
        <div>
          {data && (
            <Pagination
              pageNumber={pageNumber}
              getFirstPage={getFirstPage}
              getNextPage={getNextPage}
              data={data}
            />
          )}
        </div>
      </Row>
    </div>
  );
};

export default AuditTrail;
