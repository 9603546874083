import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const Hazards = ({
  hazard,
  setHazard,
  hazards,
  secondHazard,
  setSecondHazard,
  thirdHazard,
  setThirdHazard,
  fourthHazard,
  setFourthHazard,
  fifthHazard,
  setFifthHazard,
  sixthHazard,
  setSixthHazard,
  seventhHazard,
  setSeventhHazard,
  eighthHazard,
  setEighthHazard,
  ninthHazard,
  setNinthHazard,
  tenthHazard,
  setTenthHazard,
  setShowSecondFactor,
  showSecondFactor,
  secondFactor,
  setFailure,
  setRootCause,
  handleStateChange,
}) => {
  // Show above hazards when clicked
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  const [showFourth, setShowFourth] = useState(false);
  const [showFifth, setShowFifth] = useState(false);
  const [showSixth, setShowSixth] = useState(false);
  const [showSeventh, setShowSeventh] = useState(false);
  const [showEighth, setShowEighth] = useState(false);
  const [showNinth, setShowNinth] = useState(false);
  const [showTenth, setShowTenth] = useState(false);

  useEffect(() => {}, [hazards, hazard, secondFactor]);

  function checkHazardExists(value) {
    const getHazard = hazards.filter((item) => item.hazard === value);

    if (getHazard.length === 0) {
      return value;
    }
  }

  function handleFailureRootValue(e, setHazard) {
    handleStateChange(e.target.value, setHazard);
    setFailure("");
    setRootCause("");
  }

  return (
    <div className="root_cause">
      <label className="ds_label" htmlFor="hazard">
        Hazard
      </label>

      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          id="hazard"
          tabIndex="0"
          onChange={(e) => handleFailureRootValue(e, setHazard)}
          value={hazard}
        >
          <option value="">Select</option>

          {/* If the value is not in the hazards list then display at the top
                      of the options */}

          {hazards && checkHazardExists(hazard) ? (
            <option value={hazard}>{hazard}</option>
          ) : (
            ""
          )}

          {hazards
            ? hazards.map((item, index) => {
                return (
                  <option key={index} value={item.hazard}>
                    {item.hazard}
                  </option>
                );
              })
            : ""}
        </select>

        {showSecond === false && secondHazard === "" ? (
          <button className="hazard_button" onClick={() => setShowSecond(true)}>
            Add secondary hazard
          </button>
        ) : (
          ""
        )}

        {showSecondFactor === false && secondFactor === "" ? (
          <button
            onClick={(e) => setShowSecondFactor(true)}
            className="hazard_button"
            type="button"
          >
            Add 2nd Factor
          </button>
        ) : (
          ""
        )}
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>

      {/* Second Hazard */}
      {showSecond || secondHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="secondHazard">
            Second Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="secondHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setSecondHazard)
                }
                value={secondHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(secondHazard) ? (
                  <option value={secondHazard}>{secondHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowSecond(false), setSecondHazard(""))}
            />
          </div>

          {showThird === false && thirdHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowThird(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Third Hazard */}
      {showThird || thirdHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="thirdHazard">
            Third Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="thirdHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setThirdHazard)
                }
                value={thirdHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(thirdHazard) ? (
                  <option value={thirdHazard}>{thirdHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowThird(false), setThirdHazard(""))}
            />
          </div>

          {showFourth === false && fourthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowFourth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Fourth Hazard */}
      {showFourth || fourthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="fourthHazard">
            Fourth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="fourthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setFourthHazard)
                }
                value={fourthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(fourthHazard) ? (
                  <option value={fourthHazard}>{fourthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowFourth(false), setFourthHazard(""))}
            />
          </div>
          {showFifth === false && fifthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowFifth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Fifth Hazard */}
      {showFifth || fifthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="fifthHazard">
            Fifth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="fifthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setFifthHazard)
                }
                value={fifthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(fifthHazard) ? (
                  <option value={fifthHazard}>{fifthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowFifth(false), setFifthHazard(""))}
            />
          </div>
          {showSixth === false && sixthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowSixth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Sixth Hazard */}
      {showSixth || sixthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="sixthHazard">
            Sixth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="sixthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setSixthHazard)
                }
                value={sixthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(sixthHazard) ? (
                  <option value={sixthHazard}>{sixthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>
            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowSixth(false), setSixthHazard(""))}
            />
          </div>

          {showSeventh === false && seventhHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowSeventh(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Seventh Hazard */}
      {showSeventh || seventhHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="seventhHazard">
            Seventh Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="seventhHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setSeventhHazard)
                }
                value={seventhHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(seventhHazard) ? (
                  <option value={seventhHazard}>{seventhHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowSeventh(false), setSeventhHazard(""))}
            />
          </div>

          {showEighth === false && eighthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowEighth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* eighth Hazard */}
      {showEighth || eighthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="eighthHazard">
            Eighth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="eighthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setEighthHazard)
                }
                value={eighthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(eighthHazard) ? (
                  <option value={eighthHazard}>{eighthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowEighth(false), setEighthHazard(""))}
            />
          </div>
          {showNinth === false && ninthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowNinth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* Ninth Hazard */}
      {showNinth || ninthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="ninthHazard">
            Ninth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="ninthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setNinthHazard)
                }
                value={ninthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(ninthHazard) ? (
                  <option value={ninthHazard}>{ninthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>

              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>

            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowNinth(false), setNinthHazard(""))}
            />
          </div>

          {showTenth === false && tenthHazard === "" ? (
            <button
              className="hazard_button"
              onClick={() => setShowTenth(true)}
            >
              Add secondary hazard
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {showTenth || tenthHazard !== "" ? (
        <div className="hazard">
          <label className="ds_label" htmlFor="tenthHazard">
            Tenth Hazard
          </label>
          <div className="hazard_container">
            <div className="ds_select-wrapper">
              <select
                className="ds_select"
                id="tenthHazard"
                // tabindex="0"
                onChange={(e) =>
                  handleStateChange(e.target.value, setTenthHazard)
                }
                value={tenthHazard}
              >
                <option value="">Select</option>

                {hazards && checkHazardExists(tenthHazard) ? (
                  <option value={tenthHazard}>{tenthHazard}</option>
                ) : (
                  ""
                )}

                {hazards
                  ? hazards.map((item, index) => {
                      return (
                        <option key={index} value={item.hazard}>
                          {item.hazard}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <span className="ds_select-arrow" aria-hidden="true"></span>
            </div>
            <DeleteIcon
              className="hazard_delete"
              onClick={() => (setShowTenth(false), setTenthHazard(""))}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Hazards;
