import { useState } from "react";
import moment from "moment";

const DateFilter = ({
  name,
  state,
  setState,
  attributeName,
  messageOne,
  messageTwo,
  fromDateState,
  toDateState,
}) => {
  const [validationMessage, setValidationMessage] = useState(null);

  function handleChange(e, stateSet) {
    setValidationMessage(null);

    if (attributeName === "fromDate") {
      if (e.target.value > moment().format("YYYY-MM-DD")) {
        setValidationMessage(messageOne);
      } else if (e.target.value > toDateState && toDateState !== "") {
        setValidationMessage(messageTwo);
      } else {
        stateSet(e.target.value);
      }
    }

    if (attributeName === "toDate") {
      if (e.target.value < fromDateState) {
        setValidationMessage(messageOne);
      } else {
        stateSet(e.target.value);
      }
    }
  }

  return (
    <div data-module="ds-datepicker-from">
      <label className="ds_label ov_label" htmlFor={attributeName}>
        {name}
      </label>
      <div className="ds_select-wrapper">
        <input
          name={attributeName}
          id={attributeName}
          value={state}
          onChange={(e) => handleChange(e, setState)}
          data-dateformat="dd/mm/yyyy"
          placeholder="dd/mm/yyyy"
          type="date"
          className="ds_select"
        />
        <span className="ds_select-calendar"></span>
      </div>
      {validationMessage && (
        <div style={{ marginTop: "1rem" }}>
          <span style={{ color: "red" }}>
            <strong>{validationMessage}</strong>
          </span>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
