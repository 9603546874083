const RegCheckBox = ({
  name,
  regulation,
  handleStateChange,
  state,
  setState,
}) => {
  return (
    <div className="ds_checkbox  ds_checkbox--small">
      <input
        className="ds_checkbox__input"
        id={regulation}
        type="checkbox"
        checked={state}
        onChange={(e) => handleStateChange(e.target.checked, setState)}
      />
      <label className="ds_checkbox__label" htmlFor={regulation}>
        {name}
      </label>
    </div>
  );
};

export default RegCheckBox;
