import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AccountContext } from "../../components/Account";

const Home = ({ notificationSettings }) => {
  const { userGroup } = useContext(AccountContext);

  return (
    <div className="regionArea">
      <div className="regionHeader">
        <h1>Home</h1>
      </div>

      <div className="regionBody">
        <p>Welcome to the Scottish Water data upload portal.</p>

        <ul className="home">
          {userGroup === "SWEIUserPoolGroup" ? (
            <li>
              <NavLink to={"/upload-document/"}>E&amp;I Data Uploads</NavLink>
            </li>
          ) : (
            ""
          )}
          {userGroup === "SWRegUserPoolGroup" ? (
            <li>
              <NavLink to={"/upload-document/"}>
                Regulatory Data Uploads
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {notificationSettings && (
            <li>
              <NavLink
                to={`/notifications-hub?view-type=&category=${notificationSettings.join()}`}
              >
                Notifications
              </NavLink>
            </li>
          )}
        </ul>

        <p>
          This area displays all system reminders, upload updates and error
          messages from data uploads.
        </p>
      </div>
    </div>
  );
};

export default Home;
