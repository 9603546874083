import { useState } from "react";
import BucketFiles from "../components/BucketFiles";
import Alert from "../components/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ShapeFiles = () => {
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <div className="page_wrapper">
      {error && errorMessage && (
        <Alert
          type="error"
          style={{
            backgroundColor: "#07437e",
            borderColor: "#07437e",
          }}
          icon={<ErrorOutlineIcon />}
          heading="Error retrieving shape files"
          message={errorMessage}
        />
      )}

      <div className="regionArea">
        <div className="regionHeader">
          <h1>Shape Files</h1>
        </div>

        <BucketFiles
          path="gold/shape_files/"
          BUCKET_NAME={process.env.REACT_APP_BUCKET_ATTACHED}
          setError={setError}
          setErrorMessage={setErrorMessage}
          dataOrder={false}
          tableHeadings={[
            "File Name",
            "Uploaded By",
            "Upload Date",
            "Download",
          ]}
          bucketKeys={["filename", "username", "uploaddate", "download"]}
        />
      </div>
    </div>
  );
};

export default ShapeFiles;
