import { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountContext } from "../../components/Account";
import { CallBackend } from "../../utils/apiCall";
import HazardManagement from "./HazardManagement";
import FailureElementManagement from "./FailureElementManagement";
import RootCauseManagement from "./RootCauseManagement";
import SecondFactors from "./SecondFactors";
import { ParameterContext } from "../ParametersProvider";

const Form = ({ data, dataSubmitted, setDataSubmitted }) => {
  const { refreshUser } = useContext(AccountContext);
  const { getParameters } = useContext(ParameterContext);

  // This state used in list dropdown
  const [hazards, setHazards] = useState(data.hazards);
  const [failureElements, setFailureElements] = useState(data.failure_elements);
  const [rootCauses, setRootCauses] = useState(data.root_causes);
  const [secondFactors, setSecondFactors] = useState(data.second_factors);

  // This state is used to be sent in the body
  const [newHazards, setNewHazards] = useState(data.hazards);
  const [newFailureElements, setNewFailureElements] = useState(
    data.failure_elements
  );
  const [newRootCauses, setNewRootCauses] = useState(data.root_causes);
  const [newSecondFactors, setNewSecondFactors] = useState(data.second_factors);
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      assessors: data.assessors,
      areas: data.areas,
      hazards: newHazards,
      regulations_contravened: data.regulations_contravened,
      failure_elements: newFailureElements,
      root_causes: newRootCauses,
      scottish_water_group_email: data.scottish_water_group_email,
      scottish_water_ei_email: data.scottish_water_ei_email,
      second_factors: newSecondFactors,
    };

    await refreshUser().then((result) => {
      CallBackend(body, "params", "PUT", result)
        .then(() => {
          setDataSubmitted(!dataSubmitted);
          if (error) {
            setError(null);
          }
          getParameters();
        })
        .catch((error) => {
          setError(error);
        });
    });
  }

  useEffect(() => {
    setHazards(data.hazards);
    setFailureElements(data.failure_elements);
    setRootCauses(data.root_causes);
    setSecondFactors(data.second_factors);
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      {error && (
        <Row>
          <Col sm={6}>
            <div className="regionArea">
              <div className="assetHeader">
                <h1>Error saving changes.</h1>
              </div>
              <div className="regionBody">
                There was an error when saving your changes.
              </div>
            </div>
          </Col>
        </Row>
      )}

      {data && hazards ? (
        <HazardManagement
          hazards={hazards}
          newHazards={newHazards}
          setNewHazards={setNewHazards}
          failureElements={failureElements}
          data={data}
        />
      ) : (
        ""
      )}

      {data && failureElements ? (
        <FailureElementManagement
          failureElements={failureElements}
          newFailureElements={newFailureElements}
          setNewFailureElements={setNewFailureElements}
          hazards={hazards}
          rootCauses={rootCauses}
          data={data}
        />
      ) : (
        ""
      )}

      {data && rootCauses ? (
        <RootCauseManagement
          failureElements={failureElements}
          setNewFailureElements={setNewFailureElements}
          rootCauses={rootCauses}
          newRootCauses={newRootCauses}
          setNewRootCauses={setNewRootCauses}
          data={data}
        />
      ) : (
        ""
      )}

      {data && secondFactors ? (
        <SecondFactors
          secondFactors={secondFactors}
          newSecondFactors={newSecondFactors}
          setNewSecondFactors={setNewSecondFactors}
          data={data}
        />
      ) : (
        ""
      )}
    </form>
  );
};

export default Form;
