import { useEffect } from "react";

const EventCategory = ({
  eventCategoryLabel,
  severityScore,
  negativeImpact,
  setEventCategoryLabel,
  setEventCategory,
  setDidStateChange,
}) => {
  function getEventCategoryOption(score, impact) {
    if (
      (score <= 37 && impact === "Very Low") ||
      (score <= 19 && impact === "Low") ||
      (score <= 11 && impact === "Medium") ||
      (score <= 11 && impact === "High")
    ) {
      setEventCategoryLabel("Not Significant (0 points)"), setEventCategory(0);
    } else if (
      (score >= 38 && score <= 43 && impact === "Very Low") ||
      (score >= 20 && score <= 37 && impact === "Low") ||
      (score >= 12 && score <= 19 && impact === "Medium") ||
      (score >= 12 && score <= 19 && impact === "High") ||
      (score >= 5 && score <= 11 && impact === "Very High")
    ) {
      setEventCategoryLabel("Minor (0 points)"), setEventCategory(0);
    } else if (
      (score >= 38 && score <= 43 && impact === "Low") ||
      (score >= 20 && score <= 37 && impact === "Medium") ||
      (score >= 20 && score <= 28 && impact === "High") ||
      (score >= 12 && score <= 19 && impact === "Very High")
    ) {
      setEventCategoryLabel("Significant (3 points)"), setEventCategory(3);
    } else if (
      (score >= 38 && score <= 43 && impact === "Medium") ||
      (score >= 29 && score <= 37 && impact === "High") ||
      (score >= 20 && score <= 28 && impact === "Very High")
    ) {
      setEventCategoryLabel("Serious (4 points)"), setEventCategory(4);
    } else if (
      (score >= 38 && score <= 43 && impact === "High") ||
      (score >= 29 && score <= 43 && impact === "Very High")
    ) {
      setEventCategoryLabel("Major (5 points)"), setEventCategory(5);
    } else if (impact === "") {
      setEventCategoryLabel(""), setEventCategory(0);
    }
  }

  function handleEventScore(e) {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll("option")[idx];
    const score = option.getAttribute("data-score");

    setDidStateChange(true);

    setEventCategoryLabel(e.target.value);
    setEventCategory(parseInt(score));
  }

  useEffect(() => {
    getEventCategoryOption(severityScore, negativeImpact);
  }, [severityScore, negativeImpact]);
  return (
    <>
      <label className="ds_label" htmlFor="eventCategory">
        Event Category
      </label>
      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          id="eventCategory"
          value={eventCategoryLabel}
          onChange={(e) => handleEventScore(e)}
        >
          <option value="">Select</option>
          <option value="Not Significant (0 points)" data-score={0}>
            Not Significant (0 points)
          </option>
          <option value="Minor (0 points)" data-score={0}>
            Minor (0 points)
          </option>
          <option value="Significant (3 points)" data-score={3}>
            Significant (3 points)
          </option>
          <option value="Serious (4 points)" data-score={4}>
            Serious (4 points)
          </option>
          <option value="Major (5 points)" data-score={5}>
            Major (5 points)
          </option>
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </>
  );
};

export default EventCategory;
