import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../Account";
import { Row } from "react-bootstrap";
import AWS from "aws-sdk";
import Spinner from "../Spinner";
import moment from "moment";
import ValidationContainer from "../ValidationContainer";

const SWEIBucketUpload = (props) => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET;

  const { configCredentials, getUserName, activeUser } =
    useContext(AccountContext);

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [validateFileMessage, setValidateFileMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const myBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  async function uploadFile(e, file) {
    e.preventDefault();
    setDisabled(true);

    await getUserName().then((result) => {
      // Pass object to Metadata within params

      const objectData = {
        filename: selectedFile.name,
        uploadDate: moment().format("DD/MM/YY"),
        eventnumber: props.eventnumber,
        username: result,
        filetype: props.fileType,
      };

      const params = {
        Body: file,
        Bucket: `${BUCKET_NAME}/${props.path}`,
        Key: selectedFile.name,
        Metadata: objectData,
      };

      if (activeUser) {
        if (configCredentials !== null) {
          return myBucket
            .putObject(params)
            .on("success", (response) => {
              // Update state from here and display success message.
              if (response.httpResponse.statusCode === 200) {
                props.setShowSuccess(true);
                props.setFileOptionValue("");
                props.setEventNo("");
                setSpinner(false);
                props.setSuccessMessage(
                  props.successMessageValue(selectedFile.name)
                );
                setDisabled(false);

                resetFileInput();
              }
            })
            .send((err) => {
              if (err) {
                setSpinner(false);
              }
            });
        }
      }
    });
  }

  const resetFileInput = () => {
    inputRef.current.value = null;
    setSelectedFile(undefined);
  };

  useEffect(() => {
    let isSubscribed = true;
    props.fileType !== "" ? props.setValidationMessage("") : "";
    selectedFile !== undefined ? setValidateFileMessage("") : "";
    props.eventnumber !== "" ? props.setEventValidationMessage("") : "";

    return () => {
      isSubscribed = false;
    };
  }, [selectedFile, props.fileType, props.eventnumber]);

  return (
    <>
      {spinner ? <Spinner /> : ""}

      <Row>
        <div className="upload_file">
          <ValidationContainer validationMessage={validateFileMessage}>
            <div className="upload_file_wrapper">
              <input
                className="uploadInput"
                title="Upload"
                aria-label="Upload"
                id="uploaded-files"
                name="Upload"
                accept=".csv"
                aria-describedby="basic-addon1"
                type="file"
                ref={inputRef}
                onChange={handleFileInput}
              />
            </div>
          </ValidationContainer>

          <div className="upload_file_wrapper">
            <button
              disabled={disabled}
              className="upload_button"
              onClick={(e) => {
                (props.fileType === "event_details" &&
                  selectedFile === undefined &&
                  props.eventnumber === "") ||
                (props.fileType === "event_updates" &&
                  selectedFile === undefined &&
                  props.eventnumber === "")
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"))
                  : (props.fileType === "incident_report" &&
                      selectedFile === undefined &&
                      props.eventnumber === "") ||
                    (props.fileType === "add_info" &&
                      selectedFile === undefined &&
                      props.eventnumber === "")
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"),
                    props.setEventValidationMessage(
                      "Please provide an event number."
                    ))
                  : (props.fileType === "incident_report" &&
                      selectedFile !== undefined &&
                      props.eventnumber === "") ||
                    (props.fileType === "add_info" &&
                      selectedFile !== undefined &&
                      props.eventnumber === "")
                  ? (e.preventDefault(),
                    props.setEventValidationMessage(
                      "Please provide an event number."
                    ))
                  : props.fileType === "" && selectedFile === undefined
                  ? (e.preventDefault(),
                    setValidateFileMessage("Please select a file to upload"),
                    props.setValidationMessage(
                      "Please select a file type from the dropdown."
                    ))
                  : props.fileType === "" &&
                    selectedFile !== undefined &&
                    props.eventnumber !== ""
                  ? props.setValidationMessage(
                      "Please select a file type from the dropdown"
                    )
                  : uploadFile(e, selectedFile);
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Row>
    </>
  );
};

export default SWEIBucketUpload;
