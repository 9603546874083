import { useEffect } from "react";
const SecondFactor = ({
  setSecondFactor,
  secondFactor,
  secondfactors,
  handleStateChange,
}) => {
  useEffect(() => {}, [secondfactors]);

  return (
    <div className="root_cause">
      <label className="ds_label" htmlFor="secondFactor">
        2nd Factor
      </label>
      <div className="ds_select-wrapper">
        <select
          className="ds_select"
          id="secondFactor"
          value={secondFactor}
          onChange={(e) => handleStateChange(e.target.value, setSecondFactor)}
        >
          <option value="">Select</option>

          {secondfactors &&
          secondFactor !== "" &&
          secondfactors.filter(
            (item) => item.second_factor === secondFactor
          ) ? (
            <option value={secondFactor}>{secondFactor}</option>
          ) : (
            ""
          )}

          {secondfactors &&
            secondfactors
              .filter((item) => item.second_factor !== secondFactor)
              .map((item, index) => {
                return (
                  <option key={index} value={item.second_factor}>
                    {item.second_factor}
                  </option>
                );
              })}
        </select>
        <span className="ds_select-arrow" aria-hidden="true"></span>
      </div>
    </div>
  );
};

export default SecondFactor;
