import React from "react";

const About = () => {
  return (
    <p>
      This solution is owned and operated by the Drinking Water Quality
      Regulator for Scotland (DWQR). Further information about the regulator can
      be obtained via the main web site at{" "}
      <a
        className="ds_link"
        href="https://dwqr.scot/about-us/"
        target="_blank"
        rel="noreferrer noopener"
      >
        DWQR-About-Us
      </a>
    </p>
  );
};

export default About;
