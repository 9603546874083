import { useEffect, useContext, useState } from "react";
import { AccountContext } from "../components/Account";
import { NotificationContext } from "../components/NotificationsProvider";
import { useParams, useHistory } from "react-router-dom";
import FilteredNotification from "../components/notifications/FilteredNotification";

const SpecificNotification = () => {
  const history = useHistory();
  const currentParams = useParams();
  const { loggedUser } = useContext(AccountContext);
  const { notifications: data, notificationSettings } =
    useContext(NotificationContext);

  useEffect(() => {}, [loggedUser, data]);

  const handleClick = (e) => {
    e.preventDefault();
    history.push(
      `/notifications-hub?view-type=&category=${notificationSettings.join()}`
    );
  };

  return (
    <div className="page_wrapper">
      <div className="region-wrapper">
        <div className="regionArea">
          <div className="regionHeader">
            <h1>Notification</h1>
          </div>

          <div className="regionBody">
            <a href="" onClick={(e) => handleClick(e)} className="ds_link">
              All Notifications
            </a>
          </div>
        </div>

        {data &&
        data.Data.find((item) => item._id === currentParams.notifID) ? (
          <FilteredNotification
            data={data.Data.filter(
              (item) => item._id === currentParams.notifID
            )}
            currentParamsID={currentParams.notifID}
            loggedUser={loggedUser}
          />
        ) : (
          "No Notification Found"
        )}
      </div>
    </div>
  );
};

export default SpecificNotification;
