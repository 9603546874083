import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CalltoActionButton from "./CalltoActionButton";

const SecondFactors = ({
  secondFactors,
  newSecondFactors,
  setNewSecondFactors,
  data,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [newSecondFactorValue, setNewSecondFactorValue] = useState("");
  const [editSecondFactor, setEditSecondFactor] = useState("");

  const [validationMessage, setValidationMessage] = useState(null);

  function handleSecondFactorState(e) {
    setValidationMessage(null);
    setSelectedOption(e.target.value);
    setEditSecondFactor(e.target.value);
  }

  function createNewSecondFactor(e) {
    setValidationMessage(null);
    setNewSecondFactorValue(e.target.value);
  }

  function addNewSecondFactor() {
    setValidationMessage(null);

    // CHECK ROOT CAUSE LIST FOR ANY DUPLICATES

    const checkSecondFactors = [...newSecondFactors].filter((item) => {
      item.second_factor === newSecondFactorValue;
    });

    // IF FALSE THEN ADD VALUE TO END OF ARRAY AS AN OBJECT AND THEN SORT

    if (checkSecondFactors.length === 0) {
      const updatedSecondFactors = [
        ...newSecondFactors,
        {
          second_factor: newSecondFactorValue,
        },
      ];

      const sortSecondFactors = updatedSecondFactors.sort((a, b) =>
        a.second_factor.toLowerCase() < b.second_factor.toLowerCase() ? -1 : 1
      );

      setNewSecondFactors(sortSecondFactors);
    }
  }

  function setValidationState(value) {
    const hasDuplicate = newSecondFactors.filter((item) => {
      return item.second_factor.toLowerCase() === value.toLowerCase();
    });

    if (selectedOption !== value) {
      value !== "" && hasDuplicate.length > 0
        ? setValidationMessage(
            "This second factor already exists, please update the value."
          )
        : setValidationMessage(null);
    }

    if (selectedOption === "Add New Second Factor") {
      value !== "" && hasDuplicate.length > 0
        ? setValidationMessage(
            "This second factor already exists, please update the below value."
          )
        : setValidationMessage(null);
    }
  }

  function updateEditState(e) {
    setValidationMessage(null);
    setEditSecondFactor(e.target.value);
  }

  async function updateSecondFactor() {
    setValidationMessage(null);
    const index = [...newSecondFactors].findIndex((item) => {
      return item.second_factor === selectedOption;
    });

    if (index !== -1) {
      [...newSecondFactors][index].second_factor = editSecondFactor;
    }
    setNewSecondFactors([...newSecondFactors]);
  }

  function deleteSecondFactor() {
    const filteredSecondFactors = [...secondFactors].filter((item) => {
      return item.second_factor !== selectedOption;
    });
    setNewSecondFactors(filteredSecondFactors);
  }

  function handleKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  function resetState() {
    setSelectedOption("");
    setValidationMessage(null);
    setNewSecondFactorValue("");
    setEditSecondFactor("");
  }

  useEffect(() => {
    resetState();
  }, [newSecondFactors, data]);

  return (
    <>
      <Row className="root_cause">
        <Col sm={6}>
          <label htmlFor="secondFactors" className="ds_label">
            Second Factors
          </label>
          <div className="ds_select-wrapper">
            <select
              name="secondFactors"
              id="secondFactors"
              className="ds_select"
              value={selectedOption}
              onChange={(e) => handleSecondFactorState(e)}
            >
              <option value="">Select</option>
              {secondFactors
                ? secondFactors.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item.second_factor}
                        data-index={index}
                      >
                        {item.second_factor}
                      </option>
                    );
                  })
                : ""}
              <option value="Add New Second Factor">
                Add New Second Factor
              </option>
            </select>
            <span className="ds_select-arrow" aria-hidden="true"></span>
          </div>
        </Col>
      </Row>

      {selectedOption === "Add New Second Factor" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="addSecondFactor">
              Add New Second Factor
            </label>
            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}
              <input
                type="text"
                name="addSecondFactor"
                id="addSecondFactor"
                className="ds_input"
                value={newSecondFactorValue}
                onChange={(e) => createNewSecondFactor(e)}
                onBlur={() => setValidationState(newSecondFactorValue)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {selectedOption !== "" && selectedOption !== "Add New Second Factor" ? (
        <Row className="root_cause">
          <Col sm={6}>
            <label className="ds_label" htmlFor="editSecondFactor">
              Edit Second Factor
            </label>

            <div
              className="validationContainer"
              style={{
                borderLeft:
                  validationMessage !== null ? "6px solid red" : "none",
                paddingLeft: validationMessage !== null ? "6px" : "0px",
              }}
            >
              {validationMessage !== null ? (
                <div className="validation">
                  <span>{validationMessage}</span>
                </div>
              ) : (
                <div></div>
              )}

              <input
                type="text"
                id="editSecondFactor"
                name="editSecondFactor"
                className="ds_input"
                value={editSecondFactor}
                onChange={(e) => updateEditState(e)}
                onBlur={(e) => setValidationState(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="root_cause">
        <Col sm={6}>
          {selectedOption !== "" &&
          selectedOption !== "Add New Second Factor" ? (
            <div className="multiple_button_container">
              {validationMessage === null ? (
                <CalltoActionButton
                  submitFunction={deleteSecondFactor}
                  name="Delete"
                />
              ) : (
                ""
              )}

              {editSecondFactor !== "" &&
              selectedOption !== editSecondFactor &&
              validationMessage === null ? (
                <CalltoActionButton
                  submitFunction={updateSecondFactor}
                  name="Save"
                />
              ) : (
                ""
              )}
            </div>
          ) : selectedOption === "Add New Second Factor" &&
            newSecondFactorValue !== "" &&
            !validationMessage ? (
            <div className="button_container">
              <CalltoActionButton
                submitFunction={addNewSecondFactor}
                name="Save"
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
};

export default SecondFactors;
