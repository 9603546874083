const CheckBox = ({ name, index, state, handleStateChange }) => {
  return (
    <div className="ds_checkbox  ds_checkbox--small">
      <input
        className="ds_checkbox__input"
        id={name}
        type="checkbox"
        checked={state}
        onChange={(e) => handleStateChange(index, e.target.checked)}
      />
      <label className="ds_checkbox__label" htmlFor={name}>
        {name}
      </label>
    </div>
  );
};

export default CheckBox;
