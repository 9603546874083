export const dwqAdminOptions = [
  "Assessment Report Upload",
  "File Status",
  "File Upload",
  "System Reminder",
  "Validation Errors",
];

export const dwqStandardOptions = [
  "Assessment Report Upload",
  "File Status",
  "File Upload",
  "System Reminder",
  "Validation Errors",
];

export const swUserOptions = [
  "File Status",
  "File Upload",
  "System Reminder",
  "Validation Errors",
];

export const laUserOptions = [
  "File Status",
  "File Upload",
  "Validation Errors",
];
